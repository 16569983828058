import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout, ProgressBar } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { ClientTranInfo, AcCodeInfo, ChnlInfo } from './Types'
import { SystemHeader, ClientPicker } from './CommonUI';
import { ClientTranEntryView, ClientTranEntryEdit } from './ClientTranEntry';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface CancelPrevTradeNewProps {
    gsb: AxiosInstance,
    onCancel?: () => void,
}


export function CancelPrevTradeNew({ gsb, onCancel = () => { } }: CancelPrevTradeNewProps) {
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />

    const { t, i18n } = useTranslation();    

    let ProgressId2 = ""

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [HistoryData, setHistoryData] = useState([] as ClientTranInfo[])
    const [currentHistoryRec, setcurrentHistoryRec] = useState({} as ClientTranInfo)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)

    const [ShowWait, setShowWait] = useState(false)
    const [ShowRst, setShowRst] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState([])
    const [isShowAlert3, setisShowAlert3] = useState(false)
    const [ShowAlert3Msg, setShowAlert3Msg] = useState([])

    const [s_ClCode1, sets_ClCode1] = useState("")
    const [s_ClCode2, sets_ClCode2] = useState("")
    const [setClCodeValue, setSetClCodeValue] = useState(() => sets_ClCode1)
    const [isClPickerOpen, setIsClPickerOpen] = useState(false)
    const [s_Date1, sets_Date1] = useState(utils.formatNumber(new Date()))
    const [s_Date2, sets_Date2] = useState(utils.formatNumber(new Date()))


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    
    useEffect(() => {
        setShowRst(false)
        setShowNoRecord(false)
        setShowOK(true)
    }, [s_ClCode1, s_ClCode2, s_Date1, s_Date2 ])

    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            //console.log("Progresslist ProgressId: ", ProgressId2) 
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 1*1000)
        return () => {clearInterval(timer)}
    }, [gsb, ProgressId, nPage])


    const onSearchHistory = () => {
        setShowWait(true)
        setShowOK(false)
        ;

        //console.log("onSearchHistory data", HistoryData);
        (async () => {
            try {
                const res = await gsb.post('/Transaction', 
                    {   sb_data: {
                        ProgressId: ProgressId2,
                        s_ClCode1: s_ClCode1,
                        s_ClCode2: s_ClCode2,
                        s_Date1: s_Date1,
                        s_Date2: s_Date2,
                    },
                    cPgmId: "CancelCorrectTrade",
                    cAction: "HistoryList",
                });
                console.log("onSearchHistory res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRst(true)
                    setHistoryData(res.data.data)
            
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRst(false)
                }
            } catch (error) {
                utils.showError(error)
                setShowWait(false)
                setShowRst(false)
            }
        })()
    }

    const onSelectHistoryRec = (Err_Msg_Rst: String) => {
        console.log("onSelectHistoryRec currentHistoryRec", currentHistoryRec);
        console.log("onSelectHistoryRec Err_Msg_Rst", Err_Msg_Rst);
        setShowWait(true)
        setShowOK(false)
        ;

        (async () => {
            try {
                const res = await gsb.post('/Transaction', 
                    {   sb_data: currentHistoryRec,
                    Err_Msg_Rst: Err_Msg_Rst,
                    cPgmId: "CancelCorrectTrade",
                    cAction: "SelectHistoryRec",
                });
                console.log("onSelectHistoryRec res.data.data: ", res.data.data) 
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                    setisShowAlert1(true)
                    setShowAlert1Msg(res.data.data)
                    onCancel()
                } else {
                    //utils.showWarn(res.data.data)
                    if ( res.data.Err_Msg_type == "3") {
                        setisShowAlert3(true)
                        setShowAlert3Msg(res.data.data)
                    } else {
                        setisShowAlert2(true)
                        setShowAlert2Msg(res.data.data)
                    }
                }
                setShowWait(false)
                setShowRst(false)
                setShowOK(true)
            } catch (error) {
                utils.showError(error)
                setShowWait(false)
                setShowRst(false)
                setShowOK(true)
            }
        })()
    }

    let Historylist = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-TranDate">Tran Date</th>
                <th className="TCol-SettleDate">Settle Date</th>
                <th className="TCol-BSNote">BS Note No</th>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-StkCode">Stock Code</th>
                <th className="TCol-CcassStk">CCASS</th>
                <th className="TCol-StampFlag">Stamp</th>
                <th className="TCol-Qty1">Qty 1</th>
                <th className="TCol-Price1">Price 1</th>
                <th className="TCol-NetAmount">Net Amount</th>
            </thead>
            <tbody id="ma_tr">
                {HistoryData.map((ClientTran, i) =>
                    <tr key={i} onDoubleClick={() => { onSelectHistoryRec("0") }}>
                        <td className="TCol-TranDate" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{utils.dispDate(ClientTran.d_His_Tran_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-SettleDate" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{utils.dispDate(ClientTran.d_MS_settle_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-BSNote" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_Doc_No}</td>
                        <td className="TCol-ACCode" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_acName1}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_StkNo}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_StkCode}</td>
                        <td className="TCol-CcassStk" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_CcassStk}</td>
                        <td className="TCol-StampFlag" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_StampFlag}</td>
                        <td className="TCol-Qty1" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_Trade_Qty1}</td>
                        <td className="TCol-Price1" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{ClientTran.d_Trade_Price1}</td>
                        <td className="TCol-NetAmount" onClick={() => { setcurrentHistoryRec(ClientTran) }}>{utils.formatNumber2(ClientTran.d_Net_Amount)}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && <div>{imgWait}</div> }

    </>    

    return (
        <div>
            <ClientPicker gsb={gsb} isOpen={isClPickerOpen} onClose={()=>setIsClPickerOpen(false)} onSelect={(ac)=>{setClCodeValue(ac.d_acCode); setIsClPickerOpen(false)}}/>
            <div>
                <H4>Search Previous Day Trade</H4>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Start Account No." labelFor="inputClCode1" >
                    <InputGroup 
                        className="inputClCode"
                        id="inputClCode1"
                        placeholder=" Account No. ..."
                        value={s_ClCode1}
                        onChange={utils.handleStringChange(s => sets_ClCode1(s))}
                        rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>sets_ClCode1); setIsClPickerOpen(true)}}></Button>}
                    />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Account No." labelFor="inputClCode2">
                    <InputGroup
                        className="inputClCode"
                        id="inputClCode2"
                        placeholder=" Account No. ..."
                        value={s_ClCode2}
                        onChange={utils.handleStringChange(s => sets_ClCode2(s))}
                        rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>sets_ClCode2); setIsClPickerOpen(true)}}></Button>}
                    />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Start Tran Date"labelFor="inputs_Date1">
                    <InputGroup id="inputs_Date1" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={utils.handleStringChange(s => sets_Date1(s)) } />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Tran Date"labelFor="inputs_Date2">
                    <InputGroup id="inputs_Date2" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={utils.handleStringChange(s => sets_Date2(s)) } />
                </FormGroup>
            </div>
            <div className="Row">
                <Button className="Col-1-1" disabled={! ShowOK} onClick={() => onSearchHistory()}>Search</Button>
                <Button className="Col-2-1" onClick={() => onCancel()}>Back</Button>
            </div>
            <div className="Spacing-V-16" />
            {/*
            {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
            {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }
            */}
            {/*ShowWait && <div>{imgWait}</div> */}
            {ShowNoRecord && <p>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-3"  >
                        <span id="Err_msg">No Record found !!!</span>
                    </FormGroup>
                </div>
            </p>
            }
            {ShowRst && Historylist}
            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg([]); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                        <div className="Spacing-V-16"></div>
                        {ShowAlert2Msg.map((Wmsg, j) => 
                            <div>{ShowAlert2Msg[j]}</div>
                        )}
                    </p>
                </Alert>
                <Alert
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    icon="warning-sign"
                    intent={Intent.DANGER}
                    isOpen={isShowAlert3}
                    onCancel={ () => { setisShowAlert3(false); onSelectHistoryRec("2") } }
                    onConfirm={ () => { setShowAlert3Msg([]); setisShowAlert3(false); onSelectHistoryRec("3") } }
                >
                    <p>
                        {/*ShowAlert3Msg*/}
                        <div className="Spacing-V-16"></div>
                        {ShowAlert3Msg.map((Wmsg, j) => 
                            <div>{ShowAlert3Msg[j]}</div>
                        )}
                    </p>
                </Alert>
        </div>
    )
}

interface CancelPrevTradeMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function CancelPrevTradeMain({ gsb }: CancelPrevTradeMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    const [data, setData] = useState([] as ClientTranInfo[])
    const [ChnlList, setChnlList] = useState([] as ChnlInfo[])
    const [ChnlList2, setChnlList2] = useState([] as ChnlInfo[])

    const [searchKey, setSearchKey] = useState("SeqNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchAcName, setsearchAcName] = useState("")
    const [searchStkNo, setsearchStkNo] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentClientTran, setcurrentClientTran] = useState({} as ClientTranInfo)
    const [BlankClientTran, setBlankClientTran] = useState({} as ClientTranInfo)

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")


    const [showChannel, setshowChannel] = useState(false)

    //gsb default value
    //BlankClientTran.d_Currency = "HKD"   //no work
    BlankClientTran.d_acCode = ""
    BlankClientTran.d_Tran_type = "B"
    BlankClientTran.d_StkNo = ""
    BlankClientTran.d_Net_Amount = 0
    BlankClientTran.d_Trade_Qty1 =0
    BlankClientTran.d_Trade_Qty2 = 0
    BlankClientTran.d_Trade_Qty3 = 0
    BlankClientTran.d_Trade_Qty4 = 0
    BlankClientTran.d_Trade_Price1 = 0
    BlankClientTran.d_Trade_Price2 = 0
    BlankClientTran.d_Trade_Price3 = 0
    BlankClientTran.d_Trade_Price4 = 0
    BlankClientTran.d_CommRate = 0
    BlankClientTran.d_Commission = 0
    BlankClientTran.d_Stamp = 0
    BlankClientTran.d_Levy = 0
    BlankClientTran.d_Trading_Fee = 0
    BlankClientTran.d_FRC = 0
    BlankClientTran.d_CCASS_Fee = 0
    BlankClientTran.d_Transfer_Fee = 0
    BlankClientTran.d_SAS_Handling_Fee = 0
    BlankClientTran.d_SAS_Management_Fee = 0
    BlankClientTran.d_SAS_Transfer_Fee = 0
    BlankClientTran.d_SAS_Transfer_Fee_HK = 0
    BlankClientTran.d_SAS_Stamp_Duty = 0
    BlankClientTran.d_Skip_Recal = "N"
    BlankClientTran.d_Aggr_transaction_HKIDR = "N"

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("CancelPrevTradeMain disp user_key: ", res.data.Flag.user_key)
                //console.log("CancelPrevTradeMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("CancelPrevTradeMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("CancelPrevTradeMain disp GsbFlag: ", GsbFlag)
                //console.log("CancelPrevTradeMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("CancelPrevTradeMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("CancelPrevTradeMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("CancelPrevTradeMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("CancelPrevTradeMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
 
                setshowChannel( GsbFlag.MULTI_CHNL ) ;

                ChnlList2.push({d_ChnlCode: "", d_ChnlDesc: "Normal"})
                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.CHN_STATUS.substr(i, 1).trim().length > 0 ) {
                        let tmpChnlName = "Normal"
                        tmpChnlName = GsbFlag.CHN_DESC[i]

                        ChnlList2.push({d_ChnlCode: GsbFlag.CHN_STATUS.substr(i, 1), d_ChnlDesc: tmpChnlName})
                    }
                }
                setChnlList(ChnlList2)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])




    const PrevRecProcess = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call PrevRecProcess", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "CancelCorrectTrade", cAction: "CancelPrevTradePrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_Seq)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentClientTran(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "CancelCorrectTrade", cAction: "CancelPrevTradeDelRec"})
            showSuccess("Seq No. " + ClientTran.d_Seq+ClientTran.d_SeqC +", " + ClientTran.d_Doc_No + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call NextRecProcess", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "CancelCorrectTrade", cAction: "CancelPrevTradeNextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call newOnSave", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "CancelCorrectTrade", cAction: "CancelPrevTradeAddRec"})
            //showSuccess("Seq No. " + ClientTran.d_Seq + " record added")
            showSuccess("Seq No. " + res.data.data.d_Seq+ClientTran.d_SeqC + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (ClientTran: ClientTranInfo) => {
        try {
            //console.log("call editOnSave", ClientTran)
            const res = await gsb.post('/Transaction', {sb_data: ClientTran, cPgmId: "CancelCorrectTrade", cAction: "CancelPrevTradeUpdateRec"})
            showSuccess("Seq No. " + ClientTran.d_Seq+ClientTran.d_SeqC + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    //console.log("editOnSave data", i, data)
                    setData(data)
                    //setCurrentStk(stk)
                    break
                }
            }
            //console.log("editOnSave stk", stk)
            //console.log("editOnSave res.data.data", res.data.data)
            setcurrentClientTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get CancelPrevTrade list if StkNo have value
        try {
            //console.log("CancelPrevTradeMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            AcName: searchAcName,
                            StkNo: searchStkNo,
                        }, 
                    },
                    cPgmId: "CancelCorrectTrade",
                    cAction: "CancelPrevTradeListRec",
                });

                //console.log("onSubmit res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentClientTran(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        d_Seq: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "CancelCorrectTrade",
                    cAction: "CancelPrevTradeGetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setcurrentClientTran(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one CancelPrevTrade record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("CancelPrevTradeMain useEffect length > 0  called", data)
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "CancelCorrectTrade",
                        cAction: "CancelPrevTradeListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentClientTran(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                AcName: searchAcName,
                                StkNo: searchStkNo,
                            }, 
                        },
                        cPgmId: "CancelCorrectTrade",
                        cAction: "CancelPrevTradeListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentClientTran(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <ClientTranEntryView gsb={gsb} ClientTran={currentClientTran} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentClientTran)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentClientTran)} onNextRec={() => NextRecProcess(currentClientTran)} PgmMode="Cancel"></ClientTranEntryView>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            //detail = <ClientTranEntryEdit gsb={gsb} ClientTran={BlankClientTran} ChnlList={ChnlList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } PgmMode="Cancel"></ClientTranEntryEdit>
            detail = <CancelPrevTradeNew gsb={gsb} onCancel={() => { setCurrentMode("show"); setSelectedTabId("list") } } ></CancelPrevTradeNew>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <ClientTranEntryEdit gsb={gsb} ClientTran={currentClientTran} ChnlList={ChnlList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="Cancel"></ClientTranEntryEdit>
            break
        }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-SeqNo">Seq No</th>
                <th className="TCol-TranDate">Tran Date</th>
                <th className="TCol-SettleDate">Settle Date</th>
                <th className="TCol-TranType">Tran Type</th>
                <th className="TCol-BSNote">BS Note No</th>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-StkCode">Stock Code</th>
                <th className="TCol-CcassStk">CCASS</th>
                <th className="TCol-StampFlag">Stamp</th>
                <th className="TCol-Qty1">Qty 1</th>
                <th className="TCol-Price1">Price 1</th>
                <th className="TCol-NetAmount">Net Amount</th>
                <th className="TCol-Action">Action</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ClientTran, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-SeqNo" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_Seq}</td>
                        <td className="TCol-TranDate" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(ClientTran.d_CC_Tran_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-SettleDate" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(ClientTran.d_MS_settle_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-TranType" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_Tran_type}</td>
                        <td className="TCol-BSNote" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_Doc_No}</td>
                        <td className="TCol-ACCode" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_acName1}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_StkNo}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentClientTran(ClientTran) ; MoblieDev && setSelectedTabId("detail") }}>{ClientTran.d_StkCode}</td>
                        <td className="TCol-CcassStk" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_CcassStk}</td>
                        <td className="TCol-StampFlag" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_StampFlag}</td>
                        <td className="TCol-Qty1" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_Trade_Qty1}</td>
                        <td className="TCol-Price1" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_Trade_Price1}</td>
                        <td className="TCol-NetAmount" onClick={() => { setcurrentClientTran(ClientTran) }}>{utils.formatNumber2(ClientTran.d_Net_Amount)}</td>
                        <td className="TCol-Action" onClick={() => { setcurrentClientTran(ClientTran) }}>{ClientTran.d_Action}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>3F1 Cancel Previous Day Trade</H3>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for Client Transaction " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="SeqNo">Seq No</option>
                                    {/* <option value="acCode">Account No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-2" label="Account Name" labelFor="searchAcName">
                                <InputGroup id="searchAcName" value={searchAcName} onChange={utils.handleStringChange(s => setsearchAcName(s))} />
                            </FormGroup>
                            <FormGroup className="Col-3-1" label="Stock No." labelFor="searchStkNo">
                                <InputGroup id="searchStkNo" value={searchStkNo} onChange={utils.handleStringChange(s => setsearchStkNo(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default CancelPrevTradeMain