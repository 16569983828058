import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { CCYParamInfo } from './Types'
import { SystemHeader } from './CommonUI';

import { transpileModule } from 'typescript';
import { stringify } from 'querystring';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface PrimeRateViewProps {
    gsb: AxiosInstance,
    PrimeRate: CCYParamInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function PrimeRateView({ gsb, PrimeRate = {} as CCYParamInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: PrimeRateViewProps) {

    const [isDeletePress, setisDeletePress] = useState(false)

    const [dateFormat, setdateFormat] = useState("1")


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');

                setdateFormat(GsbFlag.dateFormat)
            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, PrimeRate ])



    if (! ("x_PRIME_RATE" in PrimeRate)) return null


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>

                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
             
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Prime Int Rate" >
                    <InputGroup readOnly value={PrimeRate.x_PRIME_RATE} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
        </div>
    )
}

interface PrimeRateEditProps {
    gsb: AxiosInstance,
    PrimeRate?: CCYParamInfo,
    isNew?: boolean,
    onSave?: (PrimeRate: CCYParamInfo) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function PrimeRateEdit({ gsb, PrimeRate = {} as CCYParamInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: PrimeRateEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<CCYParamInfo>()
  
    const [dateFormat, setdateFormat] = useState("1")

    const [PrimeRateValue, setPrimeRateValue] = useState(PrimeRate.x_PRIME_RATE)

  

    useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("x_PRIME_RATE" in PrimeRate) {
            reset(PrimeRate)
        }
    }, [PrimeRate, reset])

    
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');

                setdateFormat(GsbFlag.dateFormat)

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, PrimeRate])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_status" })
    }, [register])

    
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    if (! isNew && !("x_PRIME_RATE" in PrimeRate)) return null


    const onSubmit = (data: CCYParamInfo) => {
        //console.log("onsubmit called", data)

        data.d_Currency = ""
        data.x_PRIME_RATE = PrimeRateValue;

        ;
        (async () => {
            try {
                //console.log("call PrimeRateedit data", data)
                //console.log("call PrimeRateedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "PrimeRate", cAction: "PrimeRateMastValidate", isNew: isNew})
                //console.log("PrimeRateedit res.data.ok", res.data.ok)
                //console.log("PrimeRateedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Prime Int Rate">
                    <InputGroup id="inputPrimeRate" defaultValue={PrimeRateValue} value={PrimeRateValue} onChange={utils.handleStringChange(n => setPrimeRateValue(n))} />
                    {errors.x_PRIME_RATE && errors.x_PRIME_RATE.type === "min" && <span id="Err_msg">Prime Int Rate sholud be greater than 0 </span>}
                    {errors.x_PRIME_RATE && errors.x_PRIME_RATE.types && <span id="Err_msg">{errors.x_PRIME_RATE.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
        </form>
    )
}

interface PrimeRateMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function PrimeRateMastMain({ gsb }: PrimeRateMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as CCYParamInfo[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentAc, setCurrentAc] = useState({} as CCYParamInfo)
    const [BlankAc, setBlankAc] = useState({} as CCYParamInfo)


    const [currentMode, setCurrentMode] = useState("show")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    

    
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankAc.d_Currency = ""
    BlankAc.x_PRIME_RATE = "0"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("PrimeRateMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("PrimeRateMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("PrimeRateMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("PrimeRateMastMain disp GsbFlag: ", GsbFlag)
                //console.log("PrimeRateMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("PrimeRateMastMain disp dateFormat: ", dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("PrimeRateMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("PrimeRateMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("PrimeRateMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;

                //console.log("PrimeRateMastMain isProduction: ", GsbFlag.isProduction)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])


    useEffect(() => {

        console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, GsbFlag, cardOpen01, currentMode])


    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "PrimeRate",
                        cAction: "ListRec",
                    });
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                console.log("main res.data", res.data.data)
                console.log("main currentAc", currentAc)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    const PrevRecProcess = async (PrimeRate: CCYParamInfo) => {
    }

    const DeleteProcess = async (PrimeRate: CCYParamInfo) => {
    }

    const NextRecProcess = async (PrimeRate: CCYParamInfo) => {
    }


    const newOnSave = async (PrimeRate: CCYParamInfo) => {
    }

    const editOnSave = async (PrimeRate: CCYParamInfo) => {
        try {
            console.log("call editOnSave", PrimeRate)
            const res = await gsb.post('/Master', {sb_data: PrimeRate, cPgmId: "PrimeRate", cAction: "UpdateRec"})
            //showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record saved")
            //setCurrentAc(res.data.data)
            setCurrentAc(res.data.data[0])
            setCurrentMode("show")

        } catch (error) {
            utils.showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <PrimeRateView gsb={gsb} PrimeRate={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></PrimeRateView>
    switch (currentMode) {
        case "edit":
            detail = <PrimeRateEdit gsb={gsb} PrimeRate={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></PrimeRateEdit>
            break
        case "new":
            detail = <PrimeRateEdit gsb={gsb} PrimeRate={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></PrimeRateEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    

    return (
        <>
            <form >
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Alert Prime Interest Rate</H3>
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}

                    {detail}
                </Card>
            </form>
        </>
    );
}

export default PrimeRateMastMain