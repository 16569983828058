import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'
import svgdoubledown from './svg/double-chevron-down.svg'
import svgDocument from './svg/Circle-icons-document.svg'

import { SmEntitInfo, PfEntitInfo, StkCodeInfo, AcCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface PfentitEntryEditProps {
    gsb: AxiosInstance,
    currentPfentit?: PfEntitInfo,
    currentSmentit?: SmEntitInfo,
    isNew?: boolean,
    onSave?: (PfE: PfEntitInfo) => void,
    onCancel?: () => void;
}

function PfentitEntryEdit({ gsb, currentPfentit = {} as PfEntitInfo, currentSmentit = {} as SmEntitInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: PfentitEntryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<PfEntitInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [Enter2tab, setEnter2tab] = useState("Y")

    
    const [AcCodeValue, setAcCodeValue] = useState(currentPfentit.d_acCode)
    const [AcCodeList, setAcCodeList] = useState([] as AcCodeInfo[])
    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")

    const [oh_qtyValue, setoh_qtyValue] = useState(currentPfentit.d_oh_qty)
    const [Book_Closed_QtyValue, setBook_Closed_QtyValue] = useState(currentPfentit.d_Book_Closed_Qty)
    const [Log_to_RegisterValue, setLog_to_RegisterValue] = useState(currentPfentit.d_Log_to_Register)
    const [Previous_Book_Closed_QtyValue, setPrevious_Book_Closed_QtyValue] = useState(currentPfentit.d_Previous_Book_Closed_Qty)


    useEffect(() => {
        // Call reset to get the values of record PfEntitInfo 
        if ("d_acCode" in currentPfentit) {
            reset(currentPfentit)
        }
    }, [currentPfentit, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Dividend_Currency" })
    }, [register])


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                setEnter2tab(GsbFlag.Enter2tab)               

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, currentPfentit, GsbFlag])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [gsb, currentPfentit, GsbFlag, Enter2tab])



    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("PfentitEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    //setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    //setLatestQtyValue(res.data.data[0].acbal_LatestBal) 
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)

                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])


    useEffect(() => {


        if ( typeof(currentSmentit.d_StkNo) === "undefined" ) {
            currentSmentit.d_StkNo = ""
        }
        if ( typeof(currentSmentit.d_AnnouncementNo) === "undefined" ) {
            currentSmentit.d_AnnouncementNo = ""
        }

        ;

        if ( AcCodeValue.length > 0 ) {

        (async () => {
            try {
                //console.log("PfentitEntryEdit AcCodeValue", AcCodeValue, currentSmentit.d_StkNo)
                //console.log("PfentitEntryEdit currentSmentit", currentSmentit)
                const res = await gsb.post('/Dividend',
                {   sb_data: currentSmentit,
                    cAcCode: AcCodeValue,
                    cPgmId: "DividendProcess",
                    cAction: "AdjBookClosedGetRec",
                });

                //console.log("PfentitEntryEdit new res.data.data: ", res.data.data, res.data.count) 

                if ( AcCodeValue.trim().length > 0 ) {
                    if ( res.data.count > 0 ) {
                        setoh_qtyValue(res.data.data.d_oh_qty)
                        setBook_Closed_QtyValue(res.data.data.d_Book_Closed_Qty)
                        setLog_to_RegisterValue(res.data.data.d_Log_to_Register)
                        setPrevious_Book_Closed_QtyValue(res.data.data.d_Previous_Book_Closed_Qty)
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        }

    }, [gsb, AcCodeValue])



    if (! isNew && ! ("d_acCode" in currentPfentit)) return null



    const onSubmit = (data: PfEntitInfo) => {


        //console.log("PfentitEntryEdit onsubmit data: ", data)
        //console.log("PfentitEntryEdit onsubmit StkPrice: ", StkPrice)
        //console.log("PfentitEntryEdit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        // put value to json/field before write database dbf

        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        data.d_acCode = data.d_acCode.toUpperCase();


        data.d_StkNo = currentSmentit.d_StkNo
        data.d_AnnouncementNo = currentSmentit.d_AnnouncementNo
        data.d_oh_qty = oh_qtyValue;
        data.d_Book_Closed_Qty = Book_Closed_QtyValue;
        data.d_Log_to_Register = Log_to_RegisterValue;
        data.d_Previous_Book_Closed_Qty = Previous_Book_Closed_QtyValue;

/*
        data.d_StkNo = StkNoValue ;
        data.d_StkCode = StkCodeValue;
        
        data.d_Scrip_indicator = "Y";
        if (Scrip_indicatorValue) {
            data.d_Scrip_indicator = "Y";
        } else {
            data.d_Scrip_indicator = "N";
        }
*/

        const Data3 = [] ;
        Data3.push(data);

       

        (async () => {
            try {
                
                //console.log("call PfentitEntryEdit new data", Data3);

/*                
                const res = await gsb.post('/Dividend', {sb_data: data, cPgmId: "StockAnnouncement", cAction: "StockAnnouncementValidate", isNew: isNew})
                //console.log("PfentitEntryEdit res.data.ok", res.data.ok)
                //console.log("PfentitEntryEdit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                setsavePfEntit(data)

                //console.log("call PfentitEntryEdit Announcement", savePfEntit)

                if ( isNew) {
                    if ( isAddAccess ) {
                        onSave(data)
                    } else {
                        setcAction("new")
                        setisOPenAlert(true)
                        //setisOpenDialog(true)
                    }
                } else {
                    if ( isEditAccess ) {
                        onSave(data)
                    } else {
                        setcAction("edit")
                        setisOPenAlert(true)
                        //setisOpenDialog(true)
                    }
                }
                //onSave(data)
*/


                (async () => {
                    try {
                        const res = await gsb.post('/Dividend', {
                            sb_data: Data3, 
                            cPgmId: "DividendProcess",
                            cAction: "AdjBookClosedListSave",
                        })                
                        if ( res.data.ok ) {
                            utils.showSuccess(res.data.data)
                        } else {
                            utils.showWarn(res.data.data)
                        }
                    } catch (error) {
                        utils.showError(error)
                    }
                })()

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }



    const StkRenderer: ItemRenderer<StkCodeInfo> = (stk, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${stk.d_StkCode}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={stk.d_StkNo}
                key={stk.d_StkNo}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StkPredicate: ItemPredicate<StkCodeInfo> = (query, stk, _index, exactMatch) => {
        const normalizedText = `${stk.d_StkNo} - ${stk.d_StkCode}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StkValueRenderer = (stk: StkCodeInfo) => {
        return stk.d_StkNo
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }




    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-2" icon="cross" onClick={() => onCancel()}>Cancel / Exit</Button>
                <Button className="Col-3-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>

             </div>
            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelFor="inputacCode" labelInfo="*">
                    <InputGroup id="inputacCode" value={AcCodeValue} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s)) } inputRef={register({ required: true , maxLength: 8}) } />
                    {errors.d_acCode && errors.d_acCode.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name" labelFor="inputAcName1">
                    <div>
                    <div id="DispText">{AcName1Value}</div>
                    <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
            </div>
             


            <div className="Row">
                <FormGroup className="Col-1-2" label="On Hand Qty" labelFor="inputoh_qty">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputoh_qty" defaultValue={oh_qtyValue} value={oh_qtyValue} type="number" onChange={utils.handleStringChange(n => setoh_qtyValue(n))}  />
                    {errors.d_oh_qty && errors.d_oh_qty.types && <span id="Err_msg">{errors.d_oh_qty.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Book Closed Qty" labelFor="inputBooklosed_Qty">
                    <InputGroup className="inputNumberNoSpin" id="inputBooklosed_Qty" defaultValue={Book_Closed_QtyValue} value={Book_Closed_QtyValue} type="number" onChange={utils.handleStringChange(n => setBook_Closed_QtyValue(n))}  />
                    {errors.d_Book_Closed_Qty && errors.d_Book_Closed_Qty.types && <span id="Err_msg">{errors.d_Book_Closed_Qty.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Log to Register" labelFor="inputLog_to_Register">
                    <InputGroup className="inputNumberNoSpin" id="inputLog_to_Register" defaultValue={Log_to_RegisterValue} value={Log_to_RegisterValue} type="number" onChange={utils.handleStringChange(n => setLog_to_RegisterValue(n))}  />
                    {errors.d_Log_to_Register && errors.d_Log_to_Register.types && <span id="Err_msg">{errors.d_Log_to_Register.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Previous Book Closed Qty" labelFor="inputPrevious_Book_Closed_Qty">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputPrevious_Book_Closed_Qty" defaultValue={Previous_Book_Closed_QtyValue} value={Previous_Book_Closed_QtyValue} type="number" onChange={utils.handleStringChange(n => setPrevious_Book_Closed_QtyValue(n))}  />
                    {errors.d_Previous_Book_Closed_Qty && errors.d_Previous_Book_Closed_Qty.types && <span id="Err_msg">{errors.d_Previous_Book_Closed_Qty.types.message}</span>}
                </FormGroup>
            </div>

        </form>
    )
}


interface DividendProcessMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function DividendProcessMain({ gsb }: DividendProcessMainProps) {

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    //let Stkno = ""
    let Stkno = utils.getUrlParameter('Stkno', url); 
    let { Stkno: defaultSearchValue = "" }: { Stkno: string } = useParams()


    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgdoubledown = <img className="svgdoubledownIcon" src={svgdoubledown} height="20" width="30" alt="doubledown" />
    const imgDocument = <img className="MenuIcon" src={svgDocument} alt="Document" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [Data, setData] = useState([] as PfEntitInfo[])
    const [Data2, setData2] = useState([] as SmEntitInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkNo, setsearchStkNo] = useState("")
    const [showButton, setshowButton] = useState(true)
    const [showSaveButton, setshowSaveButton] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [ChgGenDivPayment, setChgGenDivPayment] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentPfentit, setcurrentPfentit] = useState({} as PfEntitInfo)
    const [BlankPfentit, setBlankPfentit] = useState({} as PfEntitInfo)
    const [currentSmentit, setcurrentSmentit] = useState({} as SmEntitInfo)

    const [Scrip_Fee_RateValue, setScrip_Fee_RateValue] = useState(currentSmentit.d_Scrip_Fee_Rate)

    const [currentMode, setcurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    const [isShowAlert3, setisShowAlert3] = useState(false)
    const [ShowAlert3Msg, setShowAlert3Msg] = useState([])
    
    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [dateFormat, setdateFormat] = useState("")

    const [showPgmName, setshowPgmName] = useState("")
    const [IPAddr, setIPAddr] = useState("")

    const [GEN_CHQ, setGEN_CHQ] = useState(false)


    BlankPfentit.d_StkNo = ""
    BlankPfentit.d_AnnouncementNo = ""
    BlankPfentit.d_acCode = ""


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("DividendProcessMain disp user_key: ", res.data.Flag.user_key)
                //console.log("DividendProcessMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("DividendProcessMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("DividendProcessMain disp GsbFlag: ", GsbFlag)
                //console.log("DividendProcessMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("DividendProcessMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("DividendProcessMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("DividendProcessMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("DividendProcessMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setdateFormat(GsbFlag.dateFormat);
                setGEN_CHQ(GsbFlag.GEN_CHQ);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( qString.trim().length > 7 && selectedTabId == "list" ) {

            setshowButton(true)
            setShowWait(true)
 
            ;

            (async () => {
                try {
                    //console.log("SmMastMain onsubmit called", data)
                    const res = await gsb.post('/Dividend',
                    {   sb_data: {
                            stkNo: qString,
                            FirstOneOnly: true,
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "DividendProcess",
                        cAction: "AdjBookClosedList",
                    });

                    //console.log("PfEntit res.data.data: ", res.data.data, res.data.count) 
                    //console.log("SmEntit res.data.data2: ", res.data.data2) 
                    setData2(res.data.data2)
                    setcurrentSmentit(res.data.data2[0])
                    setScrip_Fee_RateValue(res.data.data2[0].d_Scrip_Fee_Rate)
                    //console.log("currentSmentit: ", currentSmentit) 

                    if ( res.data.count > 0 ) {
                        setData(res.data.data)

                        setshowButton(true)
                        setcurrentMode("show")
                        setChgGenDivPayment(false)

                    } else {
                        setshowButton(false)
                        setcurrentMode("show")
                        setChgGenDivPayment(false)
                    }
                    setShowWait(false)
                } catch (error) {
                    showError(error)
                }
                setShowWait(false)
            })()
        }

    }, [gsb, selectedTabId, ChgGenDivPayment])


    useEffect(() => {
        if ( showPgmName.length > 0 ) {
            (async () => {
                try {
                    // get GsbFlag from SB.XBS
                    const res = await gsb.post('/PgmEntry', {
                        cPgmName: showPgmName,
                        cIPAddr: IPAddr,
                    });
                    setshowPgmName("")
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, showPgmName])


    const newOnSave = async (Announcement: PfEntitInfo) => {

    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
    }        


    const onChangBCQty = (acCode: string, qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangBCQty acCode", acCode, qty);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode === acCode ) {
                    Data[j].d_Book_Closed_Qty = qty
                }
            }
            //setData(newData)
            //console.log("onChangBCQty Data", Data);
            setshowButton(true)
            
        }
    }

    const onChangLogRegister = (acCode: string, log: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangLogRegister acCode", acCode, log);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Log_to_Register = log
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            
        }
    }

    const onSaveScrip_Fee_Rate = () => {
        if (currentSmentit.d_StkNo.length > 0 ) {
            setshowSaveButton(false)
            currentSmentit.d_Scrip_Fee_Rate = Scrip_Fee_RateValue

            //console.log("onSaveScrip_Fee_Rate d_StkNo", currentSmentit.d_StkNo , currentSmentit.d_Scrip_Fee_Rate );
            ;
            (async () => {
                try {
                    const res = await gsb.post('/Dividend', {
                        sb_data: currentSmentit, 
                        cPgmId: "DividendProcess",
                        cAction: "UpdateScrip_Fee_Rate",
                    })                
                    if ( res.data.ok ) {
                        utils.showSuccess(res.data.data)
                    } else {
                        utils.showWarn(res.data.data)
                    }
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }

    const onAdjSaveRec = () => {
        setshowButton(false)
        ;

        //console.log("onAdjSaveRec data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: Data, 
                    cPgmId: "DividendProcess",
                    cAction: "AdjBookClosedListSave",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }
    
    const onGenDividendPayment = () => {
        setShowWait(true)
        ;
        //console.log("onGenDividendPayment currentSmentit", currentSmentit);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: currentSmentit, 
                    cPgmId: "DividendProcess",
                    cAction: "GenerateDividendPayment",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                    setisShowAlert3(true)
                    setShowAlert3Msg(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }

            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setChgGenDivPayment(true)
    }

    const onResetDividendPayment = () => {
        setShowWait(true)
        ;
        //console.log("onResetDividendPayment currentSmentit", currentSmentit);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: currentSmentit, 
                    cPgmId: "DividendProcess",
                    cAction: "ResetDividendPayment",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }

            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setChgGenDivPayment(true)
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <PfentitEntryEdit gsb={gsb} currentPfentit={BlankPfentit} currentSmentit={currentSmentit} isNew onSave={newOnSave} onCancel={() => { setcurrentMode("show"); setSelectedTabId("list") } } ></PfentitEntryEdit>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            detail = <PfentitEntryEdit gsb={gsb} currentPfentit={BlankPfentit} currentSmentit={currentSmentit} isNew onSave={newOnSave} onCancel={() => { setcurrentMode("show"); setSelectedTabId("list") } } ></PfentitEntryEdit>
            break
        }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let SmEntitRec = <>
        {Data2.map((SmEntit, i) =>
            <div >
                <div >
                    <tbody >
                        <tr><td>Stock No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_StkNo} &nbsp; &nbsp; {SmEntit.d_StkCode}</td></tr>
                        <tr><td>Announcement No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_AnnouncementNo}</td></tr>
                        <tr><td>Summary :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary1}</td></tr>
                        <tr><td></td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary2}</td></tr>
                    </tbody>
                </div>
                <div className="Spacing-V-16" />
                <div >
                    Ex date: &nbsp; {utils.dispDate(SmEntit.d_Ex_date, dateFormat)} &nbsp;
                    Book Close Date: &nbsp; {utils.dispDate(SmEntit.d_BC_date, dateFormat)} &nbsp;
                    Payable date: &nbsp; {utils.dispDate(SmEntit.d_PY_date, dateFormat)} &nbsp;
                </div>
                <div >
                    Dividend per share: &nbsp; {SmEntit.d_Dividend_perShare} &nbsp;
                    Payin Currency: &nbsp; {SmEntit.d_Dividend_Currency} &nbsp;
                </div>
                <div >
                    Convert Dividend into Bonus Share at Unit Price: &nbsp; {SmEntit.d_Dividend_Bonus_unitPrice} &nbsp;
                    &nbsp; Stock: &nbsp; {SmEntit.d_Dividend_Bonus_OhterStock} &nbsp;
                </div>
                <div >
                    { (SmEntit.d_Scrip_indicator === "Y" ) 
                    ? <tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        { showSaveButton && <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td><td><InputGroup className="inputNumberNoSpin" id="inputScrip_Fee_Rate" defaultValue={Scrip_Fee_RateValue} value={Scrip_Fee_RateValue} onChange={utils.handleStringChange(n => setScrip_Fee_RateValue(n))}  /></td><td>&nbsp;</td><td><Button icon="confirm" onClick={() => onSaveScrip_Fee_Rate()}>Save</Button></td></tr> } 
                        { ! showSaveButton && <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td><td> &nbsp;{SmEntit.d_Scrip_Fee_Rate}&nbsp;</td><td>&nbsp;</td><td><Button icon="edit" onClick={() => setshowSaveButton(true)}>Edit</Button></td></tr> } 
                    </tbody>
                    :<tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td></tr> 
                    </tbody>
                    }
                </div>
            </div>
        )}
        <div className="Spacing-V-16" />
    </>


    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setcurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
            <Button disabled={! showButton} className="Col-2-1" icon="confirm" onClick={() => { onAdjSaveRec() } }>Save</Button> 
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-oh_qty">On Hand Qty</th>
                <th className="TCol-Book_Closed_Qty">Book Closed Qty</th>
                <th className="TCol-Log_to_Register">Log to Register</th>
                <th className="TCol-Previous_Book_Closed_Qty">Previous Book Closed Qty</th>
            </thead>
            <tbody id="ma_tr">
                {Data.map((PfEntit, i) =>
                    <tr key={i} onDoubleClick={() => { } }>
                        <td className="TCol-ACCode" onClick={() => { } }>{PfEntit.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { } }>{PfEntit.d_acName1}</td>
                        <td className="TCol-oh_qty" onClick={() => { } }>{PfEntit.d_oh_qty}</td>
                        {/* <td className="TCol-Book_Closed_Qty" onClick={() => { } }>{PfEntit.d_Book_Closed_Qty}</td> */}
                        <td className="TCol-Book_Closed_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputBook_Closed_Qty" defaultValue={PfEntit.d_Book_Closed_Qty}  type="number" onChange={utils.handleStringChange(s => onChangBCQty(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-Log_to_Register" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputLog_to_Register" defaultValue={PfEntit.d_Log_to_Register}  type="number" onChange={utils.handleStringChange(s => onChangLogRegister(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-Previous_Book_Closed_Qty" onClick={() => { } }>{PfEntit.d_Previous_Book_Closed_Qty}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>94 Dividend Process</H3>
                    </div>
                    <div className="Spacing-V-16" />
                    {SmEntitRec}
                    <div className="Spacing-V-16" />
                    {ShowWait && <div>{imgWait}</div> }
                    <div className="Spacing-V-16" />
                    <table>
                        <tr><td><Button className="Col-1-2" icon="document" onClick={() => { setshowPgmName("94 Adjust_Book_Closed_Qty") ; window.open(`/#/BookClosedQty?Stkno=${currentSmentit.d_StkNo}?AnnouncementNo=${currentSmentit.d_AnnouncementNo}?dProcess=D`, '_self') } } >Adjust Book Closed Qty</Button></td></tr>
                        <tr><td id="td_center">{imgdoubledown}</td></tr>
                        <tr><td><Button className="Col-1-2" icon="document" onClick={() => { setshowPgmName("94 Handling_Charges") ; window.open(`/#/DividendProcess02?Stkno=${currentSmentit.d_StkNo}?AnnouncementNo=${currentSmentit.d_AnnouncementNo}?dProcess=D`, '_self') } } >Handling Charges & Margin Client Cheque Option</Button></td></tr>
                        <tr><td id="td_center">{imgdoubledown}</td></tr>
                        <tr><td><Button className="Col-1-2" icon="document" onClick={() => { setshowPgmName("94 Dividend_Report") ; window.open(`/#/DividendProcess03?Stkno=${currentSmentit.d_StkNo}?AnnouncementNo=${currentSmentit.d_AnnouncementNo}?dProcess=D`, '_self') } } >Print Dividend Report</Button></td></tr>
                        <tr><td id="td_center">{imgdoubledown}</td></tr>
                        <tr><td><Button className="Col-1-2" icon="document" onClick={() => { setshowPgmName("94 Direct_Debit_List") ; window.open(`/#/DividendProcess04?Stkno=${currentSmentit.d_StkNo}?AnnouncementNo=${currentSmentit.d_AnnouncementNo}?dProcess=D`, '_self') } } >Print Dividend Cheque / "Direct Debit List" to Client</Button></td></tr>
                        <tr><td id="td_center">{imgdoubledown}</td></tr>
                        {GEN_CHQ && <tr><td><Button className="Col-1-2" intent={currentSmentit.d_Generate_Div_Payment == 2 ? Intent.SUCCESS : "none"} icon={currentSmentit.d_Generate_Div_Payment == 2 ? "tick-circle" : imgDocument} onClick={() => { setshowPgmName("94 GenDividendPayment") ; onGenDividendPayment() } }>Generate Dividend Payment to Client and Div Cheque to Bank Reconcialtion</Button></td><td><Button className="Col-5-1" onClick={() => { onResetDividendPayment() } } >Reset</Button></td></tr> }
                        {! GEN_CHQ && <tr><td><Button className="Col-1-2" intent={currentSmentit.d_Generate_Div_Payment == 2 ? Intent.SUCCESS : "none"} icon={currentSmentit.d_Generate_Div_Payment == 2 ? "tick-circle" : imgDocument} onClick={() => { setshowPgmName("94 GenDividendPayment") ; onGenDividendPayment() } }>Generate Dividend Payment to Client </Button></td><td><Button className="Col-5-1" onClick={() => { onResetDividendPayment() } } >Reset</Button></td></tr> }
                    </table>
                    <div className="Spacing-V-16" />
                    <Button className="Col-1-2" onClick={() => window.location.reload()}>Refresh Status</Button>
                    <div className="Spacing-V-16"></div>
                    <p>Notes</p>
                    <div>
                        <div>
                            The Scrip Fee will be set to DEFAULT if 0 
                        </div>
                    </div >
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="warning-sign"
                        intent={Intent.DANGER}
                        isOpen={isShowAlert3}
                        onCancel={ () => { setisShowAlert3(false) } }
                        onConfirm={ () => { setShowAlert3Msg([]); setisShowAlert3(false) } }
                    >
                        <p>
                            {/*ShowAlert3Msg*/}
                            <div className="Spacing-V-16"></div>
                            {ShowAlert3Msg.map((Wmsg, j) => 
                                <div>{ShowAlert3Msg[j]}</div>
                            )}

                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default DividendProcessMain