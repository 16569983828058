import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup,  Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, ProgressBar ,AnchorButton} from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait1 from './svg/wait1.svg'
import svgWait2 from './svg/wait2.gif'

import i18next from 'i18next';

import { SystemHeader } from './CommonUI';

//import CryptoJS from "crypto-js";
import { BUTTON } from '@blueprintjs/core/lib/esm/common/classes';
import { Container } from 'konva/lib/Container';

interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}

var GsbFlag: any;


interface DviewTransDateMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

 
interface BackupInfo {
    filename :string, 
    StatementType :string,
    PathName: string,
    size: number,
    date: string,
    time: string,  
    isChecked:boolean,
}


function DviewTransDateMain({ gsb }: DviewTransDateMainProps) {

    let ProgressId2 = ""
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    
    const { t, i18n } = useTranslation();

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)

    const [showMU_BRANCH, setshowMU_BRANCH] = useState(false)
    const [stmtEncode, setstmtEncode] = useState("N")   

    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
 
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)
 
    const [s_ClCode1, sets_ClCode1] = useState("")
    const [s_ClCode2, sets_ClCode2] = useState("")
    const [s_Consent, sets_Consent] = useState("")
    const [s_SkipAE, sets_SkipAE] = useState("Y")
    const [s_Lang, sets_Lang] = useState("Chi_ZH")
    const [DataFormatValue, setDataFormatValue] = useState(false)
    const [CSVFormat, setCSVFormat] = useState("0")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(20)
    const [searchBackward, setSearchBackward] = useState(false)
    const [selectedTabId, setSelectedTabId] = useState("list")


   
    //const [cPrintMode, setcPrintMode] = useState("PS")
 
    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)

 

    const [s_Date1, sets_Date1] = useState(utils.formatNumber(new Date()))
    const [s_Date2, sets_Date2] = useState(utils.formatNumber(new Date()))

    const [s_Statement, sets_Statement] = useState("B")

    const [s_PrintAddress, sets_PrintAddress] = useState("N") 

    const [s_ByAECode1, sets_ByAECode1] = useState("")
    const [s_ByAECode2, sets_ByAECode2] = useState("")
    const [s_Location, sets_Location] = useState("")
   
    const scretkey = "youngunicornsrunfree"

    const [s_AcStatus, sets_AcStatus] = useState("A")
 
    const [backupInfoList, setBackupInfoList] = useState([] as BackupInfo[])
    const [ countnumber,setcountnumber] = useState(0)
 
    const [ count ,setcount] =useState(0)
 
    
    const hangedHandler = (e : React.ChangeEvent<HTMLInputElement>) =>
    {
        var i = 0;
        const { name, checked}= e.target;
        //console.log(name);
//  const checkedvalue= userData.map( (user)=>user.username ===name? {...user, isChecked:checked}:user);
        if(name==="allselect")
        {
            const checkedvalue = backupInfoList.map( (f)=>{ return {...f, isChecked:checked}});              
            console.log(name);
            setBackupInfoList(checkedvalue);
        }
        else {
            const checkedvalue= backupInfoList.map( (f)=>f.PathName ===name? {...f, isChecked:checked}:f);
           // console.log(name);
            console.log(checkedvalue);
            setBackupInfoList(checkedvalue);
            
            for(let i=0; i<backupInfoList.length; i++)
            {
              if(backupInfoList[i].isChecked===true)
              {
               console.log(backupInfoList[i].PathName);
              }
              
            }
 
        }
    }

     
 
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)        
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setshowMU_BRANCH(GsbFlag.MU_BRANCH) ;
                setstmtEncode (GsbFlag.stmtEncode);
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}
                setEnter2tab(GsbFlag.Enter2tab) ;
                setCSVFormat(GsbFlag.CSVFormat) ;

                setSUSPEND_AC( GsbFlag.SUSPEND_AC) ;

               
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    /*
    useEffect(() => {
        setShowRpt(false)
        setShowNoRecord(false)
        setShowOK(true)
    }, [s_ClCode1, s_ClCode2])
*/
 


useEffect(() => {
    setShowRpt(false)
    setShowNoRecord(false)
    setShowOK(true)
}, [s_ClCode1, s_ClCode2, DataFormatValue])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    const [clickedButton, setClickedButton] = useState('');

    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    
        const button: HTMLButtonElement = event.currentTarget;
        setClickedButton(button.name);
      };
 
    
    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let RptNameaRef = <>
        { RptNameNo > 0 && <div> Download / Print &nbsp;
            {aRptNameNo.map((no, j) => 
                <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}-${no.n}.pdf`}> <span id="RPageNo2" onClick={() => {} }> {no.n} &nbsp; </span> </a>
            )}
        </div> }
        { RptNameNo <= 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download All / Print </a>}
    </>    

    let list = <>
    <div className="Spacing-V-16" />
    {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download All</a>}
    &nbsp; &nbsp; {/*RptLogName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${RptLogName}&inline`}>View Log</a>*/}
    {ShowRpt && ! DataFormatValue}
    {/* display search list */}
    <HTMLTable striped interactive condensed>
        {/*   <thead id="rpt_th_r">
            <tr >
                { ! DataFormatValue && <th className="RCol-Head_p">
                        {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download All</a>}
                    </th>
                }
            </tr>                    
            <tr>
                <td className="RCol-Head1">{RptHead1}</td>
            </tr>                    
        </thead>*/}
        <tbody id="rpt_tr">
            {Rstdata.map((rpt, i) =>
                <tr key={i} onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>{rpt.Rst_Line}</td>
                </tr>
            )}
        </tbody>
        <div className="Spacing-V-16" />
        {ShowRpt && ! DataFormatValue }
    </HTMLTable>
    </>

    let list2 = <>
        <div className="Spacing-V-16" />       
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}  Total no: {countnumber}
        {/* display search list */}
        <div className="Spacing-V-16" />
                      <HTMLTable striped interactive condensed>
                    <thead>
                        <tr>
                            {/*<th><input type = "checkbox" name="allselect"    onChange={hangedHandler} /></th>
                            <th className="TCol-FileName">sr</th>*/}
                             <th>No.</th>
                            <th className="TCol-FileName">File Name</th>
                            <th className="Tcol-FileName">Statement Type</th>
                            <th className="TCol-FileName">Date</th>        
                            
                            <th className="TCol-Records"><div className="Align-R">Size</div></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {backupInfoList.map((f, index) =>
                            <tr key={index}>                              
                                 {/*<th><input type = "checkbox" name={f.PathName}      checked={f?.isChecked|| false }  onChange={hangedHandler} /></th>
                               <td className="TCol-FileName">{index+1}</td>*/}
                                <td>{index+1}</td>
                                <td className="TCol-FileName">{f.PathName}</td>
                                <td className="TCol-FileName">{f.StatementType}</td>                  
                                <td className="TCol-DateTime">{f.date}</td>
                                <td className="TCol-Records"><div className="Align-R">{utils.fileSize(f.size)}</div></td>
                                <td><AnchorButton icon={IconNames.DOWNLOAD} href={`${gsb.defaults.baseURL}/ViewPdf?fileName=${f.filename}`}>Download</AnchorButton></td>
                                <td><AnchorButton icon={IconNames.DOWNLOAD} href={`${gsb.defaults.baseURL}/ViewPdf?fileName=${f.filename}&inline`} target="_blank" >View</AnchorButton></td>
                            
                                {/* <td><Button icon={IconNames.UNARCHIVE} disabled={false} onClick={() => onSBRetore(f.PathName) }>Restore</Button></td> */}

                            </tr>
                        )}
                    </tbody>
                </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>
  
    const onSubmit = async (e: FormEvent) => {

        e.preventDefault()

        setShowWait(true)
     

        setShowOK(false)
        //setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptName("")

        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0)

        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }
 

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Report',
                {   sb_data: {
                        ProgressId: ProgressId2,
                        s_ClCode1: s_ClCode1,
                        s_ClCode2: s_ClCode2,
                        s_Date1: s_Date1,
                        s_Date2: s_Date2,
                        s_Today :"Y",
                        s_CurrMonthStmt:"N",
                        s_ByAECode1 :s_ByAECode1,
                        s_ByAECode2 :s_ByAECode2,
                        s_Location : s_Location,
                        s_Statement :s_Statement,
                        s_PrintAddress: s_PrintAddress,
                        sbbatch : "N",
                        nPage: 1,                                     
                        backward: false
                    },
                    cPgmId: "DviewMast",
                    cAction: "Dviewlist",
                });
                setcountnumber(Object.keys(res.data.data).length)
                setBackupInfoList(res.data.data)
                var  limit =searchLimit;
  
                var count = Object.keys(res.data.data).length
                console.log(count)
                if (count > 0) {
                    const aPageNumber1 = []

                    setnTotalPage(Math.ceil(count / searchLimit))
                    for ( let j = 1; j <= Math.ceil(count/searchLimit); j++ ) {
                        aPageNumber1.push({n: j});
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
               
              		setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    setRptHead1(res.data.Rpthead1)
                    setRptName(res.data.RptName)
                  
                    setRptNameNo(res.data.RptNameNo)
                }
                else
                {
                    setShowNoRecord(true)
                }
            } else {

            }
            setShowWait(false)
            setnPage(1)

        }catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
  
    }
       //Press page No 1 2 3 4
       useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Report',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            nPage: nPage,
                            limit: searchLimit,
                            s_ClCode1: s_ClCode1,
                            s_ClCode2: s_ClCode2,
                            s_Date1: s_Date1,
                            s_Date2: s_Date2,
                            s_ByAECode1 :s_ByAECode1,
                            s_ByAECode2 :s_ByAECode2,
                            s_Location : s_Location,
                            s_Today :"Y",
                            s_CurrMonthStmt:"N",
                            sbbatch : "N",
                            s_Statement :s_Statement,  
                            s_PrintAddress:s_PrintAddress,                            
                            backward: false
                        },
                        cPgmId: "DviewMast",
                        cAction: "Dviewlist",
                    });

                    setBackupInfoList(res.data.data)
                    console.log("nPage1")
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

 
    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>


    return (
        <>

                  
    <H2></H2>

    <form onSubmit={onSubmit}>

 
        <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                <H3>AD3 DVIEW Statement (Tran Date:{TRAN_DATE})</H3>
                <div className="Spacing-V-16" />
                {(stmtEncode==="Y")
                ?
                 <div>View Daily Statement or  Monthly Statement (no show Address)</div>                
                : <div>View Daily Statement or  Monthly Statement</div>             
                }
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Start Account No." labelFor="inputClCode1">
                        <InputGroup
                            id="inputClCode1"
                            autoFocus
                            placeholder=" Account No. ..."
                            value={s_ClCode1}
                            onChange={utils.handleStringChange(s => sets_ClCode1(s))}
                        />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Account No." labelFor="inputClCode2">
                        <InputGroup
                            id="inputClCode2"
                            placeholder=" Account No. ..."
                            value={s_ClCode2}
                            onChange={utils.handleStringChange(s => sets_ClCode2(s))}
                        />
                    </FormGroup>
                </div>

                <div className="Row">               

                    <FormGroup className="Col-1-2" label="From AECode." labelFor="inputByAECode1">
                        <InputGroup
                            id="inputByAECode1"
                            placeholder="From AECode ..."
                            value={s_ByAECode1}
                            onChange={utils.handleStringChange(s => sets_ByAECode1(s))}
                        />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="To AECode." labelFor="inputByAECode2">
                        <InputGroup
                            id="inputByAECode2"
                            placeholder="To AECode ..."
                            value={s_ByAECode2}
                            onChange={utils.handleStringChange(s => sets_ByAECode2(s))}
                        />
                    </FormGroup>
                </div>
                {(showMU_BRANCH) &&
                    <div className="Row">
                        <FormGroup className="Col-1-1" label="Location." labelFor="inputLocation">
                            <InputGroup
                                id="inputLocation"
                                placeholder="Branch ..."
                                value={s_Location}
                                onChange={utils.handleStringChange(s => sets_Location(s))}
                            />
                        </FormGroup>
                    </div>
                }

                {/*
                <div className="Row">
                <FormGroup className="Col3-1-1" label="Peroid" labelFor="inputPeroid">
                    <select  id="inputPeroid" value={s_Lang} onChange={utils.handleStringChange(s => sets_Lang(s))}>
                        <option value="1m">One Month</option>
                        <option value="6m">Six Month</option>
                        <option value="1yearMore">More than 1year</option>
                    </select>
                </FormGroup>
                </div>*/}
                {/*
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Start Date" labelFor="inputs_Date1">
                         <InputGroup id="inputs_Date1" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={utils.handleStringChange(s => sets_Date1(s)) } />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Date"labelFor="inputs_Date2">
                        <InputGroup id="inputs_Date2" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={utils.handleStringChange(s => sets_Date2(s)) } />
                    </FormGroup>
                </div>
                */}

                {/*<FormGroup className="Col3-1-1" label="Statement type" labelFor="inputStatement">
                    <select  id="inputStatement" value={s_Statement} onChange={utils.handleStringChange(s => sets_Statement(s))}>
                        <option value="B">BOTH</option>
                        <option value="D">Daily</option>
                        <option value="M">Monthly</option>
                                                                        
                    </select>
                </FormGroup>*/}
                
                <FormGroup className="Col3-1-1" label="Statement type">
                    <RadioGroup
                        inline={true}
                        selectedValue={s_Statement}
                        onChange={utils.handleStringChange(s => sets_Statement(s)) }
                    >
                        <Radio label="BOTH"  value="B"/>
                        <Radio label="Daily"  value="D"/>
                        <Radio label="Monthly"  value="M"/>     
                    </RadioGroup>
                </FormGroup> 




                <div className="Row">
                    <div>PDF Format</div>
                </div>
               
                <div className="Spacing-V-16" />
                <div className="Row">
                    <Button   type="submit">OK</Button>
                </div>
                {list2}
                
               
            </Card>
                {/* {ShowWait && <div>{imgWait}</div> } */}
                {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
                {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }
                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">No Record found !!!</span>
                        </FormGroup>
                    </div>
                </p>
                }
                {countnumber > 0 && ShowRpt && list}
            
     
           </form>
        </>
    );
}

export default DviewTransDateMain