import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { SmEntitInfo, StkCodeInfo, CurrencyInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface StockAnnouncementEntryViewProps {
    gsb: AxiosInstance,
    Announcement: SmEntitInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}


function StockAnnouncementEntryView({ gsb, Announcement = {} as SmEntitInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: StockAnnouncementEntryViewProps) {

    const { t, i18n } = useTranslation();   

    const [isDeletePress, setisDeletePress] = useState(false)

    const [isOpenDialog, setisOpenDialog] = useState(false)
    const [isOPenAlert, setisOPenAlert] = useState(false)
    const [cAction, setcAction] = useState("")
    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")


    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)


/*
    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("StockAnnouncementEntryEdit Announcement.d_acCode: ", Announcement.d_acCode) 
                const res = await gsb.post('/Dividend', {sb_data: "", cPgmId: "Clmast", cAction: "GetACBalInfo", cAcCode: Announcement.d_acCode, CCY: Announcement.d_Currency})
                //console.log("StockAnnouncementEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StockAnnouncementEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( Announcement.d_acCode.trim().length > 0 && res.data.count > 0 )
                {
                    setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    setLatestBalValue(res.data.data[0].acbal_LatestBal) 
                    setUnClrChqValue(res.data.data[0].acbal_UnClrChq) 
                } else {
                    setPrevDayValue(0)
                    setLatestBalValue(0)
                    setUnClrChqValue(0)
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, Announcement.d_acCode, Announcement.d_Currency, Announcement.d_Amount])
*/



    if (! ("d_StkNo" in Announcement)) return null

    
    return (
        <div>
            {/* define add,change,delete button */}
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => setisDeletePress(true)} >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                <Button className="Col-7-2" icon="document" onClick={() => { window.open(`/#/DividendProcess?Stkno=${Announcement.d_StkNo}?AnnouncementNo=${Announcement.d_AnnouncementNo}`, '_blank') } } >Dividend Process</Button>
                <Button className="Col-9-3" icon="document" onClick={() => { window.open(`/#/WarrantRightsProcess?Stkno=${Announcement.d_StkNo}?AnnouncementNo=${Announcement.d_AnnouncementNo}`, '_blank') } } >Bonus, Warrant, Rights, Split & Consolidation Process</Button>
                {/* <Button className="Col-7-2" icon="document" onClick={() => { window.open(`/#/BookClosedQty?Stkno=${Announcement.d_StkNo}?AnnouncementNo=${Announcement.d_AnnouncementNo}`, '_blank') } } >Book Closed Qty</Button> */}
            </div>
            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                    <InputGroup readOnly value={Announcement.d_StkNo} />
                </FormGroup>
                <FormGroup className="Col-2-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{Announcement.d_StkCode}</div>
                    <div id="DispText"></div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-7-1" label="Market">
                    <div>
                    <div id="DispText">{Announcement.d_Market}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-1" label="Currency">
                    <div>
                    <div id="DispText">{Announcement.d_Currency}</div>
                    </div>
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Announcement No." labelFor="inputAnnouncementNo" labelInfo="*">
                    <InputGroup readOnly value={Announcement.d_AnnouncementNo} />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Scrip Fee" labelFor="inputScrip_indicator" >
                    <RadioGroup
                        name="d_Scrip_indicator"
                        disabled={true}
                        inline={true}
                        selectedValue={Announcement.d_Scrip_indicator}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                {/* (GsbFlag.SHOW_ENTL) && */}
                <FormGroup className="Col-3-2" label="Show Info on Stmt" labelFor="inputShow_Entitle_Stm" >
                    <RadioGroup
                        name="d_Show_Entitle_Stm"
                        disabled={true}
                        inline={true}
                        selectedValue={Announcement.d_Show_Entitle_Stm}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Ex date"labelFor="inputEx_date">
                    <InputGroup readOnly value={utils.dispDate(Announcement.d_Ex_date, GsbFlag.dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Book Close Date"labelFor="inputBC_date">
                    <InputGroup readOnly value={utils.dispDate(Announcement.d_BC_date, GsbFlag.dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Payable Date"labelFor="inputPY_date">
                    <InputGroup readOnly value={utils.dispDate(Announcement.d_PY_date, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-6" label="Summary"labelFor="inputSummary1">
                    <InputGroup readOnly value={Announcement.d_Summary1} />
                    <InputGroup readOnly value={Announcement.d_Summary2} />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Dividend per share"labelFor="inputDividend_perShare">
                    <InputGroup readOnly value={utils.numToString(Announcement.d_Dividend_perShare)} />
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) && 
                    <FormGroup className="Col-3-2" label="Payin Currency"labelFor="inputDividend_Currency">
                        <InputGroup readOnly value={Announcement.d_Dividend_Currency} />
                        { Announcement.d_Dividend_Currency === "" && <div id="DispText" >as Stock Currency</div> }
                    </FormGroup>
                }
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Bonus" labelFor="inputBonus_Unit">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Bonus_Unit)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="for shares" labelFor="inputBonus_Base">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Bonus_Base)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Entitlement Begin Date" labelFor="inputEntitlement_Begin_Date">
                    <InputGroup readOnly value={utils.dispDate(Announcement.d_Entitlement_Begin_Date, GsbFlag.dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-7-2" label="Entitled Stock" labelFor="inputBonus_StockNo">
                    <InputGroup readOnly value={Announcement.d_Bonus_StockNo} />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="1st Warrant" labelFor="inputWarrant_1_Unit">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Warrant_1_Unit)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="for shares" labelFor="inputWarrant_1_Base">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Warrant_1_Base)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="2nd Warrant" labelFor="inputWarrant_2_Unit">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Warrant_2_Unit)} />
                </FormGroup>
                <FormGroup className="Col-7-2" label="for shares" labelFor="inputWarrant_2_Base">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Warrant_2_Base)} />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Rights" labelFor="inputRights_Unit">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Rights_Unit)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="for shares" labelFor="inputRights_Base">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Rights_Base)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Subscription Price" labelFor="inputRights_Price">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Rights_Price)} />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Split" labelFor="inputSplit_Old">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Split_Old)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="into shares" labelFor="inputSplit_New">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_Split_New)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Consolidate" labelFor="inputcombine_Old">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_combine_Old)} />
                </FormGroup>
                <FormGroup className="Col-7-2" label="into shares" labelFor="inputcombine_New">
                    <InputGroup readOnly value={utils.formatNumber(Announcement.d_combine_New)} />
                </FormGroup>
            </div>

            <H6>Convert Dividend into Bonus Share</H6>
            <div className="Row">
                <FormGroup className="Col-1-2" label="at Unit Price" labelFor="inputDividend_Bonus_unitPricee">
                    <InputGroup readOnly value={utils.numToString(Announcement.d_Dividend_Bonus_unitPrice)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="of other Stock" labelFor="inputDividend_Bonus_OhterStock">
                    <InputGroup readOnly value={Announcement.d_Dividend_Bonus_OhterStock} />
                </FormGroup>
                <FormGroup className="Col-5-6" label="Remaining Bonuse Share Qty Back to Dividend" labelFor="inputRemain_Bonus_Backto_Dividend" >
                    <RadioGroup
                        name="d_Remain_Bonus_Backto_Dividend"
                        disabled={true}
                        inline={true}
                        selectedValue={Announcement.d_Remain_Bonus_Backto_Dividend}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <p>Notes</p>
            <div>
                <div>
                    The Payin Currency is same as stock currency if blank
                </div>
            </div>
        </div>
    )
}

interface StockAnnouncementEntryEditProps {
    gsb: AxiosInstance,
    Announcement?: SmEntitInfo,
    CurrencyList?: CurrencyInfo[],
    isNew?: boolean,
    onSave?: (stk: SmEntitInfo) => void,
    onCancel?: () => void;
}

function StockAnnouncementEntryEdit({ gsb, Announcement = {} as SmEntitInfo, CurrencyList=[] as CurrencyInfo[], isNew = false, onSave = () => { }, onCancel = () => { } }: StockAnnouncementEntryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<SmEntitInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    


    const [StkNoValue, setStkNoValue] = useState(Announcement.d_StkNo)
    const [Caretdown2, setCaretdown2] = useState(false)
    const [StkCodeList, setStkCodeList] = useState([] as StkCodeInfo[])
    const [StkCodeValue, setStkCodeValue] = useState(Announcement.d_StkCode)
    const [StkNameValue, setStkNameValue] = useState(Announcement.d_StkName)
    const [CCASSStockValue, setCCASSStockValue] = useState(false)
    const [MarketValue, setMarketValue] = useState(Announcement.d_Market)
    const [CurrencyValue, setCurrencyValue] = useState(Announcement.d_Currency)
    const [Scrip_indicatorValue, setScrip_indicatorValue] = useState(Announcement.d_Scrip_indicator)
    const [Show_Entitle_StmValue, setShow_Entitle_StmValue] = useState(Announcement.d_Show_Entitle_Stm)
    const [Remain_Bonus_Backto_DividendValue, setRemain_Bonus_Backto_DividendValue] = useState(Announcement.d_Remain_Bonus_Backto_Dividend)




    useEffect(() => {
        // Call reset to get the values of record SmEntitInfo 
        if ("d_StkNo" in Announcement) {
            reset(Announcement)
        }
    }, [Announcement, reset])



    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        register({ name: "d_Dividend_Currency" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])



    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNoValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNoValue.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCodeValue(res.data.data[0].d_StkCode)
                    setStkNameValue(res.data.data[0].d_StkName)
                    setCCASSStockValue(res.data.data[0].d_CcassStk==="Y")
                    setMarketValue(res.data.data[0].d_Market)
                    //setFeeCodeValue(res.data.data[0].d_FeeCode)
                    setCurrencyValue(res.data.data[0].d_Currency)

                } else { 
                    setStkCodeValue("")
                    setStkNameValue("")
                    setCCASSStockValue(false)
                    setMarketValue("")
                    //setFeeCodeValue("")
                    setCurrencyValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNoValue])


    useEffect(() => {
        (async () => {
            try {
                // get Stock Code List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "StkCodeList"})
                //console.log("StkCodeList: ", res.data.data) 
                setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    if (! isNew && ! ("d_StkNo" in Announcement)) return null


    let DescLength = 45
    if ( GsbFlag.LONG_DESC ) {
        DescLength = 60
    }
    
    const onSubmit = (data: SmEntitInfo) => {

        //console.log("StockAnnouncementEntryedit onsubmit data: ", data)
        //console.log("StockAnnouncementEntryedit onsubmit StkPrice: ", StkPrice)
        //console.log("StockAnnouncementEntryedit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        // put value to json/field before write database dbf


/*
        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        
        data.d_acCode = data.d_acCode.toUpperCase();
*/        


        data.d_StkNo = StkNoValue ;
        data.d_StkCode = StkCodeValue;
        
        data.d_Scrip_indicator = Scrip_indicatorValue

        data.d_Show_Entitle_Stm = Show_Entitle_StmValue

        data.d_Remain_Bonus_Backto_Dividend = Remain_Bonus_Backto_DividendValue

        ;
/*
        data.d_Market = MarketValue;
        data.d_FeeCode = FeeCodeValue;
        data.d_Currency = CurrencyValue;
*/


       

        (async () => {
            try {
                console.log("call StockAnnouncementEntryedit data", data)
                //console.log("call StockAnnouncementEntryedit isNew", isNew)
                const res = await gsb.post('/Dividend', {sb_data: data, cPgmId: "StockAnnouncement", cAction: "StockAnnouncementValidate", isNew: isNew})
                //console.log("StockAnnouncementEntryedit res.data.ok", res.data.ok)
                //console.log("StockAnnouncementEntryedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }



    const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (CurrencyList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CurrencyList.d_CcyDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CurrencyList.d_Currency}
                key={CurrencyList.d_Currency}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (query, CurrencyList, _index, exactMatch) => {
        const normalizedText = `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
        return CurrencyList.d_Currency
    }

    

    const StkRenderer: ItemRenderer<StkCodeInfo> = (stk, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${stk.d_StkCode}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={stk.d_StkNo}
                key={stk.d_StkNo}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StkPredicate: ItemPredicate<StkCodeInfo> = (query, stk, _index, exactMatch) => {
        const normalizedText = `${stk.d_StkNo} - ${stk.d_StkCode}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StkValueRenderer = (stk: StkCodeInfo) => {
        return stk.d_StkNo
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }



    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>

             </div>
            <div className="Spacing-V-16" />

             
            <div className="Row">
                { ! Caretdown2 && 
                    <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                        <InputGroup disabled={! isNew} id="inputStkNo" value={StkNoValue} autoFocus={isNew} defaultValue="" placeholder="Stock No. ..." onChange={utils.handleStringChange(s => setStkNoValue(s)) } inputRef={register({ required: true , maxLength: 6}) } />
                        {errors.d_StkNo && errors.d_StkNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                        {errors.d_StkNo && errors.d_StkNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                        {errors.d_StkNo && errors.d_StkNo.types && <span id="Err_msg">{errors.d_StkNo.types.message}</span>}
                    </FormGroup>
                }
                {/* Caretdown2 && 
                    <FormGroup className="Col3-1-1" label="Stock No." labelFor="inputStkNo" >
                        <Suggest
                            inputProps={{ placeholder: "Please choose Stock No. ..." }}
                            defaultSelectedItem={StkCodeList.find((stk1) => stk1.d_StkNo === StkNoValue)}
                            items={StkCodeList}
                            itemRenderer={StkRenderer}
                            itemPredicate={StkPredicate}
                            inputValueRenderer={StkValueRenderer}
                            onItemSelect={(Stk) => { setStkNoValue(Stk.d_StkNo) ; setStkCodeValue(Stk.d_StkCode) ; setStkNameValue(Stk.d_StkName) ; setCaretdown2(!Caretdown2) }}
                        />
                    </FormGroup>
                */}
                {/* <span onClick={() => { setCaretdown2(!Caretdown2) } }> {imgCaretdown} </span> */}
                <FormGroup className="Col-2-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCodeValue}</div>
                    <div id="DispText">{StkNameValue}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-7-1" label="Market">
                    <div>
                    <div id="DispText">{MarketValue}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-1" label="Currency">
                    <div>
                    <div id="DispText">{CurrencyValue}</div>
                    </div>
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Announcement No." labelFor="inputAnnouncementNo" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputAnnouncementNo" name="d_AnnouncementNo" defaultValue="" placeholder="Announcement No. ..." inputRef={register({ required: true , maxLength: 10}) } />
                    {errors.d_AnnouncementNo && errors.d_AnnouncementNo.type === "required" && <span id="Err_msg">Stock Code cannot be Blank</span>}
                    {errors.d_AnnouncementNo && errors.d_AnnouncementNo.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_AnnouncementNo && errors.d_AnnouncementNo.types && <span id="Err_msg">{errors.d_AnnouncementNo.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Scrip Fee" labelFor="inputScrip_indicator" >
                    <RadioGroup
                        name="d_Scrip_indicator"
                        inline={true}
                        selectedValue={Scrip_indicatorValue}
                        onChange={utils.handleStringChange(s => setScrip_indicatorValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Scrip_indicator && errors.d_Scrip_indicator.types && <span id="Err_msg">{errors.d_Scrip_indicator.types.message}</span>}
                </FormGroup>
                {/* (GsbFlag.SHOW_ENTL) && */}
                <FormGroup className="Col-3-2" label="Show Info on Stmt" labelFor="inputShow_Entitle_Stm" >
                    <RadioGroup
                        name="d_Show_Entitle_Stm"
                        inline={true}
                        selectedValue={Show_Entitle_StmValue}
                        onChange={utils.handleStringChange(s => setShow_Entitle_StmValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Show_Entitle_Stm && errors.d_Show_Entitle_Stm.types && <span id="Err_msg">{errors.d_Show_Entitle_Stm.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Ex date" labelFor="inputEx_date">
                    <InputGroup id="inputEx_date" name="d_Ex_date" type="date" autoFocus={! isNew} placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_Ex_date && errors.d_Ex_date.types && <span id="Err_msg">{errors.d_Ex_date.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Book Close Date" labelFor="inputBC_date">
                    <InputGroup id="inputBC_date" name="d_BC_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_BC_date && errors.d_BC_date.types && <span id="Err_msg">{errors.d_BC_date.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Payable Date" labelFor="inputPY_date">
                    <InputGroup id="inputPY_date" name="d_PY_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_PY_date && errors.d_PY_date.types && <span id="Err_msg">{errors.d_PY_date.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-6" label="Summary" labelFor="inputSummary1">
                    <InputGroup id="inputSummary1" name="d_Summary1" defaultValue="" placeholder="Summary ..." inputRef={register({ maxLength: 40}) } />
                    {errors.d_Summary1 && errors.d_Summary1.type === "maxLength" && <span id="Err_msg">Max length is 40</span>}
                    {errors.d_Summary1 && errors.d_Summary1.types && <span id="Err_msg">{errors.d_Summary1.types.message}</span>}
                    <InputGroup id="inputSummary2" name="d_Summary2" defaultValue="" placeholder="Summary ..." inputRef={register({ maxLength: 40}) } />
                    {errors.d_Summary2 && errors.d_Summary2.type === "maxLength" && <span id="Err_msg">Max length is 40</span>}
                    {errors.d_Summary2 && errors.d_Summary2.types && <span id="Err_msg">{errors.d_Summary2.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Dividend per shares" labelFor="inputDividend_perShare">
                    <InputGroup className="inputNumberNoSpin" id="inputDividend_perShare" name="d_Dividend_perShare"  type="number" inputRef={register} />
                    {errors.d_Dividend_perShare && errors.d_Dividend_perShare.types && <span id="Err_msg">{errors.d_Dividend_perShare.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                <FormGroup className="Col-3-2" label="Payin Currency" labelFor="inputDividend_Currency">
                    {/* <InputGroup id="inputDividend_Currency" name="d_Dividend_Currency" defaultValue="" placeholder="Payin Currency ..." inputRef={register({ maxLength: 3}) } />
                    {errors.d_Dividend_Currency && errors.d_Dividend_Currency.type === "maxLength" && <span id="Err_msg">Max length is 3</span>}
                    */}
                    <Suggest
                            inputProps={{ placeholder: "Payin Currency ..." }}
                            defaultSelectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === Announcement.d_Dividend_Currency)}
                            items={CurrencyList}
                            itemRenderer={CurrencyRenderer}
                            itemPredicate={CurrencyPredicate}
                            inputValueRenderer={CurrencyValueRenderer}
                            onItemSelect={(CurrencyList) => { setValue("d_Dividend_Currency", CurrencyList.d_Currency) }}
                        />
                    {errors.d_Dividend_Currency && errors.d_Dividend_Currency.types && <span id="Err_msg">{errors.d_Dividend_Currency.types.message}</span>}
                </FormGroup>
                }
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Bonus" labelFor="inputBonus_Unit">
                    <InputGroup className="inputNumberNoSpin" id="inputBonus_Unit" name="d_Bonus_Unit"  type="number" inputRef={register} />
                    {errors.d_Bonus_Unit && errors.d_Bonus_Unit.types && <span id="Err_msg">{errors.d_Bonus_Unit.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="for shares" labelFor="inputBonus_Base">
                    <InputGroup className="inputNumberNoSpin" id="inputBonus_Base" name="d_Bonus_Base"  type="number" inputRef={register} />
                    {errors.d_Bonus_Base && errors.d_Bonus_Base.types && <span id="Err_msg">{errors.d_Bonus_Base.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Entitlement Begin Date" labelFor="inputEntitlement_Begin_Date">
                    <InputGroup id="inputEntitlement_Begin_Date" name="d_Entitlement_Begin_Date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_Entitlement_Begin_Date && errors.d_Entitlement_Begin_Date.types && <span id="Err_msg">{errors.d_Entitlement_Begin_Date.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-7-2" label="Entitled Stock" labelFor="inputBonus_StockNo">
                    <InputGroup id="inputBonus_StockNo" name="d_Bonus_StockNo" defaultValue="" placeholder="Bonus Stock No (if Different)  ..." inputRef={register({ maxLength: 6}) } />
                    {errors.d_Bonus_StockNo && errors.d_Bonus_StockNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Bonus_StockNo && errors.d_Bonus_StockNo.types && <span id="Err_msg">{errors.d_Bonus_StockNo.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="1st Warrant" labelFor="inputWarrant_1_Unit">
                    <InputGroup className="inputNumberNoSpin" id="inputWarrant_1_Unit" name="d_Warrant_1_Unit"  type="number" inputRef={register} />
                    {errors.d_Warrant_1_Unit && errors.d_Warrant_1_Unit.types && <span id="Err_msg">{errors.d_Warrant_1_Unit.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="for shares" labelFor="inputWarrant_1_Base">
                    <InputGroup className="inputNumberNoSpin" id="inputWarrant_1_Base" name="d_Warrant_1_Base"  type="number" inputRef={register} />
                    {errors.d_Warrant_1_Base && errors.d_Warrant_1_Base.types && <span id="Err_msg">{errors.d_Warrant_1_Base.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="2nd Warrant" labelFor="inputWarrant_2_Unit">
                    <InputGroup className="inputNumberNoSpin" id="inputWarrant_2_Unit" name="d_Warrant_2_Unit"  type="number" inputRef={register} />
                    {errors.d_Warrant_2_Unit && errors.d_Warrant_2_Unit.types && <span id="Err_msg">{errors.d_Warrant_2_Unit.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-7-2" label="for shares" labelFor="inputWarrant_2_Base">
                    <InputGroup className="inputNumberNoSpin" id="inputWarrant_2_Base" name="d_Warrant_2_Base"  type="number" inputRef={register} />
                    {errors.d_Warrant_1_Base && errors.d_Warrant_1_Base.types && <span id="Err_msg">{errors.d_Warrant_1_Base.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Rights" labelFor="inputRights_Unit">
                    <InputGroup className="inputNumberNoSpin" id="inputRights_Unit" name="d_Rights_Unit"  type="number" inputRef={register} />
                    {errors.d_Rights_Unit && errors.d_Rights_Unit.types && <span id="Err_msg">{errors.d_Rights_Unit.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="for shares" labelFor="inputRights_Base">
                    <InputGroup className="inputNumberNoSpin" id="inputRights_Base" name="d_Rights_Base"  type="number" inputRef={register} />
                    {errors.d_Rights_Base && errors.d_Rights_Base.types && <span id="Err_msg">{errors.d_Rights_Base.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Subscription Price" labelFor="inputRights_Price">
                    <InputGroup className="inputNumberNoSpin" id="inputRights_Price" name="d_Rights_Price"  type="number" inputRef={register} />
                    {errors.d_Rights_Price && errors.d_Rights_Price.types && <span id="Err_msg">{errors.d_Rights_Price.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Split" labelFor="inputSplit_Old">
                    <InputGroup className="inputNumberNoSpin" id="inputSplit_Old" name="d_Split_Old"  type="number" inputRef={register} />
                    {errors.d_Split_Old && errors.d_Split_Old.types && <span id="Err_msg">{errors.d_Split_Old.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="into shares" labelFor="inputSplit_New">
                    <InputGroup className="inputNumberNoSpin" id="inputSplit_New" name="d_Split_New"  type="number" inputRef={register} />
                    {errors.d_Split_New && errors.d_Split_New.types && <span id="Err_msg">{errors.d_Split_New.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Consolidate" labelFor="inputcombine_Old">
                    <InputGroup className="inputNumberNoSpin" id="inputcombine_Old" name="d_combine_Old"  type="number" inputRef={register} />
                    {errors.d_combine_Old && errors.d_combine_Old.types && <span id="Err_msg">{errors.d_combine_Old.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-7-2" label="into shares" labelFor="inputcombine_New">
                    <InputGroup className="inputNumberNoSpin" id="inputcombine_New" name="d_combine_New"  type="number" inputRef={register} />
                    {errors.d_combine_New && errors.d_combine_New.types && <span id="Err_msg">{errors.d_combine_New.types.message}</span>}
                </FormGroup>
            </div>

            <H6 >Convert Dividend into Bonus Share</H6>
            <div className="Row">
                <FormGroup className="Col-1-2" label="at Unit Price" labelFor="inputDividend_Bonus_unitPricee">
                    <InputGroup className="inputNumberNoSpin" id="inputDividend_Bonus_unitPricee" name="d_Dividend_Bonus_unitPrice"  type="number" inputRef={register} />
                    {errors.d_Dividend_Bonus_unitPrice && errors.d_Dividend_Bonus_unitPrice.types && <span id="Err_msg">{errors.d_Dividend_Bonus_unitPrice.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="of other Stock" labelFor="inputDividend_Bonus_OhterStock">
                    <InputGroup id="inputDividend_Bonus_OhterStock" name="d_Dividend_Bonus_OhterStock" defaultValue="" placeholder="Bonus Stock No (if Different)  ..." inputRef={register({ maxLength: 6}) } />
                    {errors.d_Dividend_Bonus_OhterStock && errors.d_Dividend_Bonus_OhterStock.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Dividend_Bonus_OhterStock && errors.d_Dividend_Bonus_OhterStock.types && <span id="Err_msg">{errors.d_Dividend_Bonus_OhterStock.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-6" label="Remaining Bonuse Share Qty Back to Dividend" labelFor="inputRemain_Bonus_Backto_Dividend" >
                    <RadioGroup
                        name="d_Remain_Bonus_Backto_Dividend"
                        inline={true}
                        selectedValue={Remain_Bonus_Backto_DividendValue}
                        onChange={utils.handleStringChange(s => setRemain_Bonus_Backto_DividendValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Remain_Bonus_Backto_Dividend && errors.d_Remain_Bonus_Backto_Dividend.types && <span id="Err_msg">{errors.d_Remain_Bonus_Backto_Dividend.types.message}</span>}
                </FormGroup>
            </div>
            <p>Notes</p>
            <div>
                <div>
                    The Payin Currency is same as stock currency if blank
                </div>
            </div>
        </form>
    )
}

interface StockAnnouncementEntryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function StockAnnouncementEntryMain({ gsb }: StockAnnouncementEntryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    const [data, setData] = useState([] as SmEntitInfo[])
    const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])
    const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchAcName, setsearchAcName] = useState("")
    const [searchStkNo, setsearchStkNo] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAnnouncement, setcurrentAnnouncement] = useState({} as SmEntitInfo)
    const [BlankAnnouncement, setBlankAnnouncement] = useState({} as SmEntitInfo)

    const [currentMode, setcurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")


    //gsb default value
    //BlankAnnouncement.d_Currency = "HKD"   //no work
    BlankAnnouncement.d_StkNo = ""
    BlankAnnouncement.d_AnnouncementNo = ""
    BlankAnnouncement.d_Scrip_indicator = "Y"
    BlankAnnouncement.d_Show_Entitle_Stm = "Y"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("StockAnnouncementEntryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("StockAnnouncementEntryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("StockAnnouncementEntryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("StockAnnouncementEntryMain disp GsbFlag: ", GsbFlag)
                //console.log("StockAnnouncementEntryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("StockAnnouncementEntryMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("StockAnnouncementEntryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("StockAnnouncementEntryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("StockAnnouncementEntryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                // get Market List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYList"})

                //console.log("MarketList: ", res.data.data) 
                CurrencyList2.push({d_Currency: "", d_CcyDesc: "as Stock Currency"})
                CurrencyList2.push({d_Currency: "HKD", d_CcyDesc: "HKD"})
                for (let j = 0; j < res.data.data.length; j++) {
                    CurrencyList2.push({d_Currency: res.data.data[j][0], d_CcyDesc: res.data.data[j][1]})
                }
                setCurrencyList(CurrencyList2)
                //console.log("set CurrencyList: ", CurrencyList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    const PrevRecProcess = async (Announcement: SmEntitInfo) => {
        try {
            //console.log("call PrevRecProcess", Announcement)
            const res = await gsb.post('/Dividend', {sb_data: Announcement, cPgmId: "StockAnnouncement", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo+data[i].d_AnnouncementNo === data[i].d_StkNo+data[i].d_AnnouncementNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentAnnouncement(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setcurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (Announcement: SmEntitInfo) => {
        try {
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Dividend', {sb_data: Announcement, cPgmId: "StockAnnouncement", cAction: "DelRec"})
            showSuccess("Stock No. " + Announcement.d_StkNo+","+Announcement.d_AnnouncementNo + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo+data[i].d_AnnouncementNo === data[i].d_StkNo+data[i].d_AnnouncementNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentAnnouncement(res.data.data)
            setcurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Announcement: SmEntitInfo) => {
        try {
            //console.log("call NextRecProcess", Announcement)
            const res = await gsb.post('/Dividend', {sb_data: Announcement, cPgmId: "StockAnnouncement", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo+data[i].d_AnnouncementNo === data[i].d_StkNo+data[i].d_AnnouncementNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentAnnouncement(res.data.data)
            setcurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (Announcement: SmEntitInfo) => {
        try {
            //console.log("call newOnSave", Announcement)
            const res = await gsb.post('/Dividend', {sb_data: Announcement, cPgmId: "StockAnnouncement", cAction: "AddRec"})
            showSuccess("Stock No. " + Announcement.d_StkNo+","+Announcement.d_AnnouncementNo + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo+data[i].d_AnnouncementNo === data[i].d_StkNo+data[i].d_AnnouncementNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentAnnouncement(res.data.data)
            setcurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (Announcement: SmEntitInfo) => {
        try {
            //console.log("call editOnSave", Announcement)
            const res = await gsb.post('/Dividend', {sb_data: Announcement, cPgmId: "StockAnnouncement", cAction: "UpdateRec"})
            showSuccess("Stock No. " + Announcement.d_StkNo+","+Announcement.d_AnnouncementNo + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo+data[i].d_AnnouncementNo === data[i].d_StkNo+data[i].d_AnnouncementNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    //console.log("editOnSave data", i, data)
                    setData(data)
                    //setCurrentStk(stk)
                    break
                }
            }
            //console.log("editOnSave stk", stk)
            //console.log("editOnSave res.data.data", res.data.data)
            setcurrentAnnouncement(res.data.data)
            setcurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get StockAnnouncementEntry list if StkNo have value
        try {
            //console.log("StockAnnouncementEntryMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Dividend',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                        }, 
                    },
                    cPgmId: "StockAnnouncement",
                    cAction: "ListRec",
                });

                //console.log("onSubmit res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentAnnouncement(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Dividend',
                {   sb_data: {
                        d_StkNo: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "StockAnnouncement",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setcurrentAnnouncement(res.data.data)
                    setcurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one StockAnnouncementEntry record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("StockAnnouncementEntryMain useEffect length > 0  called", data)
                const res = await gsb.post('/Dividend',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "StockAnnouncement",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentAnnouncement(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Dividend',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                            }, 
                        },
                        cPgmId: "StockAnnouncement",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentAnnouncement(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <StockAnnouncementEntryView gsb={gsb} Announcement={currentAnnouncement} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAnnouncement)} onNew={() => setcurrentMode("new")} onEdit={() => setcurrentMode("edit")} onDelete={() => DeleteProcess(currentAnnouncement)} onNextRec={() => NextRecProcess(currentAnnouncement)} ></StockAnnouncementEntryView>
    switch (currentMode) {
        //case "PrevRec":
        // ?? will call 2 times ??
        //    console.log("currentMode = PrevRec")
        //    PrevRecProcess(currentStk)
        //    break
        case "new":
            //console.log("currentMode = new")
            detail = <StockAnnouncementEntryEdit gsb={gsb} Announcement={BlankAnnouncement} CurrencyList={CurrencyList} isNew onSave={newOnSave} onCancel={() => { setcurrentMode("show"); setSelectedTabId("detail") } } ></StockAnnouncementEntryEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <StockAnnouncementEntryEdit gsb={gsb} Announcement={currentAnnouncement} CurrencyList={CurrencyList} onSave={editOnSave} onCancel={() => setcurrentMode("show")} ></StockAnnouncementEntryEdit>
            break
        //case "delete":
        //    console.log("currentMode = delete")
        //    DeleteProcess(currentStk)
        //    break
        }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setcurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-StkCode">Stock Code</th>
                <th className="TCol-Announcement">Announcement</th>
                <th className="TCol-Ex_date">Ex date</th>
                <th className="TCol-BC_date">Book Close Date</th>
                <th className="TCol-PY_date">Payable Date</th>
                <th className="TCol-Summary">Summary</th>
                <th className="TCol-Dividend">Dividend</th>
                <th className="TCol-1st_Warrant">1st_Warrant</th>
                <th className="TCol-for_shares">for shares</th>
                <th className="TCol-Rights">Rights</th>
                <th className="TCol-for_shares">for shares</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((Announcement, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-StkNo" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_StkNo}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_StkCode}</td>
                        <td className="TCol-Announcement" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_AnnouncementNo}</td>
                        <td className="TCol-Ex_date" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(Announcement.d_Ex_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-BC_date" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(Announcement.d_BC_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-PY_date" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(Announcement.d_PY_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-Summary" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_Summary1}</td>
                        <td className="TCol-Dividend" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_Dividend_perShare}</td>
                        <td className="TCol-1st_Warrant" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_Warrant_1_Unit}</td>
                        <td className="TCol-for_shares" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_Warrant_1_Base}</td>
                        <td className="TCol-Rights" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_Rights_Unit}</td>
                        <td className="TCol-for_shares" onClick={() => { setcurrentAnnouncement(Announcement) ; MoblieDev && setSelectedTabId("detail") }}>{Announcement.d_Rights_Base}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>91 Stock Announcement Entry</H3>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="StkNo">Stock No</option>
                                    {/* <option value="StkCode">Stock Code</option> */}
                                    {/* <option value="AnnouncementkNo">Announcement No</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            {/*
                            <FormGroup className="Col-1-2" label="Account Name" labelFor="searchAcName">
                                <InputGroup id="searchAcName" value={searchAcName} onChange={utils.handleStringChange(s => setsearchAcName(s))} />
                            </FormGroup>
                            <FormGroup className="Col-3-1" label="Stock No." labelFor="searchStkNo">
                                <InputGroup id="searchStkNo" value={searchStkNo} onChange={utils.handleStringChange(s => setsearchStkNo(s))} />
                            </FormGroup>
                            */}
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default StockAnnouncementEntryMain