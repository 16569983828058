import { AxiosInstance } from 'axios';
import { useState, FormEvent, useEffect } from 'react';

import { Classes, Button, ControlGroup, FormGroup, InputGroup, Dialog, DialogBody, HTMLSelect, HTMLTable } from '@blueprintjs/core';
import { IconNames } from "@blueprintjs/icons";

import * as utils from "./utils";
import { Account } from './Types'

interface SystemHeaderProps {
    companyLogo: string
    companyName: string
    dataPath: string
    tradeDate: string
    settlementDate: string
}

interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}


export function SystemHeader({companyLogo, companyName, dataPath, tradeDate, settlementDate}: SystemHeaderProps) {
    const coLogo = <img className="coLogo" src={companyLogo} alt="Co Logo" />
    const coHead = <div style={{display: 'flex', width:'100%', marginTop: '8px'}}>
        {coLogo}
        <div style={{width: '100%', marginLeft: '8px'}}>
            <div style={{display: 'flex', width: '100%'}}>
                <div id="DispCoName" style={{width: '85%'}}> <b>{ companyName } </b> </div>
                <div id="DispTrnDate" style={{width: '15%', textAlign: 'right'}}>Tran Date: {tradeDate} </div>
            </div>
            <div style={{display: 'flex', width: '100%'}}>
                <div id="DispCoName2" style={{width: '85%'}}>Curr Path: {dataPath}</div>
                <div id="DispTrnDate" style={{width: '15%', textAlign: 'right'}}>Setl Date: {settlementDate} </div>
            </div>
        </div>
    </div>

    return coHead
}


interface ClientPickerProps {
    gsb: AxiosInstance
    isOpen: boolean
    onSelect?: (ac: Account) => void
    onClose?: () => void
}

export function ClientPicker({gsb, isOpen, onSelect = ()=>{}, onClose = ()=>{}}: ClientPickerProps) {
    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchTel, setSearchTel] = useState("")
    const [searchStatus, setSearchStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)

    const [data, setData] = useState([] as Account[])


    async function listRec() {

        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        const res = await gsb.post('/Master',
        {   sb_data: {
                by: searchKey,
                start: searchValue,
                nPage: 1,
                limit: 50,
                PageShowInfo: aPageShowInfo,
                refreshPageShow: true,
                backward: false,
                SortByName: false,
                filters: {
                    idNo: searchIdNo,
                    tel: searchTel,
                    status: searchStatus
                },
            },
            cPgmId: "Clmast",
            cAction: "ListRec",
        });

        //console.log("ListRec res.data.data", res.data.data)
        //console.log("ListRec res.data.count", res.data.count)
        setaPageShowInfo(res.data.PageShowInfo)
        setData(res.data.data)

        if ( res.data.count > 0 ) {
            const aPageNumber1 = []
            let k = 0
            for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                if ( res.data.PageShowInfo[j-1].pageShow ) {
                    k++
                    aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                    if ( res.data.PageShowInfo[j-1].pageLast ) { 
                        setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                        setPageReachEnd(true)
                    }
                }
            }
            setaPageNumber(aPageNumber1)
            setnPage(1)
        }
    }

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0  ) {
    
            setPageReachEnd(false)

            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: 50,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: false,
                            SortByName: false,
                            filters: {
                                idNo: searchIdNo,
                                tel: searchTel,
                                status: searchStatus
                            },
                            },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                    });

                    //console.log("ListRec Press  res.data.PageShowInfo", res.data.PageShowInfo)
                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)


                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }
    
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage])

        

    function PageNo(n: number) {
        if (n > 0 ) {
            if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                 {aPageNumber.map((no, j) => 
                    (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
                { (nPage > 0) && ! ( PageReachEnd ) &&
                    <Button minimal >...</Button>
                }
            </FormGroup>
        </div>
    </>

    let list = <>
        <div className="Spacing-V-16" />
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-IDNo">ID</th>
                <th className="TCol-Tel">Phone</th>
                <th className="TCol-AECode">Runner</th>
                <th className="TCol-Status">Status</th>
            </thead>
            <tbody>
                {data.map((ac, i) =>
                    <tr key={i} onDoubleClick={() => { onSelect(ac) }}>
                        <td className="TCol-ACCode" onClick={() => { MoblieDev && onSelect(ac) }}>{ac.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { MoblieDev && onSelect(ac) }}>{ac.d_acName1}</td>
                        <td className="TCol-IDNo">{ac.d_idNo}</td>
                        <td className="TCol-Tel">{ac.d_tel}</td>
                        <td className="TCol-AECode">{ac.d_aeCode}</td>
                        <td className="TCol-Status">{ac.d_status}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
    </>

    return <Dialog isOpen={isOpen} onClose={onClose} style={{minWidth: '66.67%', height: '60vh'}}>
        <DialogBody>
            <form onSubmit={(e)=>{e.preventDefault(); e.stopPropagation(); listRec()}}>
            <div className="Row-8 No-Print">
                <FormGroup className="Col-1-5" label="Search for Client Master" labelFor="inputSearchValue">
                    <ControlGroup fill>
                        <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                            <option value="acCode">Account</option>
                            <option value="acName">Name</option>
                            <option value="aeCode">Runner</option>
                            {/* <option value="_recordNo">Record No.</option> */}
                        </HTMLSelect>
                        <InputGroup
                            id="inputSearchValue"
                            placeholder="Search"
                            value={searchValue}
                            onChange={utils.handleStringChange(s => setSearchValue(s))}
                            leftIcon="search"
                            rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal onClick={() => {listRec()} } ></Button>}
                        />
                    </ControlGroup>
                </FormGroup>
                <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" onClick={() => {listRec()} } >List</Button>
                {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                {/* <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button> */}
            </div>
            </form>
            {list}
        </DialogBody>
    </Dialog>
}