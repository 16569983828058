import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Classes, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import Axios, { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';

interface CrsAccount {
    LACCT: string,
    LastName: string,
    FirstName: string,
    MiddleName: string,
    IdentityCard: string,
}



interface PageInfo {
    n: number
}

var GsbFlag: any;

interface ClMast2ViewProps {
    ac: CrsAccount,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function ClMast2View({ ac = {} as CrsAccount, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: ClMast2ViewProps) {

    const [isPrevRec, setisPrevRec] = useState(false)
    const [isAdd, setisAdd] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [isDelete, setisDelete] = useState(false)
    const [isNextRec, setisNextRec] = useState(false)

    if (! ("LACCT" in ac)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>

    function buttonPress(buttonName: string)
    {
        if ( buttonName == "Prev" )
        {
            onPrevRec()
        }
        if ( buttonName == "new" )
        {
            onNew()
        }
        if ( buttonName == "edit" )
        {
            onEdit()
        }
        if ( buttonName == "delete" )
        {
            setisDelete(true)
        }
        if ( buttonName == "Next" )
        {
            onNextRec()
        }
    }

    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => buttonPress("Prev")} >Prev</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isPrevRec}
                        onConfirm={ () => { setisPrevRec(false) } }
                    >
                        <p>
                            PREV REC : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-2-1" icon="add" onClick={() => buttonPress("new")} >New</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isAdd}
                        onConfirm={ () => { setisAdd(false) } }
                    >
                        <p>
                            NEW : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-3-1" icon="edit" onClick={() => buttonPress("edit")} >Edit</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isEdit}
                        onConfirm={ () => { setisEdit(false) } }
                    >
                        <p>
                            EDIT : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-4-1" icon="delete" onClick={() => buttonPress("delete")} >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDelete}
                        onCancel={ () => setisDelete(false) }
                        onConfirm={ () => { onDelete(); setisDelete(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => buttonPress("Next")} >Next</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isNextRec}
                        onConfirm={ () => { setisNextRec(false) } }
                    >
                        <p>
                            NEXT REC : Access Denied
                        </p>
                    </Alert>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No.">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup readOnly value={ac.LACCT} />
                    </Tooltip>
                </FormGroup>
                <FormGroup className="Col-2-3" label="Client Name">
                    <InputGroup readOnly value={ac.LastName} />
                    <InputGroup readOnly value={ac.FirstName} />
                    <InputGroup readOnly value={ac.MiddleName} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="ID No.">
                    <InputGroup readOnly value={ac.IdentityCard} />
                </FormGroup>
            </div>
            <div className="Row">
            </div>
        </div>
    )
}

interface ClMast2EditProps {
    gsb: AxiosInstance,
    ac?: CrsAccount,
    isNew?: boolean,
    onSave?: (ac: CrsAccount) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function ClMast2Edit({ gsb, ac = {} as CrsAccount, isNew = false, onSave = () => { }, onCancel = () => { } }: ClMast2EditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<CrsAccount>()


    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>
   
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("LACCT" in ac) {
            reset(ac)
        }
    }, [ac, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_aeCode" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    if (!isNew && !("LACCT" in ac)) return null



    const onSubmit = (data: CrsAccount) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.LACCT = data.LACCT.toUpperCase();


/*
        (async () => {
            try {
                //console.log("call ClMast2edit data", data)
                //console.log("call ClMast2edit isNew", isNew)
                const res = await gsb.post('/ClMast2', {sb_data: data, cAction: "ClValidate", isNew: isNew})
                //console.log("ClMast2edit res.data.ok", res.data.ok)
                //console.log("ClMast2edit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()
*/
        onSave(data)

    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputAcCode">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup disabled={! isNew} id="inputAcCode" name="LACCT" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } />
                    </Tooltip>    
                    {errors.LACCT && errors.LACCT.type === "required" && (<span id="Err_msg">Account No. cannot be Blank</span>)}
                    {errors.LACCT && errors.LACCT.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.LACCT && errors.LACCT.types && <span id="Err_msg">{errors.LACCT.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="Client Name" labelFor="inputAcName1">
                    <InputGroup id="inputAcName1" name="LastName" autoFocus={! isNew} defaultValue="" placeholder="Name 1 ..." inputRef={register({ maxLength: 70}) } />
                    <InputGroup id="inputAcName2" name="FirstName" defaultValue="" placeholder="Name  ..." inputRef={register({ maxLength: 70}) } />
                    <InputGroup id="inputAcName3" name="MiddleName" defaultValue="" placeholder="Name  ..." inputRef={register({ maxLength: 120}) } />
                    {errors.LastName && errors.LastName.types && <span id="Err_msg">{errors.LastName.types.message}</span>}
                    {errors.FirstName && errors.FirstName.types && <span id="Err_msg">{errors.FirstName.types.message}</span>}
                    {errors.MiddleName && errors.MiddleName.types && <span id="Err_msg">{errors.MiddleName.types.message}</span>}
                    {errors.LastName && errors.LastName.type === "maxLength" && <span id="Err_msg">Max length is 70</span>}
                    {errors.FirstName && errors.FirstName.type === "maxLength" && <span id="Err_msg">Max length is 70</span>}
                    {errors.MiddleName && errors.MiddleName.type === "maxLength" && <span id="Err_msg">Max length is 120</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="ID No." labelFor="inputIdNo">
                    <InputGroup id="inputIdNo" name="IdentityCard" defaultValue="" placeholder="ID No. ..." inputRef={register({ maxLength: 30}) } />
                    {errors.IdentityCard && errors.IdentityCard.types && <span id="Err_msg">{errors.IdentityCard.types.message}</span>}
                    {errors.IdentityCard && errors.IdentityCard.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                </FormGroup>
            </div>
            <div className="Row">
            </div>
        </form>
    )
}

interface ClMast2MainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClMast2Main({ gsb }: ClMast2MainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    const [data, setData] = useState([] as CrsAccount[])


    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchTel, setSearchTel] = useState("")
    const [searchStatus, setSearchStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as CrsAccount)
    const [BlankAc, setBlankAc] = useState({} as CrsAccount)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))

    //gsb default value
    //BlankAc.d_sendemail = "N"

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ClMast2Main disp user_key: ", res.data.Flag.user_key)
                //console.log("ClMast2Main disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClMast2Main disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ClMast2Main disp GsbFlag: ", GsbFlag)
                //console.log("ClMast2Main disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ClMast2Main disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("ClMast2Main disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClMast2Main disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ClMast2Main disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;

                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

/*    
    useEffect(() => {
        (async () => {
            try {
                // get data from c3 api
                //const response = await fetch('http://alex-win7-64-t2/api/AcctInfo?AcctNo1=A0016')
                //const response = await Axios.get('http://alex-win7-64-t2/api/AcctInfo?AcctNo1=A0016')
                const res = await Axios.get('http://alex-win7-64-t2/api/AcctInfo')
                console.log("AccInfoMain res: ", res)
                console.log("AccInfoMain data.LACCT: ", res.data[0].LACCT)
                console.log("AccInfoMain data Name: ", res.data[0].FirstName, res.data[0].LastName)
                //const data = await response.data;
                //console.log("AccInfoMain response.data: ", data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
*/


    const PrevRecProcess = async (ac: CrsAccount) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/ClMast2', {sb_data: ac, cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].LACCT === res.data.data.LACCT) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (ac: CrsAccount) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/ClMast2', {sb_data: ac, cAction: "DelRec"})
            showSuccess("Account No. " + ac.LACCT + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].LACCT === res.data.data.LACCT) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/      
        alert('Cannot delete')
    }

    const NextRecProcess = async (ac: CrsAccount) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/ClMast2', {sb_data: ac, cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].LACCT === res.data.data.LACCT) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (ac: CrsAccount) => {
/*        
        try {
            const res = await gsb.post('/ClMast2', {sb_data: ac, cAction: "AddRec"})
            showSuccess("Account Code " + ac.LACCT + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].LACCT === res.data.data.LACCT) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
        setCurrentMode("show")
}

    const editOnSave = async (ac: CrsAccount) => {
/*
        try {
            console.log("call editOnSave", ac)
            const res = await gsb.post('/ClMast2', {sb_data: ac, cAction: "UpdateRec"})
            showSuccess("Account Code " +ac.LACCT + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].LACCT === res.data.data.LACCT) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/      
        setCurrentMode("show")
        alert('Cannot save')
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await Axios.get('http://alex-win7-64-t2/api/AcctInfo?limit='+m_limit)

                console.log("ListRec res.data.data", res.data)
                //console.log("ListRec res.data.count", res.data.count)
                setData(res.data)
                if (res.data.length > 0) {
                    setCurrentAc(res.data[0])
                }
/*
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
*/                
            } else {
                const res = await Axios.get('http://alex-win7-64-t2/api/AcctInfo?AcctNo1='+searchValue)

                //console.log("onSubmit res.data", res.data)
                setCurrentAc(res.data[0])
                setCurrentMode("show")

            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            //console.log("onSubmit error", error)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
/*    
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await gsb.post('/ClMast2',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            SortByName: false,
                        },
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])
*/

/*
    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/ClMast2',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                idNo: searchIdNo,
                                tel: searchTel,
                                status: searchStatus
                            },
                            },
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
*/

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <ClMast2View ac={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></ClMast2View>
    switch (currentMode) {
        case "edit":
            detail = <ClMast2Edit gsb={gsb} ac={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></ClMast2Edit>
            break
        case "new":
            detail = <ClMast2Edit gsb={gsb} ac={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></ClMast2Edit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-ACName"></th>
                <th className="TCol-ACName"></th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ac, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.LACCT}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.LastName}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.FirstName}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.MiddleName}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-5" label="Search for CRS Client Master testing" labelFor="inputSearchValue">
                        <ControlGroup fill>
                            <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                <option value="acCode">Account</option>
                                {/* <option value="acName">Name</option> */}
                            </HTMLSelect>
                            <InputGroup
                                id="inputSearchValue"
                                placeholder="Search"
                                value={searchValue}
                                onChange={utils.handleStringChange(s => setSearchValue(s))}
                                leftIcon="search"
                                rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                            />
                        </ControlGroup>
                    </FormGroup>
                    <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" type="submit" >Search/List</Button>
                    {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                    <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                </div>
                <Collapse isOpen={showFilters}>
                    <div className="Row">
                    {/* 
                        <FormGroup className="Col-1-1" label="ID" labelFor="searchIdNo">
                            <InputGroup id="searchIdNo" value={searchIdNo} onChange={utils.handleStringChange(s => setSearchIdNo(s))} />
                        </FormGroup>
                        <FormGroup className="Col-2-1" label="Tel" labelFor="searchTel">
                            <InputGroup id="searchTel" value={searchTel} onChange={utils.handleStringChange(s => setSearchTel(s))} />
                        </FormGroup>
                    */}
                        <div className="Col-3-2 Col-H">
                    {/* 
                            <FormGroup label="Status" labelFor="searchStatus">
                                <InputGroup className="Input-XS" id="searchStatus" value={searchStatus} onChange={utils.handleStringChange(s => setSearchStatus(s))} />
                            </FormGroup>
                    */}
                            {/* <FormGroup label="Limit" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>*/}
                        </div>
                    </div>
                </Collapse>
            </form>
            <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                <Tab id="list" title="List" panel={list} />
                <Tab id="detail" title="Detail" panel={detail} />
            </Tabs>
        </>
    );
}

export default ClMast2Main