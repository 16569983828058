import React, { useState, useEffect, FormEvent } from 'react';
import { FileInput, ProgressBar, Checkbox } from "@blueprintjs/core";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';
import * as utils from "./utils";

import svgWait1 from './svg/wait1.svg'
import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';

interface ImportHkConsentCsvMainProps {
    gsb: AxiosInstance
    currentFile?: File
}


 interface File {
    fileName: string,
    select: string,
    size: number,
    type: string,
    contentDate: string,
    records: number,
    error: string,
    delete: string,
    clear: string,     
    UploadFile1: string   
}



interface FileInfo {
    fileName: string,
    select: string,
    size: number,
    type: string,
    contentDate: string,
    records: number,
    error: string,
    delete: string,
    clear: string
}

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


var GsbFlag: any;

function ImportHkConsentCsvMain({ gsb,currentFile ={} as File }: ImportHkConsentCsvMainProps) {
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [fileInfoList, setFileInfoList] = useState([] as FileInfo[])
    const [file1Label, setFile1Label] = useState("Choose file...")
    const [file2Label, setFile2Label] = useState("Choose file...")
    const [file3Label, setFile3Label] = useState("Choose file...")
    const [file4Label, setFile4Label] = useState("Choose file...")
    const [file5Label, setFile5Label] = useState("Choose file...")
    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [SAS_MKT, setSAS_MKT] = useState(false)
    const [SYNC_TEXT, setSYNC_TEXT] = useState(false)
    const [IMP_INET, setIMP_INET] = useState(false)

    const [importok, setimport] = useState(false)
    const [nUploaded, setnUploaded] = useState(0)


    useEffect(() => {
        (async () => {
            try {

                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}

                setSAS_MKT(GsbFlag.SAS_MKT)
                setIMP_INET(GsbFlag.IMP_INET)
                setSYNC_TEXT(GsbFlag.SYNC_TEXT)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
/*
    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/import',
                {   sb_data: {
                        
                    },
                    cPgmId: "importCSVlist",
                });                
                setFileInfoList(res.data.data)
                
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
*/
    useEffect(() => {
        
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await gsb.post('/tasklist', taskList.map((t) => t.id));
                    setTaskList(res.data.data)
                    
                } catch (error) {
                    utils.showError(error)
                }
            }, 3+1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, gsb])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        if ( file1Label == "Choose file  ...  "  || file1Label.length == 0) {
            currentFile.UploadFile1 = ""
            utils.showError("Please choose a file"); 

            return
        } 
        
        (async () => {
            try {
                const res = await gsb.post('/importupload',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setShowTasks(false)
                setimport(true)

                if ( file1Label == "Choose file  ...  " ) {
                    currentFile.UploadFile1 = ""
                } 
                setnUploaded(res.data)
                
                utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)
              //  setFileInfoList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }
 
    const onImportFiles = () => {

        if ( file1Label == "Choose file  ...  " ) {
            currentFile.UploadFile1 = ""
        } else {
            currentFile.UploadFile1 = file1Label
        }
        if ( currentFile.UploadFile1.length > 0 ) {
    
            
            (async () => {
                    try {
                        const res = await gsb.post('/import',
                            {   sb_data: currentFile,
                            cPgmId: "importHkConsentfiles",
                        });                
                        setTaskList(res.data.data)
                        console.count(res.data.data)
                        setShowTasks(true)
                        setFile1Label("Choose file  ...  ")
                        setimport(false)
                        setnUploaded(0)
                        res.data.uploaded = 0
                    } catch (error) {
                        utils.showError(error)
                    }
                })()
        }else {

            utils.showError("Please choose a file"); 

        }
    }

 
    const onSelectfile = (fileName: string) => {
        if (fileName.length > 0 ) {
            //console.log("onSelectfile fileName", fileName);
            //console.log("onSelectfile fileInfoList", fileInfoList);
            let newfileInfoList = [...fileInfoList]

            for (let j = 0; j < newfileInfoList.length; j++) {
                if ( newfileInfoList[j].fileName == fileName ) {
                    if ( newfileInfoList[j].select == "Y" ) {
                        newfileInfoList[j].select = "N"
                    } else {
                        newfileInfoList[j].select = "Y"
                    }
                    break
                }
            }
            setFileInfoList(newfileInfoList);
        }
    }

    const onClearfile = (fileName: string) => {
        if (fileName.length > 0 ) {
            //console.log("onClearfile fileName", fileName);
            console.log("onClearfile fileInfoList", fileInfoList);

            for (let j = 0; j < fileInfoList.length; j++) {
                if ( fileInfoList[j].fileName == fileName ) {
                    if ( fileInfoList[j].clear == "Y" ) {
                        fileInfoList[j].clear = "N"
                    } else if ( fileInfoList[j].clear == "N" ) {
                        fileInfoList[j].clear = "Y"
                    }
                    break
                }
            }
        }
    }
 
    const onDeletefile = (fileName: string) => {
        if (fileName.length > 0 ) {
//            console.log("onDeletefile fileName", fileName);
            (async () => {
                try {
                    const res = await gsb.post('/import',
                        {   sb_data: {
                        filename: fileName   
                        },
                        cPgmId: "deletefile",
                    });                
                    setFileInfoList(res.data.data)
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
        <form className="Import" onSubmit={onSubmit}>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>            
            <div className="Spacing-V-8"></div>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                <p>Upload Files</p>
                    <div className="Row">
                        <FileInput className="Col-1-1" inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                    </div>

                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-1" type="submit" icon={IconNames.UPLOAD}>Upload</Button>               
                    
                        <Button disabled= {!importok} className="Col-2-1" icon={IconNames.IMPORT} onClick={onImportFiles}>Import</Button>
                
                </div>

                <div className="Spacing-V-8"></div>
            
                
    
                <div className="Spacing-V-16"></div>
                <Collapse isOpen={showTasks}>
                    <p>Import Files</p>
                    <HTMLTable striped interactive condensed>
                        <thead>
                            <th className="TCol-Ref">File Name</th>
                            <th className="TCol-Progress">Progress</th>
                            <th className="TCol-Message">Message</th>
                            <th className="TCol-Log">Log</th>
                        </thead>
                        <tbody>
                            {taskList.map((t, i) =>
                                <tr key={i}>
                                    <td className="TCol-Ref">{t.ref}</td>
                                    <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                    <td className="TCol-Message">{t.message}</td>
                                    <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                                </tr>
                            )}
                        </tbody>
                    </HTMLTable>
                    {/*
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-1" type="submit" icon={IconNames.CROSS} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                    </div>
                    */}
                    <div className="Spacing-V-16"></div>
                </Collapse>
                <br></br>
                <p>Notes</p>
                {/*  <div>
                        <div>Trades</div>
                        { SYNC_TEXT && <div>31R Real-Time Data Exchange -- Trade Import, file name: FEBSTRAN.txt</div> }
                        { IMP_INET && <div>Client Trading Text File (Standard), file name: 31I_yyyymmdd.txt</div> }
                        <div><br></br></div>
                        <div>32M HK Stock AMS/3-MWS TEXT FILE FROM SEHK, file name: yyyymmdd.ctf</div>
                        { SAS_MKT && <div>SAS/SZMK Market Stock AMS/3-MWS TEXT FILE FROM SEHK, file name: yyyymmddashr.ctf</div> }
                        <div><br></br></div>
                        <div>Closing Price</div>
                        <div>HK Stock Closing Price, file name: yyyymmdd.cp</div>
                        { SAS_MKT && <div>Shanghai Stock Closing Price, file name: asclospric</div> }
                        { SAS_MKT && <div>Shenzhen Stock Closing Price, file name: szclospric</div> }
                        <div><br></br></div>
                        { IMP_INET && <div>P/R Transaction Text File (Standard), file name: 33I_yyyymmdd.txt</div> }
                        { IMP_INET && <div>D/W Transaction Text File (Standard), file name: 34I_yyyymmdd.txt</div> }
                </div >*/}
            </Card>
        </form>
        </>
    )
}

export default ImportHkConsentCsvMain