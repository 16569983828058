import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'


import { Currency2Info, CCYParamInfo } from './Types'
import { SystemHeader } from './CommonUI';

import { transpileModule } from 'typescript';
import { stringify } from 'querystring';
import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface ExchangeRateViewProps {
    gsb: AxiosInstance,
    ExchangeRate: Currency2Info[],
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function ExchangeRateView({ gsb, ExchangeRate = [] as Currency2Info[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: ExchangeRateViewProps) {

    const [isDeletePress, setisDeletePress] = useState(false)
    const [isParametPress, setisParametPress] = useState(false)

    const [CurrInfo, setCurrInfo] = useState({} as Currency2Info)
    const [currentParameter, setcurrentParameter] = useState({} as CCYParamInfo)

    //console.log("ExchangeRateView: " , ExchangeRate)            

    //if (! ("d_CURCY" in ExchangeRate[0])) return null

    const onPressParameter = (i: number) => {
        //console.log("onPressParameter i: ", i);
        if (i >= 0 ) {
            let CurrCode = ExchangeRate[i].d_CURCY
            console.log("onPressParameter CurrCode: ", CurrCode);

            setCurrInfo(ExchangeRate[i])
            console.log("onPressParameter CurrInfo: ", CurrInfo);
        }
    }


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
               
            </div>
            <div className="Spacing-V-16" />
            {/* display list */}
            <HTMLTable striped interactive condensed>
                <thead>
                    <th className="TCol-Curcy"></th>
                    <th className="TCol-Curcy">Currency</th>
                    <th className="TCol-Curcy"></th>
                    <th className="TCol-Curcy">Exc.Rate </th>
                    <th className="TCol-Curcy"></th>
                    <th className="TCol-PnnChq">Print Chq</th>
                    <th className="TCol-Parameters"></th>
                </thead>
                <tbody id="ma_tr">
                    {ExchangeRate.map((ExRate, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            <td className="TCol-Curcy" onClick={() => { } }>{ExRate.d_EXBASE}</td>
                            <td className="TCol-Curcy" onClick={() => { } }>{ExRate.d_CURCY}</td>
                            <td className="TCol-Curcy">=</td>
                            <td className="TCol-Curcy" onClick={() => { } }>{ExRate.d_EXRATE}</td>
                            <td className="TCol-Curcy" onClick={() => { } }>{ExRate.d_XCURCY}</td>
                            <td className="TCol-PnnChq" onClick={() => { } }><Checkbox disabled={true} checked={ExRate.d_PCHQ=="Y"} /></td>
                            <td><Button disabled={(ExRate.d_CURCY === "")} onClick={() => { onPressParameter(i); setisParametPress(true) } }>Parameters</Button></td>
                        </tr>
                    )}
                </tbody>
            </HTMLTable>

            <Dialog
                icon={IconNames.EDIT} 
                title = "Currency Parameters"
                isOpen={isParametPress}
                onClose={ () => { setisParametPress(false) } }
                style={{ minWidth: '70.0%' }}
            >
                <DialogBody>
                <CurrencyParameterForm gsb={gsb} CurrRec={CurrInfo} CurrRec0={currentParameter} ViewMode={"V"} logKey2="" ></CurrencyParameterForm> 
                </DialogBody>
            </Dialog>
        </div>
    )
}

interface ExchangeRateEditProps {
    gsb: AxiosInstance,
    ExchangeRate?: Currency2Info[],
    isNew?: boolean,
    onSave?: (ExchangeRate: Currency2Info[]) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function ExchangeRateEdit({ gsb, ExchangeRate = [] as Currency2Info[], isNew = false, onSave = () => { }, onCancel = () => { } }: ExchangeRateEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Currency2Info>()
  
    const [ExchangeRateData, setExchangeRateData] = useState([] as Currency2Info[])


/*
    useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_CURCY" in ExchangeRate) {
            reset(ExchangeRate)
        }
    }, [ExchangeRate, reset])
*/
    

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_status" })
    }, [register])

    
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    
    useEffect(() => {
        setExchangeRateData(ExchangeRate)
    }, [])


    //if (! isNew && !("d_tr_date" in ExchangeRate)) return null

    const onChangeEXBASE = (i: number, ExBase: string) => {
        //console.log("onChangeEXBASE i: ", i, ExBase);
        if (i >= 0 ) {
            let n = Number.parseInt(ExBase)
            ExchangeRateData[i].d_EXBASE = n.toString()
            //console.log("onChangeEXBASE ExchangeRateData", ExchangeRateData);
        }
    }

    const onChangeCURCY = (i: number, CURCY: string) => {
        //console.log("onChangeCURCY i: ", i, ExBase);
        if (i >= 0 ) {
            ExchangeRateData[i].d_CURCY = CURCY
            //console.log("onChangeCURCY ExchangeRate", ExchangeRate);
        }
    }
    const onChangeEXRATE = (i: number, EXRATE: string) => {
        //console.log("onChangeEXRATE i: ", i, ExBase);
        if (i >= 0 ) {
            ExchangeRateData[i].d_EXRATE = EXRATE
            //console.log("onChangeEXRATE ExchangeRate", ExchangeRate);
        }
    }
    const onChangeXCURCY = (i: number, XCURCY: string) => {
        //console.log("onChangeXCURCY i: ", i, ExBase);
        if (i >= 0 ) {
            ExchangeRateData[i].d_XCURCY = XCURCY
            //console.log("onChangeXCURCY ExchangeRate", ExchangeRate);
        }
    }

    const onChangePCHQ = (i: number) => {
        //console.log("onChangePCHQ i: ", i);
        if (i >= 0 ) {
            let newData = [...ExchangeRateData]
            if ( newData[i].d_PCHQ == "Y" ) {
                newData[i].d_PCHQ = "N"
            } else {
                newData[i].d_PCHQ = "Y"
            }
            setExchangeRateData(newData)
            //console.log("onChangePCHQ ExchangeRateData", ExchangeRateData);
        }
    }


    const onSaveRec = () => {
        ;

        //console.log("onSaveRec data", data);
        onSave(ExchangeRate)
    }


    const onSubmit = (data: Currency2Info[]) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        //data.d_CE_No = data.d_CE_No.toUpperCase();

/*        

        (async () => {
            try {
                //console.log("call ExchangeRateedit data", data)
                //console.log("call ExchangeRateedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "ExchangeRate", cAction: "ExchangeRateValidate", isNew: isNew})
                //console.log("ExchangeRateedit res.data.ok", res.data.ok)
                //console.log("ExchangeRateedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()
*/
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            //handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>

            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={() => { onSaveRec() }}>Save</Button>
            </div>
            <div className="Spacing-V-16" />
            {/* display list */}
            <HTMLTable striped interactive condensed>
                <thead>
                    <th className="TCol-Curcy"></th>
                    <th className="TCol-Curcy">Currency</th>
                    <th className="TCol-Curcy"></th>
                    <th className="TCol-Curcy">Exc.Rate </th>
                    <th className="TCol-Curcy"></th>
                    <th className="TCol-PnnChq">Print Chq</th>
                </thead>
                <tbody id="ma_tr">
                    {ExchangeRateData.map((ExRate, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            <td className="TCol-Curcy" onClick={() => { } }><InputGroup id="inputEXBASE" defaultValue={ExRate.d_EXBASE} onChange={utils.handleStringChange(s => onChangeEXBASE(i, s ))} /></td>
                            <td className="TCol-Curcy" onClick={() => { } }><InputGroup id="inputEXBASE" defaultValue={ExRate.d_CURCY} onChange={utils.handleStringChange(s => onChangeCURCY(i, s ))} /></td>
                            <td className="TCol-Curcy">=</td>
                            <td className="TCol-Curcy" onClick={() => { } }><InputGroup id="inputEXBASE" defaultValue={ExRate.d_EXRATE} onChange={utils.handleStringChange(s => onChangeEXRATE(i, s ))} /></td>
                            <td className="TCol-Curcy" onClick={() => { } }><InputGroup id="inputEXBASE" defaultValue={ExRate.d_XCURCY} onChange={utils.handleStringChange(s => onChangeXCURCY(i, s ))} /></td>
                            <td className="TCol-PnnChq" onClick={() => { } }><Checkbox checked={ExRate.d_PCHQ=="Y"} onChange={() => onChangePCHQ(i)} /></td>
                        </tr>
                    )}
                </tbody>
            </HTMLTable>

        
        </form>
    )
}


interface CurrencyParameterFormProps {
    gsb: AxiosInstance,
    CurrRec: Currency2Info,
    CurrRec0: CCYParamInfo,
    ViewMode: any,
    logKey2: any,
}


function CurrencyParameterForm({ gsb, CurrRec = {} as Currency2Info, CurrRec0 = {} as CCYParamInfo, ViewMode, logKey2  }: CurrencyParameterFormProps) {

    const { t, i18n } = useTranslation();

    const { register, reset, setError, errors } = useForm<CCYParamInfo>()

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [isCurrencyParameterNew, setisCurrencyParameterNew] = useState(false)
    const [isCurrencyParameterEdit, setisCurrencyParameterEdit] = useState(false)
    const [isCurrencyParameterDeletePress, setisCurrencyParameterDeletePress] = useState(false)

    const [currentParameter, setcurrentParameter] = useState({} as CCYParamInfo)
    const [LastParameter, setLastParameter] = useState({} as CCYParamInfo)
    const [BlankParameter, setBlankParameter] = useState({} as CCYParamInfo)
    const [NoRecParameter, setNoRecParameter] = useState(false)

    const [RateYMDValue, setRateYMDValue] = useState("P")
    const [PrimeRateValue, setPrimeRateValue] = useState("0")
    const [DrMinAmtValue, setDrMinAmtValue] = useState("0")
    const [CrMinAmtValue, setCrMinAmtValue] = useState("0")
    const [YearDaysValue, setYearDaysValue] = useState("365")


    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    //cbMast default value
    BlankParameter.d_Currency = ""
    BlankParameter.d_RateYMD = "P"
    BlankParameter.d_PrimeRate = 0
    BlankParameter.d_DrMinAmt = 0
    BlankParameter.d_CrMinAmt = 0
    BlankParameter.d_YearDays = 365


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;

                //setshowCurrency( (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) );

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        (async () => {
            if ( ViewMode === "V" ) {
                try {
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYParam", CCY: CurrRec.d_CURCY})
                    //console.log("CurrencyParameterForm GetCCYParam: ", res.data.data) 
                    if ( CurrRec.d_CURCY.trim().length > 0 && res.data.count > 0 )
                    {
                        setcurrentParameter(res.data.data[0])
                        setLastParameter(res.data.data[0])
                        setNoRecParameter(false)
                    } else {
                        BlankParameter.d_Currency = CurrRec.d_CURCY
                        setcurrentParameter(BlankParameter)
                        setLastParameter(BlankParameter)
                        setNoRecParameter(true)
                        setisCurrencyParameterNew(true)
                    }
            
                    } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [gsb, CurrRec, CurrRec.d_CURCY])

    useEffect(() => { 
        if ( ViewMode === "V" ) {
            reset(currentParameter);
            setRateYMDValue(currentParameter.d_RateYMD)
            setPrimeRateValue(utils.numToString(currentParameter.d_PrimeRate))
            setDrMinAmtValue(utils.numToString(currentParameter.d_DrMinAmt))
            setCrMinAmtValue(utils.numToString(currentParameter.d_CrMinAmt))
            setYearDaysValue(utils.numToString(currentParameter.d_YearDays))
        }

        if ( ViewMode === "L" ) {
            setRateYMDValue(CurrRec0.d_RateYMD)
            setPrimeRateValue(utils.numToString(CurrRec0.d_PrimeRate))
            setDrMinAmtValue(utils.numToString(CurrRec0.d_DrMinAmt))
            setCrMinAmtValue(utils.numToString(CurrRec0.d_CrMinAmt))
            setYearDaysValue(utils.numToString(CurrRec0.d_YearDays))
        }

    }, [gsb, CurrRec, CurrRec.d_CURCY, currentParameter, currentParameter.d_Currency, isCurrencyParameterEdit])


    const onCurrencyParamenterDelete = ( ) => {

    }

    const onCurrencyParameterSave1 = ( ) => {
        currentParameter.d_Currency = CurrRec.d_CURCY

        // currentCB.d_AuthTran = ""
        // if (isReqAuth) {
        //     currentCB.d_AuthTran = "Y"
        // }

        currentParameter.d_RateYMD = RateYMDValue;
        currentParameter.d_PrimeRate = utils.StringToNum(PrimeRateValue);
        currentParameter.d_DrMinAmt = utils.StringToNum(DrMinAmtValue);
        currentParameter.d_CrMinAmt = utils.StringToNum(CrMinAmtValue);
        currentParameter.d_YearDays = utils.StringToNum(YearDaysValue);

        ;
        (async () => {
            try {
                //console.log("onCurrencyParameterSave1 currentParameter", currentParameter)
                const res = await gsb.post('/Master', {sb_data: currentParameter, cPgmId: "ExchangeRate", cAction: "CPValidate", isNew: isCurrencyParameterNew})
                //console.log("onCurrencyParameterSave1 CPValidate res.data.data", res.data.data)
                ;
                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    setisCurrencyParameterEdit(true)
                    return
                }

                onCurrencyParameterSave2(currentParameter)

            } catch (error) {
                showErrorBox(error)
            }
        })()
    }

    const onCurrencyParameterSave2 = (CurrParadata: CCYParamInfo) => {
        (async () => {
            try {
                console.log("onCurrencyParameterSave2 CurrParadata", CurrParadata)
                var res1_data = {} as CCYParamInfo
                if ( isCurrencyParameterNew ) {
                    const res = await gsb.post('/Master', {sb_data: CurrParadata, cPgmId: "ExchangeRate", cAction: "CPAddRec"})
                    res1_data = res.data.data
                } else {
                    const res = await gsb.post('/Master', {sb_data: CurrParadata, cPgmId: "ExchangeRate", cAction: "CPUpdateRec"})
                    res1_data = res.data.data
                }
                console.log("onForeignCurrencySave2 save res1.data.data", res1_data)
                setcurrentParameter(res1_data)
                setLastParameter(res1_data)
                setisCurrencyParameterEdit(false)

                // if (isReqAuth2) {
                //     utils.showSuccess("Account No. " + res1_data.d_Currency + " record saving, waiting for authorization")

                //     setisShowAlert1(true)
                //     setShowAlert1Msg("Account No. " + res1_data.d_Currency  + " record saving, waiting for authorization")
                // } else {
                    utils.showSuccess("Currency Parameter " + res1_data.d_Currency + " record saved")

                    setisShowAlert1(true)
                    setShowAlert1Msg("Currency Parameter " + res1_data.d_Currency + " record saved")
                // }

            } catch (error) {
                //utils.showError(error)
                showErrorBox(error)
            }
        })()
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    if (! ("d_CURCY" in CurrRec)) return null


    return (
        <form>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >            
                <div><H3>Currency Parameters</H3></div>
                { ! isCurrencyParameterEdit && ViewMode === "V" && <div>
                    <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => {} } >Prev</Button>
                    <Button className="Col-2-1" disabled={! isCurrencyParameterNew} icon="add" onClick={() => { BlankParameter.d_Currency = CurrRec.d_CURCY; setcurrentParameter(BlankParameter); setisCurrencyParameterNew(true) ; setisCurrencyParameterEdit(true) } } >New</Button>
                    <Button className="Col-3-1" disabled={NoRecParameter} icon="edit" onClick={() => { setisCurrencyParameterNew(false) ; setisCurrencyParameterEdit(true) } } >Edit</Button>
                    <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisCurrencyParameterDeletePress(true) } >Delete</Button>
                        <Alert
                            cancelButtonText="Cancel"
                            confirmButtonText="Delete"
                            canEscapeKeyCancel={true}
                            icon="trash"
                            intent={Intent.DANGER}
                            isOpen={isCurrencyParameterDeletePress}
                            onCancel={ () => setisCurrencyParameterDeletePress(false) }
                            onConfirm={ () => { onCurrencyParamenterDelete(); setisCurrencyParameterDeletePress(false) } }
                        >
                            <p>
                                Are you sure to Delete ?
                            </p>
                        </Alert>
                    <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => {} } >Next</Button>
                </div> }
                { isCurrencyParameterEdit && ViewMode === "V" && <div>
                    <Button className="Col-1-1" icon="cross" onClick={() => { setcurrentParameter(LastParameter); setisCurrencyParameterEdit(false) } }>Cancel</Button>
                    <Button className="Col-2-1" icon="tick" onClick={() => { onCurrencyParameterSave1() } }>Save</Button>
                </div> }
                { ViewMode === "L" && <div>
                    Currency: {currentParameter.d_Currency}
                    { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                    { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                    { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                    <div className="Spacing-V-16" />
                </div> }

                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Currency" labelInfo="*">
                        <InputGroup readOnly value={CurrRec.d_CURCY} />
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    { ! isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Int Rate Calc. Y/P/D)" labelInfo="*">
                        <InputGroup readOnly value={RateYMDValue} />
                        { currentParameter.d_RateYMD === "Y" && <div id="DispText" >Y:  Yearly Rate</div> }
                        { currentParameter.d_RateYMD === "P" && <div id="DispText" >P:  Prime Rate</div> }
                        { currentParameter.d_RateYMD === "D" && <div id="DispText" >D:  Daily Rate</div> }
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (CurrRec0.d_RateYMD.toString() == "") && 
                        <div id="DispText2" > {CurrRec0.d_RateYMD} </div> }
                    </FormGroup> }
                    { isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Int Rate Calc. Y/P/D)" labelInfo="*">
                        {/* <InputGroup id="inputRateYMD" value={RateYMDValue} autoFocus={true} placeholder="Int Rate Calc. Y/P/D) ..." onChange={utils.handleStringChange(s => setRateYMDValue(s)) } inputRef={register({ }) } /> */}
                        <select  id="inputRateYMD" value={RateYMDValue} onChange={utils.handleStringChange(s => setRateYMDValue(s)) } >
                        <option value="Y">Y:  Yearly Rate</option>
                        <option value="P">P:  Prime Rate</option>
                        <option value="D">D:  Daily Rate</option>
                    </select>
                        {errors.d_RateYMD && errors.d_RateYMD.types && <span id="Err_msg">{errors.d_RateYMD.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Prime Int Rate" >
                        <InputGroup readOnly value={PrimeRateValue.toString()} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (CurrRec0.d_PrimeRate.toString() == "") && 
                        <div id="DispText2" > {CurrRec0.d_PrimeRate} </div> }
                    </FormGroup> }
                    { isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Prime Int Rate">
                        <InputGroup id="inputPrimeRate" defaultValue={PrimeRateValue} value={PrimeRateValue} onChange={utils.handleStringChange(n => setPrimeRateValue(n))} />
                        {errors.d_PrimeRate && errors.d_PrimeRate.type === "min" && <span id="Err_msg">Prime Int Rate sholud be greater than 0 </span>}
                        {errors.d_PrimeRate && errors.d_PrimeRate.types && <span id="Err_msg">{errors.d_PrimeRate.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Min Debit Amount" >
                        <InputGroup readOnly value={DrMinAmtValue.toString()} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (CurrRec0.d_DrMinAmt.toString() == "") && 
                        <div id="DispText2" > {CurrRec0.d_DrMinAmt} </div> }
                    </FormGroup> }
                    { isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Min Debit Amount">
                        <InputGroup id="inputDrMinAmt" defaultValue={DrMinAmtValue} value={DrMinAmtValue} onChange={utils.handleStringChange(n => setDrMinAmtValue(n))} />
                        {errors.d_DrMinAmt && errors.d_DrMinAmt.type === "min" && <span id="Err_msg">Min Debit Amount sholud be greater than 0 </span>}
                        {errors.d_DrMinAmt && errors.d_DrMinAmt.types && <span id="Err_msg">{errors.d_DrMinAmt.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Min Credit Amount" >
                        <InputGroup readOnly value={CrMinAmtValue.toString()} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (CurrRec0.d_CrMinAmt.toString() == "") && 
                        <div id="DispText2" > {CurrRec0.d_CrMinAmt} </div> }
                    </FormGroup> }
                    { isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Min Credit Amount">
                        <InputGroup id="inputCrMinAmt" defaultValue={CrMinAmtValue} value={CrMinAmtValue} onChange={utils.handleStringChange(n => setCrMinAmtValue(n))} />
                        {errors.d_CrMinAmt && errors.d_CrMinAmt.type === "min" && <span id="Err_msg">Min Debit Amount sholud be greater than 0 </span>}
                        {errors.d_CrMinAmt && errors.d_CrMinAmt.types && <span id="Err_msg">{errors.d_CrMinAmt.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Day Count Convention" >
                        <InputGroup readOnly value={YearDaysValue.toString()} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (CurrRec0.d_YearDays.toString() == "") && 
                        <div id="DispText2" > {CurrRec0.d_YearDays} </div> }
                    </FormGroup> }
                    { isCurrencyParameterEdit && <FormGroup className="Col-1-2" label="Day Count Convention">
                        <InputGroup id="inputYearDays" defaultValue={YearDaysValue} value={YearDaysValue} onChange={utils.handleStringChange(n => setYearDaysValue(n))} />
                        {errors.d_YearDays && errors.d_YearDays.type === "min" && <span id="Err_msg">Day Count Convention sholud be greater than 0 </span>}
                        {errors.d_YearDays && errors.d_YearDays.types && <span id="Err_msg">{errors.d_YearDays.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
            </Card>
            <div className="Spacing-V-16"></div>
            <Alert
                confirmButtonText="OK"
                icon="tick-circle"
                intent={Intent.SUCCESS}
                isOpen={isShowAlert1}
                onCancel={ () => setisShowAlert1(false) }
                onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
            >
                <p>
                    {ShowAlert1Msg}
                </p>
            </Alert>
            <Alert
                confirmButtonText="OK"
                icon="cross"
                intent={Intent.WARNING}
                isOpen={isShowAlert2}
                onCancel={ () => setisShowAlert2(false) }
                onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
            >
                <p>
                    {ShowAlert2Msg}
                </p>
            </Alert>
        </form>
    )

}


interface ExchangeRateMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ExchangeRateMain({ gsb }: ExchangeRateMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as Currency2Info[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentAc, setCurrentAc] = useState([] as Currency2Info[])
    const [BlankAc, setBlankAc] = useState({} as Currency2Info)


    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    

    
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankAc.d_EXBASE = ""
    BlankAc.d_CURCY = ""
    BlankAc.d_EXRATE = "1"
    BlankAc.d_XCURCY = "HKD"
    BlankAc.d_PCHQ = "N"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ExchangeRateMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ExchangeRateMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ExchangeRateMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ExchangeRateMain disp GsbFlag: ", GsbFlag)
                //console.log("ExchangeRateMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ExchangeRateMain disp dateFormat: ", dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("ExchangeRateMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ExchangeRateMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ExchangeRateMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;

                //console.log("ExchangeRateMain isProduction: ", GsbFlag.isProduction)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])


    useEffect(() => {

        console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, GsbFlag, cardOpen01, currentMode])


    useEffect(() => {

        setnPage(1)
        ;

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "ExchangeRate",
                        cAction: "ListRec",
                    });
                console.log("ExchangeRateMain Currency2Info ", res.data.data) 
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data)
                }

                console.log("main currentAc", currentAc)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb ])



    const PrevRecProcess = async (ExchangeRate: Currency2Info[]) => {
/*        
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "ExchangeRate", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const DeleteProcess = async (ExchangeRate: Currency2Info[]) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "ExchangeRate", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const NextRecProcess = async (ExchangeRate: Currency2Info[]) => {
/*        
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "ExchangeRate", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const CancelProcess = () => {
        console.log("CancelProcess currentAc", currentAc)
        window.location.reload()
        setCurrentMode("show")
    }

    const newOnSave = async (ExchangeRate: Currency2Info[]) => {
/*        
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "ExchangeRate", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const editOnSave = async (ExchangeRate: Currency2Info[]) => {

        try {
            console.log("call editOnSave ExchangeRate", ExchangeRate)

            const res = await gsb.post('/Master', {sb_data: ExchangeRate, cPgmId: "ExchangeRate", cAction: "UpdateRec"})

            console.log("call editOnSave res.data.data", res.data.data)
            
            if ( res.data.ok ) {
                setCurrentAc(res.data.data)
                setisShowAlert1(true)
                setShowAlert1Msg(res.data.data_Msg)
                setCurrentMode("show")
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
                setCurrentMode("show")
            }

        } catch (error) {
            utils.showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <ExchangeRateView gsb={gsb} ExchangeRate={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></ExchangeRateView>
    switch (currentMode) {
        case "edit":
            detail = <ExchangeRateEdit gsb={gsb} ExchangeRate={currentAc} onSave={editOnSave} onCancel={() => {CancelProcess(); setCurrentMode("show") } }></ExchangeRateEdit>
            break
        case "new":
            detail = <ExchangeRateEdit gsb={gsb} ExchangeRate={currentAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></ExchangeRateEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    

    return (
        <>
            <form >
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>122 Exchange Rate Setup</H3>
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}

                    {detail}
                </Card>
                <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>
            </form>
        </>
    );
}

export default ExchangeRateMain