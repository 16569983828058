import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, Button, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { Alert, Dialog, DialogBody, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';

var GsbFlag: any;


interface ReUpdateTrnMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


function ReUpdateTrnMain({ gsb }: ReUpdateTrnMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [ReindexMsg, setReindexMsg] = useState("")


    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    const [isShowAlert3, setisShowAlert3] = useState(false)
    const [ShowAlert3Msg, setShowAlert3Msg] = useState([])

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    useEffect(() => {
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await gsb.post('/tasklist', taskList.map((t) => t.id));
                    //console.log("tasklist res.data.data", res.data.data)
                    setTaskList(res.data.data)
                } catch (error) {
                    utils.showError(error)
                }
            }, 3*1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, gsb])


    useEffect(() => {
        setTimeout(async () => {
            setReindexMsg("")
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "xProcess",
                });                
                if ( res.data.count > 0 ) {
                    if ( res.data.data[0].x_Porcessing.substr(2,7) == "Reindex" ) {
                        setReindexMsg(res.data.data[0].x_Porcessing)
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        }, 1*1000)
    }, [taskList, gsb])


    const onReUpdateTrn = () => {
        //console.log("onReUpdateTrn");
        (async () => {
            try {
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "ReUpdateTrn",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                setTaskList(res.data.data)
                setShowTasks(true)
                if (! res.data.ok) {
                    if (res.data.Prompt_Msg.isShowMsg) {
                        setisShowAlert3(true)
                        setShowAlert3Msg(res.data.Prompt_Msg.MsgShow)
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <p><H3>7R Re-update Current Day Transactions</H3></p>
                    <div>
                        <div>Process : {ReindexMsg}</div>
                    </div >
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-3" icon={IconNames.BUILD} onClick={onReUpdateTrn}>Re-update Current Day Transactions</Button>
                        <Button className="Col-4-1" icon={IconNames.LIST} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <Collapse isOpen={showTasks}>
                        <p>Tasks</p>
                        <HTMLTable striped interactive condensed>
                            <thead>
                                <th className="TCol-Ref">File Name</th>
                                <th className="TCol-Progress">Progress</th>
                                <th className="TCol-Message">Message</th>
                                <th className="TCol-Log">Log</th>
                            </thead>
                            <tbody>
                                {taskList.map((t, i) =>
                                    <tr key={i}>
                                        <td className="TCol-Ref">{t.ref}</td>
                                        <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                        <td className="TCol-Message">{t.message}</td>
                                        <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                                    </tr>
                                )}
                            </tbody>
                        </HTMLTable>
                        {/*
                        <div className="Spacing-V-8"></div>
                        <div className="Row">
                            <Button className="Col-1-1" type="submit" icon={IconNames.CROSS} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                        </div>
                        */}
                    </Collapse>
                    <div className="Spacing-V-16"></div>
                    {/*
                    <div >Note : </div>
                    <div >Re-update Current Day Transactions</div>
                    */}
                </Card>
                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert3}
                        onCancel={ () => setisShowAlert3(false) }
                        onConfirm={ () => { setShowAlert3Msg([]); setisShowAlert3(false) } }
                    >
                        <p>
                            {/*ShowAlert3Msg*/}
                            <div className="Spacing-V-16"></div>
                            {ShowAlert3Msg.map((Wmsg, j) => 
                                <div>{ShowAlert3Msg[j]}</div>
                            )}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default ReUpdateTrnMain