import React, { useState, FormEvent, useEffect } from "react";

import { useParams } from "react-router-dom";

import { Suggest, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import {
  FormGroup,
  InputGroup,
  AnchorButton,
  Button,
  Tabs,
  Tab,
  Collapse,
  HTMLSelect,
  HTMLTable,
  ControlGroup,
  NumericInput,
  TextArea,
  Position,
  Toaster,
  Intent,
  MenuItem,
} from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from "axios";

import * as utils from "./utils";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import svgWait2 from "./svg/wait2.gif";

import { StockAllocationInfo } from "./Types";
import { SystemHeader } from "./CommonUI";

import i18next from "i18next";

interface PageInfo {
  n: number;
}

var GsbFlag: any;

interface StockAllocationMastViewProps {
  gsb: AxiosInstance;
  StockAllocation: StockAllocationInfo;
  baseUrl?: string;
  token?: string | null;
  onPrevRec?: () => void;
  onNew?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onNextRec?: () => void;
}

function StockAllocationMastView({
  gsb,
  StockAllocation = {} as StockAllocationInfo,
  baseUrl = "",
  token = "",
  onPrevRec = () => {},
  onNew = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onNextRec = () => {},
}: StockAllocationMastViewProps) {
  const [isAdd, setisAdd] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isDelete, setisDelete] = useState(false);

  const { t, i18n } = useTranslation();

  // const [AcCodeValue, setAcCodeValue] = useState(StockAllocation.d_acCode);
  // const [StkNoValue, setStkNoValue] = useState(StockAllocation.d_StkNo);
  //const [TransferQtyValue, setTransferQtyValue] = useState(0);
  const [OHQtyValue, setOHQtyValue] = useState(0);
  const [T1QtyValue, setT1QtyValue] = useState(0);
  const [T2QtyValue, setT2QtyValue] = useState(0);
  const [ShowError, setShowError] = useState("");
  const [CCASSStockValue, setCCASSStockValue] = useState("");
  const [StkNameValue, setStkNameValue] = useState("");
  const [StkLotsize, setStkLotsize] = useState(0);
  const [AcName1Value, setAcName1Value] = useState("");
  const [AcName2Value, setAcName2Value] = useState("");

  function buttonPress(buttonName: string) {
    if (buttonName == "new") {
      onNew();
    }
    if (buttonName == "edit") {
      onEdit();
    }
    if (buttonName == "delete") {
      setisDelete(true);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await gsb.post("/Master", {
          sb_data: "",
          cPgmId: "StockAllocationEntry",
          cAction: "GetCLSTKInfo",
          cAcCode: StockAllocation.d_acCode,
          cStkNo: StockAllocation.d_StkNo,
        });
        // console.log("StockAllocationEntry View GetCLSTKInfo data : ", res.data.data)
        // console.log("StockAllocationEntry View GetCLSTKInfo  count: ", res.data.count)

        if (
          StockAllocation.d_acCode.trim().length > 0 &&
          StockAllocation.d_StkNo.trim().length > 0 &&
          res.data.count > 0
        ) {
          setOHQtyValue(res.data.data[0].d_OH_Qty);
          setT1QtyValue(res.data.data[0].d_T1_Qty);
          setT2QtyValue(res.data.data[0].d_T2_Qty);
          setAcName1Value(res.data.data[0].d_acName1);
          setAcName2Value(res.data.data[0].d_acName2);
        } else {
          setOHQtyValue(0);
          setT1QtyValue(0);
          setT2QtyValue(0);
          setAcName1Value("");
          setAcName2Value("");
        }
      } catch (error) {
        //showErrorBox(error)
      }
    })();
  }, [gsb, StockAllocation.d_acCode, StockAllocation.d_StkNo]);

  // 20230508
  useEffect(() => {
    (async () => {
      try {
        // get Stk Info
        const res = await gsb.post("/Master", {
          sb_data: "",
          cPgmId: "Smmast",
          cAction: "GetStkInfo",
          cStkNo: StockAllocation.d_StkNo,
        });

        if (StockAllocation.d_StkNo.trim().length > 0 && res.data.count > 0) {
          if (res.data.data[0].d_CcassStk === "Y") {
            setCCASSStockValue("Y");
          } else {
            setCCASSStockValue("N");
          }
          setStkNameValue(res.data.data[0].d_StkName);
          setStkLotsize(res.data.data[0].d_LotSize);
        } else {
          setStkNameValue("");
          setCCASSStockValue("");
          setStkLotsize(0);
        }
      } catch (error) {
        //showErrorBox(error)
      }
    })();
  }, [gsb, StockAllocation.d_StkNo]);

  useEffect(() => {
    if (
      OHQtyValue -
        StockAllocation.d_Str_Qty -
        StockAllocation.d_Com_Qty -
        StockAllocation.d_Cas_Qty -
        StockAllocation.d_Nom_Qty -
        StockAllocation.d_Td_Qty -
        StockAllocation.d_Ban_Qty -
        StockAllocation.d_Oth_Qty -
        T2QtyValue -
        StockAllocation.d_Day_Dw -
        T1QtyValue !=
      0
    ) {
      setShowError("** ERROR **");
    } else {
      setShowError("");
    }
  }, [gsb, OHQtyValue, T1QtyValue, T2QtyValue]);

  if (!("d_acCode" in StockAllocation)) return null;

  return (
    <div>
      {/* define add,change,delete button */}
      <div className="Row">
        <Button
          className="Col-1-1"
          icon="direction-left"
          onClick={() => onPrevRec()}
        >
          Prev
        </Button>
        {/* <Button
          className="Col-2-1"
          icon="add"
          onClick={() => buttonPress("new")}
        >
          New
        </Button>
        <Alert
          confirmButtonText="OK"
          icon="disable"
          intent={Intent.WARNING}
          isOpen={isAdd}
          onConfirm={() => {
            setisAdd(false);
          }}
        >
          <p>NEW : Access Denied</p>
        </Alert> */}
        <Button
          className="Col-3-1"
          icon="edit"
          onClick={() => buttonPress("edit")}
        >
          Edit
        </Button>
        <Alert
          confirmButtonText="OK"
          icon="disable"
          intent={Intent.WARNING}
          isOpen={isEdit}
          onConfirm={() => {
            setisEdit(false);
          }}
        >
          <p>EDIT : Access Denied</p>
        </Alert>
        {/* <Button
          className="Col-4-1"
          icon="delete"
          onClick={() => buttonPress("delete")}
        >
          Delete
        </Button>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          canEscapeKeyCancel={true}
          icon="trash"
          intent={Intent.DANGER}
          isOpen={isDelete}
          onCancel={() => setisDelete(false)}
          onConfirm={() => {
            onDelete();
            setisDelete(false);
          }}
        >
          <p>Are you sure to Delete ?</p>
        </Alert> */}
        <Button
          className="Col-5-1"
          icon="direction-right"
          onClick={() => onNextRec()}
        >
          Next
        </Button>
      </div>
      <div className="Spacing-V-16" />
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Account No."
          labelFor="inputAcCode"
        >
          <InputGroup readOnly value={StockAllocation.d_acCode} />
        </FormGroup>
        <FormGroup
          className="Col-3-3"
          label="Account Name"
          labelFor="inputAcName"
        >
          <div>
            <div id="DispText">{AcName1Value}</div>
            <div id="DispText">{AcName2Value}</div>
          </div>
        </FormGroup>
        <FormGroup className="Col-6-1" label="" labelFor="inputQtyOnHand">
          <div id="Err_msg">{ShowError}</div>
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo">
          <InputGroup readOnly value={StockAllocation.d_StkNo} />
        </FormGroup>
        <FormGroup
          className="Col-3-2"
          label="Stock Code/Name"
          labelFor="inputStkCode"
        >
          {/* <InputGroup readOnly value={StockAllocation.d_StkCode} /> */}
          <div>
            <div id="DispText">{StockAllocation.d_StkCode}</div>
            <div id="DispText">{StkNameValue}</div>
            <div id="Err_msg"></div>
          </div>
        </FormGroup>
        <FormGroup
          className="Col-5-1"
          label="Stock Information"
          labelFor="inputStkCode"
        >
          <div>
            <div id="DispText">CCASS Stock: {CCASSStockValue} </div>
            <div id="DispText">Lot Size: {StkLotsize}</div>
          </div>
        </FormGroup>
        <FormGroup
          className="Col-6-1"
          label="Qty On Hand"
          labelFor="inputQtyOnHand"
        >
          <InputGroup readOnly value={utils.formatNumber2(OHQtyValue)} />
          {/* <div id="DispText">Qty On Hand: {utils.formatNumber2(OHQtyValue)}</div>
            <div id="DispText">Unsettled B/S Qty: {utils.formatNumber2(StockAllocation.d_Day_Bs)}</div>
            <div id="DispText">Current Day D/W Qty: {utils.formatNumber2(StockAllocation.d_Day_Dw)}</div>
            <div id="DispText">Current Day STL Qty: {utils.formatNumber2(T2QtyValue)}</div> */}
        </FormGroup>

        {/* <FormGroup
          className="Col-6-1"
          label="Unsettled B/S Qty"
          labelFor="inputUnsettleBSQty"
        >
            <div id="DispText">{utils.formatNumber2(StockAllocation.d_Day_Bs)}</div>
        </FormGroup>
        <FormGroup
          className="Col-7-1"
          label="Current Day D/W Qty"
          labelFor="inputDayDWQty"
        >
            <div id="DispText">{utils.formatNumber2(StockAllocation.d_Day_Dw)}</div>
        </FormGroup>
        <FormGroup
          className="Col-8-1"
          label="Current Day STL Qty"
          labelFor="inputDaySTLQty"
        >
            <div id="DispText">{utils.formatNumber2(T2QtyValue)}</div>
        </FormGroup>         */}
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="1) Street Name"
          labelFor="inputStrQty"
        >
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Str_Qty)}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="2) Nominee Name"
          labelFor="inputComQty"
        >
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Com_Qty)}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="3) CCASS (Street)"
          labelFor="inputCasQty"
        >
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Cas_Qty)}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="4) CCASS (Nominee)"
          labelFor="inputNomQty"
        >
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Nom_Qty)}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="5) Registry"
          labelFor="inputTdQty"
        >
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Td_Qty)}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup className="Col-1-1" label="6) Bank" labelFor="inputBanQty">
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Ban_Qty)}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup className="Col-1-1" label="7) Other" labelFor="inputBanQty">
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Oth_Qty)}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Unsettled B/S Qty"
          labelFor="inputUnsettleBSQty"
        >
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Day_Bs)}
          />
          {/* <div>
            <div id="DispText">
              {utils.formatNumber2(StockAllocation.d_Day_Bs)}
            </div>
          </div> */}
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Current Day D/W Qty"
          labelFor="inputDayDWQty"
        >
          <InputGroup
            readOnly
            value={utils.formatNumber2(StockAllocation.d_Day_Dw)}
          />
          {/* <div id="DispText">
              {utils.formatNumber2(StockAllocation.d_Day_Dw)}
            </div> */}
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Current Day STL Qty"
          labelFor="inputDaySTLQty"
        >
          <InputGroup readOnly value={utils.formatNumber2(T2QtyValue)} />
          {/* <div id="DispText">{utils.formatNumber2(T2QtyValue)}</div> */}
        </FormGroup>
      </div>
    </div>
  );
}

interface StockAllocationMastEditProps {
  gsb: AxiosInstance;
  StockAllocation?: StockAllocationInfo;
  isNew?: boolean;
  onSave?: (StockAllocation: StockAllocationInfo) => void;
  onCancel?: () => void;
}

function StockAllocationMastEdit({
  gsb,
  StockAllocation = {} as StockAllocationInfo,
  isNew = false,
  onSave = () => {},
  onCancel = () => {},
}: StockAllocationMastEditProps) {
  const { register, handleSubmit, reset, setValue, setError, errors } =
    useForm<StockAllocationInfo>();
  const { t, i18n } = useTranslation();

  const [AcCodeValue, setAcCodeValue] = useState(StockAllocation.d_acCode);
  const [StkNoValue, setStkNoValue] = useState(StockAllocation.d_StkNo);
  const [TransferQtyValue, setTransferQtyValue] = useState(0);
  const [OHQtyValue, setOHQtyValue] = useState(0);
  const [T1QtyValue, setT1QtyValue] = useState(0);
  const [T2QtyValue, setT2QtyValue] = useState(0);
  const [ShowError, setShowError] = useState(""); // 20230418
  const [FromLocValue, setFromLocValue] = useState("");
  const [ToLocValue, setToLocValue] = useState("");

  const [NewStrQty, setNewStrQty] = useState(0);
  const [NewComQty, setNewComQty] = useState(0);
  const [NewCasQty, setNewCasQty] = useState(0);
  const [NewNomQty, setNewNomQty] = useState(0);
  const [NewTdQty, setNewTdQty] = useState(0);
  const [NewBanQty, setNewBanQty] = useState(0);
  const [NewOthQty, setNewOthQty] = useState(0);

  const [CCASSStockValue, setCCASSStockValue] = useState("");
  const [StkLotsize, setStkLotsize] = useState(0);
  const [StkNameValue, setStkNameValue] = useState("");
  const [AcName1Value, setAcName1Value] = useState("");
  const [AcName2Value, setAcName2Value] = useState("");

  useEffect(() => {
    // Suggest don't have ref to use with react-hook-form, so register it (d_Channel) manually and call setValue when item is selected
    register({ name: "d_StkNo" });
  }, [register]);

  useEffect(() => {
    // Call reset to get the values of record StkInfo
    if ("d_acCode" in StockAllocation) {
      reset(StockAllocation);
    }
  }, [StockAllocation, reset]);

  useEffect(() => {
    if (GsbFlag.Enter2tab == "Y") {
      let inputs = utils.PerformEnter2Tab();
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // get Stk Info
        const res = await gsb.post("/Master", {
          sb_data: "",
          cPgmId: "Smmast",
          cAction: "GetStkInfo",
          cStkNo: StockAllocation.d_StkNo,
        });

        if (StockAllocation.d_StkNo.trim().length > 0 && res.data.count > 0) {
          if (res.data.data[0].d_CcassStk === "Y") {
            setCCASSStockValue("Y");
          } else {
            setCCASSStockValue("N");
          }
          setStkNameValue(res.data.data[0].d_StkName);
          setStkLotsize(res.data.data[0].d_LotSize);
        } else {
          setStkNameValue("");
          setCCASSStockValue("");
          setStkLotsize(0);
        }
      } catch (error) {
        //showErrorBox(error)
      }
    })();
  }, [gsb, StockAllocation.d_StkNo]);

  useEffect(() => {
    (async () => {
      try {
        const res = await gsb.post("/Master", {
          sb_data: "",
          cPgmId: "StockAllocationEntry",
          cAction: "GetCLSTKInfo",
          cAcCode: AcCodeValue,
          cStkNo: StkNoValue,
        });

        if (
          AcCodeValue.trim().length > 0 &&
          StkNoValue.trim().length > 0 &&
          res.data.count > 0
        ) {
          setOHQtyValue(res.data.data[0].d_OH_Qty);
          setT1QtyValue(res.data.data[0].d_T1_Qty);
          setT2QtyValue(res.data.data[0].d_T2_Qty);
          setAcName1Value(res.data.data[0].d_acName1);
          setAcName2Value(res.data.data[0].d_acName2);
        } else {
          setOHQtyValue(0);
          setT1QtyValue(0);
          setT2QtyValue(0);
          setAcName1Value("");
          setAcName2Value("");
        }
      } catch (error) {
        //showErrorBox(error)
      }
    })();
  }, [gsb, AcCodeValue, StkNoValue]);

  useEffect(() => {
    if (
      OHQtyValue -
        StockAllocation.d_Str_Qty -
        StockAllocation.d_Com_Qty -
        StockAllocation.d_Cas_Qty -
        StockAllocation.d_Nom_Qty -
        StockAllocation.d_Td_Qty -
        StockAllocation.d_Ban_Qty -
        StockAllocation.d_Oth_Qty -
        T2QtyValue -
        StockAllocation.d_Day_Dw -
        T1QtyValue !=
      0
    ) {
      setShowError("** ERROR **");
    } else {
      setShowError("");
    }
  }, [gsb, OHQtyValue, T1QtyValue, T2QtyValue]);

  useEffect(() => {
    setNewStrQty(StockAllocation.d_Str_Qty);
    setNewComQty(StockAllocation.d_Com_Qty);
    setNewCasQty(StockAllocation.d_Cas_Qty);
    setNewNomQty(StockAllocation.d_Nom_Qty);
    setNewTdQty(StockAllocation.d_Td_Qty);
    setNewBanQty(StockAllocation.d_Ban_Qty);
    setNewOthQty(StockAllocation.d_Oth_Qty);

    switch (FromLocValue) {
      case "1":
        // console.log("1: ",StockAllocation.d_Str_Qty, TransferQtyValue,StockAllocation.d_Str_Qty - TransferQtyValue );
        setNewStrQty(StockAllocation.d_Str_Qty - TransferQtyValue);
        // console.log("NewStrQty: ",NewStrQty);
        break;
      case "2":
        setNewComQty(StockAllocation.d_Com_Qty - TransferQtyValue);
        break;
      case "3":
        setNewCasQty(StockAllocation.d_Cas_Qty - TransferQtyValue);
        break;
      case "4":
        setNewNomQty(StockAllocation.d_Nom_Qty - TransferQtyValue);
        break;
      case "5":
        setNewTdQty(StockAllocation.d_Td_Qty - TransferQtyValue);
        break;
      case "6":
        setNewBanQty(StockAllocation.d_Ban_Qty - TransferQtyValue);
        break;
      case "7":
        setNewOthQty(StockAllocation.d_Oth_Qty - TransferQtyValue);
        break;
    }
    switch (ToLocValue) {
      case "1":
        setNewStrQty(StockAllocation.d_Str_Qty + TransferQtyValue);
        break;
      case "2":
        setNewComQty(StockAllocation.d_Com_Qty + TransferQtyValue);
        break;
      case "3":
        setNewCasQty(StockAllocation.d_Cas_Qty + TransferQtyValue);
        break;
      case "4":
        setNewNomQty(StockAllocation.d_Nom_Qty + TransferQtyValue);
        break;
      case "5":
        setNewTdQty(StockAllocation.d_Td_Qty + TransferQtyValue);
        break;
      case "6":
        setNewBanQty(StockAllocation.d_Ban_Qty + TransferQtyValue);
        break;
      case "7":
        setNewOthQty(StockAllocation.d_Oth_Qty + TransferQtyValue);
        break;
    }
  }, [gsb, TransferQtyValue, FromLocValue, ToLocValue]);

  if (!isNew && !("d_acCode" in StockAllocation)) return null;

  const onSubmit = (data: StockAllocationInfo) => {
    // console.log("StockAllocationMastEdit onsubmit data: ", data)

    // put value to json/field before write database dbf
    data.d_Tranf_Qty = TransferQtyValue;
    // data.d_From_Loc = FromLocValue
    // data.d_To_Loc = ToLocValue;
    (async () => {
      try {
        //console.log("call StockAllocationMastEdit data", data)
        //console.log("call StockAllocationMastEdit isNew", isNew)
        const res = await gsb.post("/Master", {
          sb_data: data,
          cPgmId: "StockAllocationEntry",
          cAction: "StockAllocationValidate",
          isNew: isNew,
        });
        // console.log("StockAllocationMastEdit res.data.ok", res.data.ok)
        // console.log("StockAllocationMastEdit res.data.data", res.data.data)

        if (!res.data.ok) {
          for (let j = 0; j < res.data.data.length; j++) {
            setError(res.data.data[j][0], {
              message: res.data.data[j][1],
            });
          }
          return;
        }
        onSave(data);
      } catch (error) {
        showErrorBox(error);
      }
    })();

    //onSave(data)
  };

  function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map(escapeRegExpChars);
    if (words.length === 0) {
      return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  }

  function escapeRegExpChars(text: string) {
    //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
  }

  const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
    //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

    // ctrl + s
    if (e.ctrlKey && e.keyCode === 83) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const showErrorBox = (err: any) => {
    alert(
      "Error:" +
        err.response.data.subSystem +
        " - " +
        err.response.data.description +
        ", OS code:" +
        err.response.data.osCode
    );
  };

  /*<form onSubmit={handleSubmit(onSubmit)}>*/
  return (
    <form onKeyDown={handleHotkey}>
      <div className="Row">
        <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          className="Col-2-1"
          icon="tick"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
      <div className="Spacing-V-16" />
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Account No."
          labelFor="inputAcCode"
        >
          <InputGroup
            disabled={!isNew}
            id="inputAcCode"
            name="d_acCode"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-3-2"
          label="Account Name"
          labelFor="inputAcName"
        >
          <div>
            <div id="DispText">{AcName1Value}</div>
            <div id="DispText">{AcName2Value}</div>
          </div>
        </FormGroup>
        <FormGroup className="Col-6-1" label="" labelFor="">
          <div id="Err_msg">{ShowError}</div>
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup className="Col-1-1" label="Stock No" labelFor="inputStkNo">
          <InputGroup
            disabled={!isNew}
            id="inputStkNo"
            name="d_StkNo"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-3-2"
          label="Stock Code/Name"
          labelFor="inputStkCode"
        >
          {/* <InputGroup readOnly value={StockAllocation.d_StkCode} /> */}
          <div>
            <div id="DispText">{StockAllocation.d_StkCode}</div>
            <div id="DispText">{StkNameValue}</div>
            <div id="Err_msg"></div>
          </div>
        </FormGroup>
        <FormGroup
          className="Col-5-1"
          label="Stock Information"
          labelFor="inputStkCode"
        >
          <div>
            <div id="DispText">CCASS Stock: {CCASSStockValue} </div>
            <div id="DispText">Lot Size: {StkLotsize}</div>
          </div>
        </FormGroup>
        <FormGroup
          className="Col-6-1"
          label="Qty On Hand"
          labelFor="inputQtyOnHand"
        >
          {/* <InputGroup readOnly value={utils.formatNumber2(StockAllocation.d_Qty_Bf +StockAllocation.d_Qty_Tt+StockAllocation.d_Day_Qty)} /> */}
          {/* <InputGroup
            disabled={!isNew}
            readOnly
            value={utils.formatNumber2(OHQtyValue)}
          /> */}
          <InputGroup
            disabled={!isNew}
            id="inputOHQty"
            name="d_OH_Qty"
            autoFocus={isNew}
            defaultValue={utils.formatNumber2(OHQtyValue)}
            inputRef={register()}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="1) Street Name"
          labelFor="inputStrQty"
        >
          {/* <InputGroup readOnly value={utils.formatNumber2(StockAllocation.d_Str_Qty)} />   */}
          <InputGroup
            disabled={!isNew}
            id="inputStrQty"
            name="d_Str_Qty"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-3-1"
          label="New Street Name"
          labelFor="inputStrQty"
        >
          {/* <InputGroup readOnly value={utils.formatNumber2(StockAllocation.d_Str_Qty)} />   */}
          <InputGroup
            disabled={!isNew}
            id="inputStrQty"
            name="NewStrQty"
            autoFocus={isNew}
            defaultValue=""
            value={utils.formatNumber2(NewStrQty)}
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-6-1"
          label="Transfer Qty"
          labelFor="inputTransQty"
          labelInfo="*"
        >
          <NumericInput
            fill
            className="inputNumberNoSpin"
            id="inputTransQty"
            defaultValue="0"
            value={TransferQtyValue}
            onValueChange={(n) => setTransferQtyValue(n)}
            type="number"
            buttonPosition="none"
            inputRef={register({ min: 0 })}
          />
          {errors.d_Tranf_Qty && errors.d_Tranf_Qty.type === "min" && (
            <span id="Err_msg">Quantity sholud be greater than 0 </span>
          )}
          {errors.d_Tranf_Qty && errors.d_Tranf_Qty.types && (
            <span id="Err_msg">{errors.d_Tranf_Qty.types.message}</span>
          )}
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="2) Nominee Name"
          labelFor="inputComQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputComQty"
            name="d_Com_Qty"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-3-1"
          label="New Nominee Name"
          labelFor="inputComQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputComQty"
            name="newComQty"
            autoFocus={isNew}
            defaultValue=""
            value={utils.formatNumber2(NewComQty)}
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col3-6-1"
          label="From Allocation"
          labelFor="inputFromallocation"
        >
          <select
            id="inputFromallocation"
            name="d_From_Loc"
            onChange={utils.handleStringChange((s) => setFromLocValue(s))}
            ref={register}
          >
            <option value=" "> </option>
            <option value="1">1: Street Name</option>
            <option value="2">2: Nominee Name</option>
            <option value="3">3: CCASS - Street</option>
            <option value="4">4: CCASS - Nominee</option>
            <option value="5">5: Registry</option>
            <option value="6">6: Bank Custody</option>
            <option value="7">7: Others</option>
          </select>
          {errors.d_From_Loc && errors.d_From_Loc.types && (
            <span id="Err_msg">{errors.d_From_Loc.types.message}</span>
          )}
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="3) CCASS (Street)"
          labelFor="inputCasQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputCasQty"
            name="d_Cas_Qty"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-3-1"
          label="New CCASS (Street)"
          labelFor="inputCasQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputCasQty"
            name="NewCasQty"
            autoFocus={isNew}
            defaultValue=""
            value={utils.formatNumber2(NewCasQty)}
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col3-6-1"
          label="To Allocation"
          labelFor="inputToAllocation"
        >
          <select
            id="inputToAllocation"
            name="d_To_Loc"
            onChange={utils.handleStringChange((s) => setToLocValue(s))}
            ref={register}
          >
            <option value=" "> </option>
            <option value="1">1: Street Name</option>
            <option value="2">2: Nominee Name</option>
            <option value="3">3: CCASS - Street</option>
            <option value="4">4: CCASS - Nominee</option>
            <option value="5">5: Registry</option>
            <option value="6">6: Bank Custody</option>
            <option value="7">7: Others</option>
          </select>
          {errors.d_To_Loc && errors.d_To_Loc.types && (
            <span id="Err_msg">{errors.d_To_Loc.types.message}</span>
          )}
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="4) CCASS (Nominee)"
          labelFor="inputNomQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputNomQty"
            name="d_Nom_Qty"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-3-1"
          label="New CCASS (Nominee)"
          labelFor="inputNomQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputNomQty"
            name="NewNomQty"
            autoFocus={isNew}
            defaultValue=""
            value={utils.formatNumber2(NewNomQty)}
            inputRef={register()}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="5) Registry"
          labelFor="inputTdQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputTdQty"
            name="d_Td_Qty"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup
          className="Col-3-1"
          label="New Registry"
          labelFor="inputTdQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputTdQty"
            name="NewTdQty"
            autoFocus={isNew}
            defaultValue=""
            value={utils.formatNumber2(NewTdQty)}
            inputRef={register()}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup className="Col-1-1" label="6) Bank" labelFor="inputBanQty">
          <InputGroup
            disabled={!isNew}
            id="inputBanQty"
            name="d_Ban_Qty"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup className="Col-3-1" label="New Bank" labelFor="inputBanQty">
          <InputGroup
            disabled={!isNew}
            id="inputBanQty"
            name="NewBanQty"
            autoFocus={isNew}
            defaultValue=""
            value={utils.formatNumber2(NewBanQty)}
            inputRef={register()}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup className="Col-1-1" label="7) Other" labelFor="inputOthQty">
          <InputGroup
            disabled={!isNew}
            id="inputOthQty"
            name="d_Oth_Qty"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
        <FormGroup className="Col-3-1" label="New Other" labelFor="inputOthQty">
          <InputGroup
            disabled={!isNew}
            id="inputOthQty"
            name="NewOthQty"
            autoFocus={isNew}
            defaultValue=""
            value={utils.formatNumber2(NewOthQty)}
            inputRef={register()}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Unsettled B/S Qty"
          labelFor="inputUnsettleBSQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputUnsettleBSQty"
            name="d_Day_Bs"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Current Day D/W Qty"
          labelFor="inputDayDWQty"
        >
          <InputGroup
            disabled={!isNew}
            id="inputDayDWQty"
            name="d_Day_Dw"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register()}
          />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-1"
          label="Current Day STL Qty"
          labelFor="inputDaySTLQty"
        >
          {/* <InputGroup
            disabled={!isNew}
            value={utils.formatNumber2(T2QtyValue)}
          /> */}
          <InputGroup
            disabled={!isNew}
            id="inputDaySTLQty"
            name="d_T2_Qty"
            autoFocus={isNew}
            defaultValue={utils.formatNumber2(T2QtyValue)}
            inputRef={register()}
          />
        </FormGroup>
      </div>
    </form>
  );
}

interface StockAllocationMastMainProps {
  gsb: AxiosInstance;
}

const toaster = Toaster.create({
  position: Position.TOP,
});

function StockAllocationEntryMain({ gsb }: StockAllocationMastMainProps) {
  const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />;
  const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />;

  let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams();

  let url = document.location.toString();
  let qString = url.substr(url.indexOf("?"));
  let logKey = utils.getUrlParameter("logKey", url);
  let logKey2 = logKey.substr(0, logKey.indexOf("?"));
  let logviewonly = utils.getUrlParameter("logviewonly", url);

  const [data, setData] = useState([] as StockAllocationInfo[]);
  const [searchKey, setSearchKey] = useState("acCode");
  const [searchValue, setSearchValue] = useState(defaultSearchValue);

  const [showFilters, setShowFilters] = useState(false);
  const [searchStkNo, setsearchStkNo] = useState("");
  const [searchAcCode, setsearchAcCode] = useState("");

  const [nPage, setnPage] = useState(0);
  const [nTotalPage, setnTotalPage] = useState(0);
  const [aPageNumber, setaPageNumber] = useState([] as PageInfo[]);
  const [MoblieDev, setMoblieDev] = useState(false);
  const [ShowWait, setShowWait] = useState(false);
  const [searchLimit, setSearchLimit] = useState(50);
  const [searchBackward, setSearchBackward] = useState(false);

  const [selectedTabId, setSelectedTabId] = useState("list");
  const [currentStockAllocation, setcurrentStockAllocation] = useState(
    {} as StockAllocationInfo
  );
  const [BlankStockAllocation, setBlankStockAllocation] = useState(
    {} as StockAllocationInfo
  );

  const [CurrentLog, setCurrentLog] = useState({} as StockAllocationInfo);
  const [ViewMode, setViewMode] = useState("V");

  const [currentMode, setCurrentMode] = useState("show");
  const [isShowAlert1, setisShowAlert1] = useState(false);
  const [ShowAlert1Msg, setShowAlert1Msg] = useState("");
  const [isShowAlert2, setisShowAlert2] = useState(false);
  const [ShowAlert2Msg, setShowAlert2Msg] = useState("");

  const [CO_NAME, setCO_NAME] = useState("");
  const [companyLogoName, setcompanyLogoName] = useState("");
  const [SBPath, setSBPath] = useState("");
  const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()));
  const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()));
  const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0");

  const [SING_CURR, setSING_CURR] = useState(false);
  const [showCurrency, setshowCurrency] = useState(false);
  //gsb default value
  // BlankStockAllocation.d_InDirect = "Y"
  // BlankStockAllocation.d_Combine_Stamp = "Y"

  useEffect(() => {
    (async () => {
      try {
        // get GsbFlag from SB.XBS
        const res = await gsb.post("/GsbFlag");
        //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
        //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
        //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
        GsbFlag = res.data.Flag;
        //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
        //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
        //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
        console.log("GsbFlag.user_key: ", GsbFlag.user_key);
        //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
        //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
        //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
        //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
        setCO_NAME(GsbFlag.CO_NAME);
        setcompanyLogoName(GsbFlag.companyLogoName);
        setSBPath(GsbFlag.SBPath);
        setTRAN_DATE(GsbFlag.TRAN_DATE);
        setSETL_DATE(GsbFlag.SETL_DATE);
        if (GsbFlag.isProduction == "Y") {
          setcardOpen01("cardOpen01-Theme" + GsbFlag.DEF_THEME1);
        } else {
          setcardOpen01("cardOpen01-Theme" + GsbFlag.DEF_THEME2);
        }

        //setSING_CURR(GsbFlag.SING_CURR) // 20230210
        setshowCurrency(GsbFlag.MULTI_BANK && !GsbFlag.SING_CURR);
      } catch (error) {
        utils.showError(error);
      }
    })();
  }, [gsb]);

  //   useEffect(() => {
  //     (async () => {
  //       try {
  //         const res = await gsb.post("/Master", {
  //           sb_data: "",
  //           cPgmId: "IntRmast",
  //           cAction: "ExchangeRate",
  //         });

  //         console.log("StockAllocation: ", res.data.data)
  //         CurrencyList2.push({ d_Currency: "", d_CcyDesc: "HKD" });
  //         for (let j = 0; j < res.data.data.length; j++) {
  //           CurrencyList2.push({
  //             d_Currency: res.data.data[j][0],
  //             d_CcyDesc: res.data.data[j][1],
  //           });
  //         }
  //         setCurrencyList(CurrencyList2);
  //         //console.log("set CurrencyList: ", CurrencyList)
  //       } catch (error) {
  //         utils.showError(error);
  //       }
  //     })();
  //   }, [gsb]);
  // -- end

  // useEffect(() => {
  //   if (logviewonly === "Y") {
  //       setViewMode("L")
  //   }

  //   ;
  //   (async () => {
  //       try {
  //           if ( logKey.trim().length > 0 ) {
  //               const res = await gsb.post('/Master', {
  //                   sb_data: {
  //                       d_logKey: logKey,
  //                   },
  //                   cPgmId: "StockAllocationEntry",
  //                   cAction: "ViewLogRec"
  //               })
  //               console.log("StockAllocationMastMain ViewLogRec res.data.data", res.data.data)

  //               if (res.data.ok) {
  //                   setCurrentLog(res.data.data[0])
  //               } else {
  //                   utils.showWarn(res.data.data)
  //               }
  //           }
  //       } catch (error) {
  //           showErrorBox(error)
  //       }
  //   })()
  // }, [gsb, logKey])

  const PrevRecProcess = async (StockAllocation: StockAllocationInfo) => {
    try {
      //console.log("call PrevRecProcess", StockAllocation)
      const res = await gsb.post("/Master", {
        sb_data: StockAllocation,
        cPgmId: "StockAllocationEntry",
        cAction: "PrevRec",
      });
      //console.log("PrevRecProcess show 1: ", d_MktateCode.d_StkNo)
      //console.log("PrevRecProcess data : ", data)
      //console.log("PrevRecProcess res.data : ", res.data.data)
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_acCode + data[i].d_StkNo ===
          res.data.data.d_acCode + res.data.data.d_StkNo
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      //console.log("PrevRecProcess res.data.data : ", res.data.data)
      setcurrentStockAllocation(res.data.data);
      //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
      setCurrentMode("show");
      //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
    } catch (error) {
      utils.showError(error);
    }
  };

  const DeleteProcess = async (StockAllocation: StockAllocationInfo) => {
    // try {
    //   //console.log("call DeleteProcess", Mkt)
    //   const res = await gsb.post("/Master", {
    //     sb_data: StockAllocation,
    //     cPgmId: "StockAllocationEntry",
    //     cAction: "DelRec",
    //   });
    //   showSuccess(
    //     "Bank Code " +
    //       StockAllocation.d_acCode +
    //       StockAllocation.d_BankName +
    //       " record deleted"
    //   );
    //   for (let i = 0; i < data.length; i++) {
    //     if (
    //       data[i].d_acCode + data[i].d_BankName + data[i].d_Currency ===
    //       res.data.data.d_acCode +
    //         res.data.data.d_BankName +
    //         res.data.data.d_Currency
    //     ) {
    //       data[i] = { ...data[i], ...res.data.data };
    //       setData(data);
    //       break;
    //     }
    //   }
    //   setcurrentStockAllocation(res.data.data);
    //   setCurrentMode("show");
    // } catch (error) {
    //   utils.showError(error);
    // }
  };

  const NextRecProcess = async (StockAllocation: StockAllocationInfo) => {
    try {
      //console.log("call NextRecProcess", StockAllocation)
      const res = await gsb.post("/Master", {
        sb_data: StockAllocation,
        cPgmId: "StockAllocationEntry",
        cAction: "NextRec",
      });
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_acCode + data[i].d_StkNo ===
          res.data.data.d_acCode + res.data.data.d_StkNo
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      setcurrentStockAllocation(res.data.data);
      setCurrentMode("show");
    } catch (error) {
      utils.showError(error);
    }
  };

  const newOnSave = async (StockAllocation: StockAllocationInfo) => {
    // try {
    //   //console.log("call newOnSave", StockAllocation)
    //   const res = await gsb.post("/Master", {
    //     sb_data: StockAllocation,
    //     cPgmId: "StockAllocationEntry",
    //     cAction: "AddRec",
    //   });
    //   showSuccess(
    //     "Bank Code " +
    //       StockAllocation.d_acCode +
    //       StockAllocation.d_BankName +
    //       " record added"
    //   );
    //   for (let i = 0; i < data.length; i++) {
    //     if (
    //       data[i].d_acCode + data[i].d_BankName + data[i].d_Currency ===
    //       res.data.data.d_acCode +
    //         res.data.data.d_BankName +
    //         res.data.data.d_Currency
    //     ) {
    //       data[i] = { ...data[i], ...res.data.data };
    //       setData(data);
    //       break;
    //     }
    //   }
    //   setcurrentStockAllocation(res.data.data);
    //   setCurrentMode("show");
    // } catch (error) {
    //   //utils.showError(error)
    //   showErrorBox(error);
    // }
  };

  const editOnSave = async (StockAllocation: StockAllocationInfo) => {
    try {
      console.log("call editOnSave", StockAllocation);
      const res = await gsb.post("/Master", {
        sb_data: StockAllocation,
        cPgmId: "StockAllocationEntry",
        cAction: "UpdateRec",
      });
      showSuccess(
        "Bank Code " +
          StockAllocation.d_acCode +
          "," +
          StockAllocation.d_StkNo +
          " record saved"
      );
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_acCode + data[i].d_StkNo ===
          res.data.data.d_acCode + res.data.data.d_StkNo
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      //console.log("editOnSave stk", StockAllocation)
      //console.log("editOnSave res.data.data", res.data.data)
      setcurrentStockAllocation(res.data.data);
      setCurrentMode("show");
    } catch (error) {
      //utils.showError(error)
      showErrorBox(error);
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (SING_CURR) {
      toaster.show({
        message:
          "Your SB Version have not Multi-Currency Module !  Please contact POP (Tel: 23918816) for more detail.",
        intent: Intent.WARNING,
      });
      return;
    }

    setShowWait(true);
    setnPage(1);
    let m_limit = searchLimit;
    if (selectedTabId === "detail") {
      m_limit = 1;
    }

    if (utils.getDeviceType()) {
      setMoblieDev(true);
    } else {
      setMoblieDev(false);
    }

    // get SmMast list if StkNo have value
    try {
      //console.log("SmMastMain onsubmit called", data)
      const res = await gsb.post("/Master", {
        sb_data: {
          by: searchKey,
          start: searchValue,
          nPage: 1,
          limit: m_limit,
          backward: searchBackward,
          filters: {
            AcCode: searchAcCode,
            StkNo: searchStkNo,
          },
        },
        cPgmId: "StockAllocationEntry",
        cAction: "ListRec",
      });

      if (m_limit > 1) {
        setData(res.data.data);
      }
      if (res.data.data.length > 0) {
        setcurrentStockAllocation(res.data.data[0]);
      }

      if (m_limit > 1) {
        if (res.data.count > 0) {
          const aPageNumber1 = [];
          if (res.data.count > 0) {
            setnTotalPage(Math.ceil(res.data.count / searchLimit));
            for (let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++) {
              aPageNumber1.push({ n: j });
            }
            setaPageNumber(aPageNumber1);
          }
        }
      }
      setShowWait(false);
    } catch (error) {
      utils.showError(error);
    }
  };

  // get one SmMast record if StkNo have value
  useEffect(() => {
    if (defaultSearchValue.length > 0) {
      // console.log("calling API for URL param");

      setShowWait(true);
      setnPage(1);
      if (nPage > 0) {
        //setcPrintMode("PS")
      }

      (async () => {
        //console.log("SmMastMain useEffect length > 0  called", data)
        const res = await gsb.post("/Master", {
          sb_data: {
            by: "acCode",
            start: defaultSearchValue,
            nPage: 1,
            limit: 1,
          },
          cPgmId: "StockAllocationEntry",
          cAction: "ListRec",
        });

        setData(res.data.data);
        if (res.data.data.length > 0) {
          setcurrentStockAllocation(res.data.data[0]);
        }
        setShowWait(false);
      })();
    }
  }, [gsb, defaultSearchValue]);

  //Press page No 1 2 3 4
  useEffect(() => {
    if (nPage > 0 && nPage <= nTotalPage && selectedTabId == "list") {
      setShowWait(true);
      if (nPage > 0) {
        //setcPrintMode("S")
      }

      (async () => {
        try {
          //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
          const res = await gsb.post("/Master", {
            sb_data: {
              by: searchKey,
              start: searchValue,
              nPage: nPage,
              limit: searchLimit,
              backward: searchBackward,
              filters: {
                StkNo: searchStkNo,
              },
            },
            cPgmId: "StockAllocationEntry",
            cAction: "ListRec",
          });

          setData(res.data.data);
          if (res.data.data.length > 0) {
            setcurrentStockAllocation(res.data.data[0]);
          }
          setShowWait(false);
        } catch (error) {
          utils.showError(error);
        }
      })();
    }
  }, [gsb, nPage, selectedTabId]);

  const showErrorBox = (err: any) => {
    alert(
      "Error:" +
        err.response.data.subSystem +
        " - " +
        err.response.data.description +
        ", OS code:" +
        err.response.data.osCode
    );
  };

  const showError = (err: any) => {
    if (err.response) {
      toaster.show({
        message: err.response.data.description,
        intent: Intent.WARNING,
      });
    } else if (err.request) {
      toaster.show({ message: "Request failed", intent: Intent.WARNING });
    } else {
      toaster.show({ message: err.message, intent: Intent.WARNING });
    }
  };

  const showSuccess = (message: string) => {
    toaster.show({ message: message, intent: Intent.SUCCESS });
  };

  // currentMode = show
  let detail = (
    <StockAllocationMastView
      gsb={gsb}
      StockAllocation={currentStockAllocation}
      baseUrl={gsb.defaults.baseURL}
      token={localStorage.getItem("token")}
      onPrevRec={() => PrevRecProcess(currentStockAllocation)}
      onNew={() => setCurrentMode("new")}
      onEdit={() => setCurrentMode("edit")}
      onDelete={() => DeleteProcess(currentStockAllocation)}
      onNextRec={() => NextRecProcess(currentStockAllocation)}
    ></StockAllocationMastView>
  );
  console.log("1) currentMode = view", currentStockAllocation);
  switch (currentMode) {
    case "new":
      //console.log("currentMode = new")
      detail = (
        <StockAllocationMastEdit
          gsb={gsb}
          StockAllocation={BlankStockAllocation}
          //   CurrencyList={CurrencyList}
          isNew
          onSave={newOnSave}
          onCancel={() => {
            setCurrentMode("show");
            setSelectedTabId("detail");
          }}
        ></StockAllocationMastEdit>
      );
      break;
    case "edit":
      console.log("currentMode = edit");
      console.log("2) currentMode = edit", currentStockAllocation);
      detail = (
        <StockAllocationMastEdit
          gsb={gsb}
          StockAllocation={currentStockAllocation}
          //   CurrencyList={CurrencyList}
          onSave={editOnSave}
          onCancel={() => setCurrentMode("show")}
        ></StockAllocationMastEdit>
      );
      break;
  }

  function PageNo(n: number) {
    if (n > 0) {
      if (nPage + n <= nTotalPage) {
        setnPage(nPage + n);
      }
    } else {
      if (nPage + n > 0) {
        setnPage(nPage + n);
      }
    }
    return null;
  }

  let Pagination = (
    <>
      <div className="Row">
        <FormGroup className="Col-1-12" label="">
          Page
          {nTotalPage > 1 && (
            <Button minimal onClick={() => PageNo(-1)}>
              &laquo;
            </Button>
          )}
          {nTotalPage > 1 && nPage != 1 && (
            <Button minimal onClick={() => setnPage(1)}>
              {1}
            </Button>
          )}
          {aPageNumber.map(
            (no, j) =>
              j + 1 > nPage - 10 &&
              j + 1 < nPage &&
              j + 1 != 1 && (
                <Button minimal onClick={() => setnPage(no.n)}>
                  {no.n}
                </Button>
              )
          )}
          {nTotalPage >= 1 && <Button intent="primary">{nPage}</Button>}
          {aPageNumber.map(
            (no, j) =>
              j + 1 > nPage &&
              j + 1 < nPage + 10 &&
              j + 1 != nTotalPage && (
                <Button minimal onClick={() => setnPage(no.n)}>
                  {no.n}
                </Button>
              )
          )}
          {nTotalPage > 1 && nPage != nTotalPage && (
            <Button minimal onClick={() => setnPage(nTotalPage)}>
              {nTotalPage}
            </Button>
          )}
          {nTotalPage > 1 && (
            <Button minimal onClick={() => PageNo(+1)}>
              &raquo;
            </Button>
          )}
        </FormGroup>
      </div>
    </>
  );

  let list = (
    <>
      {/* <div className="Row">
        {!SING_CURR && (
          <Button
            className="Col-1-1"
            icon="add"
            onClick={() => {
              setCurrentMode("new");
              setSelectedTabId("detail");
            }}
          >
            New
          </Button>
        )}
      </div> */}
      <div className="Spacing-V-16" />
      {ShowWait && <div>{imgWait}</div>}
      {nPage > 0 && Pagination}
      {/* display search list */}
      <HTMLTable striped interactive condensed>
        <thead>
          <th className="TCol-AcCode">Account No.</th>
          <th className="TCol-StockNo">Stock No.</th>
          <th className="TCol-QtyOnHand">Qty on Hand</th>
          <th className="TCol-StrQty">
            Street
            <br />
            Name
          </th>
          <th className="TCol-ComQty">
            Nominee
            <br />
            Name
          </th>
          <th className="TCol-CasQty">
            Ccass
            <br />
            (Street)
          </th>
          <th className="TCol-NomQty">
            Ccass
            <br />
            (Nominee)
          </th>
          <th className="TCol-TdQty">Registry</th>
          <th className="TCol-BanQty">
            Bank
            <br />
            Custody
          </th>
          <th className="TCol-OthQty">Others</th>
          {/* <th className="TCol-StockNo">Unsettled<br/>B/S Qty</th> */}
          <th className="TCol-DayDwQty">
            Current Day
            <br />
            D/W Qty
          </th>
          {/* <th className="TCol-StockNo">Current Day<br/>STL Qty</th> */}
          {/* <th className="TCol-BankDesc">Bank Description</th> */}
          {/* {showCurrency && <th className="TCol-Currency">Currency</th>} */}
        </thead>
        <tbody id="ma_tr">
          {data.map((StockAllocation, i) => (
            <tr
              key={i}
              onDoubleClick={() => {
                setSelectedTabId("detail");
              }}
            >
              <td
                className="TCol-AcCode"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {StockAllocation.d_acCode}
              </td>
              <td
                className="TCol-StockNo"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {StockAllocation.d_StkNo}
              </td>
              <td
                className="TCol-QtyOnHand"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(
                  StockAllocation.d_Qty_Bf +
                    StockAllocation.d_Qty_Tt +
                    StockAllocation.d_Day_Qty
                )}
              </td>
              <td
                className="TCol-StrQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Str_Qty)}
              </td>
              <td
                className="TCol-ComQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Com_Qty)}
              </td>
              <td
                className="TCol-CasQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Cas_Qty)}
              </td>
              <td
                className="TCol-NomQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Nom_Qty)}
              </td>
              <td
                className="TCol-TdQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Td_Qty)}
              </td>
              <td
                className="TCol-BanQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Ban_Qty)}
              </td>
              <td
                className="TCol-OthQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Oth_Qty)}
              </td>
              {/* <td
                className="TCol-StockNo"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Day_Bs)}M_UNSTQTY
              </td> */}
              <td
                className="TCol-DayDwQty"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Day_Dw)}
              </td>
              {/* <td
                className="TCol-StockNo"
                onClick={() => {
                  setcurrentStockAllocation(StockAllocation);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {utils.formatNumber2(StockAllocation.d_Day_Bs)}M_STLQTY
              </td> */}
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      {ShowWait && nTotalPage > 0 && <div>{imgWait}</div>}
      {nPage > 0 && Pagination}
    </>
  );

  let CoHead = (
    <SystemHeader
      companyLogo={companyLogoName}
      companyName={CO_NAME}
      dataPath={SBPath}
      tradeDate={TRAN_DATE}
      settlementDate={SETL_DATE}
    ></SystemHeader>
  );
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="Row">
          <FormGroup className="Col-1-12">{CoHead}</FormGroup>
        </div>
        <Card
          className={cardOpen01}
          interactive={false}
          elevation={Elevation.THREE}
        >
          {SING_CURR && (
            <div>
              <div id="DispText">
                Your SB Version have not Multi-Currency Module !
              </div>
              <div id="DispText">
                Please contact POP (Tel: 23918816) for more detail.
              </div>
            </div>
          )}
          {/* define search key */}
          <H3>
            <div style={{ justifyContent: "flex-end" }}>
              Stock Allocation Master
            </div>
          </H3>
          <div className="Row">
            <FormGroup
              className="Col-1-5"
              label="Search for "
              labelFor="inputSearchValue"
            >
              <ControlGroup fill>
                <HTMLSelect
                  className={Classes.FIXED}
                  value={searchKey}
                  onChange={utils.handleStringChange((s) => setSearchKey(s))}
                >
                  <option value="acCode">Account Code</option>
                </HTMLSelect>
                <InputGroup
                  id="inputSearchValue"
                  placeholder="Search"
                  value={searchValue}
                  onChange={utils.handleStringChange((s) => setSearchValue(s))}
                  leftIcon="search"
                  rightElement={
                    <Button
                      icon={IconNames.ARROW_RIGHT}
                      disabled={
                        selectedTabId === "detail" && !(searchKey == "acCode")
                      }
                      minimal
                      type="submit"
                    ></Button>
                  }
                />
              </ControlGroup>
            </FormGroup>
            <Button
              className="Col-6-1 Button-LabeledFormGroup"
              icon="search"
              disabled={selectedTabId === "detail" && !(searchKey == "acCode")}
              type="submit"
            >
              {selectedTabId === "detail" ? "Search" : "List"}
            </Button>
            <Button
              className="Col-7-1 Button-LabeledFormGroup"
              icon="filter"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              Filters
            </Button>
          </div>
          <Collapse isOpen={showFilters}>
            <div className="Row">
              <FormGroup
                className="Col-1-2"
                label="Account Code"
                labelFor="searchAcCode"
              >
                <InputGroup
                  id="searchAcCode"
                  value={searchAcCode}
                  onChange={utils.handleStringChange((s) => setsearchAcCode(s))}
                />
              </FormGroup>
              <FormGroup
                className="Col-3-1"
                label="Stock No."
                labelFor="searchStkNo"
              >
                <InputGroup
                  id="searchStkNo"
                  value={searchStkNo}
                  onChange={utils.handleStringChange((s) => setsearchStkNo(s))}
                />
              </FormGroup>
            </div>
          </Collapse>
          <Tabs
            onChange={(newTabId) => setSelectedTabId(newTabId as string)}
            selectedTabId={selectedTabId}
          >
            <Tab id="list" title="List" panel={list} />
            <Tab id="detail" title="Detail" panel={detail} />
          </Tabs>
        </Card>

        <div className="Spacing-V-16"></div>
        <Alert
          confirmButtonText="OK"
          icon="tick-circle"
          intent={Intent.SUCCESS}
          isOpen={isShowAlert1}
          onCancel={() => setisShowAlert1(false)}
          onConfirm={() => {
            setShowAlert1Msg("");
            setisShowAlert1(false);
          }}
        >
          <p>{ShowAlert1Msg}</p>
        </Alert>
        <Alert
          confirmButtonText="OK"
          icon="cross"
          intent={Intent.WARNING}
          isOpen={isShowAlert2}
          onCancel={() => setisShowAlert2(false)}
          onConfirm={() => {
            setShowAlert2Msg("");
            setisShowAlert2(false);
          }}
        >
          <p>{ShowAlert2Msg}</p>
        </Alert>
      </form>
    </>
  );
}

export default StockAllocationEntryMain;
