import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, Button, HTMLTable, Position, Toaster, Intent, IconName, MaybeElement, Spinner } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import svgWait2 from './svg/wait2.gif'
//import tick_circle from './svg/tick-circle.svg'
import tick_circle from './svg/checked.svg'
import flow_linear from './svg/flow-linear.svg'
import svgNotStarted from './svg/dash.svg'
import svgComleted from './svg/checked.svg'

import { SystemHeader } from './CommonUI';

interface ModuleInfo {
    Module_Desc: string,
    Module_Value: string,
}

var GsbFlag: any;

interface SBModuleMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function SBModuleMain({ gsb }: SBModuleMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgtick_circle = <img className="tickIcon" src={tick_circle} alt="completed" />
    const imgflow_linear = <img src={flow_linear} alt="Progressing" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [ModuleData, setModuleData] = useState([] as ModuleInfo[])


    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb ])

    
    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    const refreshTasks = async () => {
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)

        try {
            const res = await gsb.post('/SBModule' )
            if ( res.data.count > 0 ) {
                console.log("res.data.data: ", res.data.data) 
                setModuleData(res.data.data)

                setShowWait(false)
                setShowNoRecord(false)
                setShowRpt(true)
            } else {
                setShowWait(false)
                setShowNoRecord(true)
                setShowRpt(false)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    
    useEffect(() => {
        //console.log("/SBModule ") 
        refreshTasks()
    }, [gsb])




    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

 
    let list = <>
        <div className="Spacing-V-16" />
        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    <th className="RCol-Head_p">
                        <td>Module Description</td>
                    </th>
                    <th className="RCol-Head_p">
                        <td>Value</td>
                    </th>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                {ModuleData.map((Module, i) =>
                    <tr>
                        <td>{Module.Module_Desc}</td>
                        <td>{Module.Module_Value}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>GSB System Module</H3>
                    <div className="Spacing-V-16" />
                    {ShowWait && <div>{imgWait}</div> }
                    {ShowNoRecord && <p>
                        <div className="Spacing-V-16" />
                        <div className="Row">
                            <FormGroup className="Col-1-3"  >
                                <span id="Err_msg">No Record found !!!</span>
                            </FormGroup>
                        </div>
                    </p> }
                    {ShowRpt && list}
                </Card>
            </form>
        </>
    );
}

export default SBModuleMain