import React, { useState, FormEvent, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation, Icon } from "@blueprintjs/core";
import { DateInput2 } from "@blueprintjs/datetime2";
import { format, parse, addDays} from "date-fns";
import { IconNames } from "@blueprintjs/icons";

import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import { AxiosInstance } from 'axios';
import * as utils from "./utils";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import svgWait2 from './svg/wait2.gif'
import {StkInfo,MarketInfo,HolidayInfo} from './Types'
import { SystemHeader } from './CommonUI';
import i18next from 'i18next';

interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "M0032" ;
var isReqAuth2:boolean = false ;

interface  HolidayMastViewProps {
    gsb: AxiosInstance,
    Holiday: HolidayInfo,
    isHkHoliday : string,
    MarketList?: MarketInfo[],    
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}


function HolidayMastView({ gsb,  Holiday = {} as HolidayInfo,isHkHoliday ="N", baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: HolidayMastViewProps) {

    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

  //  const [IsHKHoliday, setIsHKHoliday] = useState("N")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

/*
    1、date 日期

2、mon 星期一

3、tue 星期二

4、wed 星期三

5、thu 星期四

6、fri 星期五

7、sat 星期六

8、sun 星期日
*/

    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(Holiday.d_HDate).getDay()]
    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Holiday])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_HDate" in Holiday)) return null

    
    return (
        <div>
            {/* define add,change,delete button */}
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div> }
            { ViewMode === "L" && <div>
                Holiday. : {Holiday.d_HDate}
                { (logKey2.indexOf("*A1") > 0 ) && <div>Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div>Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div>Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />

           {isHkHoliday ==="N" &&
                <div className="Row">
                    <FormGroup className="Col3-1-1" label="Market Code">
                        <InputGroup readOnly value={Holiday.d_Market} />
                    </FormGroup>
                </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-1" label="Holiday." labelInfo="*">
                    <InputGroup readOnly value={Holiday.d_HDate} />
                </FormGroup>
                <FormGroup className="Col-2-2" label={t('Weekday')} >
                    <InputGroup readOnly value={weekday} />
                </FormGroup>
                    
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label="Remark">
                    <InputGroup readOnly value={Holiday.d_HDesc} />
                </FormGroup>
            </div>   

      
            <div className="Row">
                <FormGroup className="Col-1-2" label=" Half trade day">
                <RadioGroup
                        label="" 
                        name="d_HNoSetl"
                        disabled={true}
                        inline={true}
                        selectedValue={Holiday.d_HNoSetl}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Holiday.d_HNoSetl.toString() == "") && 
                    <div id="DispText2" > {Holiday.d_HNoSetl} </div> }
                </FormGroup>
            </div>

        </div>
    )
}

interface  HolidayMastEditProps {
    gsb: AxiosInstance, 
    MarketList?: MarketInfo[], 
    Holiday?: HolidayInfo,
    isNew?: boolean,
    isHkHoliday?:string,
    onSave?: (Holiday: HolidayInfo) => void,
    onCancel?: () => void;
}

function HolidayMastEdit({ gsb, MarketList=[] as MarketInfo[],Holiday = {} as HolidayInfo, isHkHoliday="N", isNew = false, onSave = () => { }, onCancel = () => { } }:  HolidayMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<HolidayInfo>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false) 

    const [ListdateValue, setListdateValue] = useState(utils.formatNumber(new Date(Holiday.d_HDate)))
    const Mindate1 = new Date(1800, 0, 1)
    const Maxdate1 = addDays(new Date(), 365*5)

    
    const dateFnsFormat = "dd-MM-yyyy";
    const formatDate = useCallback((date: Date) => format(date, dateFnsFormat), []);
    const parseDate = useCallback((str: string) => parse(str, dateFnsFormat, new Date()), []);
    //const [def_d_HNoSetl, setdef_d_HNoSetl] = useState(Holiday.d_HNoSetl)
 
  //  let weekday =  ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(Holiday.d_HDate).getDay()]
  let weekday =""
    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Holiday])



    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
     
        register({ name: "d_Market" })
    }, [register])
 


    const [def_d_Market, setd_Market] = useState(Holiday.d_Market)    
    const [def_HDate, setd_HDate] = useState(Holiday.d_HDate)    
    const [def_d_HDesc, setdef_d_HDesc] = useState(Holiday.d_HDesc)
    const [def_d_HNoSetl, setdef_d_HNoSetl] = useState(Holiday.d_HNoSetl)
 
    useEffect(() => {
        // Call reset to get the values of record HolidayInfo 
        if ("d_HDate" in Holiday) {
          //  let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(Holiday.d_HDate).getDay()]
            reset(Holiday)
        }
    }, [Holiday, reset])

 
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

 
    useEffect(() => {
        let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(Holiday.d_HDate).getDay()] 
    }, [Holiday,def_HDate])
 
 

    if (! isNew && ! ("d_HDate" in Holiday)) return null 


    const onSubmit = (data: HolidayInfo) => {



        if ( typeof(data.d_Market) === "undefined") {
            data.d_Market = ""
        }

        if (isHkHoliday === "N")
        {     
            data.d_Market =data.d_Market.toUpperCase();// def_d_Market;
            //console.log(data.d_Market.toUpperCase());
        }

        //data.d_HDate = data.d_HDate 
        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }
        data.d_HNoSetl = def_d_HNoSetl;
     
     //   data.d_IsHKHoliday = "U";
      //  data.d_HNoSetl = IsHKHoliday;
        (async () => {
            try {
                //console.log("call HolidayMastEdit data", data)
                //console.log("call HolidayMastEdit isNew", isNew)
                //console.log("call HolidayMastEdit isHkHoliday", isHkHoliday)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "HolidayMast", cAction: "HolidayValidate", isNew: isNew,isHkHoliday:isHkHoliday})
                //console.log("HolidayMastEdit res.data.ok", res.data.ok)
                //console.log("HolidayMastEdit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }

    const MktRenderer: ItemRenderer<MarketInfo> = (MarketList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${MarketList.d_MktName}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={MarketList.d_Market}
                key={MarketList.d_Market}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const MktPredicate: ItemPredicate<MarketInfo> = (query, MarketList, _index, exactMatch) => {
        const normalizedText = `${MarketList.d_Market} - ${MarketList.d_MktName}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const MktValueRenderer = (MarketList: MarketInfo) => {
        return MarketList.d_Market
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
             </div>
            <div className="Spacing-V-16" />           
            
            {isHkHoliday=== "N" &&
                <div className="Row">
                    <FormGroup className="Col3-1-1" label="Market Code" labelFor="inputMarketCode">
                        {/* <InputGroup id="inputMarketCode" name="d_Market" defaultValue="" placeholder="Market Code ..." inputRef={register({ maxLength: 4}) } />
                        {errors.d_Market && errors.d_Market.type === "maxLength" && <span id="Err_msg">Max length is 4</span>} */}
                        <Suggest
                            inputProps={{ placeholder: "Market Code ..." }}
                          
                            activeItem={MarketList.find((mkt) => mkt.d_Market === Holiday.d_Market)}
                            defaultSelectedItem={MarketList.find((mkt) => mkt.d_Market === Holiday.d_Market)}
                            items={MarketList}
                            itemRenderer={MktRenderer}
                            itemPredicate={MktPredicate}
                            inputValueRenderer={MktValueRenderer}
                            onItemSelect={(Holiday) => { setValue("d_Market", Holiday.d_Market) }}
                        />
                        {errors.d_Market && errors.d_Market.types && <span id="Err_msg">{errors.d_Market.types.message}</span>}
                    </FormGroup>
                </div>
            }

     

            <div className="Row">

                <FormGroup className="Col-1-1" label={t('Holiday')} labelFor="inputHDate">
                       <InputGroup  id="inputDoB" name="d_HDate"
                        type="date"
                        placeholder="YYYY-MM-DD" 
                        onChange={utils.handleStringChange(s => setd_HDate(s)) }
                        leftIcon="calendar"
                        inputRef={register}    />
                    {errors.d_HDate && errors.d_HDate.types && <span id="Err_msg">{errors.d_HDate.types.message}</span>}
                </FormGroup>              
             
                <FormGroup className="Col-2-2" label={t('Weekday')} >
                    <InputGroup readOnly value={ ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(def_HDate).getDay()] } />
                </FormGroup>
                    
                  
            </div>
 
            <div className="Row">
                <FormGroup className="Col-1-5" label="Remark" labelFor="inputRemark">
                    <InputGroup id="inputRemark" name="d_HDesc" defaultValue="" placeholder="Remark ..." inputRef={register({ maxLength: 50}) } />
                    {errors.d_HDesc && errors.d_HDesc.type === "maxLength" && <span id="Err_msg">Max length is 50</span>}
                    {errors.d_HDesc && errors.d_HDesc.types && <span id="Err_msg">{errors.d_HDesc.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-2" label="Half trae day." labelFor="inputd_HNoSetl">
                    <RadioGroup
                        name="d_HNoSetl"
                        inline={true}
                        selectedValue={def_d_HNoSetl}
                        onChange={utils.handleStringChange(s => setdef_d_HNoSetl(s)) }
                        ref={register}>
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_HNoSetl && errors.d_HNoSetl.types && <span id="Err_msg">{errors.d_HNoSetl.message}</span>}
                </FormGroup>
            </div>
           
        </form>
    )
}

interface HolidayMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function  HolidayMastMain({ gsb }:  HolidayMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    //let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    let { Holiday_No: defaultSearchValue = "" }: { Holiday_No: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as HolidayInfo[])


    const [searchKey, setSearchKey] = useState("StkNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchHoliday, setsearchHoliday] = useState("")
    
    const [searchHalfTradeday, setsearchHalfTradeday] = useState("N")
    const [MarketList, setMarketList] = useState([] as MarketInfo[])

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentHoliday, setCurrentHoliday] = useState({} as HolidayInfo)
    const [BlankHoliday, setBlankHoliday] = useState({} as HolidayInfo)
    const [CurrentLog, setCurrentLog] = useState({} as HolidayInfo)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")  
 
    const [isHkHoliday,setisHkHoliday]= useState("Y")
     
    const [ShowMarketOK,setShowMarketOK] = useState(true)

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    //const getDayOfWeek = (new Date()) => daysOfWeek[date.getDay()]
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("HolidayMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("HolidayMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("HolidayMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("HolidayMastMain disp GsbFlag: ", GsbFlag)
                //console.log("HolidayMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("HolidayMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("HolidayMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("HolidayMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("HolidayMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HolidayMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])
 

    useEffect(() => {
        (async () => {
            try {
                // get Market List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "Market",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false
                },
                cPgmId: "Mktmast",
                cAction: "ListRec",
            });

            //console.log("MarketList: ", res.data.data) 
            res.data.data.unshift({d_Market: "", d_MktName: "blank"})
            setMarketList(res.data.data)
            //console.log("set MarketList: ", MarketList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                // get Market List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "Market",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false
                },
                cPgmId: "Mktmast",
                cAction: "ListRec",
            });

            //console.log("MarketList: ", res.data.data) 
            res.data.data.unshift({d_Market: "", d_MktName: "blank"})
            setMarketList(res.data.data)
            //console.log("set MarketList: ", MarketList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    const PrevRecProcess = async (Holiday: HolidayInfo) => {
        try {
            //console.log("call PrevRecProcess", stk)
           // Holiday.IsHKHoliday = IsHKHoliday
           const res = await gsb.post('/Master', {sb_data: Holiday, cPgmId: "HolidayMast", cAction: "PrevRec",isHkHoliday:isHkHoliday})
           // console.log("PrevRecProcess show 1: ", Holiday.d_HDate)
           // console.log("PrevRecProcess data : ", data)
           //console.log("PrevRecProcess res.data : ", res.data.data)
            ////console.log("PrevRecProcess show 1: ", Holiday.d_HDate)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_HDate === res.data.data.d_HDate) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setCurrentHoliday(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (Holiday: HolidayInfo) => {
        try {
          
            //console.log("call DeleteProcess", stk)
            //Holiday.IsHKHoliday = IsHKHoliday
            const res = await gsb.post('/Master', {sb_data: Holiday, cPgmId: "HolidayMast", cAction: "DelRec",isHkHoliday:isHkHoliday})
            if (res.data.ok) {
                if (isReqAuth2) {
                    showSuccess("Holiday. " + Holiday.d_HDate + " record deleting, waiting for authorization")
                } else {
                    if (Holiday.d_Market == null ||  Holiday.d_Market.trim().length == 0 ){
                        showSuccess("Holiday. " + Holiday.d_HDate + ", " + " record deleted")
                    }else
                    {
                        showSuccess("Holiday. " + Holiday.d_HDate + ", Market: " + Holiday.d_Market + ", " + " record deleted")
                    }
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].d_HDate === res.data.data.d_HDate) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    }
                }
                setCurrentHoliday(res.data.data)
                setCurrentMode("show")
                if (isReqAuth2) {
                    alert("Holiday. " + Holiday.d_HDate + " record deleting, waiting for authorization")
                }
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Holiday: HolidayInfo) => {
        try {
            //console.log("call NextRecProcess", stk)
            //Holiday.IsHKHoliday = IsHKHoliday
            const res = await gsb.post('/Master', {sb_data: Holiday, cPgmId: "HolidayMast", cAction: "NextRec",isHkHoliday:isHkHoliday})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_HDate === res.data.data.d_HDate) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentHoliday(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (Holiday: HolidayInfo) => {
        try {
  

            const res = await gsb.post('/Master', {sb_data: Holiday, cPgmId: "HolidayMast", cAction: "AddRec",isHkHoliday:isHkHoliday})
            if (isReqAuth2) {
                showSuccess("Holiday. " + Holiday.d_HDate + " record adding, waiting for authorization")
            } else {

                if (Holiday.d_Market == null ||  Holiday.d_Market.trim().length == 0 ){
                    showSuccess("Holiday. " + Holiday.d_HDate +  ", " + " record added")
                } else
                {
                    showSuccess("Holiday. " + Holiday.d_HDate  + ", Market: " + Holiday.d_Market + ", " + " record added")
                }
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_HDate === res.data.data.d_HDate) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentHoliday(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                alert("Holiday. " + Holiday.d_HDate + " record adding, waiting for authorization")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (Holiday: HolidayInfo) => {
        try {
            
            //console.log("stk.d_CcassStk: ", stk.d_CcassStk)

            const res = await gsb.post('/Master', {sb_data: Holiday, cPgmId: "HolidayMast", cAction: "UpdateRec",isHkHoliday:isHkHoliday})
            if (isReqAuth2) {
                showSuccess("Holiday. " + Holiday.d_HDate + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_HDate === res.data.data.d_HDate) {
                        setCurrentHoliday(data[i])
                        break
                    }
                }  
                setCurrentMode("show")
                alert("Holiday. " + Holiday.d_HDate + " record saving, waiting for authorization")
            } else {                
                
                if (Holiday.d_Market == null ||  Holiday.d_Market.trim().length == 0 ){
                    showSuccess("Holiday. " + Holiday.d_HDate  + ", " + Holiday.d_HDesc+" record updated")
     
                } else
                {
                    showSuccess("Holiday. " + Holiday.d_HDate  + ", Market: " + Holiday.d_Market + ", " + Holiday.d_HDesc+" record updated")            
     
                }

                
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_HDate === res.data.data.d_HDate) {
                        data[i] = { ...data[i], ...res.data.data }
                        //console.log("editOnSave data", i, data)
                        setData(data)
                        setCurrentHoliday(Holiday)
                        break
                    }
                }
                //console.log("editOnSave stk", stk)
                //console.log("editOnSave res.data.data", res.data.data)
                setCurrentHoliday(res.data.data)
                setCurrentMode("show")
                //setisShowAlert1(true)
                //setShowAlert1Msg("Account No. " +ac.d_acCode + " record saved")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        //,isHkHoliday:isHkHoliday

        try {
            //console.log("HolidayMastMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        isHkHoliday:isHkHoliday,
                        filters: {
                            Holiday: searchHoliday,
                            hHalfTradeday:searchHalfTradeday, 
                        }, 
                    },
                    cPgmId: "HolidayMast",
                    cAction: "ListRec",
                    isHkHoliday:isHkHoliday,
                });
                //console.log(res.data.data[0])
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentHoliday(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {                   
                        d_HDate: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "HolidayMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentHoliday(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one HolidayMast record if Holiday have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("HolidayMastMain useEffect length > 0  called", data)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                           
                        },
                        cPgmId: "HolidayMast",
                        cAction: "ListRec",
                        isHkHoliday:isHkHoliday,
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentHoliday(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,                        
                            filters: {
                                Holiday: searchHoliday,
                                hHalfTradeday:searchHalfTradeday,                                  
                            }, 
                        },
                        cPgmId: "HolidayMast",
                        cAction: "ListRec",
                        isHkHoliday:isHkHoliday,
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentHoliday(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])


    useEffect(() => {     

        setShowWait(true)
        if ( nPage > 0 ) {
            //setcPrintMode("S")
        } 
        (async () => {
            try {
                //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: nPage,
                        limit: searchLimit,
                        backward: searchBackward,                    
                        filters: {
                            Holiday: searchHoliday,
                            hHalfTradeday:searchHalfTradeday,                            
                        }, 
                    },
                    cPgmId: "HolidayMast",
                    cAction: "ListRec",
                    isHkHoliday:isHkHoliday,
                });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentHoliday(res.data.data[0])
                }
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
                setShowWait(false)
    
            } catch (error) {
                utils.showError(error)
            }
        })()
       
    }, [isHkHoliday])


      
 
 

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <HolidayMastView gsb={gsb} Holiday={currentHoliday}  isHkHoliday={isHkHoliday} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentHoliday)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentHoliday)} onNextRec={() => NextRecProcess(currentHoliday)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></HolidayMastView>
    switch (currentMode) {

        case "new":
            //console.log("currentMode = new")
            detail = <HolidayMastEdit gsb={gsb} Holiday={BlankHoliday} isHkHoliday={isHkHoliday} MarketList={MarketList}  isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></HolidayMastEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <HolidayMastEdit gsb={gsb} Holiday={currentHoliday} isHkHoliday={isHkHoliday}  MarketList={MarketList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></HolidayMastEdit>
            break

    }

    //let logKeyViewRec = <HolidayMastView gsb={gsb} Holiday={CurrentLog} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentHoliday)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentHoliday)} onNextRec={() => NextRecProcess(currentHoliday)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></HolidayMastView>


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>
    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
            <th className="TCol-StkNo">Market</th>
                <th className="TCol-StkNo">Holiday</th>
                <th className="TCol-StkCode">Remarks</th>
                <th className="TCol-StkName">Half Trade Day</th>

            </thead>
            <tbody id="ma_tr">
                {data.map((Holiday, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                          <td className="TCol-StkNo" onClick={() => { setCurrentHoliday(Holiday) ; MoblieDev && setSelectedTabId("detail") }}>{Holiday.d_Market}</td>
                        <td className="TCol-StkNo" onClick={() => { setCurrentHoliday(Holiday) ; MoblieDev && setSelectedTabId("detail") }}>{Holiday.d_HDate}  </td>
                        <td className="TCol-StkCode" onClick={() => { setCurrentHoliday(Holiday) ; MoblieDev && setSelectedTabId("detail") }}>{Holiday.d_HDesc}</td>
                        <td className="TCol-StkName" onClick={() => { setCurrentHoliday(Holiday) }}>{Holiday.d_HNoSetl==="Y"? <Icon  intent='primary' icon="tick" /> : ''}</td>                     
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    {/* define search key */}
                    <H3><div style={{justifyContent: 'flex-end'}}>Holiday Master</div></H3>
                   
                    <div className="Row">
                        Sort by
                        <FormGroup className="Col-2-6" label="">

                  
                            <RadioGroup
                                inline={true}
                                selectedValue={isHkHoliday}
                                onChange={utils.handleStringChange(s => setisHkHoliday(s))  }
                                disabled = {! ShowMarketOK}
                            >
                                <Radio label="HK Market" value="Y" />
                                <Radio label="Foreign Market"  value="N" />
                              
                             
                            </RadioGroup>
                        </FormGroup>
                    </div>


                   <div className="Row">  {/*
                        <FormGroup className="Col-1-2" label="Search for " labelFor="inputSearchValue">
                          
                            <ControlGroup fill>
                               
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="Holiday">Holiday</option>
                                   
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" 
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "Holiday"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup> */}
                        <Button className="Col-1-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "Holiday"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/*<Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                    </div> 
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-5" label="Holiday" labelFor="searchHoliday">

                                {/*   <InputGroup  id="inputDoB" name="d_HDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />*/}
                                <InputGroup id="searchHoliday" name="d_HDate"type="date" placeholder="YYYY-MM-DD" leftIcon="calendar"  value={searchHoliday} onChange={utils.handleStringChange(s => setsearchHoliday(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}                    
                        </div>
                        {/*
                        <div className="Row">
                            <FormGroup className="Col-1-2" labelFor="searchHalftradeday">
                              <RadioGroup
                                    label="Half Trade Day" 
                                                                  
                                    inline={true}
                                    selectedValue = {searchHalfTradeday}
                                    onChange={utils.handleStringChange(s => setsearchHalfTradeday(s))}
                                >
                                    <Radio label="Yes" value="Y" />
                                    <Radio label="No"  value="N" />
                                </RadioGroup>                            
                            
                            </FormGroup>
                        </div>
                        */}
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Stock Master Log View</H3>
                    <div className="Spacing-V-16" />
                    {/*logKeyViewRec*/}
                </Card> }

                <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>
            </form>
        </>
    );
}

export default  HolidayMastMain