import React, { useState, FormEvent, useEffect } from 'react';
import { FormGroup, InputGroup, Button, Position, Toaster, Intent } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';

import { useHistory } from 'react-router-dom';
import { History } from 'history'

import * as utils from "./utils"

import "./Login.scss"

import imgLogin from "./svg/login.svg"

import { SystemHeader } from './CommonUI';

var GsbFlag: any;

interface LoginProps {
    gsb: AxiosInstance
    isLoggedIn?: boolean
    onLogin?: (credential: LoginCredential, history: History) => void,
    onLogout?: () => void
}

const toaster = Toaster.create({
    position: Position.TOP,
})

export interface LoginCredential {
    login: string,
    password: string
}

function Login({ gsb, isLoggedIn = false, onLogin = () => { }, onLogout = () => { } }: LoginProps) {

    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const history = useHistory()

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [GSBx_USER, setGSBx_USER] = useState(false)
    const [GSBce_USER, setGSBce_USER] = useState(true)
    const [Enter2tab, setEnter2tab] = useState("Y")

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag0');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setEnter2tab(GsbFlag.Enter2tab) ;
                setGSBx_USER(GsbFlag.GSBx_USER) ;
                setGSBce_USER(GsbFlag.GSBce_USER) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    if (isLoggedIn) {
        return (
            <>
                <div > {CoHead} </div>
                <div className="Login">
                    <img className="LoginImage" src={imgLogin} alt="Login" />
                    <div className="Spacing-V-16"></div>
                    <Button icon={IconNames.LOG_OUT} onClick={() => onLogout()}>Logout</Button>
                </div>
            </>
        )
    } else {
        
        const handleSubmit = (e: FormEvent) => {
            e.preventDefault()
            //console.log("login: ", login, password) 

            onLogin({ login: login, password: password }, history)
        }
        

        return (
            <>
                <div > {CoHead} </div>
                <form className="Login">
                    <img className="LoginImage" src={imgLogin} alt="Login" />
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="Login" labelFor="inputLogin">
                        <InputGroup autoFocus value={login} onChange={utils.handleStringChange(s => setLogin(s))} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="inputPassword" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                    <div className="Spacing-V-16"></div>
                    <Button type="submit" icon={IconNames.LOG_IN} onClick={handleSubmit}>Login</Button>
                </form>
            </>
        )
    }

}

export default Login