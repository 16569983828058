import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MultiSelect2} from "@blueprintjs/select";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";



import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { ReqAuthList } from './Types'
import { SystemHeader } from './CommonUI';


interface PageInfo {
    n: number
}

var GsbFlag: any;
var UserID: string = "";


interface ReqAuthListMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ReqAuthListMain({ gsb }: ReqAuthListMainProps) {

    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [Data, setData] = useState([] as ReqAuthList[])
    const [UserName, setUserName] = useState("")
    const [Count1, setCount1] = useState(0)
    const [Count1CB, setCount1CB] = useState(0)
    const [Count3, setCount3] = useState(0)
    const [Count4, setCount4] = useState(0)
    const [Count5, setCount5] = useState(0)
    const [Count8, setCount8] = useState(0)
    const [Count12, setCount12] = useState(0)
    const [Count13, setCount13] = useState(0)


    const [isOPenAlert, setisOPenAlert] = useState(false)
    const [isOPenAlert2, setisOPenAlert2] = useState(false)
    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)
    const [RefreshList, setRefreshList] = useState(false)
    const [LuDate, setLuDate] = useState("")


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as ReqAuthList)
    const [BlankAc, setBlankAc] = useState({} as ReqAuthList)

    const [CurrentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    
    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

    //gsb default value


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ReqAuthListMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ReqAuthListMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ReqAuthListMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ReqAuthListMain disp GsbFlag: ", GsbFlag)
                //console.log("ReqAuthListMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ReqAuthListMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("ReqAuthListMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ReqAuthListMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ReqAuthListMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    const refreshTasks = async () => {
        try {
            if ( UserID.trim().length > 0 ) {                
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_Userid: UserID,
                    },
                    cPgmId: "ReqAuthList",
                    cAction: "GetUserAuthListInfo",
                });
                console.log("ReqAuthListMain res.data.data", res.data.data);
                console.log("ReqAuthListMain res.data.count", res.data.count1, res.data.count3, res.data.count4, res.data.count5, res.data.count8, res.data.count12 );

                if ( res.data.count > 0 ) {
                    setData(res.data.data)
                    setUserName(res.data.data[0].d_userName)
                    setCount1(res.data.count1)
                    setCount1CB(res.data.Count1CB)
                    setCount3(res.data.count3)
                    setCount4(res.data.count4)
                    setCount5(res.data.count5)
                    setCount8(res.data.count8)
                    setCount12(res.data.count12)
                    setCount13(res.data.count13)
                    setLuDate(res.data.LuTime)

                    setshowButton(true)
                    setCurrentMode("show")

                } else {
                    setData([])
                    setUserName("")
                    setshowButton(false)
                    setCurrentMode("show")
                }
                setRefreshList(false)
            }
        } catch (error) {
            utils.showError(error)
        }

    }

    
    useEffect(() => {
        refreshTasks()
    }, [gsb, UserID, RefreshList])


    useEffect(() => {
        // 15 min
        let timer = setInterval(refreshTasks, 15*60*1000)
        return () => {clearInterval(timer)}
    }, [gsb, UserID, RefreshList])



    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        refreshTasks()
    }


    const onChangeSelectAustList = (LogStatus: string) => {
        if (LogStatus.length > 0 ) {
            let newData = [...Data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_LogStatus == LogStatus ) {
                    if ( newData[j].d_select == "Y" ) {
                        newData[j].d_select = "N"
                    } else {
                        newData[j].d_select = "Y"
                    }
                    break
                }
            }
            setData(newData);
            setshowButton(true)

        }
    }


    const onAuthListSaveRec = () => {
        setShowWait(true)
        setshowButton(false)
        ;

        console.log("onAuthListSaveRec data", Data);
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: UserID,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )

                if (res2.data.ok) {
                    setPassword("")
                    const res = await gsb.post('/Master', {
                        sb_data: Data, 
                        cPgmId: "ReqAuthList",
                        cAction: "AuthListSave",
                    })                
                    if ( res.data.ok ) {
                        setRefreshList(true)
                        utils.showSuccess(res.data.data)
                        setisShowAlert1(true)
                        setShowAlert1Msg(res.data.data)
                    } else {
                        utils.showWarn(res.data.data)
                        setisShowAlert2(true)
                        setShowAlert2Msg(res.data.data)
                    }
                } else {
                    setshowButton(true)
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
    }

    const onAuthListEjectRec = () => {
        setShowWait(true)
        setshowButton(false)
        ;

        console.log("onAuthListEjectRec data", Data);
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: UserID,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )

                if (res2.data.ok) {
                    setPassword("")
                    const res = await gsb.post('/Master', {
                        sb_data: Data, 
                        cPgmId: "ReqAuthList",
                        cAction: "AuthListEject",
                    })                
                    if ( res.data.ok ) {
                        setRefreshList(true)
                        utils.showSuccess(res.data.data)
                        setisShowAlert1(true)
                        setShowAlert1Msg(res.data.data)
                    } else {
                        utils.showWarn(res.data.data)
                        setisShowAlert2(true)
                        setShowAlert2Msg(res.data.data)
                    }
                } else {
                    setshowButton(true)
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            {/* <Button disabled={true} className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
            <Button disabled={! showButton} className="Col-1-2" icon="confirm" onClick={() => { setisOPenAlert(true) } }>Authorize</Button> 
            <Button disabled={! showButton} className="Col-3-2" icon="cross" onClick={() => { setisOPenAlert2(true) } }>Reject</Button> 
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            { Count1 > 0 && <div>
                <div>
                    Pgm Name : CL MAST
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/accounts?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-View" onClick={() => { window.open(`/#/accounts?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "M0001" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            { Count1CB > 0 && <div>
                <div>
                    Pgm Name : CB MAST (Foreign Currency)
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/accounts?logKey=${AuthList.d_LogStatus}?logviewonly=Y_CB`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-View" onClick={() => { window.open(`/#/accounts?logKey=${AuthList.d_LogStatus}?logviewonly=Y_CB`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "M0001CB" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            { Count3 > 0 && <div>
                <div>
                    Pgm Name : SM MAST
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/SmMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-View" onClick={() => { window.open(`/#/SmMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "M0007" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            { Count4 > 0 && <div>
                <div>
                    Pgm Name : INT MAST
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/IntRMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-View" onClick={() => { window.open(`/#/IntRMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "M0009" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            { Count5 > 0 && <div>
                <div>
                    Pgm Name : COMM MAST
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/CommMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-View" onClick={() => { window.open(`/#/CommMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "M0011" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            { Count8 > 0 && <div>
                <div>
                    Pgm Name : BCAN MAST
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/HkidrMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-View" onClick={() => { window.open(`/#/HkidrMast?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "H0002" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            { Count12 > 0 && <div>
                <div>
                    Pgm Name : PR Transaction, Over Value Limit
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-TranType">Tran Type</th>
                    <th className="TCol-Amount">Amount</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/PayRecTranEntry?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-TranType" onClick={() => { } }>{AuthList.d_TranType}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-Amount" onClick={() => { } }>{utils.formatNumber2(AuthList.d_Amount)}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-View" onClick={() => { window.open(`/#/PayRecTranEntry?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "T0007" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            { Count13 > 0 && <div>
                <div>
                    Pgm Name : DW Transaction, Over Value Limit
                </div>
                <thead>
                    <th className="TCol-LogDate">Log Date</th>
                    <th className="TCol-LogTime">Log Time</th>
                    <th className="TCol-KeyFld">Account No.</th>
                    <th className="TCol-TranType">Tran Type</th>
                    <th className="TCol-Qty">Qty</th>
                    <th className="TCol-InputBy">Input By</th>
                    <th className="TCol-Action">Action</th>
                    <th className="TCol-View">View</th>
                    <th className="TCol-Select">Select</th>
                    <th className="TCol-AuthUser">Auth User</th>
                    <th className="TCol-AuthDate">Auth Date</th>
                    <th className="TCol-AuthTime">Auth Time</th>
                </thead>
                <tbody id="ma_tr">
                    {Data.map((AuthList, i) =>
                        <tr key={i} onDoubleClick={() => { } }>
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-LogDate" onClick={() => { } }>{AuthList.d_log_Date}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-LogTime" onClick={() => { } }>{AuthList.d_log_Time}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-KeyFld" onClick={() => { window.open(`/#/StkDepWithTranEntry?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>{AuthList.d_key_fld}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-TranType" onClick={() => { } }>{AuthList.d_TranType}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-Amount" onClick={() => { } }>{utils.formatNumber2(AuthList.d_Amount)}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-InputBy" onClick={() => { } }>{AuthList.d_Input_User}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-Action" onClick={() => { } }>{AuthList.d_Action}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-View" onClick={() => { window.open(`/#/StkDepWithTranEntry?logKey=${AuthList.d_LogStatus}?logviewonly=Y`, '_blank') } }>View</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-Select"><Checkbox disabled={! (AuthList.d_Canselect == "Y") } checked={AuthList.d_select=="Y"} onChange={() => onChangeSelectAustList(AuthList.d_LogStatus)} /></td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-AuthUser" onClick={() => { } }>{AuthList.d_Auth_User}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-AuthDate" onClick={() => { } }>{AuthList.d_Auth_Date}</td> }
                            { AuthList.d_PgmCode == "T0010" && <td className="TCol-AuthTime" onClick={() => { } }>{AuthList.d_Auth_Time}</td> }
                        </tr>
                    )}
                </tbody>
            </div> }
            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            Last Update Time : {LuDate}
            <div className="Spacing-V-16" />
            * Auto Refresh every 15 Mins
            <div className="Spacing-V-16" />
            <p>Notes</p>
            <div>
                <div>Transaction(s) not authorized will be removed after 7 days (except DEMO version)</div>
            </div >
            <div className="Spacing-V-16" />
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div className="Row">
                        <Button type="submit">Refresh</Button>
                    </div>
                    <div>
                        <H3>Authorizations</H3>
                    </div>
                    <div className="Spacing-V-16" />
                    User ID : {UserID}
                    <div className="Spacing-V-16" />
                    User Name : {UserName}
                    <div className="Spacing-V-16" />
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        {/* <Tab id="detail" title="New" panel={detail} /> */}
                    </Tabs>
                    <div className="Spacing-V-16" />
                    {/* <div >Note : </div> */}

                </Card>

                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isOPenAlert}
                    onCancel={ () => setisOPenAlert(false) }
                    onConfirm={ () => { onAuthListSaveRec(); setisOPenAlert(false) } }
                >
                    <div>
                        <div>Authorization Required</div>
                        <div>Please Input User & Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="User" labelFor="inputAuthUser">
                        <InputGroup id="inputAuthUser" disabled={true} value={UserID} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" autoFocus type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                </Alert>

                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isOPenAlert2}
                    onCancel={ () => setisOPenAlert2(false) }
                    onConfirm={ () => { onAuthListEjectRec(); setisOPenAlert2(false) } }
                >
                    <div>
                        <div>Reject Transcation</div>
                        <div>Please Input User & Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="User" labelFor="inputAuthUser">
                        <InputGroup id="inputAuthUser" disabled={true} value={UserID} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" autoFocus type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                </Alert>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default ReqAuthListMain