import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MultiSelect2} from "@blueprintjs/select";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";



import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'


import { xGroupAccess } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';

interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "S0008" ;
var isReqAuth2:boolean = false ;
var isReqValueLimitAuth2:boolean = false ;

interface GroupAccessViewProps {
    gsb: AxiosInstance,
    xGroupAccess: xGroupAccess,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function GroupAccessView({ gsb, xGroupAccess = {} as xGroupAccess, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: GroupAccessViewProps) {

    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)
    const [isCanDelete, setisCanDelete] = useState(true)
    const [isDeletePress2, setisDeletePress2] = useState(false)

    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")



    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("PayRecTranEntryView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                        setisCanDelete(false)
                        if ( res.data.data[0].d_Can_DeleteMaster == "Y" ) {
                            setisCanDelete(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, xGroupAccess])

    const onReqAuthDelRec = () => {
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: AuthUser,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )
                if (res2.data.ok) {
                    setAuthUser("")
                    setPassword("")

                    const res = await gsb.post('/Master', {
                        sb_data: {
                            userId: AuthUser,
                            password: password,
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "ReqUserGroupAccessReqAuth"
                    })
                    console.log("onReqAuthDelRec res.data.data", res.data.data);
                    if ( res.data.ok && res.data.data[0].d_Can_DeleteMaster == "Y") {
                        onDelete();
                        
                    } else {
                        utils.showWarn("Authorization failed")
                        setisShowAlert2(true)
                        setShowAlert2Msg("Authorization failed")
                    }

                } else {
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }



    if (! ("d_GroupId" in xGroupAccess)) return null

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit() } >Edit</Button>
                { isCanDelete && <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button> }
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                { ! isCanDelete && <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress2(true) } >Delete</Button> }
                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isDeletePress2}
                    onCancel={ () => setisDeletePress2(false) }
                    onConfirm={ () => { onReqAuthDelRec(); setisDeletePress2(false) } }
                >
                    <div>
                        <div>Authorization Required to Delete</div>
                        <div>Please Input User & Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="User" labelFor="inputAuthUser">
                        <InputGroup id="inputAuthUser" autoFocus value={AuthUser} onChange={utils.handleStringChange(s => setAuthUser(s))} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                </Alert>

                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>

                <Button className="Col-7-2" icon="document" onClick={() => { window.open(`/#/GroupAccess02?Groupid=${xGroupAccess.d_GroupId}`, '_blank') } } >Edit Group Pgm Access</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Group ID">
                    <InputGroup readOnly value={xGroupAccess.d_GroupId} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Group Name" labelFor="inputGroupName">
                    <InputGroup readOnly value={xGroupAccess.d_GroupName} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="D/W, P/R Transaction Value Limit Authorization" labelFor="inputAuth_Value_Limit_tran">
                    <InputGroup readOnly value={xGroupAccess.d_Auth_Value_Limit_tran} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Allow Delete Record : (33, 34, Group Access)" labelFor="inputCan_DeleteMaster">
                    <RadioGroup
                        name="d_Can_DeleteMaster"
                        disabled={true}
                        inline={true}
                        selectedValue={xGroupAccess.d_Can_DeleteMaster}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Allow View Client Document" labelFor="inputCan_ViewClientDoc">
                    <RadioGroup
                        name="d_Can_ViewClientDoc"
                        disabled={true}
                        inline={true}
                        selectedValue={xGroupAccess.d_Can_ViewClientDoc}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-4-3" label="Allow Manage Client Document" labelFor="inputCan_ManageClientDoc">
                    <RadioGroup
                        name="d_Can_ManageClientDoc"
                        disabled={true}
                        inline={true}
                        selectedValue={xGroupAccess.d_Can_ManageClientDoc}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>

        </div>
    )
}

interface GroupAccessEditProps {
    gsb: AxiosInstance,
    xGroupAccess?: xGroupAccess,
    isNew?: boolean,
    onSave?: (xGroupAccess: xGroupAccess) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function GroupAccessEdit({ gsb, xGroupAccess = {} as xGroupAccess, isNew = false, onSave = () => { }, onCancel = () => { } }: GroupAccessEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<xGroupAccess>()

    const [Can_DeleteMasterValue, setCan_DeleteMasterValue] = useState(xGroupAccess.d_Can_DeleteMaster)
    const [Can_ViewClientDocValue, setCan_ViewClientDocValue] = useState(xGroupAccess.d_Can_ViewClientDoc)
    const [Can_ManageClientDocValue, setCan_ManageClientDocValue] = useState(xGroupAccess.d_Can_ManageClientDoc)
  
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_GroupId" in xGroupAccess) {
            reset(xGroupAccess)
        }
    }, [xGroupAccess, reset])



    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    


    if (! isNew && !("d_GroupId" in xGroupAccess)) return null

    
    const onSubmit = (data: xGroupAccess) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_GroupId = data.d_GroupId.toUpperCase();

        data.d_Can_DeleteMaster = Can_DeleteMasterValue
        data.d_Can_ViewClientDoc = Can_ViewClientDocValue
        data.d_Can_ManageClientDoc = Can_ManageClientDocValue

        ;

        (async () => {
            try {
                console.log("call GroupAccessedit data", data)
                console.log("call GroupAccessedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "GroupAccess", cAction: "GroupAccessValidate", isNew: isNew})
                //console.log("SysConfigedit res.data.ok", res.data.ok)
                //console.log("SysConfigedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Group ID" labelFor="inputGroupId">
                    <InputGroup disabled={! isNew} id="inputGroupId" name="d_GroupId" autoFocus={isNew} defaultValue="" placeholder="Group ID ..." inputRef={register({ required: true , maxLength: 20}) } />
                    {errors.d_GroupId && errors.d_GroupId.types && <span id="Err_msg">{errors.d_GroupId.types.message}</span>}
                    {errors.d_GroupId && errors.d_GroupId.type === "required" && (<span id="Err_msg">User ID cannot be blank</span>)}
                    {errors.d_GroupId && errors.d_GroupId.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col-1-2" label="Group Name" labelFor="inputGroupName">
                    <InputGroup id="inputGroupName" name="d_GroupName" autoFocus={! isNew} defaultValue="" placeholder="Group Name ..." inputRef={register({ maxLength: 50}) } />
                    {errors.d_GroupName && errors.d_GroupName.types && <span id="Err_msg">{errors.d_GroupName.types.message}</span>}
                    {errors.d_GroupName && errors.d_GroupName.type === "maxLength" && <span id="Err_msg">Max length is 50</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="D/W, P/R Transaction Value Limit Authorization" labelFor="inputAuth_Value_Limit_tran">
                    <select  id="inputGroupAction" name="d_Auth_Value_Limit_tran" ref={register}>
                        <option value="F">F: Full Access</option>
                        <option value="C">C: Checker</option>
                        <option value="M">M: Maker</option>
                    </select>
                    {errors.d_Auth_Value_Limit_tran && errors.d_Auth_Value_Limit_tran.types && <span id="Err_msg">{errors.d_Auth_Value_Limit_tran.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Allow Delete Record : (33, 34, Group Access)" labelFor="inputCan_DeleteMaster">
                    <RadioGroup
                        name="d_Can_DeleteMaster"
                        inline={true}
                        selectedValue={Can_DeleteMasterValue}
                        onChange={utils.handleStringChange(s => setCan_DeleteMasterValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Can_DeleteMaster && errors.d_Can_DeleteMaster.types && <span id="Err_msg">{errors.d_Can_DeleteMaster.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Allow View Client Document" labelFor="inputCan_ViewClientDoc">
                    <RadioGroup
                        name="d_Can_ViewClientDoc"
                        inline={true}
                        selectedValue={Can_ViewClientDocValue}
                        onChange={utils.handleStringChange(s => setCan_ViewClientDocValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Can_ViewClientDoc && errors.d_Can_ViewClientDoc.types && <span id="Err_msg">{errors.d_Can_ViewClientDoc.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="Allow Manage Client Document" labelFor="inputCan_ManageClientDoc">
                    <RadioGroup
                        name="d_Can_ManageClientDoc"
                        inline={true}
                        selectedValue={Can_ManageClientDocValue}
                        onChange={utils.handleStringChange(s => setCan_ManageClientDocValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Can_ManageClientDoc && errors.d_Can_ManageClientDoc.types && <span id="Err_msg">{errors.d_Can_ManageClientDoc.types.message}</span>}
                </FormGroup>
            </div>
        </form>
    )
}

interface GroupAccessMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function GroupAccessMain({ gsb }: GroupAccessMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as xGroupAccess[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as xGroupAccess)
    const [BlankAc, setBlankAc] = useState({} as xGroupAccess)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankAc.d_GroupId = ""
    BlankAc.d_GroupName = ""
    BlankAc.d_Auth_Value_Limit_tran = "F"
    BlankAc.d_Can_DeleteMaster = "Y"
    BlankAc.d_Can_ViewClientDoc = "Y"
    BlankAc.d_Can_ManageClientDoc = "Y"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("GroupAccessMain disp user_key: ", res.data.Flag.user_key)
                //console.log("GroupAccessMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("GroupAccessMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("GroupAccessMain disp GsbFlag: ", GsbFlag)
                //console.log("GroupAccessMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("GroupAccessMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("GroupAccessMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("GroupAccessMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("GroupAccessMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])




    const PrevRecProcess = async (xGroupAccess: xGroupAccess) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: xGroupAccess, cPgmId: "GroupAccess", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_GroupId === res.data.data.d_GroupId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (xGroupAccess: xGroupAccess) => {
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: xGroupAccess, cPgmId: "GroupAccess", cAction: "DelRec"})
            showSuccess("Group ID " + xGroupAccess.d_GroupId + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_GroupId === res.data.data.d_GroupId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (xGroupAccess: xGroupAccess) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: xGroupAccess, cPgmId: "GroupAccess", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_GroupId === res.data.data.d_GroupId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (xGroupAccess: xGroupAccess) => {
        try {
            const res = await gsb.post('/Master', {sb_data: xGroupAccess, cPgmId: "GroupAccess", cAction: "AddRec"})
            showSuccess("Group ID " + xGroupAccess.d_GroupId + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_GroupId === res.data.data.d_GroupId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (xGroupAccess: xGroupAccess) => {
        try {
            console.log("call editOnSave", xGroupAccess)
            const res = await gsb.post('/Master', {sb_data: xGroupAccess, cPgmId: "GroupAccess", cAction: "UpdateRec"})
            showSuccess("Group ID " + xGroupAccess.d_GroupId + " record saved")
            
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_GroupId === res.data.data.d_GroupId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                        },
                    },
                    cPgmId: "GroupAccess",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.count", res.data.count)
                console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.data[3].d_PgmDeduction", res.data.data[3].d_PgmDeduction)
                //console.log("ListRec res.data.data[3].d_PgmDeduction[0]", res.data.data[3].d_PgmDeduction[0])
                //console.log("ListRec res.data.data[3].d_PgmDeduction[0].d_PgmCode", res.data.data[3].d_PgmDeduction[0].d_PgmCode)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_GroupId: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "GroupAccess",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    alert(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }


    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                            },
                            },
                        cPgmId: "GroupAccess",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <GroupAccessView gsb={gsb} xGroupAccess={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></GroupAccessView>
    switch (currentMode) {
        case "edit":
            detail = <GroupAccessEdit gsb={gsb} xGroupAccess={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></GroupAccessEdit>
            break
        case "new":
            detail = <GroupAccessEdit gsb={gsb} xGroupAccess={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></GroupAccessEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-GroupId">Group ID</th>
                <th className="TCol-GroupName">Group Name</th>
                <th className="TCol-d_Auth_Value_Limit">Value Limit Authorization</th>
                <th className="TCol-d_Can_DeleteMaster">Can Delete</th>
                <th className="TCol-d_Can_ViewClientDoc">Read Client Doc</th>
                <th className="TCol-Can_ManageClientDocValue">Manage Client Doc</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((xGroupAccess, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-GroupId" onClick={() => { setCurrentAc(xGroupAccess) ; MoblieDev && setSelectedTabId("detail") }}>{xGroupAccess.d_GroupId}</td>
                        <td className="TCol-GroupName" onClick={() => { setCurrentAc(xGroupAccess) ; MoblieDev && setSelectedTabId("detail") }}>{xGroupAccess.d_GroupName}</td>
                        <td className="TCol-d_Auth_Value_Limit" onClick={() => { setCurrentAc(xGroupAccess) ; MoblieDev && setSelectedTabId("detail") }}>{xGroupAccess.d_Auth_Value_Limit_tran}</td>
                        <td className="TCol-d_Can_DeleteMaster" onClick={() => { setCurrentAc(xGroupAccess) ; MoblieDev && setSelectedTabId("detail") }}>{xGroupAccess.d_Can_DeleteMaster}</td>
                        <td className="TCol-d_Can_ViewClientDoc" onClick={() => { setCurrentAc(xGroupAccess) ; MoblieDev && setSelectedTabId("detail") }}>{xGroupAccess.d_Can_ViewClientDoc}</td>
                        <td className="TCol-d_Can_ManageClientDoc" onClick={() => { setCurrentAc(xGroupAccess) ; MoblieDev && setSelectedTabId("detail") }}>{xGroupAccess.d_Can_ManageClientDoc}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>Group Access</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="Groupid">Group ID</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-2 Button-LabeledFormGroup" icon="search" type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-8-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        {/*
                        <div className="Row">
                            <FormGroup className="Col3-1-2" label="Verified Email" labelFor="searchVerifiedEmail">
                                <select  id="searchVerifiedEmail" value={searchVerifiedEmail} onChange={utils.handleStringChange(s => setsearchVerifiedEmail(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>
                        </div>
                        */}
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>
            </form>
        </>
    );
}

export default GroupAccessMain