import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
 
import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { FileInput,Radio, RadioGroup, Switch, Alert, Callout, Checkbox, Tooltip,ProgressBar } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation,Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import {IPOINFO,  CountryINFO} from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';

//import IPOResultFileMain  from './IPOResultFile';

interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "H0002" ;
var isReqAuth2:boolean = false ;


    

interface IPOMastExportProps {
    gsb: AxiosInstance,
    IPO: IPOINFO,
    baseUrl?: string,
    token?: string | null,
    ViewMode: any,
    logKey2: any
   
}

interface IPOResultFileMainProps {
    gsb: AxiosInstance,
    currentFile?: File,
    IPO?: IPOINFO

   
}


interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}

const showErrorBox = (err: any) => {
    alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
}

function IPOMastExport({ gsb, IPO = {} as IPOINFO, baseUrl = "", token = "",  ViewMode, logKey2 }: IPOMastExportProps) {
 
    let ProgressId2 = ""
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0") 
    const [isReplicate, setisReplicate] = useState(false)
    const [isRegenbcan, setisRegenbcan] = useState(false)

    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)
    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptHead2, setRptHead2] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
    const [RptLogName, setRptLogName] = useState("")
    const [ShowWait, setShowWait] = useState(false)

    const [ShowRpt, setShowRpt] = useState(false)

    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)
 
    const [s_StkCode1, sets_StkCode1] = useState("")
    const [DataFormatValue, setDataFormatValue] = useState(true)
    const [CSVFormat, setCSVFormat] = useState("0")
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    //const [cPrintMode, setcPrintMode] = useState("PS")
    const [LineLimit, setLineLimit] = useState(54 - 7)
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<IPOINFO>()
  
   

    useEffect(() => {
        setShowRpt(false)        
        setShowNoRecord(false)     
    }, [IPO.OSTKNO])

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 20*1000)
        return () => {clearInterval(timer)}
    }, [gsb, ProgressId, nPage])


     const onSubmit = (data: IPOINFO) => {
        //console.log("onsubmit called", data)
        // put value to json/field before write database dbf
        setShowOK(false)
        data.OSTKNO = IPO.OSTKNO
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptHead2("")
        setRptName("")
        setRptNameNo(0)
        setnPage(1)
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0);

        (async () => {
            try {
                console.log("IPO-> OSTKNO  ", IPO.OSTKNO)
                const res = await gsb.post('/Master',
                {   sb_data: {
                    ProgressId: ProgressId2,
                    s_StkCode1:IPO.OSTKNO,                          
                    DataFormat: DataFormatValue,
                    nPage: 1,
                    cPrintMode: "PS",
                    limit: LineLimit,
                },
                cPgmId: "IPOMast",
                cAction: "ExportIPO",
            });


                //console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    setRptHead1(res.data.Rpthead1)
                    setRptHead2(res.data.Rpthead2)
                    setRptName(res.data.RptName)
                    setRptLogName(res.data.RptLogName)
                    setRptNameNo(res.data.RptNameNo)
                    //console.log("RptName: ", RptName) 
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / LineLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / LineLimit); j++ ) {
                            aPageNumber1.push({n: j});
                       }
                        setaPageNumber(aPageNumber1)
                        
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }                        
                    }
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()


    }


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }
    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download</a>}
        &nbsp; &nbsp; {RptLogName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${RptLogName}&inline`}>View Log</a>}
        {ShowRpt && ! DataFormatValue && Pagination}
        {/* display search list */}
     

        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    { ! DataFormatValue && <th className="RCol-Head_p">
                            {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download</a>}
                        </th>
                    }
                </tr>                    
                <tr>
                    <td className="RCol-Head1">{RptHead1}</td>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                {Rstdata.map((rpt, i) =>
                    <tr key={i} onDoubleClick={() => { }}>
                        <td className="RCol-Rpt" onClick={() => {  }}>{rpt.Rst_Line}</td>
                    </tr>
                )}
            </tbody>
            <div className="Spacing-V-16" />
            {ShowRpt && ! DataFormatValue && Pagination}
        </HTMLTable>
    </>
 
    {/*if (! ("d_OSTKNO" in IPO)) return null*/}

    return (
      
     
        <form onKeyDown={handleHotkey}>
 
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div className="Spacing-V-16" />
                Export IPO list  {IPO.OSTKNO}
                <div className="Row">
                    <FormGroup className="Col-1-2" >
                        <div>ZIP Format</div>
                    </FormGroup>
                </div>
                <div className="Row">                  
                     <Button className="Col-2-1" icon="tick" disabled={! ShowOK}  onClick={handleSubmit(onSubmit)}>submit</Button>
                </div>
            </Card>
            {/* {ShowWait && <div>{imgWait}</div> } */}
            {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
            {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }
            {ShowNoRecord && <p>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-3"  >
                        <span id="Err_msg">No Record found !!!</span>
                    </FormGroup>
                </div>
            </p>
            }
            {ShowRpt && list}
        </form>  
    )
}


function IPOResultFileMain({ gsb,currentFile ={} as File ,IPO = {} as IPOINFO}: IPOResultFileMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [fileInfoList, setFileInfoList] = useState([] as FileInfo[])
    const [file1Label, setFile1Label] = useState("Choose file...")
     const [file2Label, setFile2Label] = useState("Choose file...")
    const [file3Label, setFile3Label] = useState("Choose file...")
    const [file4Label, setFile4Label] = useState("Choose file...")
    const [file5Label, setFile5Label] = useState("Choose file...")
    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))

    const [SAS_MKT, setSAS_MKT] = useState(false)
    const [SYNC_TEXT, setSYNC_TEXT] = useState(false)
    const [IMP_INET, setIMP_INET] = useState(false)

    const [importok, setimport] = useState(false)
    const [nUploaded, setnUploaded] = useState(0)
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<IPOINFO>()
  //
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as IPOINFO)
    const [RptLogName, setRptLogName] = useState("")
     
    const [ShowRpt, setShowRpt] = useState(true)

    const [ShowNoRecord, setShowNoRecord] = useState(true)
    const [ShowOK, setShowOK] = useState(true)

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptHead2, setRptHead2] = useState("")

    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
    let ProgressId2 = ""
    const [LineLimit, setLineLimit] = useState(54 - 7)
    const [data, setData] = useState([] as IPOINFO[])




    useEffect(() => {
        (async () => {
            try {

                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
               // sets_InputStkno("9595");
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}

                setSAS_MKT(GsbFlag.SAS_MKT)
                setIMP_INET(GsbFlag.IMP_INET)
                setSYNC_TEXT(GsbFlag.SYNC_TEXT)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
/*
    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/import',
                {   sb_data: {
                        
                    },
                    cPgmId: "importCSVlist",
                });                
                setFileInfoList(res.data.data)
                
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
*/
    useEffect(() => {
        
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await gsb.post('/tasklist', taskList.map((t) => t.id));
                    setTaskList(res.data.data)
                    
                } catch (error) {
                    utils.showError(error)
                }
            }, 3+1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, gsb])

    useEffect(() => {


        sets_InputStkno(IPO.OSTKNO)
        sets_InputListPrice(IPO.OIPRICE)
        sets_InputListDate(IPO.OLDATE) 

        console.count("s_InputListPrice" + s_InputListDate)      
        console.count("s_InputListPrice" + s_InputListPrice)

    },[gsb])
    useEffect(() => {
      
             
        
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                StkNo: searchStkNo,
                                StkName: searchStkName,
                                AppyDate:searchApplyDate,
                            },
                            },
                        cPgmId: "IPOMast",
                        cAction: "ListIPO",
                    });

                    console.log(res.data.data)

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                   // setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        
    }, [gsb])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        if ( file1Label == "Choose file  ...  "  || file1Label.length == 0) {
            currentFile.UploadFile1 = ""
            utils.showError("Please choose a file"); 

            //sets_InputStkno(IPO.OSTKNO)
            //sets_InputListPrice(IPO.OIPRICE)
    
            //sets_InputListDate(IPO.OLDATE)
           // sets_InputListPrice(IPO.OIPRICE)
    
           // console.count("s_InputListPrice" + s_InputListDate)      
           // console.count("s_InputListPrice" + s_InputListPrice)


            return
        } 
        
        (async () => {
            try {
                const res = await gsb.post('/importupload',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                /*
                sets_InputStkno(IPO.OSTKNO)
                sets_InputListPrice(IPO.OIPRICE)
        
                sets_InputListDate(IPO.OLDATE)
                sets_InputListPrice(IPO.OIPRICE)
        
                console.count("s_InputListPrice" + s_InputListDate)      
                console.count("s_InputListPrice" + s_InputListPrice)
                */
                setShowTasks(false)
                setimport(true)

     
                setnUploaded(res.data)
              
                //setFileInfoList(res.data.data)
                utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)
              //  setFileInfoList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit2)()
            
        }
    }

    const onSubmit2 = (data: IPOINFO) => {
        console.log("onsubmit called", data)
        // put value to json/field before write database dbf
        setShowOK(false)
        data.OSTKNO = IPO.OSTKNO
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptHead2("")
        setRptName("")
        setRptNameNo(0)
        setnPage(1)
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0);

        (async () => {
            try {
                console.log("IPO-> OSTKNO  ", IPO.OSTKNO)
                const res = await gsb.post('/Master',
                {   sb_data: {
                    ProgressId: ProgressId2,
                    s_StkCode1:IPO.OSTKNO,                          
                    //DataFormat: DataFormatValue,
                    nPage: 1,
                    cPrintMode: "PS",
                    limit: LineLimit,
                },
                cPgmId: "IPOMast",
                cAction: "ExportIPO",
            });


                //console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    setRptHead1(res.data.Rpthead1)
                    setRptHead2(res.data.Rpthead2)
                    setRptName(res.data.RptName)
                    setRptLogName(res.data.RptLogName)
                    setRptNameNo(res.data.RptNameNo)
                    //console.log("RptName: ", RptName) 
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / LineLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / LineLimit); j++ ) {
                            aPageNumber1.push({n: j});
                       }
                        setaPageNumber(aPageNumber1)
                        
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }                        
                    }
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()


    }

 
    const onImportFiles = () => {
 
        currentFile.UploadFile1 = file1Label
        if ( currentFile.UploadFile1.length > 0 ) {
    
            (async () => {
                    try {
                      
                        console.log({   sb_data: currentFile,
                            fileInfoList: fileInfoList,
                            s_InputStkno : s_InputStkno,
                            s_InputListPrice: s_InputListPrice,
                            s_InputListDate: s_InputListDate,
                          
                        });
                        const res = await gsb.post('/import', 
                            {   sb_data: currentFile,
                                fileInfoList: fileInfoList,
                                s_InputStkno : s_InputStkno,
                                s_InputListPrice: s_InputListPrice,
                                s_InputListDate: s_InputListDate,
                               cPgmId: "IPOProcessResultFile",
                        }); 
                        
                        console.log("res: ", res.data)
          
                        if (res.data.ok ){
                            setTaskList(res.data.data)
                            console.count(res.data.data)
                            setShowTasks(true)
                           // setFile1Label("Choose file  ...  ")
                            setimport(false)
                           // setFile1Label("")
                           // setFileInfoList(res.data.data)
                        } else {
                            if (! res.data.ok) {
                                for (let j = 0; j < res.data.data.length; j++) {
                                  
                                    utils.showError("error");
                                }
                                return
                            }

                        }
                        //setFile1Label("")
                        
                        
                    } catch (error) {
                        utils.showError(error)
                    }
                })()
        }else {

            utils.showError("Please choose a file"); 

        }
    }
    const [selectedFile, setSelectedFile] = React.useState<File | string>('fileurl');
   
    const [s_InputStkno, sets_InputStkno] = useState("")
    const [s_InputListPrice, sets_InputListPrice] = useState("")
    const [s_InputListDate, sets_InputListDate] = useState("")
    //const fileChangedHandler = (e : React.ChangeEvent<HTMLInputElement>) => {nUploaded(0));
    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    const [searchKey, setSearchKey] = useState("StkCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchStkNo, setSearchStkNo] = useState("")
    const [searchStkName, setSearchStkName] = useState("")
    const [searchApplyDate, setsearchApplyDate] = useState("")


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader> 
    
    return (
        <>            
          
           
            <form className="Import" onSubmit={onSubmit}> 

               <div className="Row">
                    <FormGroup className="Col-1-2" label="Stock code." labelFor="StockNo">
                        <InputGroup id="StockNo" readOnly value={s_InputStkno}  />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Stock Name." labelFor="StockName">
                        <InputGroup id="StockName" readOnly   value={IPO.OSTKNAME} />
                    </FormGroup>

                    <FormGroup className="Col-1-2" label="Apply Date." labelFor="ApplyDate">
                        <InputGroup id="Apply Date" readOnly  type="date" value={IPO.OADATE} />
                    </FormGroup>

                    <FormGroup className="Col-3-2" label="Input List Date." labelFor="inputListDate">
                        {/*}
                        <InputGroup
                            id="inputListDate"
                            placeholder="List Date.."
                            value={s_InputListDate}
                            onChange={utils.handleStringChange(s => sets_InputListDate(s))}
                        />*/}
                        <InputGroup id="inputListDate" name="Input_List_Date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar"  value={(s_InputListDate)} onChange={utils.handleStringChange(s => sets_InputListDate((s)))}  inputRef={register} required/>
                    </FormGroup>
                    
                    <FormGroup className="Col-1-2" label="Apply Price." labelFor="ApplyPrice">
                        <InputGroup id="ApplyPrice" readOnly value={IPO.OAPRICE} />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Input List Price." labelFor="inputListPrice">
                        <InputGroup
                            id="inputListPrice"
                            placeholder="List Price.." 
                            value={s_InputListPrice}
                            defaultValue={s_InputListPrice}
                            onChange={utils.handleStringChange(s => sets_InputListPrice(s))}
                            
                        />
                    </FormGroup>
                 </div>
                <div className="Row">
                    <FileInput className="Col-1-1" inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                </div>

                <div className="Spacing-V-8"></div>
                <div className="Row">
                    <Button className="Col-1-1" type="submit" icon={IconNames.UPLOAD}>Upload</Button>
                    <Button disabled= {!importok} className="Col-2-1" icon={IconNames.IMPORT} onClick={onImportFiles}>Process</Button>            
               </div>
            </form>
            <div className="Spacing-V-8"></div> 
            <div className="Spacing-V-16"></div>
            <Collapse isOpen={showTasks}>
                <p>Import Files</p>
                <HTMLTable striped interactive condensed>
                    <thead>
                        <th className="TCol-Ref">File Name</th>
                        <th className="TCol-description">description</th>                      
                        <th className="TCol-Progress">Progress</th>
                        <th className="TCol-Message">Message</th>
                        <th className="TCol-Log">Log</th>
                    </thead>
                    <tbody>
                        {taskList.map((t, i) =>
                            <tr key={i}>
                                <td className="TCol-Ref">{t.ref}</td>                                
                                <td className="TCol-description">{t.description}</td>
                                <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                <td className="TCol-Message">{t.message}</td>
                                <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                            </tr>
                        )}
                    </tbody>
                </HTMLTable>
                {/*
                <div className="Spacing-V-8"></div>
                <div className="Row">
                    <Button className="Col-1-1" type="submit" icon={IconNames.CROSS} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                </div>
                */}
                <div className="Spacing-V-16"></div>
            </Collapse>
            <br></br>
            <p>Notes</p>
          
           {/*  <div>
                <div>Trades</div>
                { SYNC_TEXT && <div>31R Real-Time Data Exchange -- Trade Import, file name: FEBSTRAN.txt</div> }
                { IMP_INET && <div>Client Trading Text File (Standard), file name: 31I_yyyymmdd.txt</div> }
                <div><br></br></div>
                <div>32M HK Stock AMS/3-MWS TEXT FILE FROM SEHK, file name: yyyymmdd.ctf</div>
                { SAS_MKT && <div>SAS/SZMK Market Stock AMS/3-MWS TEXT FILE FROM SEHK, file name: yyyymmddashr.ctf</div> }
                <div><br></br></div>
                <div>Closing Price</div>
                <div>HK Stock Closing Price, file name: yyyymmdd.cp</div>
                { SAS_MKT && <div>Shanghai Stock Closing Price, file name: asclospric</div> }
                { SAS_MKT && <div>Shenzhen Stock Closing Price, file name: szclospric</div> }
                <div><br></br></div>
                { IMP_INET && <div>P/R Transaction Text File (Standard), file name: 33I_yyyymmdd.txt</div> }
                { IMP_INET && <div>D/W Transaction Text File (Standard), file name: 34I_yyyymmdd.txt</div> }
           </div >*/}
        </>
    )
}


interface IPOMastImportMainProps {
    gsb: AxiosInstance
 
}


interface File {
    fileName: string,
    select: string,
    size: number,
    type: string,
    contentDate: string,
    records: number,
    error: string,
    delete: string,
    clear: string,     
    UploadFile1: string   
}

interface FileInfo {
    fileName: string,
    select: string,
    size: number,
    type: string,
    contentDate: string,
    records: number,
    error: string,
    delete: string,
    clear: string
}

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


const toaster = Toaster.create({
    position: Position.TOP,
})

function IPOMastImportMain({ gsb }: IPOMastImportMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [data, setData] = useState([] as IPOINFO[])


    const [searchKey, setSearchKey] = useState("StkCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchStkNo, setSearchStkNo] = useState("")
    const [searchStkName, setSearchStkName] = useState("")
    const [searchApplyDate, setsearchApplyDate] = useState("")

    const [def_id_Type, setid_Type] = useState("")

    const [searchTel, setSearchTel] = useState("")


    const [s_AcStatus, sets_AcStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [SetMode, setSetMode] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as IPOINFO)
    const [BlankAc, setBlankAc] = useState({} as IPOINFO)
    const [CurrentLog, setCurrentLog] = useState({} as IPOINFO)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
    
    const { t, i18n } = useTranslation();
   
    const [CountryList, setCountryList] = useState([] as CountryINFO[])



 


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("HkidrMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("HkidrMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("HkidrMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("HkidrMastMain disp GsbFlag: ", GsbFlag)
                //console.log("HkidrMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("HkidrMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("HkidrMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("HkidrMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("HkidrMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}
                setSUSPEND_AC(GsbFlag.SUSPEND_AC);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("HkidrMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }
        
        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "HkidrMast", 
                        cAction: "ViewLogRec"
                    })
                    console.log("HkidrMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog(res.data.data[0])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( SetMode === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            StkNo: searchStkNo,
                            StkName: searchStkName,
                            AppyDate:searchApplyDate,
                        },
                    },
                    cPgmId: "IPOMast",
                    cAction: "ListIPO",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "IPOMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    alert(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
    {/*
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {

                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            SortByName: false,
                        },
                        cPgmId: "IPOMast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])
*/}
    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && SetMode == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                StkNo: searchStkNo,
                                StkName: searchStkName,
                                AppyDate:searchApplyDate,
                            },
                            },
                        cPgmId: "IPOMast",
                        cAction: "ListIPO",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, SetMode])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }



 


    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
   //let detail =<IPOMastExport gsb={gsb} IPO={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')}  ViewMode={ViewMode} logKey2={logKey2.trim()} ></IPOMastExport>
   
   let detail =<IPOResultFileMain gsb={gsb} IPO={currentAc} ></IPOResultFileMain>
   
    let logKeyViewRec = <IPOMastExport gsb={gsb} IPO={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} ViewMode={ViewMode} logKey2={logKey2.trim()} ></IPOMastExport>


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


  
    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            {/* <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}

        
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Stock</th>
                <th className="TCol-StkName">Stock Name</th>
                 {/*<th className="TCol-ACCode">LOT SIZE</th>*/}
                <th className="TCol-ACCode">Apply date</th>
                <th className="TCol-ACCode">List date</th>    
                <th className="TCol-APrice">APPLY PRICE</th>
                <th className="TCol-ACCode">List PRICE</th>
                {/*<th className="TCol-ACCode">Gen EIPO</th>*/}
            </thead>
            <tbody id="ma_tr">
                {data.map((IPO, i) =>
                    <tr key={i} onDoubleClick={() => { setSetMode("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSetMode("detail") }}>{IPO.OSTKNO}</td>
                        <td className="TCol-StkName" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSetMode("detail") }}>{IPO.OSTKNAME}</td>
                        {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>{IPO.OAQTY}</td> */}
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSetMode("detail") }}>{utils.dispDate(IPO.OADATE, GsbFlag.dateFormat)}</td>                      
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSetMode("detail") }}>{utils.dispDate(IPO.OLDATE, GsbFlag.dateFormat)}</td>
                        <td className="TCol-APrice" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSetMode("detail") }}>${utils.formatNumber2(IPO.OAPRICE)}</td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSetMode("detail") }}>${utils.formatNumber2(IPO.OIPRICE)}</td>
                        {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>  {IPO.OGENEPIO ? <Icon  icon="tick"/> : ''}</td>*/}
                    </tr>
                )}
            </tbody>
         
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
             {(SetMode ==="list") && <form onSubmit={onSubmit}>
                { ! ( logviewonly === "Y" ) &&  (SetMode ==="list") && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>Read EIPO Allotment Result</div></H3>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for Stock No" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="StkCode">StockNo</option>
                                    <option value="StkName">StockName</option>
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" type="submit" >List</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
 
                    <Collapse isOpen={showFilters}>
 
                        <div className="Row">
                           <FormGroup className="Col-1-1" label="Stock Code" labelFor="searchStkNo">
                                <InputGroup id="searchStkNo" value={searchStkNo} onChange={utils.handleStringChange(s => setSearchStkNo(s))} />
                            </FormGroup>   
                            <FormGroup className="Col-1-1" label="Stock Name" labelFor="searchStkName">
                                <InputGroup id="searchStkName" value={searchStkName} onChange={utils.handleStringChange(s => setSearchStkNo(s))} />
                            </FormGroup>

     
                        </div>
                    </Collapse>
                    
                        {list}
                </Card>
            }

            </form>        
            }
            {(SetMode ==="detail") && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                <div className="No-Screen" style={{justifyContent: 'flex-end'}}>HKIDR IPO Master</div>                 
                  <p><H3>Upload IPO Allotment CSV Result Files</H3></p>  
                  <Button className="Col-1-1 Button-LabeledFormGroup"   onClick={() => { setSetMode('list') }} >back</Button>              
                {detail}
            </Card> }
        </>
    );
}


export default IPOMastImportMain