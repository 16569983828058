import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useHistory } from "react-router-dom";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'
import svgdoubledown from './svg/double-chevron-down.svg'

import { SmEntitInfo, PfEntitInfo, PrintChqInfo, StkCodeInfo, AcCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;



interface PrintChqRtnProps {
    gsb: AxiosInstance,
    currentPrintChq?: PrintChqInfo,
    currentSmentit?: SmEntitInfo,
    onChqProcess?: (Chq: PrintChqInfo) => void,
    onCancel?: () => void;
}

function PrintChqRtn({ gsb, currentPrintChq = {} as PrintChqInfo, currentSmentit = {} as SmEntitInfo, onChqProcess = () => { }, onCancel = () => { } }: PrintChqRtnProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<PrintChqInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [Enter2tab, setEnter2tab] = useState("Y")

    const [IPAddr, setIPAddr] = useState("")

    const [ShowButton, setShowButton] = useState(true)
    
    const [BankCodeValue, setBankCodeValue] = useState(currentSmentit.d_Bank_Code)
    const [ChequeDateValue, setChequeDateValue] = useState(currentSmentit.d_Cheque_Date)
    const [StartingChequeNoValue, setStartingChequeNoValue] = useState(currentPrintChq.d_Starting_Cheque_No)
    const [LPTValue, setLPTValue] = useState(currentPrintChq.d_LPT)


    useEffect(() => {
        // Call reset to get the values of record PfEntitInfo 
        if ("d_Bank_Code" in currentPrintChq) {
            reset(currentPrintChq)
        }
    }, [currentPrintChq, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Dividend_Currency" })
    }, [register])


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                setEnter2tab(GsbFlag.Enter2tab)               

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, currentPrintChq, GsbFlag])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [gsb, currentPrintChq, GsbFlag, Enter2tab])



    const onSubmit = (data: PrintChqInfo) => {

        setShowButton(false)

        data.d_Bank_Code = BankCodeValue;
        if ( typeof(data.d_Bank_Code) === "undefined" ) {
            data.d_Bank_Code = ""
        }
        data.d_Bank_Code = data.d_Bank_Code.toUpperCase();

        data.d_LPT = LPTValue;
        data.d_LPT = data.d_LPT.toUpperCase();

        data.d_Starting_Cheque_No = StartingChequeNoValue;
        if ( typeof(data.d_Starting_Cheque_No) === "undefined" ) {
            data.d_Starting_Cheque_No = "1"
        }

        data.d_StkNo = currentSmentit.d_StkNo;
        data.d_AnnouncementNo = currentSmentit.d_AnnouncementNo;

/*
        data.d_StkNo = StkNoValue ;
        data.d_StkCode = StkCodeValue;
        
        data.d_Scrip_indicator = "Y";
        if (Scrip_indicatorValue) {
            data.d_Scrip_indicator = "Y";
        } else {
            data.d_Scrip_indicator = "N";
        }
*/

       

        (async () => {
            try {
                
                console.log("call PrintChqRtn data", data);

                
                const res = await gsb.post('/Dividend', {sb_data: data, cPgmId: "DividendProcess", cAction: "PrintChqValidate" })
                //console.log("PrintChqRtn res.data.ok", res.data.ok)
                //console.log("PrintChqRtn res.data.daa", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    setShowButton(true)
                    
                    return
                }

                onChqProcess(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }




    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Exit</Button>
                <Button className="Col-2-2" disabled={! ShowButton} icon="tick" onClick={handleSubmit(onSubmit)}>Print Cheque</Button>
             </div>
            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col-1-2" label="Bank Code" labelFor="inputBank_Code" labelInfo="*">
                    <InputGroup id="inputBank_Code" value={BankCodeValue} defaultValue="" placeholder="Bank Code ..." onChange={utils.handleStringChange(s => setBankCodeValue(s)) } inputRef={register({ required: true , maxLength: 8}) } />
                    {errors.d_Bank_Code && errors.d_Bank_Code.type === "required" && <span id="Err_msg">Bank Code cannot be Blank</span>}
                    {errors.d_Bank_Code && errors.d_Bank_Code.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_Bank_Code && errors.d_Bank_Code.types && <span id="Err_msg">{errors.d_Bank_Code.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-1" label="Cheque Date" labelFor="inputCheque_Date">
                    <InputGroup id="inputCheque_Date" name="d_Cheque_Date" type="date" defaultValue={ChequeDateValue} placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_Cheque_Date && errors.d_Cheque_Date.types && <span id="Err_msg">{errors.d_Cheque_Date.types.message}</span>}
                </FormGroup>
            </div>
             
            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col-1-2" label="Starting Cheque No" labelFor="inputChequeNo">
                    <InputGroup className="inputNumberNoSpin" id="inputChequeNo" defaultValue={StartingChequeNoValue} value={StartingChequeNoValue} type="number" onChange={utils.handleStringChange(n => setStartingChequeNoValue(n))}  />
                    {errors.d_Starting_Cheque_No && errors.d_Starting_Cheque_No.types && <span id="Err_msg">{errors.d_Starting_Cheque_No.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="XLPT" labelFor="inputLpt" labelInfo="*">
                    <InputGroup id="inputLpt" value={LPTValue} defaultValue="" placeholder="XLPT ..." onChange={utils.handleStringChange(s => setLPTValue(s)) } inputRef={register({ required: true ,maxLength: 5}) } />
                    {errors.d_LPT && errors.d_LPT.type === "required" && <span id="Err_msg">XLPT cannot be Blank</span>}
                    {errors.d_LPT && errors.d_LPT.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                    {errors.d_LPT && errors.d_LPT.types && <span id="Err_msg">{errors.d_LPT.types.message}</span>}
                </FormGroup>
            </div>

        </form>
    )
}


interface ResetChqRtnProps {
    gsb: AxiosInstance,
    currentPrintChq?: PrintChqInfo,
    currentSmentit?: SmEntitInfo,
    onResetChqProcess?: (Chq: PrintChqInfo) => void,
    onCancel?: () => void;
}

function ResetChqRtn({ gsb, currentPrintChq = {} as PrintChqInfo, currentSmentit = {} as SmEntitInfo, onResetChqProcess = () => { }, onCancel = () => { } }: ResetChqRtnProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<PrintChqInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [Enter2tab, setEnter2tab] = useState("Y")

    const [IPAddr, setIPAddr] = useState("")

    const [ShowButton, setShowButton] = useState(true)
    
    const [BankCodeValue, setBankCodeValue] = useState(currentSmentit.d_Bank_Code)
    const [ChequeDateValue, setChequeDateValue] = useState(currentSmentit.d_Cheque_Date)
    const [ResetChequeNoValue, setResetChequeNoValue] = useState(currentPrintChq.d_Reset_Cheque_No)


    useEffect(() => {
        // Call reset to get the values of record PfEntitInfo 
        if ("d_Bank_Code" in currentPrintChq) {
            reset(currentPrintChq)
        }
    }, [currentPrintChq, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Dividend_Currency" })
    }, [register])


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                setEnter2tab(GsbFlag.Enter2tab)               

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, currentPrintChq, GsbFlag])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [gsb, currentPrintChq, GsbFlag, Enter2tab])



    const onSubmit = (data: PrintChqInfo) => {

        setShowButton(false)

        data.d_Bank_Code = BankCodeValue;
        if ( typeof(data.d_Bank_Code) === "undefined" ) {
            data.d_Bank_Code = ""
        }
        data.d_Bank_Code = data.d_Bank_Code.toUpperCase();


        data.d_Reset_Cheque_No = ResetChequeNoValue;
        if ( typeof(data.d_Reset_Cheque_No) === "undefined" ) {
            data.d_Reset_Cheque_No = "1"
        }

        data.d_StkNo = currentSmentit.d_StkNo;
        data.d_AnnouncementNo = currentSmentit.d_AnnouncementNo;


       

        (async () => {
            try {
                
                console.log("call ResetChqRtn data", data);

                
                onResetChqProcess(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }



    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Exit</Button>
                <Button className="Col-2-4" disabled={! ShowButton} icon="tick" onClick={handleSubmit(onSubmit)}>Reset Dividend Cheque No. / "Direct Debit List"</Button>
             </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Reset Dividend Cheque No. Starting From" labelFor="inputReset_Cheque_No" labelInfo="*">
                    <InputGroup id="inputReset_Cheque_No" value={ResetChequeNoValue} defaultValue="1" placeholder="Cheque No. ..." onChange={utils.handleStringChange(s => setResetChequeNoValue(s)) } inputRef={register({ required: true , maxLength: 6}) } />
                    {errors.d_Reset_Cheque_No && errors.d_Reset_Cheque_No.type === "required" && <span id="Err_msg">Bank Code cannot be Blank</span>}
                    {errors.d_Reset_Cheque_No && errors.d_Reset_Cheque_No.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Reset_Cheque_No && errors.d_Reset_Cheque_No.types && <span id="Err_msg">{errors.d_Reset_Cheque_No.types.message}</span>}
                </FormGroup>
            </div>
            
            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            <div >Note : </div>
            <div >You may key in "1" or "AUTOPY" to reset all Dividend Cheque No / "Direct Debit List" to blank</div>
        </form>
    )
}


interface DividendProcess04MainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function DividendProcess04Main({ gsb }: DividendProcess04MainProps) {

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    //let Stkno = ""
    let Stkno = utils.getUrlParameter('Stkno', url); 
    let { Stkno: defaultSearchValue = "" }: { Stkno: string } = useParams()
    let dProcess = utils.getUrlParameter('dProcess', url); 


    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgdoubledown = <img className="svgdoubledownIcon" src={svgdoubledown} height="20" width="30" alt="doubledown" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    const history = useHistory()

    const [Data, setData] = useState([] as PfEntitInfo[])
    const [Data2, setData2] = useState([] as SmEntitInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkNo, setsearchStkNo] = useState("")
    const [ShowWait, setShowWait] = useState(false)
    const [ChgPrintCheque, setChgPrintCheque] = useState(false)
    const [ChgAutopay, setChgAutopay] = useState(false)

    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentPfentit, setcurrentPfentit] = useState({} as PfEntitInfo)
    const [BlankPfentit, setBlankPfentit] = useState({} as PfEntitInfo)
    const [currentSmentit, setcurrentSmentit] = useState({} as SmEntitInfo)

    const [Scrip_Fee_RateValue, setScrip_Fee_RateValue] = useState(currentSmentit.d_Scrip_Fee_Rate)

    const [BlankPrintChq, setBlankPrintChq] = useState({} as PrintChqInfo)
    const [currentMode, setcurrentMode] = useState("MenuMode")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    const [isShowAlert3, setisShowAlert3] = useState(false)
    const [ShowAlert3Msg, setShowAlert3Msg] = useState([])

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [dateFormat, setdateFormat] = useState("")

    const [DIVCHQ_TO_BANK, setDIVCHQ_TO_BANK] = useState(false)

    const [isGenerate, setisGenerate] = useState(false)


    BlankPfentit.d_StkNo = ""
    BlankPfentit.d_AnnouncementNo = ""
    BlankPfentit.d_acCode = ""

    BlankPrintChq.d_Bank_Code = ""
    BlankPrintChq.d_Starting_Cheque_No = "1"
    BlankPrintChq.d_Reset_Cheque_No = "AUTOPY"
    BlankPrintChq.d_LPT = "XLPT1"

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("DividendProcess04Main disp user_key: ", res.data.Flag.user_key)
                //console.log("DividendProcess04Main disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("DividendProcess04Main disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("DividendProcess04Main disp GsbFlag: ", GsbFlag)
                //console.log("DividendProcess04Main disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("DividendProcess04Main disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("DividendProcess04Main disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("DividendProcess04Main disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("DividendProcess04Main disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setdateFormat(GsbFlag.dateFormat);

                setDIVCHQ_TO_BANK(GsbFlag.DIVCHQ_TO_BANK);
 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( qString.trim().length > 7 && selectedTabId == "list" ) {

            setShowWait(true)
            setRptName("")
            setRptNameNo(0)
            ;

            (async () => {
                try {
                    //console.log("SmMastMain onsubmit called", data)
                    const res = await gsb.post('/Dividend',
                    {   sb_data: {
                            stkNo: qString,
                            FirstOneOnly: true,
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "DividendProcess",
                        cAction: "AdjBookClosedList",
                    });

                    console.log("PfEntit res.data.data: ", res.data.data, res.data.count) 
                    console.log("SmEntit res.data.data2: ", res.data.data2) 
                    setData2(res.data.data2)
                    setcurrentSmentit(res.data.data2[0])
                    console.log("currentSmentit: ", currentSmentit) 
                    setScrip_Fee_RateValue(res.data.data2[0].d_Scrip_Fee_Rate)
                    console.log("d_Bank_Code: ", res.data.data2[0].d_Bank_Code) 
                    console.log("d_Cheque_Date: ", res.data.data2[0].d_Cheque_Date) 


                    if ( res.data.count > 0 ) {
                        setData(res.data.data)

                        setChgPrintCheque(false)
                        setChgAutopay(false)
                        setShowWait(false)
                    } else {

                    }
                } catch (error) {
                    utils.showError(error)
                }
                setShowWait(false)
            })()
        }

    }, [gsb, selectedTabId, ChgPrintCheque, ChgAutopay])


    const onPrintDividendCheque = (PrintChq: PrintChqInfo) => {
        setShowWait(true)
        setRptName("")
        setRptNameNo(0)
        ;
        console.log("onPrintDividendCheque PrintChq", PrintChq);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: PrintChq, 
                    cPgmId: "DividendProcess",
                    cAction: "PrintDividendCheque",
                })                
                if ( res.data.ok ) {
                    //utils.showSuccess(res.data.data)
                    setisShowAlert1(true)
                    setShowAlert1Msg(res.data.data)
                    setRptName(res.data.RptName)
                    setRptNameNo(res.data.RptNameNo)
                    if ( res.data.count > 0 ) {
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }
                    }
                } else {
                    //utils.showWarn(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }

            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setChgPrintCheque(true)
}

    const onDIVCHQ_TO_BANK = () => {
        setShowWait(true)
        setRptName("")
        setRptNameNo(0)
        ;
        console.log("onDIVCHQ_TO_BANK currentSmentit", currentSmentit);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: currentSmentit, 
                    cPgmId: "DividendProcess",
                    cAction: "Dividend_to_Bank",
                })                
                if ( res.data.ok ) {
                    //utils.showSuccess(res.data.data)
                    setisShowAlert1(true)
                    setShowAlert1Msg(res.data.data)
                } else {
                    //utils.showWarn(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }

            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setChgAutopay(true)
}

    const onChqProcess = async (PrintChq: PrintChqInfo) => {
        onPrintDividendCheque(PrintChq)
    }

    const onResetChqProcess = async (PrintChq: PrintChqInfo) => {
        setShowWait(true)
        setRptName("")
        setRptNameNo(0)
        ;
        console.log("onResetChqProcess PrintChq", PrintChq);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: PrintChq, 
                    cPgmId: "DividendProcess",
                    cAction: "ResetDividendCheque",
                })                
                if ( res.data.ok ) {
                    //utils.showSuccess(res.data.data)
                    setisShowAlert1(true)
                    setShowAlert1Msg(res.data.data)
                } else {
                    //utils.showWarn(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }

            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        if ( PrintChq.d_Reset_Cheque_No == "AUTOPY" ) {
            setChgAutopay(true)
        } else {
            setChgPrintCheque(true)
        }
    }


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
    }        



    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>

    let RptNameaRef = <>
        { RptNameNo > 0 && <div> Download / Print &nbsp;
            {aRptNameNo.map((no, j) => 
                <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}-${no.n}.pdf`}> <span id="RPageNo2" onClick={() => {} }> {no.n} &nbsp; </span> </a>
            )}
        </div> }
        { RptNameNo <= 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>}
        <div className="Spacing-V-16" />
        Please download the Cheque file to Print at the above link !!
    </>


    let SmEntitRec = <>
        {Data2.map((SmEntit, i) =>
            <div >
                <div >
                    <tbody >
                        <tr><td>Stock No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_StkNo} &nbsp; &nbsp; {SmEntit.d_StkCode}</td></tr>
                        <tr><td>Announcement No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_AnnouncementNo}</td></tr>
                        <tr><td>Summary :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary1}</td></tr>
                        <tr><td></td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary2}</td></tr>
                    </tbody>
                </div>
                <div className="Spacing-V-16" />
                <div >
                    Ex date: &nbsp; {utils.dispDate(SmEntit.d_Ex_date, dateFormat)} &nbsp;
                    Book Close Date: &nbsp; {utils.dispDate(SmEntit.d_BC_date, dateFormat)} &nbsp;
                    Payable date: &nbsp; {utils.dispDate(SmEntit.d_PY_date, dateFormat)} &nbsp;
                </div>
                { ( dProcess === "D" ) && <div >
                    Dividend per share: &nbsp; {SmEntit.d_Dividend_perShare} &nbsp;
                    Payin Currency: &nbsp; {SmEntit.d_Dividend_Currency} &nbsp;
                </div> }
                { ( dProcess === "D" ) && <div >
                    Convert Dividend into Bonus Share at Unit Price: &nbsp; {SmEntit.d_Dividend_Bonus_unitPrice} &nbsp;
                    &nbsp; Stock: &nbsp; {SmEntit.d_Dividend_Bonus_OhterStock} &nbsp;
                </div> }
                { ( dProcess === "WR" ) && <div >
                    <tbody >
                        <tr><td>Bonus :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Bonus_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Bonus_Base}&nbsp;</td><td> Entitled Stock :</td><td> &nbsp;{SmEntit.d_Bonus_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Bonus_StockNo_lot}&nbsp;</td></tr>
                        <tr><td>1st Warrant :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Warrant_1_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Warrant_1_Base}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Warrant_1_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Warrant_1_Lot}&nbsp;</td></tr>
                        <tr><td>2nd Warrant :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Warrant_2_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Warrant_2_Base}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Warrant_2_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Warrant_2_Lot}&nbsp;</td></tr>
                        <tr><td>Rights :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Rights_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Rights_Base}&nbsp;</td><td> Subscription Price :</td><td> &nbsp;{SmEntit.d_Rights_Price}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Rights_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Rights_Lot}&nbsp;</td></tr>
                        <tr><td>Split :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Split_Old}&nbsp;</td><td> into shares</td><td> &nbsp;{SmEntit.d_Split_New}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Split_combine_StockNo}&nbsp;</td></tr>
                        <tr><td>Consolidate :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_combine_Old}&nbsp;</td><td> into shares</td><td> &nbsp;{SmEntit.d_combine_New}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Split_combine_StockNo}&nbsp;</td></tr>
                    </tbody>
                </div> }
                <div >
                    { (SmEntit.d_Scrip_indicator === "Y" )
                    ? <tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td><td> &nbsp;{SmEntit.d_Scrip_Fee_Rate}&nbsp;</td></tr>
                    </tbody>
                    : <tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td></tr>
                    </tbody>
                    }
                </div>
            </div>
        )}
        <div className="Spacing-V-16" />
    </>


    let PrintChqSection = <PrintChqRtn gsb={gsb} currentPrintChq={BlankPrintChq} currentSmentit={currentSmentit} onChqProcess={onChqProcess} onCancel={() => { setcurrentMode("MenuMode") } } ></PrintChqRtn>

    let ResetChqSection = <ResetChqRtn gsb={gsb} currentPrintChq={BlankPrintChq} currentSmentit={currentSmentit} onResetChqProcess={onResetChqProcess} onCancel={() => { setcurrentMode("MenuMode") } } ></ResetChqRtn>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>94 Dividend Process,
                        Print Dividend Cheque / "Direct Debit List" to Client &nbsp; &nbsp; &nbsp;</H3>
                        <Button icon="arrow-left" onClick={() => history.goBack()}>Back</Button>
                    </div>
                    <div className="Spacing-V-16" />
                    {SmEntitRec}
                    <div className="Spacing-V-16" />
                    {ShowWait && <div>{imgWait}</div> }
                    <div className="Spacing-V-16" />
                    { ( currentMode == "MenuMode" ) && <table>
                        <tr><td><Button className="Col-3-2" intent={currentSmentit.d_Dividend_Cheque_Printed == 2 ? Intent.SUCCESS : "none"} icon="document" onClick={() => { setcurrentMode("PrintChqMode")  } }>Print Dividend Cheque to Client</Button></td></tr>
                        <tr><td id="td_center">&nbsp;</td></tr>
                        <tr><td><Button className="Col-3-2" disabled={! DIVCHQ_TO_BANK} intent={currentSmentit.d_Generate_Div_to_Bank == 2 ? Intent.SUCCESS : "none"} icon="document" onClick={() => { setisGenerate(true) } }>Generate "Direct Debit List" to HSBCNET</Button></td></tr>
                        {DIVCHQ_TO_BANK && <tr><td id="td_center">&nbsp;</td></tr>}
                        <Alert
                            cancelButtonText="Cancel"
                            confirmButtonText="Generate"
                            canEscapeKeyCancel={true}
                            icon="flow-branch"
                            intent={Intent.SUCCESS}
                            isOpen={isGenerate}
                            onCancel={ () => setisGenerate(false) }
                            onConfirm={ () => { onDIVCHQ_TO_BANK(); setisGenerate(false) } }
                        >
                            <p>
                                Are you sure to Generate "Direct Debit List" to HSBCNET ?
                            </p>
                        </Alert>                        
                        <tr><td><Button className="Col-3-2" icon="document" onClick={() => { setcurrentMode("ResetChqMode")  } }>Reset Dividend Cheque No. / "Direct Debit List" to Blank</Button></td></tr>
                        <tr><td id="td_center">&nbsp;</td></tr>
                    </table> }
                    <div className="Spacing-V-16" />
                    { ( currentMode == "PrintChqMode" ) && <div >
                        {PrintChqSection}
                        {RptName.trim().length > 0 && RptNameaRef}
                    </div> }
                    { ( currentMode == "ResetChqMode" ) && <div >
                        {ResetChqSection}
                    </div> }
                    <div className="Spacing-V-16" />
                </Card>
                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert3}
                        onCancel={ () => setisShowAlert3(false) }
                        onConfirm={ () => { setShowAlert3Msg([]); setisShowAlert3(false) } }
                    >
                        <p>
                            {/*ShowAlert3Msg*/}
                            <div className="Spacing-V-16"></div>
                            {ShowAlert3Msg.map((Wmsg, j) => 
                                <div>{ShowAlert3Msg[j]}</div>
                            )}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default DividendProcess04Main