import React, { useState, useEffect } from 'react';
import { HTMLTable, Button, ProgressBar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';
import * as utils from "./utils";

import "./Task.scss"

interface TaskMainProps {
    gsb: AxiosInstance
}

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}

function TaskMain({ gsb }: TaskMainProps) {
    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [taskList2, setTaskList2] = useState([] as TaskInfo[])

    const [s_ListallUser, sets_ListallUser] = useState(true)


    useEffect(() => {
        //console.log("running task(s) found, refresh tasklist in 1s")
        const refreshTasks = async () => {
            try {
                //console.log("calling tasklist")
                const res = await gsb.post('/tasklist');
                setTaskList(res.data.data)
                //console.log("tasklist completed, call refreshTasks in 1s")
                //setTimeout(refreshTasks, 1000)
            } catch (error) {
                utils.showError(error)
            }
        }
        //console.log("useEffect call refreeshTasks")
        refreshTasks()
        let timer = setInterval(refreshTasks, 3*1000)
        return () => {clearInterval(timer)}
    }, [gsb, s_ListallUser])


    useEffect(() => {
        //console.log("running task(s) found, refresh tasklist2 in 1s")
        const refreshTasks2 = async () => {
            try {
                //console.log("calling tasklist2")
                const res = await gsb.post('/tasklist2');
                setTaskList2(res.data.data)
                //console.log("tasklist completed, call refreshTasks in 1s")
                //setTimeout(refreshTasks2, 1000)
            } catch (error) {
                utils.showError(error)
            }
        }
        //console.log("useEffect call refreeshTasks2")
        refreshTasks2()
        let timer = setInterval(refreshTasks2, 2*1000)
        return () => {clearInterval(timer)}
    }, [gsb])


    const onClearTasks = () => {
        (async () => {
            try {
                const res = await gsb.post('/taskpurge');
                setTaskList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    return (
        <>
            <p>Tasks in Progress</p>
            <HTMLTable striped interactive condensed>
                <thead>
                    <tr>
                        <th className="TCol-Start">Start</th>
                        <th className="TCol-UserId">UserId</th>
                        <th className="TCol-Task">Task</th>
                        <th className="TCol-Progress">Progress</th>
                        <th className="TCol-Message">Message</th>
                        <th className="TCol-End">End</th>
                    </tr>
                </thead>
                <tbody>
                    {taskList2.map((t, i) =>
                        <tr key={i}>
                            <td className="TCol-Start">{utils.formatTime(utils.hb_StoD(t.startTime))}</td>
                            <td className="TCol-UserId">{t.userId}</td>
                            <td className="TCol-Task">{t.description}</td>
                            <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                            <td className="TCol-Message">{t.message}</td>
                            <td className="TCol-End">{t.endTime && utils.formatTime(utils.hb_StoD(t.endTime))}</td>
                        </tr>
                    )}
                </tbody>
            </HTMLTable>
            <div className="Spacing-V-8"></div>
            <div className="Spacing-V-8"></div>
            <p>Tasks</p>
            <HTMLTable striped interactive condensed>
                <thead>
                    <tr>
                        <th className="TCol-Start">Start</th>
                        <th className="TCol-UserId">UserId</th>
                        <th className="TCol-Task">Task</th>
                        <th className="TCol-Progress">Progress</th>
                        <th className="TCol-Message">Message</th>
                        <th className="TCol-End">End</th>
                        <th className="TCol-Log">Log</th>
                    </tr>
                </thead>
                <tbody>
                    {taskList.map((t, i) =>
                        <tr key={i}>
                            <td className="TCol-Start">{utils.formatTime(utils.hb_StoD(t.startTime))}</td>
                            <td className="TCol-UserId">{t.userId}</td>
                            <td className="TCol-Task">{t.description}</td>
                            <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                            <td className="TCol-Message">{t.message}</td>
                            <td className="TCol-End">{t.endTime && utils.formatTime(utils.hb_StoD(t.endTime))}</td>
                            <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                        </tr>
                    )}
                </tbody>
            </HTMLTable>
            <div className="Spacing-V-8"></div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} type="submit" icon={IconNames.CROSS} onClick={onClearTasks}>Clear</Button>
            </div>
            <div className="Spacing-V-16"></div>
        </>
    )
}

export default TaskMain