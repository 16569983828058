import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { MktFeeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;


interface MktDividendChargeViewProps {
    gsb: AxiosInstance,
    MktFee: MktFeeInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}


function MktDividendChargeView({ gsb, MktFee = {} as MktFeeInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: MktDividendChargeViewProps) {

    const [isAdd, setisAdd] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [isDelete, setisDelete] = useState(false)

    const { t, i18n } = useTranslation();    

    if (! ("d_Market" in MktFee)) return null

    function buttonPress(buttonName: string)
    {
        if ( buttonName == "new" )
        {
            onNew()
        }
        if ( buttonName == "edit" )
        {
            onEdit()
        }
        if ( buttonName == "delete" )
        {
            setisDelete(true)
        }
    }

    return (
        <div>
            {/* define add,change,delete button */}
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => buttonPress("new")} >New</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isAdd}
                        onConfirm={ () => { setisAdd(false) } }
                    >
                        <p>
                            NEW : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-3-1" icon="edit" onClick={() => buttonPress("edit")} >Edit</Button>
                    <Alert
                        confirmButtonText="OK"
                        icon="disable"
                        intent={Intent.WARNING}
                        isOpen={isEdit}
                        onConfirm={ () => { setisEdit(false) } }
                    >
                        <p>
                            EDIT : Access Denied
                        </p>
                    </Alert>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => buttonPress("delete")} >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDelete}
                        onCancel={ () => setisDelete(false) }
                        onConfirm={ () => { onDelete(); setisDelete(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Market Code" labelFor="inputMktCode" labelInfo="*">
                    <InputGroup readOnly value={MktFee.d_Market} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fee Code" labelFor="inputMktName" labelInfo="*">
                    <InputGroup readOnly value={MktFee.d_FeeCode} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Dividend Collection Rate (%)" labelFor="inputCollection_rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Collection_rate)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Collection fee" labelFor="inputMin_Collection_fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Min_Collection_fee)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Max Collection fee" labelFor="inputMax_Collection_fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Max_Collection_fee)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fixed Dividend handling Charges" labelFor="inputDividend_handling_Charges">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Dividend_handling_Charges)} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Dividend Tax Rate (%)" labelFor="inputDividend_Tax_rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Dividend_Tax_rate)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Scrip fee" labelFor="inputScrip_fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Scrip_fee)} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-4" label="New Shares (Bonus, Warrant, Rights) Collection Fee per Lot" labelFor="inputNew_Shares_Collection_rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_New_Shares_Collection)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="New Shares Min Collection fee" labelFor="inputMin_New_Shares_Collection_fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Min_New_Shares_Collection_fee)} />
                </FormGroup>
                <FormGroup className="Col-7-2" label="New Shares Max Collection fee" labelFor="inputMax_New_Shares_Collection_fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Max_New_Shares_Collection_fee)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fixed Bonus handling Fee" labelFor="inputBonus_handling_fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Bonus_handling_fee)} />
                </FormGroup>
            </div>

        </div>
    )
}


interface MktDividendChargeEditProps {
    gsb: AxiosInstance,
    MktFee?: MktFeeInfo,
    isNew?: boolean,
    onSave?: (MktFee: MktFeeInfo) => void,
    onCancel?: () => void;
}

function MktDividendChargeEdit({ gsb, MktFee = {} as MktFeeInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: MktDividendChargeEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<MktFeeInfo>()
    const { t, i18n } = useTranslation();    

    const [InDirectValue, setInDirectValue] = useState(MktFee.d_InDirect==="Y")
    const [CombineStampValue, setCombineStampValue] = useState(MktFee.d_Combine_Stamp==="Y")

    
    useEffect(() => {
        // Call reset to get the values of record StkInfo 
        if ("d_Market" in MktFee) {
            reset(MktFee)
        }
    }, [MktFee, reset])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    if (! isNew && ! ("d_Market" in MktFee)) return null


    
    const onSubmit = (data: MktFeeInfo) => {

        //console.log("MktDividendChargeEdit onsubmit data: ", data)

        // put value to json/field before write database dbf
        data.d_Market = data.d_Market.toUpperCase();
        data.d_FeeCode = data.d_FeeCode.toUpperCase();

/*
        data.d_InDirect = "Y";
        if (InDirectValue) {
            data.d_InDirect = "Y";
        } else {
            data.d_InDirect = "N";
        }
*/



        (async () => {
            try {
                //console.log("call MktDividendChargeEdit data", data)
                //console.log("call MktDividendChargeEdit isNew", isNew)
                const res = await gsb.post('/Dividend', {sb_data: data, cPgmId: "MktDividendCharge", cAction: "MktDividendChargeValidate", isNew: isNew})
                //console.log("MktDividendChargeEdit res.data.ok", res.data.ok)
                //console.log("MktDividendChargeEdit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" disabled={true} label="Market Code" labelFor="inputMktCode" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputMktCode" name="d_Market" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 4 }) } />
                    {errors.d_Market && errors.d_Market.type === "required" && (<span id="Err_msg">Market Code cannot be Blank</span>)}
                    {errors.d_Market && errors.d_Market.type === "maxLength" && <span id="Err_msg">Max length is 4</span>}
                    {errors.d_Market && errors.d_Market.types && <span id="Err_msg">{errors.d_Market.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" disabled={true} label="Fee Code" labelFor="inputFeeCode" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputFeeCode" name="d_FeeCode" autoFocus={! isNew} defaultValue="" placeholder="Fee Code ..." inputRef={register({ required: true , maxLength: 2}) } />
                    {errors.d_FeeCode && errors.d_FeeCode.type === "required" && (<span id="Err_msg">Fee Code cannot be Blank</span>)}
                    {errors.d_FeeCode && errors.d_FeeCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                    {errors.d_FeeCode && errors.d_FeeCode.types && <span id="Err_msg">{errors.d_FeeCode.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Dividend Collection Rate (%)" labelFor="inputCollection_rate">
                    <InputGroup className="inputNumberNoSpin" id="inputCollection_rate" name="d_Collection_rate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Collection_rate && errors.d_Collection_rate.types && <span id="Err_msg">{errors.d_Collection_rate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Collection fee" labelFor="inputMin_Collection_fee">
                    <InputGroup className="inputNumberNoSpin" id="inputMin_Collection_fee" name="d_Min_Collection_fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Min_Collection_fee && errors.d_Min_Collection_fee.types && <span id="Err_msg">{errors.d_Min_Collection_fee.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="Max Collection fee" labelFor="inputMax_Collection_fee">
                    <InputGroup className="inputNumberNoSpin" id="inputMax_Collection_fee" name="d_Max_Collection_fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Max_Collection_fee && errors.d_Max_Collection_fee.types && <span id="Err_msg">{errors.d_Max_Collection_fee.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fixed Dividend handling Charges" labelFor="inputDividend_handling_Charges">
                    <InputGroup className="inputNumberNoSpin" id="inputDividend_handling_Charges" name="d_Dividend_handling_Charges" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Dividend_handling_Charges && errors.d_Dividend_handling_Charges.types && <span id="Err_msg">{errors.d_Dividend_handling_Charges.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Dividend Tax Rate (%)" labelFor="inputDividend_Tax_rate">
                    <InputGroup className="inputNumberNoSpin" id="inputDividend_Tax_rate" name="d_Dividend_Tax_rate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Dividend_Tax_rate && errors.d_Dividend_Tax_rate.types && <span id="Err_msg">{errors.d_Dividend_Tax_rate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Scrip fee" labelFor="inputScrip_fee">
                    <InputGroup className="inputNumberNoSpin" id="inputScrip_fee" name="d_Scrip_fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Scrip_fee && errors.d_Scrip_fee.types && <span id="Err_msg">{errors.d_Scrip_fee.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-4" label="New Shares (Bonus, Warrant, Rights) Collection Fee per Lot" labelFor="inputNew_Shares_Collection_rate">
                    <InputGroup className="inputNumberNoSpin" id="inputNew_Shares_Collection_rate" name="d_New_Shares_Collection" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_New_Shares_Collection && errors.d_New_Shares_Collection.types && <span id="Err_msg">{errors.d_New_Shares_Collection.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="New Shares Min Collection fee" labelFor="inputMin_New_Shares_Collection_fee">
                    <InputGroup className="inputNumberNoSpin" id="inputMin_New_Shares_Collection_fee" name="d_Min_New_Shares_Collection_fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Min_New_Shares_Collection_fee && errors.d_Min_New_Shares_Collection_fee.types && <span id="Err_msg">{errors.d_Min_New_Shares_Collection_fee.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-7-2" label="New Shares Max Collection fee" labelFor="inputMax_New_Shares_Collection_fee">
                    <InputGroup className="inputNumberNoSpin" id="inputMax_New_Shares_Collection_fee" name="d_Max_New_Shares_Collection_fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Max_New_Shares_Collection_fee && errors.d_Max_New_Shares_Collection_fee.types && <span id="Err_msg">{errors.d_Max_New_Shares_Collection_fee.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fixed Bonus handling Fee" labelFor="inputBonus_handling_fee">
                    <InputGroup className="inputNumberNoSpin" id="inputBonus_handling_fee" name="d_Bonus_handling_fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Bonus_handling_fee && errors.d_Bonus_handling_fee.types && <span id="Err_msg">{errors.d_Bonus_handling_fee.types.message}</span>}
                </FormGroup>
            </div>
        </form>
    )
}

interface MktDividendChargeMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function MktDividendChargeMain({ gsb }: MktDividendChargeMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let { MktFeeCode: defaultSearchValue = "" }: { MktFeeCode: string } = useParams()

    const [data, setData] = useState([] as MktFeeInfo[])

    const [searchKey, setSearchKey] = useState("Market")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentMktFee, setcurrentMktFee] = useState({} as MktFeeInfo)
    const [BlankMktFee, setBlankMktFee] = useState({} as MktFeeInfo)

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    
    const [SING_CURR, setSING_CURR] = useState(false)

    //gsb default value
    BlankMktFee.d_InDirect = "Y"
    BlankMktFee.d_Combine_Stamp = "Y"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}

                setSING_CURR(GsbFlag.SING_CURR)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    const PrevRecProcess = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call PrevRecProcess", MktFee)
            const res = await gsb.post('/Dividend', {sb_data: MktFee,  cPgmId: "MktDividendCharge",cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", d_MktateCode.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentMktFee(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call DeleteProcess", Mkt)
            const res = await gsb.post('/Dividend', {sb_data: MktFee, cPgmId: "MktDividendCharge", cAction: "DelRec"})
            showSuccess("Market Fee Code " + MktFee.d_Market+MktFee.d_FeeCode + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market === res.data.data.d_Market) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call NextRecProcess", MktFee)
            const res = await gsb.post('/Dividend', {sb_data: MktFee, cPgmId: "MktDividendCharge", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call newOnSave", MktFee)
            const res = await gsb.post('/Dividend', {sb_data: MktFee, cPgmId: "MktDividendCharge", cAction: "AddRec"})
            showSuccess("Market Fee Code " + MktFee.d_Market+MktFee.d_FeeCode + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (MktFee: MktFeeInfo) => {
        try {
            console.log("call editOnSave", MktFee)
            const res = await gsb.post('/Dividend', {sb_data: MktFee, cPgmId: "MktDividendCharge", cAction: "UpdateRec"})
            showSuccess("Market Fee Code " + MktFee.d_Market+MktFee.d_FeeCode + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("editOnSave stk", MktFee)
            //console.log("editOnSave res.data.data", res.data.data)
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( SING_CURR ) {
            toaster.show({message: 'Your SB Version have not Multi-Currency Module !  Please contact POP (Tel: 23918816) for more detail.', intent: Intent.WARNING })
            return
        }
 
        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        // get SmMast list if StkNo have value
        try {
            //console.log("SmMastMain onsubmit called", data)
            const res = await gsb.post('/Dividend',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                        }, 
                    },
                    cPgmId: "MktDividendCharge",
                    cAction: "ListRec",
                });

            console.log("setData res.data.data", res.data.data);
            if ( m_limit > 1 ) {
                setData(res.data.data)
            }
            if (res.data.data.length > 0) {
                setcurrentMktFee(res.data.data[0])
            }

            if ( m_limit > 1 ) {
                if ( res.data.count > 0 ) {
                const aPageNumber1 = []
                if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                    }
                        setaPageNumber(aPageNumber1)
                    }
                }
            }
            setShowWait(false)
        } catch (error) {
            utils.showError(error)
        }
    }

    // get one SmMast record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                //console.log("SmMastMain useEffect length > 0  called", data)
                const res = await gsb.post('/Dividend',
                    {   sb_data: {
                            by: "Market",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "MktDividendCharge",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentMktFee(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Dividend',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                            }, 
                        },
                        cPgmId: "MktDividendCharge",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentMktFee(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <MktDividendChargeView gsb={gsb} MktFee={currentMktFee} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentMktFee)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentMktFee)} onNextRec={() => NextRecProcess(currentMktFee)} ></MktDividendChargeView>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            detail = <MktDividendChargeEdit gsb={gsb} MktFee={BlankMktFee} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></MktDividendChargeEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <MktDividendChargeEdit gsb={gsb} MktFee={currentMktFee} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></MktDividendChargeEdit>
            break
        }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        {/* <div className="Row">
            { (! SING_CURR ) &&
                <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
            }
        </div> */}
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-MarketCode">Market Code</th>
                <th className="TCol-MktFee">Fee Code</th>
                <th className="TCol-MktFee">Dividend Collection Rate</th>
                <th className="TCol-MktFee">Fixed Dividend handling Charges</th>
                <th className="TCol-MktFee">Dividend Tax Rate</th>
                <th className="TCol-MktFee">New Shares (Bonus, Warrant, Rights) Collection Fee</th>
                <th className="TCol-MktFee">Fixed Bonus handling Feee</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((MktFee, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-MarketCode" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Market}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_FeeCode}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Collection_rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Dividend_handling_Charges}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Dividend_Tax_rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_New_Shares_Collection}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Bonus_handling_fee}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>9M Foreign Market Dividend Charges Setup</H3>
                    </div>
                    <div className="Spacing-V-16" />
                    { ( SING_CURR ) && 
                        <div>
                        <div id="DispText">Your SB Version have not Multi-Currency Module !</div>
                        <div id="DispText">Please contact POP (Tel: 23918816) for more detail.</div>
                        </div>
                    }
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for Market Fee Master" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="Market">Market Code</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "Market"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "Market"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            {/* <FormGroup className="Col-5-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default MktDividendChargeMain