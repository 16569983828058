import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FileInput, ProgressBar } from "@blueprintjs/core";
import { Suggest, Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Callout, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { Account, cbMast, Document, StatusInfo, CurrencyInfo, HkidrInfo, CommInfo, IntRInfo } from './Types'
import { LocationInfo, BranchEmailInfo, ExtraFeeInfo, MktFeeInfo, RegionCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';
import { CLHkidrMastView } from './HkidrMast';
import { CLHkCrsMastView } from './HkCrsMast';

import i18next from 'i18next';
import { Label } from 'react-konva';


interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}


var GsbFlag: any;

var UserID: string;
var PgmCode = "AT003" ;
var isReqAuth2:boolean = false ;


interface ClMastViewProps {
    gsb: AxiosInstance,
    ac: Account,
    ac0: Account,
    StatusList?: StatusInfo[],
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}


function ClMastView({ gsb, ac = {} as Account, ac0 = {} as Account, StatusList=[] as StatusInfo[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: ClMastViewProps) {


    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [StatusDesc, setStatusDesc] = useState("")

    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    

    useEffect(() => {
        setStatusDesc("")
        let StatusItem3 = StatusList.findIndex((StatusCode: StatusInfo) => StatusCode.d_StatusCode === ac.d_status)
        if ( StatusItem3 > 0 ) {
            setStatusDesc(StatusList[StatusItem3].d_StatusDesc)
        }

    }, [ac, ac.d_status])



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_acCode" in ac)) return null



    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>

    
    return (
        <div>
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec() } >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div> }

            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelInfo="*">
                    <InputGroup readOnly value={ac.d_acCode} />
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name">
                    <InputGroup readOnly value={ac.d_acName1} />
                    <InputGroup readOnly value={ac.d_acName2} />
                </FormGroup>
                <FormGroup className="Col3-6-1" label="Status">
                    <InputGroup readOnly value={ac.d_status} />
                    <div>
                    <div id="DispText">{StatusDesc}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="CCASS Segregated A/C" labelFor="">
                    <InputGroup readOnly value={ac.d_SegAC} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>

        </div>
    )
}


interface ClMastEditProps {
    gsb: AxiosInstance,
    ac?: Account,
    StatusList?: StatusInfo[],
    isNew?: boolean,
    onSave?: (ac: Account) => void,
    onCancel?: () => void;
}

function ClMastEdit({ gsb, ac = {} as Account, StatusList=[] as StatusInfo[], isNew = false, onSave = () => { }, onCancel = () => { } }: ClMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    const [StatusDesc, setStatusDesc] = useState("")

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])


   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in ac) {
            reset(ac)
        }
    }, [ac, reset])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    useEffect(() => {
        setStatusDesc("")
        let StatusItem3 = StatusList.findIndex((StatusCode: StatusInfo) => StatusCode.d_StatusCode === ac.d_status)
        if ( StatusItem3 > 0 ) {
            setStatusDesc(StatusList[StatusItem3].d_StatusDesc)
        }

    }, [ac, ac.d_status])

    if (!isNew && !("d_acCode" in ac)) return null


    const onSubmit = (data: Account) => {
        console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = data.d_acCode.toUpperCase();

        data.d_AuthTran = ""
        // if (isReqAuth) {
        //     data.d_AuthTran = "Y"
        // }

        ;
        (async () => {
            try {
                //console.log("call clmastedit data", data)
                //console.log("call clmastedit isNew", isNew)
                const res = await gsb.post('/Transaction', {sb_data: data, cPgmId: "Ati_Rpt", cAction: "Ati_Rpt3Validate", isNew: isNew})
                //console.log("clmastedit res.data.ok", res.data.ok)
                //console.log("clmastedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const StatusRenderer: ItemRenderer<StatusInfo> = (StatusList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${StatusList.d_StatusDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={StatusList.d_StatusCode}
                key={StatusList.d_StatusCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StatusPredicate: ItemPredicate<StatusInfo> = (query, StatusList, _index, exactMatch) => {
        const normalizedText = `${StatusList.d_StatusCode} - ${StatusList.d_StatusDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StatusValueRenderer = (StatusList: StatusInfo) => {
        return StatusList.d_StatusCode
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelInfo="">
                    <InputGroup disabled={! isNew} id="inputAcCode" name="d_acCode" defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } />
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name">
                    <InputGroup readOnly value={ac.d_acName1} />
                    <InputGroup readOnly value={ac.d_acName2} />
                </FormGroup>
                <FormGroup className="Col3-6-1" label="Status">
                    <InputGroup readOnly value={ac.d_status} />
                    <div>
                    <div id="DispText">{StatusDesc}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="CCASS Segregated A/C" labelFor="">
                    <InputGroup id="inputAcName1" name="d_SegAC" autoFocus={true} defaultValue="" placeholder="Segregated A/C ..." inputRef={register({ maxLength: 8}) } />
                    {errors.d_SegAC && errors.d_SegAC.types && <span id="Err_msg">{errors.d_SegAC.types.message}</span>}
                    {errors.d_SegAC && errors.d_SegAC.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                </FormGroup>
            </div>
        </form>
    )
}

interface ClMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClMastMain({ gsb }: ClMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 
    
    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as Account[])
    const [StatusList, setStatusList] = useState([] as StatusInfo[])
    const [StatusList2, setStatusList2] = useState([] as StatusInfo[])

    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchTel, setSearchTel] = useState("")
    const [searchStatus, setSearchStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as Account)
    const [BlankAc, setBlankAc] = useState({} as Account)
    const [CurrentLog1, setCurrentLog1] = useState({} as Account)
    const [CurrentLog0, setCurrentLog0] = useState({} as Account)
    const [CbCurrentLog1, setCbCurrentLog1] = useState({} as cbMast)
    const [CbCurrentLog0, setCbCurrentLog0] = useState({} as cbMast)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")


    //gsb default value


    //console.log("ClMastMain logKey: ", logKey)
    //console.log("ClMastMain logKey2: ", logKey2)
    //console.log("ClMastMain logviewonly: ", logviewonly)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ClMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ClMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ClMastMain disp GsbFlag: ", GsbFlag)
                //console.log("ClMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ClMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("ClMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ClMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
 

                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.AC_STATUS.substr(i, 1).trim().length > 0 ) {
                        let tmpStatusName = "Active"
                        tmpStatusName = GsbFlag.AC_SDESC[i]

                        StatusList2.push({d_StatusCode: GsbFlag.AC_STATUS.substr(i, 1), d_StatusDesc: tmpStatusName})
                    }
                }
                setStatusList(StatusList2)
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {

        if (logviewonly === "Y") {
            setViewMode("L")
        }
        if (logviewonly === "Y_CB") {
            setViewMode("L_CB")
        }
        //console.log("ClMastMain ViewMode: ", ViewMode)

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 && logviewonly === "Y" ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "Clmast", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("ClMastMain Clmast ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
                if ( logKey.trim().length > 0 && logviewonly === "Y_CB" ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "Cbmast", 
                        cAction: "ViewLogRec"
                    })
                    console.log("ClMastMain Cbmast ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCbCurrentLog0(res.data.data[0])
                        setCbCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])


    
    const PrevRecProcess = async (ac: Account) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (ac: Account) => {
    }

    const NextRecProcess = async (ac: Account) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (ac: Account) => {
    }

    const editOnSave = async (ac: Account) => {
        try {
            //console.log("call editOnSave", ac)
            const res = await gsb.post('/Transaction', {sb_data: ac, cPgmId: "Ati_Rpt", cAction: "Ati_Rpt3Update"})
            if (isReqAuth2) {
                showSuccess("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
            } else {
                showSuccess("Account No. " +ac.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
                //setisShowAlert1(true)
                //setShowAlert1Msg("Account No. " +ac.d_acCode + " record saved")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        PageShowInfo: aPageShowInfo,
                        refreshPageShow: true,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            idNo: searchIdNo,
                            tel: searchTel,
                            status: searchStatus
                        },
                    },
                    cPgmId: "Clmast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                //console.log("ListRec res.data.PageShowInfo", res.data.PageShowInfo)
                setData(res.data.data)
                setaPageShowInfo(res.data.PageShowInfo)

                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    {/*
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                    */}
                    const aPageNumber1 = []
                    let k = 0
                    for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                        if ( res.data.PageShowInfo[j-1].pageShow ) {
                            k++
                            aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                            if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                setPageReachEnd(true)
                            }
                        }
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
                    //console.log("ListRec k", k, aPageNumber1)
                } else {
                    setnPage(0)
                    setnTotalPage(0)
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "Clmast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)

            ;

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            SortByName: false,
                        },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && selectedTabId == "list" ) {
    
            setShowWait(true)
            setPageReachEnd(false)

            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                idNo: searchIdNo,
                                tel: searchTel,
                                status: searchStatus
                            },
                            },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                    });

                    //console.log("ListRec Press  res.data.PageShowInfo", res.data.PageShowInfo)
                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)

                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }

                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }
    
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <ClMastView gsb={gsb} ac={currentAc} ac0={currentAc} StatusList={StatusList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></ClMastView>
    switch (currentMode) {
        case "edit":
            detail = <ClMastEdit gsb={gsb} ac={currentAc} StatusList={StatusList} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></ClMastEdit>
            break
        case "new":
            detail = <ClMastEdit gsb={gsb} ac={BlankAc} StatusList={StatusList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></ClMastEdit>
            break
    }

    let logKeyViewRec = <ClMastView gsb={gsb} ac={CurrentLog1} ac0={CurrentLog0} StatusList={StatusList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></ClMastView>


    function PageNo(n: number) {
        if (n > 0 ) {
            if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                {aPageNumber.map((no, j) => 
                    (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
                { (nPage > 0) && ! ( PageReachEnd ) &&
                    <Button minimal >...</Button>
                }
            </FormGroup>
        </div>
    </>


    // old, backup 
    let Pagination2 = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-IDNo">Segregated A/C</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ac, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acName1}</td>
                        <td className="TCol-IDNo" onClick={() => { setCurrentAc(ac) }}>{ac.d_SegAC}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && <><div className="Spacing-V-16" />{Pagination}</>}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly.substr(0,1) === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>Segregated A/C Setup</div></H3>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Name</option>
                                    <option value="aeCode">Runner</option>
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button> */}
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            <FormGroup label="Status" labelFor="searchStatus">
                                <InputGroup className="Input-XS" id="searchStatus" value={searchStatus} onChange={utils.handleStringChange(s => setSearchStatus(s))} />
                            </FormGroup>
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default ClMastMain