import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { IntRInfo, CCYParamInfo, CurrencyInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "M0009" ;
var isReqAuth2:boolean = false ;


interface IntRMastViewProps {
    gsb: AxiosInstance,
    IntR: IntRInfo,
    IntR0: IntRInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}


function IntRMastView({ gsb, IntR = {} as IntRInfo, IntR0 = {} as IntRInfo,baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: IntRMastViewProps) {

    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)


    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const [Currency, setCurrency] = useState(IntR.d_Currency)

    const [RateYMD, setRateYMD] = useState("")
    const [PrimeRate, setPrimeRate] = useState(0)

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, IntR])


    useEffect(() => {
        // Call reset to get the values of record StkInfo 
        if ("d_IntRateCode" in IntR) {
            //reset(IntR)
        }
    }, [IntR])

    //setCurrency(IntR.d_Currency)   =>  Error: Too many re-renders. React limits the number of renders to prevent an infinite loop.

    useEffect(() => {
        (async () => {
            try {
                // get CCYParam
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYParam", CCY: IntR.d_Currency})
                //console.log("IntRMastView GetCCYParam: ", res.data.data) 
                if ( IntR.d_Currency.trim().length === 0 )
                {
                    //setRateYMD(res.data.data[0][1]) 
                    //setPrimeRate(res.data.data[0][2]) 
                    setRateYMD(res.data.data[0].d_RateYMD) 
                    setPrimeRate(res.data.data[0].d_PrimeRate) 
                    //console.log("IntRMastView RateYMD: ", IntR.d_Currency, RateYMD, res.data.data[0][1]) 
                    //console.log("IntRMastView PrimeRate: ", IntR.d_Currency, PrimeRate, res.data.data[0][2]) 
                } else {
                    setRateYMD(res.data.data[0].d_RateYMD) 
                    setPrimeRate(res.data.data[0].d_PrimeRate) 
                    //onsole.log("IntRMastView RateYMD: ", IntR.d_Currency, RateYMD, res.data.data[0].d_RateYMD) 
                    //console.log("IntRMastView PrimeRate: ", IntR.d_Currency, PrimeRate, res.data.data[0].d_PrimeRate) 
                }
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, IntR.d_Currency])


    if (! ("d_IntRateCode" in IntR)) return null

 

    //console.log("IntRMastView Currency: ", Currency, IntR.d_Currency)


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    return (
        <div>
            {/* define add,change,delete button */}
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div> }
            { ViewMode === "L" && <div>
                Interest Code : {IntR.d_IntRateCode}
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />
            { RateYMD === "Y" &&
                <div className="Row">
                    <FormGroup className="Col-1-12" label="">YEARLY INTEREST RATE</FormGroup>
                </div>
            }
            { RateYMD === "P" &&
                <div className="Row">
                    <FormGroup className="Col-1-12" label="">PRIME INTEREST RATE ({PrimeRate}) </FormGroup>
                </div>
            }
            { RateYMD === "D" &&
                <div className="Row">
                    <FormGroup className="Col-1-12" label="">DAILY INTEREST RATE</FormGroup>
                </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-2" label="Interest Code" labelInfo="*">
                    <InputGroup readOnly value={IntR.d_IntRateCode} />
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                    <FormGroup className="Col3-3-1" label="Currency">
                        <InputGroup readOnly value={IntR.d_Currency} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Debit Interest Rate">
                    <InputGroup readOnly value={utils.formatNumber2(IntR.d_DB_Rate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (IntR0.d_DB_Rate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(IntR0.d_DB_Rate)} </div> }
                </FormGroup>
                { (RateYMD === "P") && (IntR.d_DB_Rate === 0) &&  
                    <FormGroup className="Col-3-2" label="0=Zero Interest">
                        <RadioGroup
                            label="" 
                            name="d_0DB_Int"
                            disabled={true}
                            inline={true}
                            selectedValue={IntR.d_0DB_Int}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                }
                { (RateYMD === "P") && ! (IntR.d_DB_Rate === 0) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; {utils.formatNumber3(IntR.d_DB_Rate+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (IntR.d_DB_Rate === 0) && ! (IntR.d_0DB_Int === "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; {utils.formatNumber3(IntR.d_DB_Rate+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (IntR.d_DB_Rate === 0) && (IntR.d_0DB_Int === "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; 0</span>
                    </FormGroup>
                }
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Credit Interest Rate">
                    <InputGroup readOnly value={utils.formatNumber2(IntR.d_CR_Rate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (IntR0.d_CR_Rate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(IntR0.d_CR_Rate)} </div> }
                </FormGroup>
                { (RateYMD === "P") && (IntR.d_CR_Rate === 0) &&  
                    <FormGroup className="Col-3-2" label="0=Zero Interest">
                        <RadioGroup
                            label="" 
                            name="d_0CR_Int"
                            disabled={true}
                            inline={true}
                            selectedValue={IntR.d_0CR_Int}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                }
                { (RateYMD === "P") && ! (IntR.d_CR_Rate === 0) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        {/* <span id="DispText">=&gt; {utils.formatNumber2(IntR.d_CR_Rate+PrimeRate)}</span> */}
                        <span id="DispText">=&gt; {utils.formatNumber3(IntR.d_CR_Rate+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (IntR.d_CR_Rate === 0) && ! (IntR.d_0CR_Int === "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; {utils.formatNumber3(IntR.d_CR_Rate+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (IntR.d_CR_Rate === 0) && (IntR.d_0CR_Int === "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; 0</span>
                    </FormGroup>
                }
            </div>

            {( (GsbFlag.OVRMGN_INT) || ( GsbFlag.OVRLMT_INT) ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Penalty Interest Rate">
                        <InputGroup readOnly value={utils.formatNumber2(IntR.d_Penalty_DB_Rate)} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (IntR0.d_Penalty_DB_Rate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(IntR0.d_Penalty_DB_Rate)} </div> }
                    </FormGroup>
                </div>
            }
        </div>
    )
}


interface IntRMastEditProps {
    gsb: AxiosInstance,
    IntR?: IntRInfo,
    CurrencyList?: CurrencyInfo[],
    isNew?: boolean,
    onSave?: (IntR: IntRInfo) => void,
    onCancel?: () => void;
}

function IntRMastEdit({ gsb, IntR = {} as IntRInfo, CurrencyList=[] as CurrencyInfo[], isNew = false, onSave = () => { }, onCancel = () => { } }: IntRMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<IntRInfo>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    
    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, IntR])


    const [Currency, setCurrency] = useState(IntR.d_Currency)
    const [RateYMD, setRateYMD] = useState("")
    const [PrimeRate, setPrimeRate] = useState(0)
    const [DB_Rate, setDB_Rate] = useState(utils.numToString(IntR.d_DB_Rate))
    const [CR_Rate, setCR_Rate] = useState(utils.numToString(IntR.d_CR_Rate))
    const [Z0DB_Int, setZ0DB_Int] = useState(IntR.d_0DB_Int)
    const [Z0CR_Int, setZ0CR_Int] = useState(IntR.d_0CR_Int)



    useEffect(() => {
        // Call reset to get the values of record StkInfo 
        if ("d_IntRateCode" in IntR) {
            reset(IntR)
        }
    }, [IntR, reset])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    useEffect(() => {
        (async () => {
            try {
                // get CCYParam
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYParam", CCY: Currency})
                //console.log("IntRMastEdit GetCCYParam: ", res.data.data) 
                if ( Currency.trim().length === 0 )
                {
                    //setRateYMD(res.data.data[0][1]) 
                    //setPrimeRate(res.data.data[0][2]) 
                    setRateYMD(res.data.data[0].d_RateYMD) 
                    setPrimeRate(res.data.data[0].d_PrimeRate) 
                    //console.log("IntRMastEdit RateYMD: ", Currency, RateYMD, res.data.data[0][1]) 
                    //console.log("IntRMastEdit PrimeRate: ", Currency, PrimeRate, res.data.data[0][2]) 
                } else {
                    setRateYMD(res.data.data[0].d_RateYMD) 
                    setPrimeRate(res.data.data[0].d_PrimeRate) 
                    //console.log("IntRMastEdit RateYMD: ", Currency, RateYMD, res.data.data[0].d_RateYMD) 
                    //console.log("IntRMastEdit PrimeRate: ", Currency, PrimeRate, res.data.data[0].d_PrimeRate) 
                }
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, Currency])

    if (! isNew && ! ("d_IntRateCode" in IntR)) return null



    
    const onSubmit = (data: IntRInfo) => {

        //console.log("IntRMastEdit onsubmit data: ", data)
        //console.log("IntRMastEdit onsubmit StkPrice: ", StkPrice)

        // put value to json/field before write database dbf

        data.d_IntRateCode = data.d_IntRateCode.toUpperCase();
        //data.d_Currency = data.d_Currency.toUpperCase();

//console.log("IntRMastEdit onsubmit Currency: ", Currency)

        data.d_Currency = Currency;
        if ( typeof(data.d_Currency) === "undefined" ) {
            data.d_Currency = ""
        }        
        data.d_Currency = data.d_Currency.toUpperCase();
        data.d_DB_Rate = utils.StringToNum(DB_Rate);
        data.d_CR_Rate = utils.StringToNum(CR_Rate);
        data.d_0DB_Int = Z0DB_Int;
        data.d_0CR_Int = Z0CR_Int;

        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }

        (async () => {
            try {
                //console.log("call IntRMastEdit data", data)
                //console.log("call IntRMastEdit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "IntRmast", cAction: "IntRValidate", isNew: isNew})
                //console.log("IntRMastEdit res.data.ok", res.data.ok)
                //console.log("IntRMastEdit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }


    const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (CurrencyList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CurrencyList.d_CcyDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CurrencyList.d_Currency}
                key={CurrencyList.d_Currency}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (query, CurrencyList, _index, exactMatch) => {
        const normalizedText = `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
        return CurrencyList.d_Currency
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div>
            <div className="Spacing-V-16" />
            { RateYMD === "Y" &&
                <div className="Row">
                    <FormGroup className="Col-1-12" label="">YEARLY INTEREST RATE</FormGroup>
                </div>
            }
            { RateYMD === "P" &&
                <div className="Row">
                    <FormGroup className="Col-1-12" label="">PRIME INTEREST RATE ({PrimeRate}) </FormGroup>
                </div>
            }
            { RateYMD === "D" &&
                <div className="Row">
                    <FormGroup className="Col-1-12" label="">DAILY INTEREST RATE</FormGroup>
                </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-2" label="Interest Code" labelFor="inputIntRateCode" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputIntRateCode" name="d_IntRateCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 2 }) } />
                    {errors.d_IntRateCode && errors.d_IntRateCode.type === "required" && (<span id="Err_msg">Interest Code cannot be Blank</span>)}
                    {errors.d_IntRateCode && errors.d_IntRateCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                    {errors.d_IntRateCode && errors.d_IntRateCode.types && <span id="Err_msg">{errors.d_IntRateCode.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                    <FormGroup className="Col3-3-1" label="Currency" labelFor="inputCurrency">
                        {/* <InputGroup disabled={! isNew} id="inputCurrency" value={Currency} defaultValue="" placeholder="Currency ..." onChange={utils.handleStringChange(s => setCurrency(s)) } inputRef={register({ maxLength: 3 }) } />
                        {errors.d_Currency && errors.d_Currency.type === "maxLength" && <span id="Err_msg">Max length is 3</span>}
                        {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>} */}
                        <Suggest
                                disabled={! isNew}
                                inputProps={{ placeholder: "Currency ..." }}
                                defaultSelectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === IntR.d_Currency)}
                                items={CurrencyList}
                                itemRenderer={CurrencyRenderer}
                                itemPredicate={CurrencyPredicate}
                                inputValueRenderer={CurrencyValueRenderer}
                                onItemSelect={(CurrencyList) => { setCurrency(CurrencyList.d_Currency) }}
                            />
                        {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Debit Interest Rate" labelFor="inputDBRate">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputDBRate" autoFocus={! isNew} defaultValue="0" value={DB_Rate} onValueChange={(n => setDB_Rate(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputDBRate" autoFocus={! isNew} defaultValue={DB_Rate} value={DB_Rate} type="number" onChange={utils.handleStringChange(n => setDB_Rate(n))}  />
                    {errors.d_DB_Rate && errors.d_DB_Rate.types && <span id="Err_msg">{errors.d_DB_Rate.types.message}</span>}
                </FormGroup>
                { (RateYMD === "P") && (utils.StringToNum(DB_Rate) === 0) &&    
                    <FormGroup className="Col-3-2" label="0=Zero Interest" labelFor="input0DBInt">
                        <RadioGroup
                            name="d_0DB_Int"
                            inline={true}
                            selectedValue={Z0DB_Int}
                            onChange={utils.handleStringChange(s => setZ0DB_Int(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_0DB_Int && errors.d_0DB_Int.types && <span id="Err_msg">{errors.d_0DB_Int.types.message}</span>}
                    </FormGroup>
                }
                { (RateYMD === "P") && ! (utils.StringToNum(DB_Rate) === 0) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; {utils.formatNumber3(utils.StringToNum(DB_Rate)+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (utils.StringToNum(DB_Rate) === 0) && ! (Z0DB_Int === "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; {utils.formatNumber3(utils.StringToNum(DB_Rate)+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (utils.StringToNum(DB_Rate) === 0) && (Z0DB_Int === "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; 0</span>
                    </FormGroup>
                }

            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Credit Interest Rate" labelFor="inputCRRate">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputCRRate"  defaultValue="0" value={CR_Rate} onValueChange={(n => setCR_Rate(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputCRRate" defaultValue={CR_Rate} value={CR_Rate} type="number" onChange={utils.handleStringChange(n => setCR_Rate(n))}  />
                    {errors.d_CR_Rate && errors.d_CR_Rate.types && <span id="Err_msg">{errors.d_CR_Rate.types.message}</span>}
                </FormGroup>
                { (RateYMD === "P") && (utils.StringToNum(CR_Rate) === 0) &&    
                    <FormGroup className="Col-3-2" label="0=Zero Interest" labelFor="input0CRInt">
                        <RadioGroup
                            name="d_0CR_Int"
                            inline={true}
                            selectedValue={Z0CR_Int}
                            onChange={utils.handleStringChange(s => setZ0CR_Int(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_0CR_Int && errors.d_0CR_Int.types && <span id="Err_msg">{errors.d_0CR_Int.types.message}</span>}
                    </FormGroup>
                }
                { (RateYMD === "P") && ! (utils.StringToNum(CR_Rate) === 0) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; {utils.formatNumber3(utils.StringToNum(CR_Rate)+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (utils.StringToNum(CR_Rate) === 0) && ! (Z0CR_Int === "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; {utils.formatNumber3(utils.StringToNum(CR_Rate)+PrimeRate, 6)}</span>
                    </FormGroup>
                }
                { (RateYMD === "P") && (utils.StringToNum(CR_Rate) === 0) && (Z0CR_Int == "Y" ) &&
                    <FormGroup className="Col-5-2" label="Actual Rate">
                        <span id="DispText">=&gt; 0</span>
                    </FormGroup>
                }
            </div>
            {( (GsbFlag.OVRMGN_INT) || ( GsbFlag.OVRLMT_INT) ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Penalty Interest Rate" labelFor="inputPenaltyDBRate">
                        <InputGroup className="inputNumberNoSpin" id="inputPenaltyDBRate" name="d_Penalty_DB_Rate"  defaultValue="0" type="number" inputRef={register} />
                        {errors.d_Penalty_DB_Rate && errors.d_Penalty_DB_Rate.types && <span id="Err_msg">{errors.d_Penalty_DB_Rate.types.message}</span>}
                    </FormGroup>
                </div>
             }

        </form>
    )
}

interface IntRMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function IntRMastMain({ gsb }: IntRMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { IntRateCode: defaultSearchValue = "" }: { IntRateCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 
    
    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as IntRInfo[])
    const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])
    const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])

    const [searchKey, setSearchKey] = useState("IntRateCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchCurrency, setsearchCurrency] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentIntR, setCurrentIntR] = useState({} as IntRInfo)
    const [BlankIntR, setBlankIntR] = useState({} as IntRInfo)
    const [CurrentLog0, setCurrentLog0] = useState({} as IntRInfo)
    const [CurrentLog1, setCurrentLog1] = useState({} as IntRInfo)
    const [ViewMode, setViewMode] = useState("V")
    
    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [showPenaltyInt, setshowPenaltyInt] = useState(false)
    const [showCurrency, setshowCurrency] = useState(false)
    

    //gsb default value
    BlankIntR.d_0DB_Int = "Y"
    BlankIntR.d_0CR_Int = "Y"

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setshowPenaltyInt( (GsbFlag.OVRMGN_INT) || ( GsbFlag.OVRLMT_INT) );
                setshowCurrency( (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) );
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("IntRMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {

        if (logviewonly === "Y") {
            setViewMode("L")
        }
        //console.log("ClMastMain ViewMode: ", ViewMode)

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "IntRmast", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("IntRMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])

    useEffect(() => {
        (async () => {
            try {

                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYList"})

                //console.log("IntRMast: ", res.data.data) 
                CurrencyList2.push({d_Currency: "", d_CcyDesc: "HKD"})
                for (let j = 0; j < res.data.data.length; j++) {
                    CurrencyList2.push({d_Currency: res.data.data[j][0], d_CcyDesc: res.data.data[j][1]})
                }
                setCurrencyList(CurrencyList2)
                //console.log("set CurrencyList: ", CurrencyList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    const PrevRecProcess = async (IntR: IntRInfo) => {
        try {
            //console.log("call PrevRecProcess", IntR)
            const res = await gsb.post('/Master', {sb_data: IntR, cPgmId: "IntRmast", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", d_IntRateCode.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_IntRateCode+data[i].d_Currency === res.data.data.d_IntRateCode+res.data.data.d_Currency) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentIntR(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (IntR: IntRInfo) => {
        try {
            IntR.d_AuthTran = ""
            if (isReqAuth2) {
                IntR.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", IntR)
            const res = await gsb.post('/Master', {sb_data: IntR, cPgmId: "IntRmast", cAction: "DelRec"})
            if (isReqAuth2) {
                showSuccess("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record deleting, waiting for authorization")
            } else {
                showSuccess("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record deleted")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_IntRateCode+data[i].d_Currency === res.data.data.d_IntRateCode+res.data.data.d_Currency) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentIntR(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record deleting, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record deleting, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (IntR: IntRInfo) => {
        try {
            //console.log("call NextRecProcess", IntR)
            const res = await gsb.post('/Master', {sb_data: IntR, cPgmId: "IntRmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_IntRateCode+data[i].d_Currency === res.data.data.d_IntRateCode+res.data.data.d_Currency) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentIntR(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (IntR: IntRInfo) => {
        try {
            //console.log("call newOnSave", IntR)
            const res = await gsb.post('/Master', {sb_data: IntR, cPgmId: "IntRmast", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record adding, waiting for authorization")
            } else {
                showSuccess("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record added")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_IntRateCode+data[i].d_Currency === res.data.data.d_IntRateCode+res.data.data.d_Currency) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentIntR(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record adding, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record adding, waiting for authorization")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (IntR: IntRInfo) => {
        try {
            //console.log("call editOnSave", IntR)
            const res = await gsb.post('/Master', {sb_data: IntR, cPgmId: "IntRmast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_IntRateCode+data[i].d_Currency === res.data.data.d_IntRateCode+res.data.data.d_Currency) {
                        setCurrentIntR(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record saving, waiting for authorization")
            } else {
                showSuccess("Interest Code " + IntR.d_IntRateCode +","+ IntR.d_Currency + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_IntRateCode+data[i].d_Currency === res.data.data.d_IntRateCode+res.data.data.d_Currency) {
                        data[i] = { ...data[i], ...res.data.data }
                        //console.log("editOnSave data", i, data)
                        setData(data)
                        //setCurrentStk(stk)
                        break
                    }
                }
                setCurrentIntR(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        // get SmMast list if StkNo have value
        try {
            //console.log("SmMastMain onsubmit called", data)
            const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            Curency: searchCurrency,
                        }, 
                    },
                    cPgmId: "IntRmast",
                    cAction: "ListRec",
                });

            if ( m_limit > 1 ) {
                setData(res.data.data)
            }
            if (res.data.data.length > 0) {
                setCurrentIntR(res.data.data[0])
            }

            if ( m_limit > 1 ) {
                if ( res.data.count > 0 ) {
                const aPageNumber1 = []
                if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                    }
                        setaPageNumber(aPageNumber1)
                    }
                }
            }
            setShowWait(false)
        } catch (error) {
            utils.showError(error)
        }
    }

    // get one SmMast record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                //console.log("SmMastMain useEffect length > 0  called", data)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "IntRateCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "IntRmast",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentIntR(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                Curency: searchCurrency,
                            }, 
                            },
                        cPgmId: "IntRmast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentIntR(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <IntRMastView gsb={gsb} IntR={currentIntR} IntR0={currentIntR} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentIntR)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentIntR)} onNextRec={() => NextRecProcess(currentIntR)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></IntRMastView>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            detail = <IntRMastEdit gsb={gsb} IntR={BlankIntR} CurrencyList={CurrencyList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></IntRMastEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <IntRMastEdit gsb={gsb} IntR={currentIntR} CurrencyList={CurrencyList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></IntRMastEdit>
            break
    }

    let logKeyViewRec = <IntRMastView gsb={gsb} IntR={CurrentLog1} IntR0={CurrentLog0} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentIntR)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentIntR)} onNextRec={() => NextRecProcess(currentIntR)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></IntRMastView>

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-InterestCode">Interest Code</th>
                { showCurrency &&
                    <th className="TCol-Currency">Currency</th>
                }
                <th className="TCol-DB_Rate">Debit Rate</th>
                <th className="TCol-CR_Rate">Credit Rate</th>
                {( showPenaltyInt ) &&
                    <th className="TCol-Penalty_Rate">Penalty Interest Rate</th>
                }
            </thead>
            <tbody id="ma_tr">
                {data.map((IntR, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-InterestCode" onClick={() => { setCurrentIntR(IntR) ; MoblieDev && setSelectedTabId("detail") }}>{IntR.d_IntRateCode}</td>
                        { showCurrency &&
                            <td className="TCol-Currency" onClick={() => { setCurrentIntR(IntR) ; MoblieDev && setSelectedTabId("detail") }}>{IntR.d_Currency}</td>
                        }
                        <td className="TCol-DB_Rate" onClick={() => { setCurrentIntR(IntR) }}>{IntR.d_DB_Rate}</td>
                        <td className="TCol-CR_Rate" onClick={() => { setCurrentIntR(IntR) }}>{IntR.d_CR_Rate}</td>
                        {( showPenaltyInt ) &&
                            <td className="TCol-Penalty_Rate" onClick={() => { setCurrentIntR(IntR) }}>{IntR.d_Penalty_DB_Rate}</td>
                        }
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    {/* define search key */}
                    <H3><div style={{justifyContent: 'flex-end'}}>Interest Master</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="IntRateCode">Interest Code</option>
                                    {/* <option value="Curency">Currency</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "IntRateCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "IntRateCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-5" label="Currency" labelFor="searchCurrency">
                                <InputGroup id="searchCurrency" value={searchCurrency} onChange={utils.handleStringChange(s => setsearchCurrency(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Interest Master Log View</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default IntRMastMain