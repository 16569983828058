import React, { useState, FormEvent, useEffect } from "react";

import { useParams } from "react-router-dom";

import { Suggest, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import {
  FormGroup,
  InputGroup,
  AnchorButton,
  Button,
  Tabs,
  Tab,
  Collapse,
  HTMLSelect,
  HTMLTable,
  ControlGroup,
  NumericInput,
  TextArea,
  Position,
  Toaster,
  Intent,
  MenuItem,
} from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from "axios";

import * as utils from "./utils";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import svgWait2 from "./svg/wait2.gif";

import { MultiBankInfo, CCYParamInfo, CurrencyInfo } from "./Types";
import { SystemHeader } from "./CommonUI";

import i18next from "i18next";

interface PageInfo {
  n: number;
}

var GsbFlag: any;

interface MultiBankMastViewProps {
  gsb: AxiosInstance;
  MultiBank: MultiBankInfo;
  baseUrl?: string;
  token?: string | null;
  onPrevRec?: () => void;
  onNew?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onNextRec?: () => void;
}

function MultiBankMastView({
  gsb,
  MultiBank = {} as MultiBankInfo,
  baseUrl = "",
  token = "",
  onPrevRec = () => {},
  onNew = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onNextRec = () => {},
}: MultiBankMastViewProps) {
  const [isAdd, setisAdd] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isDelete, setisDelete] = useState(false);

  const { t, i18n } = useTranslation();

  const [Currency, setCurrency] = useState(MultiBank.d_Currency);
  const [BankName, setBankName] = useState("");
  const [BankDesc, setBankDesc] = useState("");

  function buttonPress(buttonName: string) {
    if (buttonName == "new") {
      onNew();
    }
    if (buttonName == "edit") {
      onEdit();
    }
    if (buttonName == "delete") {
      setisDelete(true);
    }
  }

    if (!("d_BankCode" in MultiBank)) return null;

  return (
    <div>
      {/* define add,change,delete button */}
      <div className="Row">
        <Button
          className="Col-1-1"
          icon="direction-left"
          onClick={() => onPrevRec()}
        >
          Prev
        </Button>
        <Button
          className="Col-2-1"
          icon="add"
          onClick={() => buttonPress("new")}
        >
          New
        </Button>
        <Alert
          confirmButtonText="OK"
          icon="disable"
          intent={Intent.WARNING}
          isOpen={isAdd}
          onConfirm={() => {
            setisAdd(false);
          }}
        >
          <p>NEW : Access Denied</p>
        </Alert>
        <Button
          className="Col-3-1"
          icon="edit"
          onClick={() => buttonPress("edit")}
        >
          Edit
        </Button>
        <Alert
          confirmButtonText="OK"
          icon="disable"
          intent={Intent.WARNING}
          isOpen={isEdit}
          onConfirm={() => {
            setisEdit(false);
          }}
        >
          <p>EDIT : Access Denied</p>
        </Alert>
        <Button
          className="Col-4-1"
          icon="delete"
          onClick={() => buttonPress("delete")}
        >
          Delete
        </Button>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          canEscapeKeyCancel={true}
          icon="trash"
          intent={Intent.DANGER}
          isOpen={isDelete}
          onCancel={() => setisDelete(false)}
          onConfirm={() => {
            onDelete();
            setisDelete(false);
          }}
        >
          <p>Are you sure to Delete ?</p>
        </Alert>
        <Button
          className="Col-5-1"
          icon="direction-right"
          onClick={() => onNextRec()}
        >
          Next
        </Button>
      </div>
      <div className="Spacing-V-16" />
      <div className="Row">
        <FormGroup
          className="Col-1-2"
          label="Bank Code"
          labelFor="inputBankCode"
          labelInfo="*"
        >
          <InputGroup readOnly value={MultiBank.d_BankCode} />
        </FormGroup>
        <FormGroup
          className="Col3-3-1"
          label="Currency"
          labelFor="inputCurrency"
        >
          <InputGroup readOnly value={MultiBank.d_Currency} />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-3"
          label="Bank Name"
          labelFor="inputBankName"
        >
          <InputGroup readOnly value={MultiBank.d_BankName} />
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-3"
          label="Bank Description"
          labelFor="inputBankDesc"
        >
          <InputGroup readOnly value={MultiBank.d_BankDesc} />
        </FormGroup>
      </div>
      

      {/* <div className="Row">
                <FormGroup className="Col-1-1" label="PAmount" labelFor="input_Bank_PAmount">
                    <InputGroup readOnly value={utils.formatNumber2(MultiBank.d_Bank_PAmount)} />
                </FormGroup>
                <FormGroup className="Col-1-1" label="RAmount" labelFor="input_Bank_RAmount">
                    <InputGroup readOnly value={utils.formatNumber2(MultiBank.d_Bank_RAmount)} />
                </FormGroup>
                <FormGroup className="Col-1-1" label="SPAmount" labelFor="input_Bank_SPAmount">
                    <InputGroup readOnly value={utils.formatNumber2(MultiBank.d_Bank_SPAmount)} />
                </FormGroup>
                <FormGroup className="Col-1-1" label="SRAmount" labelFor="input_Bank_SRAmount">
                    <InputGroup readOnly value={utils.formatNumber2(MultiBank.d_Bank_SRAmount)} />
                </FormGroup>
                <FormGroup className="Col-1-1" label="RPAmount" labelFor="input_Bank_RPAmount">
                    <InputGroup readOnly value={utils.formatNumber2(MultiBank.d_Bank_RPAmount)} />
                </FormGroup>
                <FormGroup className="Col-1-1" label="SRAmount" labelFor="input_Bank_RRAmount">
                    <InputGroup readOnly value={utils.formatNumber2(MultiBank.d_Bank_RRAmount)} />
                </FormGroup>
            </div> */}
    </div>
  );
}

interface MultiBankMastEditProps {
  gsb: AxiosInstance;
  MultiBank?: MultiBankInfo;
  CurrencyList?: CurrencyInfo[];
  isNew?: boolean;
  onSave?: (MultiBank: MultiBankInfo) => void;
  onCancel?: () => void;
}

function MultiBankMastEdit({
  gsb,
  MultiBank = {} as MultiBankInfo,
  CurrencyList = [] as CurrencyInfo[],
  isNew = false,
  onSave = () => {},
  onCancel = () => {},
}: MultiBankMastEditProps) {
  const { register, handleSubmit, reset, setValue, setError, errors } =
    useForm<MultiBankInfo>();
  const { t, i18n } = useTranslation();

useEffect(() => {
    // Suggest don't have ref to use with react-hook-form, so register it (d_Channel) manually and call setValue when item is selected
    register({ name: "d_Currency" })
}, [register])

  useEffect(() => {
    // Call reset to get the values of record StkInfo
    if ("d_BankCode" in MultiBank) {
      reset(MultiBank);
    }
  }, [MultiBank, reset]);

  useEffect(() => {
    if (GsbFlag.Enter2tab == "Y") {
      let inputs = utils.PerformEnter2Tab();
    }
  }, []);

  if (!isNew && !("d_BankCode" in MultiBank)) return null;

  const GetCCYParam = async (i_CCY: string) => {
            return true;
    }
    
  const onSubmit = (data: MultiBankInfo) => {
    //console.log("MultiBankMastEdit onsubmit data: ", data)

    // put value to json/field before write database dbf
    data.d_BankCode = data.d_BankCode.toUpperCase();
    data.d_BankName = data.d_BankName.toUpperCase();
    data.d_BankDesc = data.d_BankDesc.toUpperCase();

    //data.d_Currency = data.d_Currency.toUpperCase();
    //console.log("call MultiBankMastEdit, Data.d_Currency", data.d_Currency);
    if (typeof data.d_Currency === "undefined") {
      data.d_Currency = "";
    }
    data.d_Currency = data.d_Currency.toUpperCase();

    (async () => {
      try {
        //console.log("call MultiBankMastEdit data", data)
        //console.log("call MultiBankMastEdit isNew", isNew)
        const res = await gsb.post("/Master", {
          sb_data: data,
          cPgmId: "MultiBankMast",
          cAction: "BankValidate",
          isNew: isNew,
        });
        //console.log("MultiBankMastEdit res.data.ok", res.data.ok)
        //console.log("MultiBankMastEdit res.data.data", res.data.data)

        if (!res.data.ok) {
          for (let j = 0; j < res.data.data.length; j++) {
            setError(res.data.data[j][0], {
              message: res.data.data[j][1],
            });
          }

          return;
        }

        onSave(data);
      } catch (error) {
        showErrorBox(error);
      }
    })();

    //onSave(data)
  };

  const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (
    CurrencyList,
    { handleClick, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = `${CurrencyList.d_CcyDesc}`;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={CurrencyList.d_Currency}
        key={CurrencyList.d_Currency}
        onClick={handleClick}
        text={highlightText(text, query)}
      />
    );
  };

  const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (
    query,
    CurrencyList,
    _index,
    exactMatch
  ) => {
    const normalizedText =
      `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedText === normalizedQuery;
    } else {
      return normalizedText.indexOf(normalizedQuery) >= 0;
    }
  };

  const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
    return CurrencyList.d_Currency;
  };

  function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map(escapeRegExpChars);
    if (words.length === 0) {
      return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  }

  function escapeRegExpChars(text: string) {
    //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
  }

  const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
    //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

    // ctrl + s
    if (e.ctrlKey && e.keyCode === 83) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const showErrorBox = (err: any) => {
    alert(
      "Error:" +
        err.response.data.subSystem +
        " - " +
        err.response.data.description +
        ", OS code:" +
        err.response.data.osCode
    );
  };

  /*<form onSubmit={handleSubmit(onSubmit)}>*/
  return (
    <form onKeyDown={handleHotkey}>
      <div className="Row">
        <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          className="Col-2-1"
          icon="tick"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-2"
          label="Bank Code"
          labelFor="inputBankCode"
          labelInfo="*"
        >
          <InputGroup
            disabled={!isNew}
            id="inputBankCode"
            name="d_BankCode"
            autoFocus={isNew}
            defaultValue=""
            inputRef={register({ required: true, maxLength: 2 })}
          />
          {errors.d_BankCode && errors.d_BankCode.type === "required" && (
            <span id="Err_msg">Bank Code cannot be Blank</span>
          )}
          {errors.d_BankCode && errors.d_BankCode.type === "maxLength" && (
            <span id="Err_msg">Max length is 2</span>
          )}
          {errors.d_BankCode && errors.d_BankCode.types && (
            <span id="Err_msg">{errors.d_BankCode.types.message}</span>
          )}
        </FormGroup>
        {GsbFlag.MULTI_BANK && !GsbFlag.SING_CURR && (
          <FormGroup
            className="Col3-3-1"
            label="Currency"
            labelFor="inputCurrency"
          >
            <Suggest
              disabled={! isNew}
              inputProps={{ placeholder: "Currency ..." }}
              defaultSelectedItem={CurrencyList.find(
                (Ccy) => Ccy.d_Currency === MultiBank.d_Currency
              )}
              items={CurrencyList}
              itemRenderer={CurrencyRenderer}
              itemPredicate={CurrencyPredicate}
              inputValueRenderer={CurrencyValueRenderer}
              onItemSelect={(CurrencyList) => {
                setValue("d_Currency", CurrencyList.d_Currency);
              }}
            />
            {errors.d_Currency && errors.d_Currency.types && (
              <span id="Err_msg">{errors.d_Currency.types.message}</span>
            )}
          </FormGroup>
        )}
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-3"
          label="Bank Name"
          labelFor="inputBankName"
        >
          <InputGroup
            id="inputFeeCode"
            name="d_BankName"
            autoFocus={!isNew}
            defaultValue=""
            placeholder="Bank Name ..."
            inputRef={register({ maxLength: 8 })}
          />
          {errors.d_BankName && errors.d_BankName.type === "maxLength" && (
            <span id="Err_msg">Max length is 8</span>
          )}
          {errors.d_BankName && errors.d_BankName.types && (
            <span id="Err_msg">{errors.d_BankName.types.message}</span>
          )}
        </FormGroup>
      </div>
      <div className="Row">
        <FormGroup
          className="Col-1-3"
          label="Bank Description"
          labelFor="inputBankDesc"
          labelInfo="*"
        >
          <InputGroup
            id="inputBankDesc"
            name="d_BankDesc"
            autoFocus={!isNew}
            defaultValue=""
            placeholder="Bank Description ..."
            inputRef={register({ maxLength: 30 })}
          />
          {errors.d_BankDesc && errors.d_BankDesc.type === "maxLength" && (
            <span id="Err_msg">Max length is 30</span>
          )}
          {errors.d_BankDesc && errors.d_BankDesc.types && (
            <span id="Err_msg">{errors.d_BankDesc.types.message}</span>
          )}
        </FormGroup>
      </div>
      
    </form>
  );
}

interface MultiBankMastMainProps {
  gsb: AxiosInstance;
}

const toaster = Toaster.create({
  position: Position.TOP,
});

function MultiBankMastMain({ gsb }: MultiBankMastMainProps) {
  const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />;
  const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />;

  let { MultiBankCode: defaultSearchValue = "" }: { MultiBankCode: string } =
    useParams();


    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))      
    let logKey = utils.getUrlParameter('logKey', url);
    let logKey2 = logKey.substr(0,logKey.indexOf("?"));
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

  const [data, setData] = useState([] as MultiBankInfo[]);
  const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[]); 
  const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[]);

  const [searchKey, setSearchKey] = useState("MultiBankCode");
  const [searchValue, setSearchValue] = useState(defaultSearchValue);

  const [showFilters, setShowFilters] = useState(false);
  const [searchCurrency, setsearchCurrency] = useState(""); 

  const [nPage, setnPage] = useState(0);
  const [nTotalPage, setnTotalPage] = useState(0);
  const [aPageNumber, setaPageNumber] = useState([] as PageInfo[]);
  const [MoblieDev, setMoblieDev] = useState(false);
  const [ShowWait, setShowWait] = useState(false);
  const [searchLimit, setSearchLimit] = useState(50);
  const [searchBackward, setSearchBackward] = useState(false);

  const [selectedTabId, setSelectedTabId] = useState("list");
  const [currentMultiBank, setcurrentMultiBank] = useState({} as MultiBankInfo);
  const [BlankMultiBank, setBlankMultiBank] = useState({} as MultiBankInfo);

  const [CurrentLog, setCurrentLog] = useState({} as MultiBankInfo) 
  const [ViewMode, setViewMode] = useState("V")                     

  const [currentMode, setCurrentMode] = useState("show");
  const [isShowAlert1, setisShowAlert1] = useState(false);
  const [ShowAlert1Msg, setShowAlert1Msg] = useState("");
  const [isShowAlert2, setisShowAlert2] = useState(false);
  const [ShowAlert2Msg, setShowAlert2Msg] = useState("");

  const [CO_NAME, setCO_NAME] = useState("");
  const [companyLogoName, setcompanyLogoName] = useState("");
  const [SBPath, setSBPath] = useState("");
  const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()));
  const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()));
  const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0");

  const [SING_CURR, setSING_CURR] = useState(false);
  const [showCurrency, setshowCurrency] = useState(false); 
  //gsb default value
  // BlankMultiBank.d_InDirect = "Y"
  // BlankMultiBank.d_Combine_Stamp = "Y"

  useEffect(() => {
    (async () => {
      try {
        // get GsbFlag from SB.XBS
        const res = await gsb.post("/GsbFlag");
        //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
        //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
        //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
        GsbFlag = res.data.Flag;
        //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
        //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
        //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
        console.log("GsbFlag.user_key: ", GsbFlag.user_key);
        //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
        //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
        //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
        //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
        setCO_NAME(GsbFlag.CO_NAME);
        setcompanyLogoName(GsbFlag.companyLogoName);
        setSBPath(GsbFlag.SBPath);
        setTRAN_DATE(GsbFlag.TRAN_DATE);
        setSETL_DATE(GsbFlag.SETL_DATE);
        if (GsbFlag.isProduction == "Y") {
          setcardOpen01("cardOpen01-Theme" + GsbFlag.DEF_THEME1);
        } else {
          setcardOpen01("cardOpen01-Theme" + GsbFlag.DEF_THEME2);
        }

        //setSING_CURR(GsbFlag.SING_CURR) // 20230210
        setshowCurrency(GsbFlag.MULTI_BANK && !GsbFlag.SING_CURR);
      } catch (error) {
        utils.showError(error);
      }
    })();
  }, [gsb]);

  useEffect(() => {
    (async () => {
      try {
        const res = await gsb.post("/Master", {
          sb_data: "",
          cPgmId: "ExchangeRate",
          cAction: "GetCCYList",
        });

        console.log("MultiBank: ", res.data.data)
        CurrencyList2.push({ d_Currency: "", d_CcyDesc: "HKD" });
        for (let j = 0; j < res.data.data.length; j++) {
          CurrencyList2.push({
            d_Currency: res.data.data[j][0],
            d_CcyDesc: res.data.data[j][1],
          });
        }
        setCurrencyList(CurrencyList2);
        //console.log("set CurrencyList: ", CurrencyList)
      } catch (error) {
        utils.showError(error);
      }
    })();
  }, [gsb]);
  // -- end

  useEffect(() => {
    if (logviewonly === "Y") {
        setViewMode("L")
    }

    ;
    (async () => {
        try {
            if ( logKey.trim().length > 0 ) {
                const res = await gsb.post('/Master', {
                    sb_data: {
                        d_logKey: logKey,
                    }, 
                    cPgmId: "MultiBankMast", 
                    cAction: "ViewLogRec"
                })
                console.log("MultiBankMastMain ViewLogRec res.data.data", res.data.data)

                if (res.data.ok) {
                    setCurrentLog(res.data.data[0])
                } else {
                    utils.showWarn(res.data.data)
                }
            }
        } catch (error) {
            showErrorBox(error)
        }
    })()            
  }, [gsb, logKey])



  const PrevRecProcess = async (MultiBank: MultiBankInfo) => {
    try {
      //console.log("call PrevRecProcess", MultiBank)
      const res = await gsb.post("/Master", {
        sb_data: MultiBank,
        cPgmId: "MultiBankMast",
        cAction: "PrevRec",
      });
      //console.log("PrevRecProcess show 1: ", d_MktateCode.d_StkNo)
      //console.log("PrevRecProcess data : ", data)
      //console.log("PrevRecProcess res.data : ", res.data.data)
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_BankCode + data[i].d_BankName ===
          res.data.data.d_BankCode + res.data.data.d_BankName
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      //console.log("PrevRecProcess res.data.data : ", res.data.data)
      setcurrentMultiBank(res.data.data);
      //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
      setCurrentMode("show");
      //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
    } catch (error) {
      utils.showError(error);
    }
  };

  const DeleteProcess = async (MultiBank: MultiBankInfo) => {
    try {
      //console.log("call DeleteProcess", Mkt)
      const res = await gsb.post("/Master", {
        sb_data: MultiBank,
        cPgmId: "MultiBankMast",
        cAction: "DelRec",
      });
      showSuccess(
        "Bank Code " +
          MultiBank.d_BankCode +
          MultiBank.d_BankName +
          " record deleted"
      );
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_BankCode + data[i].d_BankName + data[i].d_Currency ===
          res.data.data.d_BankCode +
            res.data.data.d_BankName +
            res.data.data.d_Currency
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      setcurrentMultiBank(res.data.data);
      setCurrentMode("show");
    } catch (error) {
      utils.showError(error);
    }
  };

  const NextRecProcess = async (MultiBank: MultiBankInfo) => {
    try {
      //console.log("call NextRecProcess", MultiBank)
      const res = await gsb.post("/Master", {
        sb_data: MultiBank,
        cPgmId: "MultiBankMast",
        cAction: "NextRec",
      });
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_BankCode + data[i].d_BankName + data[i].d_Currency ===
          res.data.data.d_BankCode +
            res.data.data.d_BankName +
            res.data.data.d_Currency
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      setcurrentMultiBank(res.data.data);
      setCurrentMode("show");
    } catch (error) {
      utils.showError(error);
    }
  };

  const newOnSave = async (MultiBank: MultiBankInfo) => {
    try {
      //console.log("call newOnSave", MultiBank)
      const res = await gsb.post("/Master", {
        sb_data: MultiBank,
        cPgmId: "MultiBankMast",
        cAction: "AddRec",
      });
      showSuccess(
        "Bank Code " +
          MultiBank.d_BankCode +
          MultiBank.d_BankName +
          " record added"
      );
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_BankCode + data[i].d_BankName + data[i].d_Currency ===
          res.data.data.d_BankCode +
            res.data.data.d_BankName +
            res.data.data.d_Currency
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      setcurrentMultiBank(res.data.data);
      setCurrentMode("show");
    } catch (error) {
      //utils.showError(error)
      showErrorBox(error);
    }
  };

  const editOnSave = async (MultiBank: MultiBankInfo) => {
    try {
      console.log("call editOnSave", MultiBank);
      const res = await gsb.post("/Master", {
        sb_data: MultiBank,
        cPgmId: "MultiBankMast",
        cAction: "UpdateRec",
      });
      showSuccess(
        "Bank Code " +
          MultiBank.d_BankCode + "," +
          MultiBank.d_BankName + "," +
          MultiBank.d_Currency +
          " record saved"
      );
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].d_BankCode + data[i].d_BankName + data[i].d_Currency ===
          res.data.data.d_BankCode +
            res.data.data.d_BankName +
            res.data.data.d_Currency
        ) {
          data[i] = { ...data[i], ...res.data.data };
          setData(data);
          break;
        }
      }
      //console.log("editOnSave stk", MultiBank)
      //console.log("editOnSave res.data.data", res.data.data)
      setcurrentMultiBank(res.data.data);
      setCurrentMode("show");
    } catch (error) {
      //utils.showError(error)
      showErrorBox(error);
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (SING_CURR) {
      toaster.show({
        message:
          "Your SB Version have not Multi-Currency Module !  Please contact POP (Tel: 23918816) for more detail.",
        intent: Intent.WARNING,
      });
      return;
    }

    setShowWait(true);
    setnPage(1);
    let m_limit = searchLimit;
    if (selectedTabId === "detail") {
      m_limit = 1;
    }

    if (utils.getDeviceType()) {
      setMoblieDev(true);
    } else {
      setMoblieDev(false);
    }

    // get SmMast list if StkNo have value
    try {
      //console.log("SmMastMain onsubmit called", data)
      const res = await gsb.post("/Master", {
        sb_data: {
          by: searchKey,
          start: searchValue,
          nPage: 1,
          limit: m_limit,
          backward: searchBackward,
          filters: {
            Curency: searchCurrency,
          },
        },
        cPgmId: "MultiBankMast",
        cAction: "ListRec",
      });

      if (m_limit > 1) {
        setData(res.data.data);
      }
      if (res.data.data.length > 0) {
        setcurrentMultiBank(res.data.data[0]);
      }

      if (m_limit > 1) {
        if (res.data.count > 0) {
          const aPageNumber1 = [];
          if (res.data.count > 0) {
            setnTotalPage(Math.ceil(res.data.count / searchLimit));
            for (let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++) {
              aPageNumber1.push({ n: j });
            }
            setaPageNumber(aPageNumber1);
          }
        }
      }
      setShowWait(false);
    } catch (error) {
      utils.showError(error);
    }
  };

  // get one SmMast record if StkNo have value
  useEffect(() => {
    if (defaultSearchValue.length > 0) {
      // console.log("calling API for URL param");

      setShowWait(true);
      setnPage(1);
      if (nPage > 0) {
        //setcPrintMode("PS")
      }

      (async () => {
        //console.log("SmMastMain useEffect length > 0  called", data)
        const res = await gsb.post("/Master", {
          sb_data: {
            by: "MultiBankCode",
            start: defaultSearchValue,
            nPage: 1,
            limit: 1,
          },
          cPgmId: "MultiBankMast",
          cAction: "ListRec",
        });

        setData(res.data.data);
        if (res.data.data.length > 0) {
          setcurrentMultiBank(res.data.data[0]);
        }
        setShowWait(false);
      })();
    }
  }, [gsb, defaultSearchValue]);

  //Press page No 1 2 3 4
  useEffect(() => {
    if (nPage > 0 && nPage <= nTotalPage && selectedTabId == "list") {
      setShowWait(true);
      if (nPage > 0) {
        //setcPrintMode("S")
      }

      (async () => {
        try {
          //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
          const res = await gsb.post("/Master", {
            sb_data: {
              by: searchKey,
              start: searchValue,
              nPage: nPage,
              limit: searchLimit,
              backward: searchBackward,
              filters: {
                Curency: searchCurrency, 
              },
            },
            cPgmId: "MultiBankMast",
            cAction: "ListRec",
          });

          setData(res.data.data);
          if (res.data.data.length > 0) {
            setcurrentMultiBank(res.data.data[0]);
          }
          setShowWait(false);
        } catch (error) {
          utils.showError(error);
        }
      })();
    }
  }, [gsb, nPage, selectedTabId]);

  const showErrorBox = (err: any) => {
    alert(
      "Error:" +
        err.response.data.subSystem +
        " - " +
        err.response.data.description +
        ", OS code:" +
        err.response.data.osCode
    );
  };

  const showError = (err: any) => {
    if (err.response) {
      toaster.show({
        message: err.response.data.description,
        intent: Intent.WARNING,
      });
    } else if (err.request) {
      toaster.show({ message: "Request failed", intent: Intent.WARNING });
    } else {
      toaster.show({ message: err.message, intent: Intent.WARNING });
    }
  };

  const showSuccess = (message: string) => {
    toaster.show({ message: message, intent: Intent.SUCCESS });
  };

  // currentMode = show
  let detail = (
    <MultiBankMastView
      gsb={gsb}
      MultiBank={currentMultiBank}
      baseUrl={gsb.defaults.baseURL}
      token={localStorage.getItem("token")}
      onPrevRec={() => PrevRecProcess(currentMultiBank)}
      onNew={() => setCurrentMode("new")}
      onEdit={() => setCurrentMode("edit")}
      onDelete={() => DeleteProcess(currentMultiBank)}
      onNextRec={() => NextRecProcess(currentMultiBank)}
    ></MultiBankMastView>
  );
  switch (currentMode) {
    case "new":
      //console.log("currentMode = new")
      detail = (
        <MultiBankMastEdit
          gsb={gsb}
          MultiBank={BlankMultiBank}
          CurrencyList={CurrencyList}
          isNew
          onSave={newOnSave}
          onCancel={() => {
            setCurrentMode("show");
            setSelectedTabId("detail");
          }}
        ></MultiBankMastEdit>
      );
      break;
    case "edit":
      //console.log("currentMode = edit")
      detail = (
        <MultiBankMastEdit
          gsb={gsb}
          MultiBank={currentMultiBank}
          CurrencyList={CurrencyList}
          onSave={editOnSave}
          onCancel={() => setCurrentMode("show")}
        ></MultiBankMastEdit>
      );
      break;
  }

  function PageNo(n: number) {
    if (n > 0) {
      if (nPage + n <= nTotalPage) {
        setnPage(nPage + n);
      }
    } else {
      if (nPage + n > 0) {
        setnPage(nPage + n);
      }
    }
    return null;
  }

  let Pagination = (
    <>
      <div className="Row">
        <FormGroup className="Col-1-12" label="">
          Page
          {nTotalPage > 1 && (
            <Button minimal onClick={() => PageNo(-1)}>
              &laquo;
            </Button>
          )}
          {nTotalPage > 1 && nPage != 1 && (
            <Button minimal onClick={() => setnPage(1)}>
              {1}
            </Button>
          )}
          {aPageNumber.map(
            (no, j) =>
              j + 1 > nPage - 10 &&
              j + 1 < nPage &&
              j + 1 != 1 && (
                <Button minimal onClick={() => setnPage(no.n)}>
                  {no.n}
                </Button>
              )
          )}
          {nTotalPage >= 1 && <Button intent="primary">{nPage}</Button>}
          {aPageNumber.map(
            (no, j) =>
              j + 1 > nPage &&
              j + 1 < nPage + 10 &&
              j + 1 != nTotalPage && (
                <Button minimal onClick={() => setnPage(no.n)}>
                  {no.n}
                </Button>
              )
          )}
          {nTotalPage > 1 && nPage != nTotalPage && (
            <Button minimal onClick={() => setnPage(nTotalPage)}>
              {nTotalPage}
            </Button>
          )}
          {nTotalPage > 1 && (
            <Button minimal onClick={() => PageNo(+1)}>
              &raquo;
            </Button>
          )}
        </FormGroup>
      </div>
    </>
  );

  let list = (
    <>
      <div className="Row">
        {!SING_CURR && (
          <Button
            className="Col-1-1"
            icon="add"
            onClick={() => {
              setCurrentMode("new");
              setSelectedTabId("detail");
            }}
          >
            New
          </Button>
        )}
      </div>
      <div className="Spacing-V-16" />
      {ShowWait && <div>{imgWait}</div>}
      {nPage > 0 && Pagination}
      {/* display search list */}
      <HTMLTable striped interactive condensed>
        <thead>
          <th className="TCol-BankCode">Bank Code</th>
          <th className="TCol-BankName">Bank Name</th>
          <th className="TCol-BankDesc">Bank Description</th>
          {showCurrency && <th className="TCol-Currency">Currency</th>}
        </thead>
        <tbody id="ma_tr">
          {data.map((MultiBank, i) => (
            <tr
              key={i}
              onDoubleClick={() => {
                setSelectedTabId("detail");
              }}
            >
              <td
                className="TCol-BankCode"
                onClick={() => {
                  setcurrentMultiBank(MultiBank);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {MultiBank.d_BankCode}
              </td>
              <td
                className="TCol-BankName"
                onClick={() => {
                  setcurrentMultiBank(MultiBank);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {MultiBank.d_BankName}
              </td>
              <td
                className="TCol-BankDesc"
                onClick={() => {
                  setcurrentMultiBank(MultiBank);
                  MoblieDev && setSelectedTabId("detail");
                }}
              >
                {MultiBank.d_BankDesc}
              </td>
              {showCurrency && (
                <td
                  className="TCol-MultiBank"
                  onClick={() => {
                    setcurrentMultiBank(MultiBank);
                    MoblieDev && setSelectedTabId("detail");
                  }}
                >
                  {MultiBank.d_Currency}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      {ShowWait && nTotalPage > 0 && <div>{imgWait}</div>}
      {nPage > 0 && Pagination}
    </>
  );

  let CoHead = (
    <SystemHeader
      companyLogo={companyLogoName}
      companyName={CO_NAME}
      dataPath={SBPath}
      tradeDate={TRAN_DATE}
      settlementDate={SETL_DATE}
    ></SystemHeader>
  );
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="Row">
          <FormGroup className="Col-1-12">{CoHead}</FormGroup>
        </div>
        <Card
          className={cardOpen01}
          interactive={false}
          elevation={Elevation.THREE}
        >
          {SING_CURR && (
            <div>
              <div id="DispText">
                Your SB Version have not Multi-Currency Module !
              </div>
              <div id="DispText">
                Please contact POP (Tel: 23918816) for more detail.
              </div>
            </div>
          )}
          {/* define search key */}
          <H3>
            <div style={{ justifyContent: "flex-end" }}>
              Multiple Bank Master
            </div>
          </H3>
          <div className="Row">
            <FormGroup
              className="Col-1-5"
              label="Search for "
              labelFor="inputSearchValue"
            >
              <ControlGroup fill>
                <HTMLSelect
                  className={Classes.FIXED}
                  value={searchKey}
                  onChange={utils.handleStringChange((s) => setSearchKey(s))}
                >
                  <option value="MultiBankCode">Bank Code</option>
                </HTMLSelect>
                <InputGroup
                  id="inputSearchValue"
                  placeholder="Search"
                  value={searchValue}
                  onChange={utils.handleStringChange((s) => setSearchValue(s))}
                  leftIcon="search"
                  rightElement={
                    <Button
                      icon={IconNames.ARROW_RIGHT}
                      disabled={
                        selectedTabId === "detail" && !(searchKey == "MultiBankCode")
                      }
                      minimal
                      type="submit"
                    ></Button>
                  }
                />
              </ControlGroup>
            </FormGroup>
            <Button
              className="Col-6-1 Button-LabeledFormGroup"
              icon="search"
              disabled={selectedTabId === "detail" && !(searchKey == "MultiBankCode")}
              type="submit"
            >
            {(selectedTabId === "detail") ? "Search" : "List"}
            </Button>
            <Button
              className="Col-7-1 Button-LabeledFormGroup"
              icon="filter"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              Filters
            </Button>
          </div>
          <Collapse isOpen={showFilters}>
            <div className="Row">
              {/* define search filter */}
              <FormGroup
                className="Col3-1-5"
                label="Currency"
                labelFor="searchCurrency"
              >
                <InputGroup
                  id="searchCurrency"
                  value={searchCurrency}
                  onChange={utils.handleStringChange((s) =>
                    setsearchCurrency(s)
                  )}
                />
              </FormGroup>
            </div>
          </Collapse>
          <Tabs
            onChange={(newTabId) => setSelectedTabId(newTabId as string)}
            selectedTabId={selectedTabId}
          >
            <Tab id="list" title="List" panel={list} />
            <Tab id="detail" title="Detail" panel={detail} />
          </Tabs>
        </Card>

        <div className="Spacing-V-16"></div>
        <Alert
          confirmButtonText="OK"
          icon="tick-circle"
          intent={Intent.SUCCESS}
          isOpen={isShowAlert1}
          onCancel={() => setisShowAlert1(false)}
          onConfirm={() => {
            setShowAlert1Msg("");
            setisShowAlert1(false);
          }}
        >
          <p>{ShowAlert1Msg}</p>
        </Alert>
        <Alert
          confirmButtonText="OK"
          icon="cross"
          intent={Intent.WARNING}
          isOpen={isShowAlert2}
          onCancel={() => setisShowAlert2(false)}
          onConfirm={() => {
            setShowAlert2Msg("");
            setisShowAlert2(false);
          }}
        >
          <p>{ShowAlert2Msg}</p>
        </Alert>
      </form>
    </>
  );
}

export default MultiBankMastMain;
