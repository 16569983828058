import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { Checkbox } from "@blueprintjs/core";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout, ProgressBar } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useHistory } from "react-router-dom";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";
//import "./DividendProcess.scss"

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { ClientTranInfo, StkCodeInfo, AcCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';

interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}

var GsbFlag: any;

interface ClientInterestAccruedMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClientInterestAccruedMain({ gsb }: ClientInterestAccruedMainProps) {

    let ProgressId2 = ""
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    const history = useHistory()

//    const [Data, setData] = useState([] as ClientTranInfo[])
    const [searchKey, setSearchKey] = useState("StkNo")

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkNo, setsearchStkNo] = useState("")
    const [showButton, setshowButton] = useState(false)

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)
    
    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)  // 20230823

    const [s_StkNo1, sets_StkNo1] = useState("")
    const [s_InterestAccrued, sets_InterestAccrued] = useState("")  // 20230823
    const [DataFormatValue, setDataFormatValue] = useState(false)
    const [CSVFormat, setCSVFormat] = useState("0")
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)
    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentMode, setcurrentMode] = useState("show")


    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")
    const [dateFormat, setdateFormat] = useState("")

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ClientInterestAccruedMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ClientInterestAccruedMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClientInterestAccruedMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ClientInterestAccruedMain disp GsbFlag: ", GsbFlag)
                //console.log("ClientInterestAccruedMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ClientInterestAccruedMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("ClientInterestAccruedMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClientInterestAccruedMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ClientInterestAccruedMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
                setdateFormat(GsbFlag.dateFormat);
 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    // 20230823
    useEffect(() => {
        setShowRpt(false)
        setShowNoRecord(false)
        setShowOK(true)
    }, [s_StkNo1, s_InterestAccrued, DataFormatValue])


    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            //console.log("Progresslist ProgressId: ", ProgressId2) 
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 20*1000)
        return () => {clearInterval(timer)}
    }, [gsb, ProgressId, nPage])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowOK(false)
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptName("")
        setRptNameNo(0)
        setnPage(1)
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0)
         //console.log("SmMastMain onsubmit ProgressId: ", ProgressId2) 

         if ( nPage > 0 ) {
            //setcPrintMode("PS")
        }

        (async () => {   
            try {
                //console.log("ClientInterestAccrued onsubmit called", data)
                const res = await gsb.post('/Transaction', {
                    sb_data: {
                        ProgressId: ProgressId2,
                        s_StkNo1: s_StkNo1,
                        s_InterestAccrued: s_InterestAccrued,
                        DataFormat: DataFormatValue,
                        nPage: 1,
                        cPrintMode: "PS",
                    }, 
                    cPgmId: "ClientTranEntry",
                    cAction: "ClientInterestAccrued",
                })      
console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    setRptHead1(res.data.Rpthead1)
                    setRptName(res.data.RptName)
                    setRptNameNo(res.data.RptNameNo)
                    //console.log("RptName: ", RptName) 
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(res.data.TotalPageNo)
                        for ( let j = 1; j <= res.data.TotalPageNo; j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }
                    }
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        // -- end
    }    
    
//Press page No 1 2 3 4
useEffect(() => {
    if (nPage > 0 && nPage <= nTotalPage && RptName.trim().length > 0) {
        // console.log("calling API for Pagination");
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])

        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0)

        if ( nPage > 0 ) {
            //setcPrintMode("S")
        } 

        (async () => {
            try {
                //console.log("useEffect nPage > 0  ", nPage)
                const res = await gsb.post('/Transaction', {
                    sb_data: {
                        ProgressId: ProgressId2,
                        s_StkNo1: s_StkNo1,
                        s_InterestAccrued: s_InterestAccrued,
                        DataFormat: DataFormatValue,
                        nPage: nPage,
                        cPrintMode: "S",
                    }, 
                    cPgmId: "ClientTranEntry",
                    cAction: "ClientInterestAccrued",
                })      
                
                //console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    //console.log("Rstdata: ", Rstdata) 
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }
}, [gsb, nPage])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    let RptNameaRef = <>
    { RptNameNo > 0 && <div> Download / Print &nbsp;
        {aRptNameNo.map((no, j) => 
            <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}-${no.n}.pdf`}> <span id="RPageNo2" onClick={() => {} }> {no.n} &nbsp; </span> </a>
        )}
    </div> }
    { RptNameNo <= 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>}
    </>

    let list = <>
    <div className="Spacing-V-16" />
    {RptName.trim().length > 0 && RptNameaRef}
    {ShowRpt && ! DataFormatValue && Pagination}
    <HTMLTable striped interactive condensed>
        <thead id="rpt_th_r">
            <tr >
                { ! DataFormatValue && <th className="RCol-Head_p">
                    {RptName.trim().length > 0 && RptNameaRef}
                </th>
                }
            </tr>                    
            <tr>
                <td className="RCol-Head1">{RptHead1}</td>
            </tr>                    
        </thead>
        <tbody id="rpt_tr">
            {Rstdata.map((rpt, i) =>
                <tr key={i} onDoubleClick={() => { }}>
                    <td className="RCol-Rpt" onClick={() => {  }}>{rpt.Rst_Line}</td>
                </tr>
            )}
        </tbody>
        <div className="Spacing-V-16" />
        {ShowRpt && ! DataFormatValue && Pagination}
    </HTMLTable>
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>31V1 Setup Interest Accrued For Client Trades</H3>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup inline className="Col-1-5" label="Bond Stock No." labelFor="inputStkNo1">
                            <InputGroup
                                id="inputStkNo1"
                                autoFocus={true}
                                placeholder="Bond Stock No ..."
                                value={s_StkNo1}
                                onChange={utils.handleStringChange(s => sets_StkNo1(s))}
                            />
                        </FormGroup>
                        {/* <FormGroup className="Col-6-3">
                            <div id="DispText">{currentWithdrawal.d_StkCode1}</div>
                            <div id="DispText">{currentWithdrawal.d_StkName1}</div>
                        </FormGroup> */}
                    </div>
                    <div className="Row">
                        <FormGroup inline className="Col-1-3" label="Bond Accrued Interest (per lot)" labelFor="inputInterestAccrued">
                            <InputGroup className="inputNumberNoSpin" type="number"
                                id="inputInterestAccrued"
                                value={s_InterestAccrued}
                                onChange={utils.handleStringChange(s => sets_InterestAccrued(s))}
                            />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <Button disabled={! ShowOK} type="submit">OK</Button>
                    </div>
                </Card>
                {/* {ShowWait && <div>{imgWait}</div> } */}
                {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
                {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }

                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">No Record Updated !!!</span>
                        </FormGroup>
                    </div>
                </p>
                }
                {ShowRpt && list}
            </form>
        </>
    );
}

export default ClientInterestAccruedMain