import React, { useState, useEffect, FormEvent } from 'react';
import { FileInput, ProgressBar, Checkbox } from "@blueprintjs/core";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';
import * as utils from "./utils";

import svgWait1 from './svg/wait1.svg'
import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';
 
interface ImportClMast3MainProps {
    gsb: AxiosInstance
    currentFile?: File
}

interface FileInfo {
    fileName: string,
    select: string,
    size: number,
    type: string,
    contentDate: string,
    records: number,
    error: string,
    delete: string,
    clear: string
}

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


var GsbFlag: any;

function ImportClMast3Main({ gsb }: ImportClMast3MainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [fileInfoList, setFileInfoList] = useState([] as FileInfo[])
    const [file1Label, setFile1Label] = useState("Choose file...")
    const [file2Label, setFile2Label] = useState("Choose file...")
    const [file3Label, setFile3Label] = useState("Choose file...")
    const [importok, setimportok] = useState(false)
    const [nUploaded, setnUploaded] = useState(0)
        
    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))


    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    useEffect(() => {
        (async () => {
            try {

                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }


            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await gsb.post('/tasklist', taskList.map((t) => t.id));
                    setTaskList(res.data.data)
                    
                } catch (error) {
                    utils.showError(error)
                }
            }, 3+1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, gsb])

    const onUpload = (e: FormEvent) => {
        e.preventDefault();
        setnUploaded(0);

        if ( file1Label == "Choose file..."  || file1Label.length == 0) {
            utils.showError("Please choose a file"); 

            return
        } 
        //console.log("onUpload file1Label", file1Label)
        ;
        
        (async () => {
            try {
                const res = await gsb.post('/importupload2',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });


                
                setShowTasks(false)
                if ( res.data.uploaded > 0) {
                    setimportok(true)
                }
     
                setnUploaded(res.data.uploaded)
              
                utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }
 
    const onImportFiles = () => {

        if ( file1Label == "Choose file..."  || file1Label.length == 0) {
            utils.showError("Please choose a file"); 

            return
        } 
        //console.log("onImportFiles file1Label", file1Label)
        ;
        if ( file1Label.length > 0 ) {
    
            (async () => {
                    try {
                      
                        const res = await gsb.post('/import',
                                {   sb_data: {
                                    impfileName: file1Label
                                },
                                cPgmId: "ImportClMast3",
                            }); 
                        
                        if (res.data.ok ){
                            setTaskList(res.data.data)
                            setShowTasks(true)

                            setimportok(false)

                        } else {
                            if (! res.data.ok) {
                                for (let j = 0; j < res.data.data.length; j++) {
                                  
                                    utils.showError("error");
                                }
                                return
                            }

                        }
                       
                        
                    } catch (error) {
                        utils.showError(error)
                    }
                })()
        }else {

            utils.showError("Please choose a file"); 

        }
    }
    const [selectedFile, setSelectedFile] = React.useState<File | string>('fileurl');
   
 
    //const fileChangedHandler = (e : React.ChangeEvent<HTMLInputElement>) => {nUploaded(0));
 
    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>            
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
            
                <div className="Spacing-V-8"></div>
                <p><H3>21I Import Client Master from Text File</H3></p>
                <form className="Import" onSubmit={onUpload}>
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <FileInput className="Col-1-3" inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                    </div>

                    <div className="Spacing-V-8"></div>
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-1" type="submit" icon={IconNames.UPLOAD}>Upload</Button>               
                        <Button disabled= {! importok} className="Col-2-1" icon={IconNames.IMPORT} onClick={onImportFiles}>Process</Button>
                    </div>
                </form>

                <div className="Spacing-V-8"></div>
            
                <div className="Spacing-V-16"></div>
                <Collapse isOpen={showTasks}>
                    <p>Import Files</p>
                    <HTMLTable striped interactive condensed>
                        <thead>
                            <th className="TCol-Ref">File Name</th>
                            <th className="TCol-description">description</th>
                            <th className="TCol-Progress">Progress</th>
                            <th className="TCol-Message">Message</th>
                            <th className="TCol-Log">Log</th>
                        </thead>
                        <tbody>
                            {taskList.map((t, i) =>
                                <tr key={i}>
                                    <td className="TCol-Ref">{t.ref}</td>
                                    <td className="TCol-Ref">{t.description}</td>                                
                                    <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                    <td className="TCol-Message">{t.message}</td>
                                    <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                                </tr>
                            )}
                        </tbody>
                    </HTMLTable>
                    <div className="Spacing-V-16"></div>
                </Collapse>
            </Card>
        </>
    )
}

export default ImportClMast3Main