import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { PRTranInfo, AcCodeInfo, CurrencyInfo, PRCodeInfo } from './Types'
import { SystemHeader, ClientPicker } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "T0007" ;
var isReqAuth2:boolean = false ;
var isReqValueLimitAuth2:boolean = false ;

var LastDescValue = "" ;

interface PayRecTranEntryViewProps {
    gsb: AxiosInstance,
    PayRec: PRTranInfo,
    PayRec0: PRTranInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}


function PayRecTranEntryView({ gsb, PayRec = {} as PRTranInfo, PayRec0 = {} as PRTranInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: PayRecTranEntryViewProps) {

    const { t, i18n } = useTranslation();    
    
    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)
    const [isCanDelete, setisCanDelete] = useState(true)
    const [isDeletePress2, setisDeletePress2] = useState(false)

    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)
    const [MultiBankNameValue, setMultiBankNameValue] = useState("")
    const [PrCodeDescValue, setPrCodeDescValue] = useState("")


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("PayRecTranEntryView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                        setisCanDelete(false)
                        if ( res.data.data[0].d_Can_DeleteMaster == "Y" ) {
                            setisCanDelete(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, PayRec])

    const onReqAuthDelRec = () => {
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: AuthUser,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )
                if (res2.data.ok) {
                    setAuthUser("")
                    setPassword("")

                    const res = await gsb.post('/Master', {
                        sb_data: {
                            userId: AuthUser,
                            password: password,
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "ReqUserGroupAccessReqAuth"
                    })
                    console.log("onReqAuthDelRec res.data.data", res.data.data);
                    if ( res.data.ok && res.data.data[0].d_Can_DeleteMaster == "Y") {
                        onDelete();
                        
                    } else {
                        utils.showWarn("Authorization failed")
                        setisShowAlert2(true)
                        setShowAlert2Msg("Authorization failed")
                    }

                } else {
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }



    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("PayRecTranEntryView PayRec.d_acCode: ", PayRec.d_acCode) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACBalInfo", cAcCode: PayRec.d_acCode, CCY: PayRec.d_Currency})
                //console.log("PayRecTranEntryView GetACInfo data : ", res.data.data) 
                //console.log("PayRecTranEntryView GetACInfo  count: ", res.data.count) 
                
                if ( PayRec.d_acCode.trim().length > 0 && res.data.count > 0 )
                {
                    setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    setLatestBalValue(res.data.data[0].acbal_LatestBal) 
                    setUnClrChqValue(res.data.data[0].acbal_UnClrChq) 
                } else {
                    setPrevDayValue(0)
                    setLatestBalValue(0)
                    setUnClrChqValue(0)
                }
           
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, PayRec.d_acCode, PayRec.d_Currency, PayRec.d_Amount])

    useEffect(() => {
        (async () => {
            if (GsbFlag.MULTI_BANK) {
                try {
                    // get GetMultiBank Info
                    //console.log("PayRecTranEntryView GetMultiBankInfo: ", PayRec.d_Bank_Code, PayRec.d_Currency) 
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "MultiBankMast", cAction: "GetMultiBankInfo", cBankCode: PayRec.d_Bank_Code, CCY: PayRec.d_Currency})
                    //console.log("PayRecTranEntryView GetMultiBankInfo data : ", res.data.data) 
                    //console.log("PayRecTranEntryView GetACInfo  count: ", res.data.count) 
                    
                    if ( PayRec.d_Bank_Code.trim().length > 0 && res.data.count > 0 )
                    {
                        setMultiBankNameValue(res.data.data[0].d_BankName) 
                    } else {
                        setMultiBankNameValue("Undefined") 
                    }
                    
                } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()

    }, [gsb, PayRec.d_Bank_Code, PayRec.d_Currency])

    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key == "CHEERFUL") {
                try {
                    // get GetPRCode Info
                    //console.log("PayRecTranEntryView GetPRCodeInfo: ", PayRec.d_PR_Code) 
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PrCodeMast", cAction: "GetPRCodeInfo", cPRCode: PayRec.d_PR_Code})
                    //console.log("PayRecTranEntryView GetPRCodeInfo data : ", res.data.data) 
                    //console.log("PayRecTranEntryView GetACInfo  count: ", res.data.count) 
                    
                    if ( PayRec.d_PR_Code.trim().length > 0 && res.data.count > 0 )
                    {
                        setPrCodeDescValue(res.data.data[0].d_PR_Desc) 
                    } else {
                        setPrCodeDescValue("Undefined") 
                    }
                    
                } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()

    }, [gsb, PayRec.d_PR_Code])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    if (! ("d_Seq" in PayRec)) return null


    return (
        <div>
            {/* define add,change,delete button */}
            { ViewMode === "V" && <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                { isCanDelete && <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button> }
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                { ! isCanDelete && <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress2(true) } >Delete</Button> }
                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isDeletePress2}
                    onCancel={ () => setisDeletePress2(false) }
                    onConfirm={ () => { onReqAuthDelRec(); setisDeletePress2(false) } }
                >
                    <div>
                        <div>Authorization Required to Delete</div>
                        <div>Please Input User & Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="User" labelFor="inputAuthUser">
                        <InputGroup id="inputAuthUser" autoFocus value={AuthUser} onChange={utils.handleStringChange(s => setAuthUser(s))} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                </Alert>

                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                <Button className="Col-9-2" icon="document" onClick={() => { window.open(`/#/ClientPosEnquiry?acCode=${PayRec.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
            </div> }
            { ViewMode === "L" && <div>
                Account No. : {PayRec.d_acCode}, {PayRec.d_Tran_type} 
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No.">
                    <InputGroup readOnly value={utils.formatNumber2(PayRec.d_Seq)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Seq.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(PayRec0.d_Seq)} </div> }
                </FormGroup>
                <FormGroup className="Col3-2-1" label="Tran Type">
                    <InputGroup readOnly value={PayRec.d_Tran_type} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Tran_type.toString() == "") && 
                    <div id="DispText2" > {PayRec0.d_Tran_type} </div> }
                </FormGroup>
                <FormGroup className="Col-3-1" label="Tran Date" labelFor="inputTranDate">
                    <InputGroup readOnly value= {utils.dispDate(PayRec.d_Tran_Date, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Tran_Date.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(PayRec0.d_Tran_Date, GsbFlag.dateFormat)} </div> }
                </FormGroup>
                <FormGroup className="Col-4-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup readOnly value={PayRec.d_Input_User} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Input_User.toString() == "") && 
                    <div id="DispText2" > {PayRec0.d_Input_User} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputacCode">
                    <InputGroup readOnly value={PayRec.d_acCode} />
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name">
                    {/* 
                    <InputGroup readOnly value={PayRec.d_acName1} />
                    <InputGroup readOnly value={PayRec.d_acName2} />
                     */}
                    <div>
                    <div id="DispText">{PayRec.d_acName1}</div>
                    <div id="DispText">{PayRec.d_acName2}</div>
                    </div>
                </FormGroup>
                { ViewMode === "V" && <FormGroup className="Col-6-3" label="Outstanding Balance">
                    <div>
                    <div id="DispText">Prev. Day : {utils.formatNumber2(PrevDayValue)}</div>
                    <div id="DispText">   Latest : {utils.formatNumber2(LatestBalValue)}</div>
                    <div id="DispText">Unclr Chq : {utils.formatNumber2(UnClrChqValue)}</div>
                    </div>
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="SI TRAN,Good Receipt" labelFor="inputGoodReceipt">
                    <RadioGroup
                        name="d_Good_Receipt"
                        disabled={true}
                        inline={true}
                        selectedValue={PayRec.d_Good_Receipt}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Good_Receipt.toString() == "") && 
                    <div id="DispText2" > {PayRec0.d_Good_Receipt} </div> }
                </FormGroup>
                {(GsbFlag.POSTCU_OPT) &&
                    <FormGroup className="Col-3-2" label="Require Settlement" labelFor="inputReqSettle">
                        <RadioGroup
                            name="d_Req_Settle"
                            disabled={true}
                            inline={true}
                            selectedValue={PayRec.d_Req_Settle}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Req_Settle.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_Req_Settle} </div> }
                    </FormGroup>
                }
                {(GsbFlag.user_key == "CHEERFUL") &&
                    <FormGroup className="Col3-5-2" label="Statement Code" labelFor="inputPR_Code" labelInfo="">
                        <InputGroup readOnly value={PayRec.d_PR_Code} />
                        <div>
                        <div id="DispText">{PrCodeDescValue}</div>
                        </div>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_PR_Code.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_PR_Code} </div> }
                    </FormGroup>
                }
            </div>
            {! (GsbFlag.user_key == "CHEERFUL") && <div className="Row">
                <FormGroup className="Col-1-2" label="Settle Interest" labelFor="inputSettleInt">
                    <RadioGroup
                        name="d_Settle_Int"
                        disabled={true}
                        inline={true}
                        selectedValue={PayRec.d_Settle_Int}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Settle_Int.toString() == "") && 
                    <div id="DispText2" > {PayRec0.d_Settle_Int} </div> }
                </FormGroup>
            </div> }
            {(GsbFlag.user_key == "CHEERFUL") && <div className="Row">
                <FormGroup className="Col-1-2" label="Post to Interest" labelFor="inputSettleInt">
                    <RadioGroup
                        name="d_Settle_Int"
                        disabled={true}
                        inline={true}
                        selectedValue={PayRec.d_Settle_Int}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Settle_Int.toString() == "") && 
                    <div id="DispText2" > {PayRec0.d_Settle_Int} </div> }
                </FormGroup>
            </div> }
            <div className="Row">
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                    <FormGroup className="Col3-1-1" label="Currency" labelFor="inputCurrency">
                        <InputGroup readOnly value={PayRec.d_Currency} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Currency.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_Currency} </div> }
                    </FormGroup>
                }
                <FormGroup className="Col-2-2" label="Amount" labelFor="inputAmount">
                    <InputGroup readOnly value={utils.formatNumber2(PayRec.d_Amount)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Amount.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(PayRec0.d_Amount)} </div> }
                </FormGroup>
                {(GsbFlag.MULTI_BANK) &&
                    <FormGroup className="Col-4-1" label="Bank Code" labelFor="inputBankCode">
                        <InputGroup readOnly value={PayRec.d_Bank_Code} />
                        <div>
                        <div id="DispText">{MultiBankNameValue}</div>
                        </div>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Bank_Code.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_Bank_Code} </div> }
                    </FormGroup>
                }
                { PayRec.d_Authority_Limit > 0 && <FormGroup className="Col-5-2" label="." labelFor="">
                    <div id="DispText">** OVER VALUE LIMIT **</div>
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label="Description" labelFor="inputDescription">
                    <InputGroup readOnly value={PayRec.d_Description} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Description.toString() == "") && 
                    <div id="DispText2" > {PayRec0.d_Description} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                { ! ( PayRec.d_Tran_type === "A" ) &&
                    <FormGroup className="Col3-1-2" label="CRS & FATCA Payment Type" labelFor="inputCrsPaymentType">
                        <InputGroup readOnly value={PayRec.d_Crs_Payment_Type} />
                        {PayRec.d_Crs_Payment_Type=="501" && <span >CRS501 :  Dividends</span> }
                        {PayRec.d_Crs_Payment_Type=="502" && <span >CRS502 :  Interest</span> }
                        {PayRec.d_Crs_Payment_Type=="503" && <span >CRS503 :  Gross Proceeds from Sales\Redemption of Assets</span> }
                        {PayRec.d_Crs_Payment_Type=="504" && <span >CRS504 :  Other Income</span> }
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Crs_Payment_Type.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_Crs_Payment_Type} </div> }
                    </FormGroup>
                }
            </div>
            {(GsbFlag.PAYQ_USER) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Print Cheque" labelFor="inputPrintChq">
                        <RadioGroup
                            name="d_PrintChq"
                            disabled={true}
                            inline={true}
                            selectedValue={PayRec.d_PrintChq}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_PrintChq.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_PrintChq} </div> }
                    </FormGroup>
                    <FormGroup className="Col-3-1" label="Bank" labelFor="inputBank">
                        <InputGroup readOnly value={PayRec.d_Bank} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Bank.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_Bank} </div> }
                    </FormGroup>
                    <FormGroup className="Col-4-1" label="Cheque No" labelFor="inputChqNo">
                        <InputGroup readOnly value={PayRec.d_Chq_No} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Chq_No.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_Chq_No} </div> }
                    </FormGroup>
                    {(GsbFlag.CHQ_TO_BANK || GsbFlag.CCHQ_FILE || GsbFlag.DIVCHQ_TO_BANK) && <FormGroup className="Col-5-1" label='Autopay Code' labelFor="inputAutopay_BankCode">
                        <InputGroup readOnly value={PayRec.d_Autopay_BankCode} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (PayRec0.d_Autopay_BankCode.toString() == "") && 
                        <div id="DispText2" > {PayRec0.d_Autopay_BankCode} </div> }
                    </FormGroup> }
                </div>
            }
            <div>
                <div className="Spacing-V-16"></div>
                <div >Note : </div>
                <div >SI TRAN,Good Receipt : No = If Received Cheque </div>
            </div>
            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>

        </div>
    )
}

interface PayRecTranEntryEditProps {
    gsb: AxiosInstance,
    PayRec?: PRTranInfo,
    CurrencyList?: CurrencyInfo[],
    isNew?: boolean,
    onSave?: (stk: PRTranInfo) => void,
    onCancel?: () => void;
}

function PayRecTranEntryEdit({ gsb, PayRec = {} as PRTranInfo, CurrencyList=[] as CurrencyInfo[], isNew = false, onSave = () => { }, onCancel = () => { } }: PayRecTranEntryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<PRTranInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    

    const [AcCodeValue, setAcCodeValue] = useState(PayRec.d_acCode)
    const [setClCodeValue, setSetClCodeValue] = useState(() => setAcCodeValue)
    const [isClPickerOpen, setIsClPickerOpen] = useState(false)
    const [AcCodeList, setAcCodeList] = useState([] as AcCodeInfo[])
    const [AcName1Value, setAcName1Value] = useState(PayRec.d_acName1)
    const [AcName2Value, setAcName2Value] = useState(PayRec.d_acName2)
    const [CurrencyValue, setCurrencyValue] = useState(PayRec.d_Currency)
    const [AmountValue, setAmountValue] = useState(utils.numToString(PayRec.d_Amount))
    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)
    const [MultiBankNameValue, setMultiBankNameValue] = useState("")
    const [PrCodeDescValue, setPrCodeDescValue] = useState("")


    const [TranTypeValue, setTranTypeValue] = useState(PayRec.d_Tran_type)
    const [GoodReceiptValue, setGoodReceiptValue] = useState(PayRec.d_Good_Receipt)
    const [ReqSettleValue, setReqSettleValue] = useState(PayRec.d_Req_Settle)
    const [SettleIntValue, setSettleIntValue] = useState(PayRec.d_Settle_Int)
    const [PrintChqValue, setPrintChqValue] = useState(PayRec.d_PrintChq)
    const [DescValue, setDescValue] = useState(PayRec.d_Description)
    const [PRCodeValue, setPRCodeValue] = useState(PayRec.d_PR_Code)
    const [MBankCodeValue, setMBankCodeValue] = useState(PayRec.d_Bank_Code)
    const [AutopayBankCodeValue, setAutopayBankCodeValue] = useState(PayRec.d_Autopay_BankCode)
    const [PRCodeList, setPRCodeList] = useState([] as PRCodeInfo[])    // 20230329
    
    let o_acct = PayRec.d_acCode.trim()
    let o_type = PayRec.d_Tran_type
    let o_amt = PayRec.d_Amount


    useEffect(() => {
        // Call reset to get the values of record PRTranInfo 
        if ("d_Seq" in PayRec) {
            reset(PayRec)
        }
    }, [PayRec, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        register({ name: "d_Bank_Code" })
        register({ name: "d_Currency" })
    }, [register])

    
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


/*    
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const dataIP = await response.json();
                setIPAddr(dataIP["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })() 
    }, [gsb])
*/

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("PayRecTranEntryEdit PayRec.d_acCode: ", PayRec.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACBalInfo", cAcCode: AcCodeValue, CCY: CurrencyValue})
                //console.log("PayRecTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("PayRecTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    setLatestBalValue(res.data.data[0].acbal_LatestBal) 
                    setUnClrChqValue(res.data.data[0].acbal_UnClrChq) 
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    if ( isNew ) {
                        setAutopayBankCodeValue(res.data.data[0].d_Autopay_BankCode)
                    }

                    let LatestBalValue2 = res.data.data[0].acbal_LatestBal
                    //console.log("PayRecTranEntryEdit o_acct: ", o_acct, o_amt) 
                    if ( o_acct === AcCodeValue.trim()  &&  o_acct.length > 0 ) {
                        if ( o_type === "P" ) {
                            LatestBalValue2 = LatestBalValue2 + o_amt
                        } else if ( o_type === "R" ){
                            LatestBalValue2 = LatestBalValue2 - o_amt
                        }
                    }
                    if ( TranTypeValue === "P" ) {
                        LatestBalValue2 = LatestBalValue2 - utils.StringToNum(AmountValue)
                    } else if ( TranTypeValue === "R" ){
                        LatestBalValue2 = LatestBalValue2 + utils.StringToNum(AmountValue)
                    }

                    //console.log("PayRecTranEntryEdit LatestBalValue2: ", LatestBalValue2, AmountValue) 
                    setLatestBalValue(LatestBalValue2)

                } else { 
                    setPrevDayValue(0)
                    setLatestBalValue(0)
                    setUnClrChqValue(0)
                    setAcName1Value("")
                    setAcName2Value("")
                    setAutopayBankCodeValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue, TranTypeValue, CurrencyValue, AmountValue])

    useEffect(() => {
        (async () => {
            if (GsbFlag.MULTI_BANK) {
                try {
                    // get GetMultiBank Info
                    //console.log("PayRecTranEntryView GetMultiBankInfo: ", PayRec.d_Bank_Code, PayRec.d_Currency) 
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "MultiBankMast", cAction: "GetMultiBankInfo", cBankCode: MBankCodeValue, CCY: CurrencyValue})
                    //console.log("PayRecTranEntryView GetMultiBankInfo data : ", res.data.data) 
                    //console.log("PayRecTranEntryView GetACInfo  count: ", res.data.count) 
                    
                    if ( MBankCodeValue.trim().length > 0 && res.data.count > 0 )
                    {
                        setMultiBankNameValue(res.data.data[0].d_BankName) 
                    } else {
                        setMultiBankNameValue("Undefined") 
                    }
                    
                } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()

    }, [gsb, MBankCodeValue, CurrencyValue])

    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key == "CHEERFUL") {
                try {
                    // get GetPRCode Info
                    //console.log("PayRecTranEntryView GetPRCodeInfo: ", PRCodeValue) 
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PrCodeMast", cAction: "GetPRCodeInfo", cPRCode: PRCodeValue})
                    //console.log("PayRecTranEntryView GetPRCodeInfo data : ", res.data.data) 
                    //console.log("PayRecTranEntryView GetACInfo  count: ", res.data.count) 
                    
                    if ( PRCodeValue.trim().length > 0 && res.data.count > 0 )
                    {
                        setPrCodeDescValue(res.data.data[0].d_PR_Desc) 
                    } else {
                        setPrCodeDescValue("Undefined") 
                    }
                    
                } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()

    }, [gsb, PRCodeValue])

    const PRCodeRenderer: ItemRenderer<PRCodeInfo> = (PRCodeList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${PRCodeList.d_PR_Desc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={PRCodeList.d_PR_Code}
                key={PRCodeList.d_PR_Desc}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const PRCodePredicate: ItemPredicate<PRCodeInfo> = (query, PRCodeList, _index, exactMatch) => {
        const normalizedText = `${PRCodeList.d_PR_Code} - ${PRCodeList.d_PR_Desc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const PRCodeValueRenderer = (PRCodeList: PRCodeInfo) => {
        return PRCodeList.d_PR_Code
    }

    useEffect(() => {
        (async () => {
            try {
                // get AC List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "acCode",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false,
                    SortByName: true
                },
                cPgmId: "Clmast",
                cAction: "ListRec",
            });

                //console.log("res.data.data: ", res.data.data) 
                //setAcCodeList(utils.sortByColumn(res.data.data, "d_acName1"))
                setAcCodeList(res.data.data)
                //console.log("set AcCodeList: ", AcCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key == "CHEERFUL") {
                try {
                    // get PRCode List (Statement Code List)
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PrCodeMast", cAction: "GetPRCodeList"})
                    //  console.log("PRCodeList: ", res.data.data) 
                    setPRCodeList(res.data.data)
                    //console.log("set PCCodeList: ", PRCodeList) 
                } catch (error) {
                    utils.showError(error)
                }
            }
        })()
    }, [gsb])


    if (! isNew && ! ("d_Seq" in PayRec)) return null


    let DescLength = 45
    if ( GsbFlag.LONG_DESC ) {
        DescLength = 60
    }
    
    const onSubmit = (data: PRTranInfo) => {

        //console.log("PayRecTranEntryedit onsubmit data: ", data)
        //console.log("PayRecTranEntryedit onsubmit StkPrice: ", StkPrice)
        //console.log("PayRecTranEntryedit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        // put value to json/field before write database dbf
        if ( typeof(data.d_Seq) === "undefined") {
            data.d_Seq = 0
        }

        data.d_Tran_type = TranTypeValue;
        data.d_Tran_type = data.d_Tran_type.toUpperCase();
//console.log("PayRecTranEntryedit onsubmit data.d_Tran_type: ", data.d_Tran_type)
        data.d_Amount = utils.StringToNum(AmountValue)
        if (data.d_Tran_type === "A" ) {
            data.d_Amount = 0
            data.d_Crs_Payment_Type = " "
        }

        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        data.d_acCode = data.d_acCode.toUpperCase();
        data.d_acName1 = AcName1Value
        data.d_acName2 = AcName2Value

        if ( typeof(data.d_Bank_Code) === "undefined" ) {
            data.d_Bank_Code = ""
        }
 
        data.d_Currency = CurrencyValue
        if ( typeof(data.d_Currency) === "undefined" ) {
            data.d_Currency = ""
        }
        data.d_Currency = data.d_Currency.toUpperCase();

        if ( GsbFlag.MULTI_BANK ) {
            data.d_Bank_Code = MBankCodeValue;
            data.d_Bank_Code = data.d_Bank_Code.toUpperCase();
        }

        if ( GsbFlag.CHQ_TO_BANK ) {
            data.d_Autopay_BankCode = AutopayBankCodeValue;
            data.d_Autopay_BankCode = data.d_Autopay_BankCode.toUpperCase();
        }


        data.d_Good_Receipt = GoodReceiptValue
        data.d_Req_Settle = ReqSettleValue
        data.d_Settle_Int = SettleIntValue
        data.d_PrintChq = PrintChqValue

        data.d_Description = DescValue;
        
        if (GsbFlag.user_key == "CHEERFUL") {
            data.d_PR_Code = PRCodeValue;
            data.d_PR_Code = data.d_PR_Code.toUpperCase();
        }
        
        data.d_AuthTran = ""
        data.d_Auth_Value_Limit_tran = ""

        ;

        (async () => {
            try {
                //console.log("call PayRecTranEntryedit data", data)
                //console.log("call PayRecTranEntryedit isNew", isNew)
                const res = await gsb.post('/Transaction', {sb_data: data, cPgmId: "PayRecTranEntry", cAction: "PayRecValidate", isNew: isNew})
                //console.log("PayRecTranEntryedit res.data.ok", res.data.ok)
                //console.log("PayRecTranEntryedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }



    const acRenderer: ItemRenderer<AcCodeInfo> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<AcCodeInfo> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: AcCodeInfo) => {
        return ac.d_acCode
    }

    
    const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (CurrencyList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CurrencyList.d_CcyDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CurrencyList.d_Currency}
                key={CurrencyList.d_Currency}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (query, CurrencyList, _index, exactMatch) => {
        const normalizedText = `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
        return CurrencyList.d_Currency
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    function showdefDesc() {
        //console.log("showdefDesc :", DescValue, TranTypeValue, AmountValue, isNew, LastDescValue)
/*        
        if ( DescValue.trim().length == 0 && ( TranTypeValue === "P" ||TranTypeValue === "R" )) {
            if ( TranTypeValue.trim().length > 0 && AmountValue > 0 ) {
                let M_DESC = ""
                if ( TranTypeValue === "P" ) {
                    M_DESC = "PAYMENT"
                } else {
                    M_DESC = "DEPOSIT"
                }
                M_DESC = M_DESC + " " + AmountValue.toString().trim()
                setDescValue(M_DESC)
            }
        }
*/      
        if ( isNew ) {
            if ( DescValue.trim().length == 0 ) { 
                setDescValue(LastDescValue)
            }
            
        }
    }


    function showdefPRCode() {
        //console.log("PRCodeValue :", PRCodeValue, TranTypeValue,)
        
        if ( PRCodeValue.trim().length == 0 && ( TranTypeValue === "P" ||TranTypeValue === "R" )) {
            if ( TranTypeValue.trim().length > 0 ) {
                let M_DESC = ""
                if ( TranTypeValue === "P" ) {
                    M_DESC = "CO"
                } else {
                    M_DESC = "CI"
                }
                M_DESC = M_DESC
                setPRCodeValue(M_DESC)
            }
        }
        
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <ClientPicker gsb={gsb} isOpen={isClPickerOpen} onClose={()=>setIsClPickerOpen(false)} onSelect={(ac)=>{setClCodeValue(ac.d_acCode); setIsClPickerOpen(false)}}/>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }

             </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No." labelFor="inputSeqNo" labelInfo="*">
                    <InputGroup className="inputNumberNoSpin" disabled={true} id="inputSeqNo" name="d_Seq" autoFocus={isNew} defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Seq && errors.d_Seq.type === "required" && (<span id="Err_msg">Seq No. cannot be Blank</span>)}
                    {errors.d_Seq && errors.d_Seq.types && <span id="Err_msg">{errors.d_Seq.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col3-2-1" label="Tran Type" labelFor="inputTranType" labelInfo="*">
                    {/*
                    <InputGroup id="inputTranType" name="d_Tran_type" autoFocus={! isNew} defaultValue="" placeholder="Tran Type ..." inputRef={register({ required: true , maxLength: 1 }) } />
                    {errors.d_Tran_type && errors.d_Tran_type.type === "required" && <span id="Err_msg">Tran Type cannot be Blank</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.types && <span id="Err_msg">{errors.d_Tran_type.types.message}</span>}
                    */}
                    <select  id="inputTranType"  
                        value={TranTypeValue}
                        autoFocus
                        onChange={utils.handleStringChange(s => setTranTypeValue(s)) }
                    >
                        <option value="A">A: Announcement</option>
                        <option value="P">P: Payment</option>
                        <option value="R">R: Receipt</option>
                    </select>
                </FormGroup>
                <FormGroup className="Col-3-1" label="Tran Date" labelFor="inputTranDate">
                    <InputGroup disabled={true} id="inputTranDate" name="d_Tran_Date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_Tran_Date && errors.d_Tran_Date.types && <span id="Err_msg">{errors.d_Tran_Date.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup disabled={true} id="inputInputUser" name="d_Input_User" defaultValue="" placeholder="Input User ..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_Input_User && errors.d_Input_User.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_Input_User && errors.d_Input_User.types && <span id="Err_msg">{errors.d_Input_User.types.message}</span>}
                </FormGroup>
             </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputacCode" labelInfo="*">
                    <InputGroup id="inputacCode" value={AcCodeValue} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s)) } inputRef={register({ required: true , maxLength: 8}) } 
                        rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>setAcCodeValue); setIsClPickerOpen(true)}}></Button>}
                    />
                    {errors.d_acCode && errors.d_acCode.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name" labelFor="inputAcName1">
                    {/*
                    <InputGroup disabled={true} id="inputAcName1" name="d_acName1" autoFocus={! isNew} defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    <InputGroup disabled={true} id="inputAcName2" name="d_acName2" defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                    {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                    {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    */}
                    <div>
                    <div id="DispText">{AcName1Value}</div>
                    <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-6-3" label="Outstanding Balance">
                    <div>
                    <div id="DispText">Prev. Day : {utils.formatNumber2(PrevDayValue)}</div>
                    <div id="DispText">   Latest : {utils.formatNumber2(LatestBalValue)}</div>
                    <div id="DispText">Unclr Chq : {utils.formatNumber2(UnClrChqValue)}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="SI TRAN,Good Receipt" labelFor="inputGoodReceipt">
                    <RadioGroup
                        name="d_Good_Receipt"
                        inline={true}
                        selectedValue={GoodReceiptValue}
                        onChange={utils.handleStringChange(s => setGoodReceiptValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Good_Receipt && errors.d_Good_Receipt.types && <span id="Err_msg">{errors.d_Good_Receipt.types.message}</span>}
                </FormGroup>
                {(GsbFlag.POSTCU_OPT) &&
                    <FormGroup className="Col-3-2" label="Require Settlement" labelFor="inputReqSettle">
                        <RadioGroup
                            name="d_Req_Settle"
                            inline={true}
                            selectedValue={ReqSettleValue}
                            onChange={utils.handleStringChange(s => setReqSettleValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_Req_Settle && errors.d_Req_Settle.types && <span id="Err_msg">{errors.d_Req_Settle.types.message}</span>}
                    </FormGroup>
                }
                 {(GsbFlag.user_key == "CHEERFUL") && 
                <FormGroup className="Col3-5-2" label="Statement Code" labelFor="inputPRCode">
                    <Suggest2
                            inputProps={{ placeholder: "Statement ..." }}
                            defaultSelectedItem={PRCodeList.find((PRCode) => PRCode.d_PR_Code === PayRec.d_PR_Code)}
                            items={PRCodeList}
                            itemRenderer={PRCodeRenderer}
                            itemPredicate={PRCodePredicate}
                            inputValueRenderer={PRCodeValueRenderer}
                            onItemSelect={(PRCodeList) => { setPRCodeValue(PRCodeList.d_PR_Code) }}
                        />
                    {errors.d_PR_Code && errors.d_PR_Code.types && <span id="Err_msg">{errors.d_PR_Code.types.message}</span>}
                </FormGroup>
                }
            </div>
            {! (GsbFlag.user_key == "CHEERFUL") && <div className="Row">
                <FormGroup className="Col-1-2" label="Settle Interest" labelFor="inputSettleInt">
                    <RadioGroup
                        name="d_Settle_Int"
                        inline={true}
                        selectedValue={SettleIntValue}
                        onChange={utils.handleStringChange(s => setSettleIntValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Settle_Int && errors.d_Settle_Int.types && <span id="Err_msg">{errors.d_Settle_Int.types.message}</span>}
                </FormGroup>
            </div> }
            {(GsbFlag.user_key == "CHEERFUL") && <div className="Row">
                <FormGroup className="Col-1-2" label="Post to Interest" labelFor="inputSettleInt">
                    <RadioGroup
                        name="d_Settle_Int"
                        inline={true}
                        selectedValue={SettleIntValue}
                        onChange={utils.handleStringChange(s => setSettleIntValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Settle_Int && errors.d_Settle_Int.types && <span id="Err_msg">{errors.d_Settle_Int.types.message}</span>}
                </FormGroup>
            </div> }
            <div className="Row">
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                    <FormGroup className="Col3-1-1" label="Currency" labelFor="inputCurrency" labelInfo="*">
                        <Suggest2
                                inputProps={{ placeholder: "Currency ..." }}
                                //activeItem={CurrencyList.find((Ccy) => Ccy.d_Currency === CurrencyValue)}
                                //selectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === CurrencyValue)}
                                defaultSelectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === CurrencyValue)}
                                items={CurrencyList}
                                itemRenderer={CurrencyRenderer}
                                itemPredicate={CurrencyPredicate}
                                inputValueRenderer={CurrencyValueRenderer}
                                onItemSelect={(PayRec) => { setCurrencyValue(PayRec.d_Currency) }}
                            />
                        {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>}
                    </FormGroup>
                }
                <FormGroup className="Col-2-2" label="Amount" labelFor="inputAmount">
                    {/* <NumericInput className="inputNumberNoSpin" disabled={TranTypeValue === "A"} id="inputAmount"  defaultValue="0" value={AmountValue} onValueChange={(n => setAmountValue(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup className="inputNumberNoSpin" disabled={TranTypeValue === "A"} id="inputAmount" defaultValue={AmountValue} value={AmountValue} type="number" onChange={utils.handleStringChange(n => setAmountValue(n))}  />
                    {errors.d_Amount && errors.d_Amount.type === "min" && <span id="Err_msg">Amount sholud be greater than 0 </span>}
                    {errors.d_Amount && errors.d_Amount.types && <span id="Err_msg">{errors.d_Amount.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_BANK) &&
                    <FormGroup className="Col-4-1" label="Bank Code" labelFor="inputBankCode" labelInfo="">
                        <InputGroup id="inputBankCode" value={MBankCodeValue} defaultValue="" placeholder="Bank Code ..." onChange={utils.handleStringChange(s => setMBankCodeValue(s)) } inputRef={register({ maxLength: 2}) } />
                        <div>
                        <div id="DispText">{MultiBankNameValue}</div>
                        </div>
                        {errors.d_Bank_Code && errors.d_Bank_Code.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                        {errors.d_Bank_Code && errors.d_Bank_Code.types && <span id="Err_msg">{errors.d_Bank_Code.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label="Description" labelFor="inputDescription">
                    {/* <InputGroup id="inputDescription" name="d_Description" defaultValue="" placeholder="Description ..." inputRef={register({ maxLength: DescLength}) } /> */}
                    <InputGroup id="inputDescription" value={DescValue} defaultValue="" placeholder="Description ..." onFocus={showdefDesc} onChange={utils.handleStringChange(s => setDescValue(s)) } inputRef={register({ maxLength: DescLength}) } />
                    {errors.d_Description && errors.d_Description.type === "maxLength" && <span id="Err_msg">Max length is {DescLength}</span>}
                    {errors.d_Description && errors.d_Description.types && <span id="Err_msg">{errors.d_Description.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                { ! ( TranTypeValue === "A" ) &&
                    <FormGroup className="Col3-1-2" label="CRS & FATCA Payment Type" labelFor="inputCrsPaymentType">
                        {/* 
                        <InputGroup id="inputCrsPaymentType" name="d_Crs_Payment_Type" defaultValue="" placeholder="CRS & FATCA Payment Type ..." inputRef={register({ maxLength: 3}) } />
                        {errors.d_Crs_Payment_Type && errors.d_Crs_Payment_Type.type === "maxLength" && <span id="Err_msg">Max length is 3</span>}
                        {errors.d_Crs_Payment_Type && errors.d_Crs_Payment_Type.types && <span id="Err_msg">{errors.d_Crs_Payment_Type.types.message}</span>}
                        */}
                        <select  id="inputCrsPaymentType" name="d_Crs_Payment_Type" ref={register}>
                            <option value=" "> </option>
                            <option value="501">CRS501 :  Dividends</option>
                            <option value="502">CRS502 :  Interest</option>
                            <option value="503">CRS503 :  Gross Proceeds from Sales\Redemption of Assets</option>
                            <option value="504">CRS504 :  Other Income</option>
                        </select>
                    </FormGroup>
                }
            </div>
            {(GsbFlag.PAYQ_USER) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Print Cheque" labelFor="inputPrintChq">
                        <RadioGroup
                            name="d_PrintChq"
                            inline={true}
                            selectedValue={PrintChqValue}
                            onChange={utils.handleStringChange(s => setPrintChqValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.d_PrintChq && errors.d_PrintChq.types && <span id="Err_msg">{errors.d_PrintChq.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-1" label="Bank" labelFor="inputBank">
                        <InputGroup disabled={true} id="inputBank" name="d_Bank" defaultValue="" placeholder="Bank ..." inputRef={register({ maxLength: 8}) } />
                        {errors.d_Bank && errors.d_Bank.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                        {errors.d_Bank && errors.d_Bank.types && <span id="Err_msg">{errors.d_Bank.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-4-1" label="Cheque No" labelFor="inputChqNo">
                        <InputGroup disabled={true} id="inputChqNo" name="d_Chq_No" defaultValue="" placeholder="Cheque No ..." inputRef={register({ maxLength: 8}) } />
                        {errors.d_Chq_No && errors.d_Chq_No.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                        {errors.d_Chq_No && errors.d_Chq_No.types && <span id="Err_msg">{errors.d_Chq_No.types.message}</span>}
                    </FormGroup>
                    {(GsbFlag.CHQ_TO_BANK || GsbFlag.CCHQ_FILE || GsbFlag.DIVCHQ_TO_BANK) && <FormGroup className="Col-5-1" label='Autopay Code' labelFor="inputAutopay_BankCode">
                        <InputGroup id="inputAutopay_BankCode" value={AutopayBankCodeValue} defaultValue="" placeholder="Autopay Code ..." onChange={utils.handleStringChange(s => setAutopayBankCodeValue(s)) } inputRef={register({ maxLength: 1}) } /> 
                        {errors.d_Autopay_BankCode && errors.d_Autopay_BankCode.types && <span id="Err_msg">{errors.d_Autopay_BankCode.types.message}</span>}
                        {errors.d_Autopay_BankCode && errors.d_Autopay_BankCode.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    </FormGroup> }
                </div>
            }
            <div>
                <div className="Spacing-V-16"></div>
                <div >Note : </div>
                <div >SI TRAN,Good Receipt : No = If Received Cheque </div>
            </div>
        </form>
    )
}

interface PayRecTranEntryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function PayRecTranEntryMain({ gsb }: PayRecTranEntryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as PRTranInfo[])
    const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])
    const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])

    const [searchKey, setSearchKey] = useState("SeqNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchAcName, setsearchAcName] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentPayRec, setcurrentPayRec] = useState({} as PRTranInfo)
    const [BlankPayRec, setBlankPayRec] = useState({} as PRTranInfo)

    const [CurrentLog1, setCurrentLog1] = useState({} as PRTranInfo)
    const [CurrentLog0, setCurrentLog0] = useState({} as PRTranInfo)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [showCurrency, setshowCurrency] = useState(false)
    const [PAYQ_USER, setPAYQ_USER] = useState(false)
    const [CHQ_TO_BANK, setCHQ_TO_BANK] = useState(false)
    
    //gsb default value
    //BlankPayRec.d_Currency = "HKD"   //no work
    BlankPayRec.d_acCode = ""
    BlankPayRec.d_Tran_type = "P"
    BlankPayRec.d_Good_Receipt = "Y"
    BlankPayRec.d_Req_Settle = "Y"
    BlankPayRec.d_Settle_Int = "N"
    BlankPayRec.d_Currency = ""
    BlankPayRec.d_Amount = 0
    BlankPayRec.d_Description = ""
    BlankPayRec.d_PR_Code = ""
    BlankPayRec.d_Bank_Code=""
    BlankPayRec.d_PrintChq = "N"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("PayRecTranEntryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("PayRecTranEntryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("PayRecTranEntryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("PayRecTranEntryMain disp GsbFlag: ", GsbFlag)
                //console.log("PayRecTranEntryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("PayRecTranEntryMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("PayRecTranEntryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("PayRecTranEntryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("PayRecTranEntryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }

                setshowCurrency( (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) );
                setPAYQ_USER(GsbFlag.PAYQ_USER) ;
                setCHQ_TO_BANK(GsbFlag.CHQ_TO_BANK);
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        setisReadOnly(false)
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("PayRecTranEntryMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])

    useEffect(() => {
        (async () => {
            try {
                // get Market List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYList"})

                //console.log("MarketList: ", res.data.data) 
                CurrencyList2.push({d_Currency: "", d_CcyDesc: "HKD"})
                for (let j = 0; j < res.data.data.length; j++) {
                    CurrencyList2.push({d_Currency: res.data.data[j][0], d_CcyDesc: res.data.data[j][1]})
                }
                setCurrencyList(CurrencyList2)
                //console.log("set CurrencyList: ", CurrencyList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Transaction', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "PayRecTranEntry", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("PayRecTranEntryMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])


    const PrevRecProcess = async (PayRec: PRTranInfo) => {
        try {
            //console.log("call PrevRecProcess", PayRec)
            const res = await gsb.post('/Transaction', {sb_data: PayRec, cPgmId: "PayRecTranEntry", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_Seq)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentPayRec(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (PayRec: PRTranInfo) => {
        try {
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Transaction', {sb_data: PayRec, cPgmId: "PayRecTranEntry", cAction: "DelRec"})
            showSuccess("Seq No. " + PayRec.d_Seq + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentPayRec(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (PayRec: PRTranInfo) => {
        try {
            //console.log("call NextRecProcess", PayRec)
            const res = await gsb.post('/Transaction', {sb_data: PayRec, cPgmId: "PayRecTranEntry", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentPayRec(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (PayRec: PRTranInfo) => {
        try {
            //console.log("call newOnSave", PayRec)
            isReqValueLimitAuth2 =  false
            const res = await gsb.post('/Transaction', {sb_data: PayRec, cPgmId: "PayRecTranEntry", cAction: "AddRec"})
            //showSuccess("Seq No. " + PayRec.d_Seq + " record added")
            if ( res.data.data.d_Auth_Value_Limit_tran === "Y" ) {
                isReqValueLimitAuth2 =  true
            }
            if (isReqValueLimitAuth2) {
                showSuccess("Seq No. " + res.data.data.d_Seq + " record adding, over value limit waiting for authorization")
            } else {
                showSuccess("Seq No. " + res.data.data.d_Seq + " record added")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setcurrentPayRec(res.data.data)
            LastDescValue = res.data.data.d_Description
            //console.log("call newOnSave LastDescValue", res.data.data.d_Description, LastDescValue)
            setCurrentMode("show")

            if (isReqAuth2) {
                //alert("Seq No. " + res.data.data.d_Seq + " record adding, over value limit waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Seq No. " + res.data.data.d_Seq + " record adding, over value limit waiting for authorization")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (PayRec: PRTranInfo) => {
        try {
            //console.log("call editOnSave", PayRec)
            //console.log("stk.d_CcassStk: ", stk.d_CcassStk)
            isReqValueLimitAuth2 =  false
            const res = await gsb.post('/Transaction', {sb_data: PayRec, cPgmId: "PayRecTranEntry", cAction: "UpdateRec"})
            //console.log("call editOnSave ", res.data.data.d_Auth_Value_Limit_tran, res.data.data)
            if ( res.data.data.d_Auth_Value_Limit_tran === "Y" ) {
                isReqValueLimitAuth2 =  true
            }
            if (isReqValueLimitAuth2) {
                showSuccess("Seq No. " + PayRec.d_Seq + " record saving, over value limit waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        setcurrentPayRec(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Seq No. " + PayRec.d_Seq + " record saving, over value limit waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Seq No. " + PayRec.d_Seq + " record saving, over value limit waiting for authorization")
        } else {
                showSuccess("Seq No. " + PayRec.d_Seq + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        data[i] = { ...data[i], ...res.data.data }
                        //console.log("editOnSave data", i, data)
                        setData(data)
                        //setCurrentStk(stk)
                        break
                    }
                }
                //console.log("editOnSave stk", stk)
                //console.log("editOnSave res.data.data", res.data.data)
                setcurrentPayRec(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get PayRecTranEntry list if StkNo have value
        try {
            //console.log("PayRecTranEntryMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            AcName: searchAcName,
                        }, 
                    },
                    cPgmId: "PayRecTranEntry",
                    cAction: "ListRec",
                });

                //console.log("onSubmit res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentPayRec(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        d_Seq: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "PayRecTranEntry",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setcurrentPayRec(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one PayRecTranEntry record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("PayRecTranEntryMain useEffect length > 0  called", data)
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "PayRecTranEntry",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentPayRec(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                AcName: searchAcName,
                            }, 
                        },
                        cPgmId: "PayRecTranEntry",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentPayRec(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <PayRecTranEntryView gsb={gsb} PayRec={currentPayRec} PayRec0={currentPayRec} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentPayRec)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentPayRec)} onNextRec={() => NextRecProcess(currentPayRec)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></PayRecTranEntryView>
    switch (currentMode) {
        //case "PrevRec":
        // ?? will call 2 times ??
        //    console.log("currentMode = PrevRec")
        //    PrevRecProcess(currentStk)
        //    break
        case "new":
            //console.log("currentMode = new")
            detail = <PayRecTranEntryEdit gsb={gsb} PayRec={BlankPayRec} CurrencyList={CurrencyList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></PayRecTranEntryEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <PayRecTranEntryEdit gsb={gsb} PayRec={currentPayRec} CurrencyList={CurrencyList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></PayRecTranEntryEdit>
            break
        //case "delete":
        //    console.log("currentMode = delete")
        //    DeleteProcess(currentStk)
        //    break
        }

    let logKeyViewRec = <PayRecTranEntryView gsb={gsb} PayRec={CurrentLog1} PayRec0={CurrentLog0} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentPayRec)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentPayRec)} onNextRec={() => NextRecProcess(currentPayRec)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></PayRecTranEntryView>

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-SeqNo">Seq No</th>
                <th className="TCol-TranType">Tran Type</th>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                { showCurrency &&
                    <th className="TCol-Currency">Currency</th>
                }
                <th className="TCol-Amount">Amount</th>
                <th className="TCol-Description">Description</th>
                { PAYQ_USER && <th className="TCol-PrintChq">Print Cheque</th> }
            </thead>
            <tbody id="ma_tr">
                {data.map((PayRec, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-SeqNo" onClick={() => { setcurrentPayRec(PayRec) ; MoblieDev && setSelectedTabId("detail") }}>{PayRec.d_Seq}</td>
                        <td className="TCol-TranType" onClick={() => { setcurrentPayRec(PayRec) ; MoblieDev && setSelectedTabId("detail") }}>{PayRec.d_Tran_type}</td>
                        <td className="TCol-ACCode" onClick={() => { setcurrentPayRec(PayRec) ; MoblieDev && setSelectedTabId("detail") }}>{PayRec.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setcurrentPayRec(PayRec) ; MoblieDev && setSelectedTabId("detail") }}>{PayRec.d_acName1}</td>
                        { showCurrency &&
                            <td className="TCol-Currency" onClick={() => { setcurrentPayRec(PayRec) }}>{PayRec.d_Currency}</td>
                        }
                        <td className="TCol-Amount" onClick={() => { setcurrentPayRec(PayRec) }}>{utils.formatNumber2(PayRec.d_Amount)}</td>
                        <td className="TCol-Description" onClick={() => { setcurrentPayRec(PayRec) ; MoblieDev && setSelectedTabId("detail") }}>{PayRec.d_Description}</td>
                        { PAYQ_USER && <td className="TCol-PrintChq" onClick={() => { setcurrentPayRec(PayRec) ; MoblieDev && setSelectedTabId("detail") }}>{PayRec.d_PrintChq}</td> }
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Payment / Receipt Transaction Entry</H3>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for Payment / Receipt Transaction " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="SeqNo">Seq No</option>
                                    <option value="acCode">Account No.</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-2" label="Account Name" labelFor="searchAcName">
                                <InputGroup id="searchAcName" value={searchAcName} onChange={utils.handleStringChange(s => setsearchAcName(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    Payment / Receipt Transaction Log View
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default PayRecTranEntryMain