import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, InputGroup, Button, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { Alert, Dialog, DialogBody, Callout } from "@blueprintjs/core";
import { Radio, RadioGroup } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';

var GsbFlag: any;


interface TempChgFieldMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


function TempChgFieldMain({ gsb }: TempChgFieldMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [isOPenAlert, setisOPenAlert] = useState(true)
    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")
    const [isPasswordOk, setisPasswordOk] = useState(false)

    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    const [isShowAlert3, setisShowAlert3] = useState(false)
    const [ShowAlert3Msg, setShowAlert3Msg] = useState([])

    const [s_DbName, sets_DbName] = useState("")
    const [s_ChgField1, sets_ChgField1] = useState("")
    const [s_ChgField2, sets_ChgField2] = useState("N")
    const [s_ChgField3, sets_ChgField3] = useState("0")
    const [s_ChgField4, sets_ChgField4] = useState("0")
    const [s_NewField, sets_NewField] = useState("N")
    const [ShowNewField, setShowNewField] = useState(false)

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    const onCheckPassword = () => {
        if ( password === "POP" ) {
            // no new field option
            setisPasswordOk(true) 
            setShowNewField(false)
        } else if ( password === "POP2" ) {
            // have new field option
            setisPasswordOk(true) 
            setShowNewField(true)
        } else {
            setisPasswordOk(false) 
            setisShowAlert2(true)
            setShowAlert2Msg("Wrong Password")
        }
    }    


    const onTempChgField = () => {
        //console.log("onTempChgField fileInfoList", fileInfoList);
        setShowWait(true);

        (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        s_DbName: s_DbName,
                        s_ChgField1: s_ChgField1,
                        s_ChgField2: s_ChgField2,
                        s_ChgField3: s_ChgField3,
                        s_ChgField4: s_ChgField4,
                        s_NewField: s_NewField,
                    },
                    cPgmId: "TempChgField",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                if ( res.data.ok ) {
                    setisShowAlert1(true)
                    setShowAlert1Msg(res.data.data)
                } else {
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
                setShowWait(false)
            } catch (error) {
                utils.showError(error)
            }
            setShowWait(false)
        })()
    }


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                { isPasswordOk && <div>
                    <H3>Temp Change Field Length</H3>
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Database Name" labelFor="inputBranch1" >
                            <InputGroup
                                id="inputBranch"
                                placeholder="Database Name ..."
                                value={s_DbName}
                                onChange={utils.handleStringChange(s => sets_DbName(s)) }
                            />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-1" label="Field Name" labelFor="inputChgField1" >
                            <InputGroup
                                id="inputChgField1"
                                placeholder="Field Name ..."
                                value={s_ChgField1}
                                onChange={utils.handleStringChange(s => sets_ChgField1(s)) }
                            />
                        </FormGroup>
                        <FormGroup className="Col-2-3" label="Field Type" labelFor="inputChgField2" >
                            <RadioGroup
                                inline={true}
                                selectedValue={s_ChgField2}
                                onChange={utils.handleStringChange(s => sets_ChgField2(s))  }
                            >
                                <Radio label="Character"  value="C" />
                                <Radio label="Numeric"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                        <FormGroup className="Col-5-1" label="Width" labelFor="inputChgField3" >
                            <InputGroup id="inputChgField3" defaultValue={s_ChgField3} value={s_ChgField3} onChange={utils.handleStringChange(n => sets_ChgField3(n))}  />
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Decimal" labelFor="inputChgField4" >
                            <InputGroup id="inputChgField4" defaultValue={s_ChgField4} value={s_ChgField4} onChange={utils.handleStringChange(n => sets_ChgField4(n))}  />
                        </FormGroup>
                    </div>
                    {ShowNewField && <div className="Row">
                        <FormGroup className="Col-1-2" label="is New Field" labelFor="inputNewField" >
                            <RadioGroup
                                inline={true}
                                selectedValue={s_NewField}
                                onChange={utils.handleStringChange(s => sets_NewField(s))  }
                            >
                                <Radio label="Yes"  value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div> }
                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <Button className="Col-1-1" onClick={() => { onTempChgField() } }>Change</Button> 
                    </div>
                    <div className="Spacing-V-16" />
                    {ShowWait && <div>{imgWait}</div> }
                    <br></br>
                    <br></br>
                    <p>Notes</p>
                    <p><H6>Please REMEMBER to <b>BACKUP DATA</b> before change the Field Length</H6></p>
                    <p><H6>and CONACT POP Tel. 2391 8816 before use</H6></p>
                    <div>
                        <div>eg.</div>
                        <div>Name : CLMAST</div>
                        <div>Field Info : "LBALPR", "N", 15, 2</div>
                        <div>eg.</div>
                        <div>Name : CLMAST</div>
                        <div>Field Info : "LNAME", "C", 30, 0</div>
                    </div >
                </div> }
                { ! isPasswordOk && <div>
                    <H3>Temp Change Field Length</H3>
                    <div className="Spacing-V-8"></div>
                    <div className="Spacing-V-16"></div>
                        <Button className="Col-1-2" onClick={() => window.location.reload()}>Refresh</Button>
                    <div className="Spacing-V-16"></div>
                </div> }
                </Card>
                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isOPenAlert}
                    onCancel={ () => setisOPenAlert(false) }
                    onConfirm={ () => { onCheckPassword(); setisOPenAlert(false) } }
                >
                    <div>
                        <div>Require Password</div>
                        <div>Please Input Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" autoFocus type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                    <div className="Spacing-V-16"></div>
                    <div>
                        <H6>Please CONACT POP Tel. 2391 8816 before use</H6>
                    </div>
                </Alert>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert3}
                        onCancel={ () => setisShowAlert3(false) }
                        onConfirm={ () => { setShowAlert3Msg([]); setisShowAlert3(false) } }
                    >
                        <p>
                            {/*ShowAlert3Msg*/}
                            <div className="Spacing-V-16"></div>
                            {ShowAlert3Msg.map((Wmsg, j) => 
                                <div>{ShowAlert3Msg[j]}</div>
                            )}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default TempChgFieldMain