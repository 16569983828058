import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'


import { AcStatusInfo } from './Types'
import { SystemHeader } from './CommonUI';

import { transpileModule } from 'typescript';
import { stringify } from 'querystring';
import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

const RESR_Code = "ACSR*#"

interface AcStatusViewProps {
    gsb: AxiosInstance,
    AcStatus: AcStatusInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function AcStatusView({ gsb, AcStatus = {} as AcStatusInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: AcStatusViewProps) {

    const [isDeletePress, setisDeletePress] = useState(false)
    const [isParametPress, setisParametPress] = useState(false)


    //console.log("AcStatusView: " , AcStatus)            

    //if (! ("d_CURCY" in AcStatus[0])) return null


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
               
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Code">
                    <InputGroup readOnly value={AcStatus.d_StatusCode1} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="Account Status">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC1} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode2} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC2} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode3} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC3} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode4} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC4} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode5} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC5} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode6} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC6} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode7} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC7} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode8} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC8} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode9} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC9} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode10} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC10} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode11} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC11} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode12} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC12} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode13} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC13} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode14} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC14} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode15} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC15} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode16} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC16} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode17} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC17} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode18} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC18} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode19} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC19} />
                </FormGroup>
            </div>
            <div className="Row">
            <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={AcStatus.d_StatusCode20} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup readOnly value={AcStatus.x_AC_SDESC20} />
                </FormGroup>
            </div>

        </div>
    )
}

interface AcStatusEditProps {
    gsb: AxiosInstance,
    AcStatus?: AcStatusInfo,
    isNew?: boolean,
    onSave?: (AcStatus: AcStatusInfo) => void,
    onCancel?: () => void;
}

function AcStatusEdit({ gsb, AcStatus = {} as AcStatusInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: AcStatusEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<AcStatusInfo>()
  
/*
    useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_CURCY" in AcStatus) {
            reset(AcStatus)
        }
    }, [AcStatus, reset])
*/
    

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "x_Status" })
    }, [register])

    
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    


    //if (! isNew && !("d_tr_date" in AcStatus)) return null




    const onSubmit = (data: AcStatusInfo) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        //data.d_CE_No = data.d_CE_No.toUpperCase();


        (async () => {
            try {
                //console.log("call AcStatusedit data", data)
                //console.log("call AcStatusedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "AcStatus", cAction: "AcStatusValidate", isNew: isNew})
                //console.log("AcStatusedit res.data.ok", res.data.ok)
                //console.log("AcStatusedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            //handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>

            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Code">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode1) >= 0 )} name="d_StatusCode1" defaultValue={AcStatus.d_StatusCode1} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode1 && errors.d_StatusCode1.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode1 && errors.d_StatusCode1.types && <span id="Err_msg">{errors.d_StatusCode1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Account Status">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC1" defaultValue={AcStatus.x_AC_SDESC1} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC1 && errors.x_AC_SDESC1.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC1 && errors.x_AC_SDESC1.types && <span id="Err_msg">{errors.x_AC_SDESC1.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode2) >= 0 )} name="d_StatusCode2" defaultValue={AcStatus.d_StatusCode2} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode2 && errors.d_StatusCode2.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode2 && errors.d_StatusCode2.types && <span id="Err_msg">{errors.d_StatusCode2.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC2" defaultValue={AcStatus.x_AC_SDESC2} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC2 && errors.x_AC_SDESC2.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC2 && errors.x_AC_SDESC2.types && <span id="Err_msg">{errors.x_AC_SDESC2.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode3) >= 0 )} name="d_StatusCode3" defaultValue={AcStatus.d_StatusCode3} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode3 && errors.d_StatusCode3.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode3 && errors.d_StatusCode3.types && <span id="Err_msg">{errors.d_StatusCode3.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC3" defaultValue={AcStatus.x_AC_SDESC3} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC3 && errors.x_AC_SDESC3.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC3 && errors.x_AC_SDESC3.types && <span id="Err_msg">{errors.x_AC_SDESC3.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode4) >= 0 )} name="d_StatusCode4" defaultValue={AcStatus.d_StatusCode4} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode4 && errors.d_StatusCode4.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode4 && errors.d_StatusCode4.types && <span id="Err_msg">{errors.d_StatusCode4.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC4" defaultValue={AcStatus.x_AC_SDESC4} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC4 && errors.x_AC_SDESC4.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC4 && errors.x_AC_SDESC4.types && <span id="Err_msg">{errors.x_AC_SDESC4.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode5) >= 0 )} name="d_StatusCode5" defaultValue={AcStatus.d_StatusCode5} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode5 && errors.d_StatusCode5.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode5 && errors.d_StatusCode5.types && <span id="Err_msg">{errors.d_StatusCode5.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC5" defaultValue={AcStatus.x_AC_SDESC5} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC5 && errors.x_AC_SDESC5.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC5 && errors.x_AC_SDESC5.types && <span id="Err_msg">{errors.x_AC_SDESC5.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode6) >= 0 )} name="d_StatusCode6" defaultValue={AcStatus.d_StatusCode6} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode6 && errors.d_StatusCode6.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode6 && errors.d_StatusCode6.types && <span id="Err_msg">{errors.d_StatusCode6.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC6" defaultValue={AcStatus.x_AC_SDESC6} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC6 && errors.x_AC_SDESC6.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC6 && errors.x_AC_SDESC6.types && <span id="Err_msg">{errors.x_AC_SDESC6.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode7) >= 0 )} name="d_StatusCode7" defaultValue={AcStatus.d_StatusCode7} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode7 && errors.d_StatusCode7.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode7 && errors.d_StatusCode7.types && <span id="Err_msg">{errors.d_StatusCode7.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC7" defaultValue={AcStatus.x_AC_SDESC7} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC7 && errors.x_AC_SDESC7.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC7 && errors.x_AC_SDESC7.types && <span id="Err_msg">{errors.x_AC_SDESC7.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode8) >= 0 )} name="d_StatusCode8" defaultValue={AcStatus.d_StatusCode8} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode8 && errors.d_StatusCode8.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode8 && errors.d_StatusCode8.types && <span id="Err_msg">{errors.d_StatusCode8.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC8" defaultValue={AcStatus.x_AC_SDESC8} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC8 && errors.x_AC_SDESC8.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC8 && errors.x_AC_SDESC8.types && <span id="Err_msg">{errors.x_AC_SDESC8.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode9) >= 0 )} name="d_StatusCode9" defaultValue={AcStatus.d_StatusCode9} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode9 && errors.d_StatusCode9.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode9 && errors.d_StatusCode9.types && <span id="Err_msg">{errors.d_StatusCode9.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC9" defaultValue={AcStatus.x_AC_SDESC9} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC9 && errors.x_AC_SDESC9.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC9 && errors.x_AC_SDESC9.types && <span id="Err_msg">{errors.x_AC_SDESC9.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode10) >= 0 )} name="d_StatusCode10" defaultValue={AcStatus.d_StatusCode10} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode10 && errors.d_StatusCode10.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode10 && errors.d_StatusCode10.types && <span id="Err_msg">{errors.d_StatusCode10.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC10" defaultValue={AcStatus.x_AC_SDESC10} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC10 && errors.x_AC_SDESC10.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC10 && errors.x_AC_SDESC10.types && <span id="Err_msg">{errors.x_AC_SDESC10.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode11) >= 0 )} name="d_StatusCode11" defaultValue={AcStatus.d_StatusCode11} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode11 && errors.d_StatusCode11.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode11 && errors.d_StatusCode11.types && <span id="Err_msg">{errors.d_StatusCode11.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC11" defaultValue={AcStatus.x_AC_SDESC11} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC11 && errors.x_AC_SDESC11.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC11 && errors.x_AC_SDESC11.types && <span id="Err_msg">{errors.x_AC_SDESC11.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode12) >= 0 )} name="d_StatusCode12" defaultValue={AcStatus.d_StatusCode12} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode12 && errors.d_StatusCode12.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode12 && errors.d_StatusCode12.types && <span id="Err_msg">{errors.d_StatusCode12.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC12" defaultValue={AcStatus.x_AC_SDESC12} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC12 && errors.x_AC_SDESC12.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC12 && errors.x_AC_SDESC12.types && <span id="Err_msg">{errors.x_AC_SDESC12.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode13) >= 0 )} name="d_StatusCode13" defaultValue={AcStatus.d_StatusCode13} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode13 && errors.d_StatusCode13.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode13 && errors.d_StatusCode13.types && <span id="Err_msg">{errors.d_StatusCode13.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC13" defaultValue={AcStatus.x_AC_SDESC13} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC13 && errors.x_AC_SDESC13.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC13 && errors.x_AC_SDESC13.types && <span id="Err_msg">{errors.x_AC_SDESC13.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode14) >= 0 )} name="d_StatusCode14" defaultValue={AcStatus.d_StatusCode14} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode14 && errors.d_StatusCode14.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode14 && errors.d_StatusCode14.types && <span id="Err_msg">{errors.d_StatusCode14.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC14" defaultValue={AcStatus.x_AC_SDESC14} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC14 && errors.x_AC_SDESC14.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC14 && errors.x_AC_SDESC14.types && <span id="Err_msg">{errors.x_AC_SDESC14.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode15) >= 0 )} name="d_StatusCode15" defaultValue={AcStatus.d_StatusCode15} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode15 && errors.d_StatusCode15.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode15 && errors.d_StatusCode15.types && <span id="Err_msg">{errors.d_StatusCode15.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC15" defaultValue={AcStatus.x_AC_SDESC15} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC15 && errors.x_AC_SDESC15.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC15 && errors.x_AC_SDESC15.types && <span id="Err_msg">{errors.x_AC_SDESC15.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode16) >= 0 )} name="d_StatusCode16" defaultValue={AcStatus.d_StatusCode16} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode16 && errors.d_StatusCode16.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode16 && errors.d_StatusCode16.types && <span id="Err_msg">{errors.d_StatusCode16.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC16" defaultValue={AcStatus.x_AC_SDESC16} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC16 && errors.x_AC_SDESC16.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC16 && errors.x_AC_SDESC16.types && <span id="Err_msg">{errors.x_AC_SDESC16.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode17) >= 0 )} name="d_StatusCode17" defaultValue={AcStatus.d_StatusCode17} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode17 && errors.d_StatusCode17.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode17 && errors.d_StatusCode17.types && <span id="Err_msg">{errors.d_StatusCode17.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC17" defaultValue={AcStatus.x_AC_SDESC17} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC17 && errors.x_AC_SDESC17.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC17 && errors.x_AC_SDESC17.types && <span id="Err_msg">{errors.x_AC_SDESC17.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode18) >= 0 )} name="d_StatusCode18" defaultValue={AcStatus.d_StatusCode18} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode18 && errors.d_StatusCode18.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode18 && errors.d_StatusCode18.types && <span id="Err_msg">{errors.d_StatusCode18.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC18" defaultValue={AcStatus.x_AC_SDESC18} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC18 && errors.x_AC_SDESC18.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC18 && errors.x_AC_SDESC18.types && <span id="Err_msg">{errors.x_AC_SDESC18.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode19) >= 0 )} name="d_StatusCode19" defaultValue={AcStatus.d_StatusCode19} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode19 && errors.d_StatusCode19.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode19 && errors.d_StatusCode19.types && <span id="Err_msg">{errors.d_StatusCode19.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC19" defaultValue={AcStatus.x_AC_SDESC19} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC19 && errors.x_AC_SDESC19.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC19 && errors.x_AC_SDESC19.types && <span id="Err_msg">{errors.x_AC_SDESC19.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" disabled={( RESR_Code.indexOf(AcStatus.d_StatusCode20) >= 0 )} name="d_StatusCode20" defaultValue={AcStatus.d_StatusCode20} placeholder="Status Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_StatusCode20 && errors.d_StatusCode20.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_StatusCode20 && errors.d_StatusCode20.types && <span id="Err_msg">{errors.d_StatusCode20.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="">
                    <InputGroup id="inputStatusDesc" name="x_AC_SDESC20" defaultValue={AcStatus.x_AC_SDESC20} placeholder="Account Status ..." inputRef={register({ maxLength: 15}) } />
                    {errors.x_AC_SDESC20 && errors.x_AC_SDESC20.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.x_AC_SDESC20 && errors.x_AC_SDESC20.types && <span id="Err_msg">{errors.x_AC_SDESC20.types.message}</span>}
                </FormGroup>
            </div>
        
        </form>
    )
}



interface AcStatusMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function AcStatusMain({ gsb }: AcStatusMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as AcStatusInfo[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentAcStatus, setcurrentAcStatus] = useState({} as AcStatusInfo)
    const [BlankAcStatus, setBlankAcStatus] = useState({} as AcStatusInfo)


    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    

    
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankAcStatus.d_StatusCode1 = "A"
    BlankAcStatus.x_AC_SDESC1 = "ACTIVE"
    BlankAcStatus.d_StatusCode2 = "C"
    BlankAcStatus.x_AC_SDESC2 = "CLOSED"
    BlankAcStatus.d_StatusCode3 = "S"
    BlankAcStatus.x_AC_SDESC3 = "SUSPENDED"
    BlankAcStatus.d_StatusCode4 = "R"
    BlankAcStatus.x_AC_SDESC4 = "RESTRICTED"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("AcStatusMain disp user_key: ", res.data.Flag.user_key)
                //console.log("AcStatusMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("AcStatusMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("AcStatusMain disp GsbFlag: ", GsbFlag)
                //console.log("AcStatusMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("AcStatusMain disp dateFormat: ", dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("AcStatusMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("AcStatusMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("AcStatusMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;

                //console.log("AcStatusMain isProduction: ", GsbFlag.isProduction)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])


    useEffect(() => {

        console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, GsbFlag, cardOpen01, currentMode])


    useEffect(() => {

        setnPage(1)
        ;

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "AcStatus",
                        cAction: "ListRec",
                    });
                console.log("AcStatusMain AcStatusInfo ", res.data.data) 
                setData(res.data.data[0])
                if (res.data.data.length > 0) {
                    setcurrentAcStatus(res.data.data[0])
                }

                console.log("main currentAcStatus", currentAcStatus)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb ])



    const PrevRecProcess = async (AcStatus: AcStatusInfo) => {
/*        
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "AcStatus", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentAcStatus(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const DeleteProcess = async (AcStatus: AcStatusInfo) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "AcStatus", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentAcStatus(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const NextRecProcess = async (AcStatus: AcStatusInfo) => {
/*        
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "AcStatus", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentAcStatus(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }


    const newOnSave = async (AcStatus: AcStatusInfo) => {
/*        
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "AcStatus", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentAcStatus(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const editOnSave = async (AcStatus: AcStatusInfo) => {

        try {
            console.log("call editOnSave AcStatus", AcStatus)

            const res = await gsb.post('/Master', {sb_data: AcStatus, cPgmId: "AcStatus", cAction: "UpdateRec"})

            console.log("call editOnSave res.data.data", res.data.data)
            
            if ( res.data.ok ) {
                setcurrentAcStatus(res.data.data[0])
                showSuccess("Account Status Code Record Saved")
                //setisShowAlert1(true)
                //setShowAlert1Msg(res.data.data_Msg)
                setCurrentMode("show")
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
                setCurrentMode("show")
            }

        } catch (error) {
            utils.showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <AcStatusView gsb={gsb} AcStatus={currentAcStatus} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAcStatus)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAcStatus)} onNextRec={() => NextRecProcess(currentAcStatus)} ></AcStatusView>
    switch (currentMode) {
        case "edit":
            detail = <AcStatusEdit gsb={gsb} AcStatus={currentAcStatus} onSave={editOnSave} onCancel={() => { setCurrentMode("show") } }></AcStatusEdit>
            break
        case "new":
            detail = <AcStatusEdit gsb={gsb} AcStatus={currentAcStatus} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></AcStatusEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    

    return (
        <>
            <form >
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Account Status Code Setup</H3>
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}

                    {detail}
                    <p>Notes</p>
                    <div>
                        {/* <div>Codes {currentAcStatus.d_RESR_Code} are reserved, please do not change them.</div> */}
                        <div>Codes {RESR_Code} are reserved, please do not change them.</div>
                    </div >
                </Card>
                <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>
            </form>
        </>
    );
}

export default AcStatusMain