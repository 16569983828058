import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { sbConfig } from './Types'
import { SystemHeader } from './CommonUI';

import { transpileModule } from 'typescript';
import { stringify } from 'querystring';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface GsbConfigViewProps {
    gsb: AxiosInstance,
    GsbConfig: sbConfig,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function GsbConfigView({ gsb, GsbConfig = {} as sbConfig, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: GsbConfigViewProps) {

    const [isDeletePress, setisDeletePress] = useState(false)

    const [showFeeSectionMore, setshowFeeSectionMore] = useState(true)
    const [showDividendSectionMore, setshowDividendSectionMore] = useState(true)
    const [showFRRSectionMore, setshowFRRSectionMore] = useState(true)
    
    const [dateFormat, setdateFormat] = useState("1")

    const [GSBx_USER, setGSBx_USER] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');

                setdateFormat(GsbFlag.dateFormat)
                setGSBx_USER(GsbFlag.GSBx_USER) ;
            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, GsbConfig ])



    if (! ("d_tr_date" in GsbConfig)) return null



    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>

                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
             
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="System Date Format">
                    { dateFormat === "0" && <div id="DispText" >YYYY-MM-DD</div> }
                    { dateFormat === "1" && <div id="DispText" >DD-MM-YYYY</div> }
                    { dateFormat === "2" && <div id="DispText" >MM-DD-YYYY</div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Transaction Date">
                    <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_tr_date, dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Next Transaction Date">
                    <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_Next_tr_date, dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Settlement Date">
                    <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_Settlement_date, dateFormat)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Month End Date">
                    <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_MonthEnd_date, dateFormat)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Year End Date">
                    <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_YearEnd_date, dateFormat)} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            { GSBx_USER && <div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Fixed Comm Amount For I Account" labelInfo="">
                        <InputGroup readOnly value={GsbConfig.x_BKR_COMM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-4" label="Comm Amount Reserved Before Rebate To Runner" labelInfo="">
                        <InputGroup readOnly value={GsbConfig.x_BASE_COMM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-4" label="Keep Runner Rebate Records for Zero Rebate Amount" labelInfo="">
                        <RadioGroup
                            name="x_ZERO_RBATE"
                            disabled={true}
                            inline={true}
                            selectedValue={GsbConfig.x_ZERO_RBATE}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Int Rate Calc. Y/P/D)" labelInfo="*">
                        <InputGroup readOnly value={GsbConfig.x_IRATE_YMD} />
                        { GsbConfig.x_IRATE_YMD === "Y" && <div id="DispText" >Y:  Yearly Rate</div> }
                        { GsbConfig.x_IRATE_YMD === "P" && <div id="DispText" >P:  Prime Rate</div> }
                        { GsbConfig.x_IRATE_YMD === "D" && <div id="DispText" >D:  Daily Rate</div> }
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Min Debit Amount">
                        <InputGroup readOnly value={utils.formatNumber2(utils.StringToNum(GsbConfig.x_DR_MINAMT))} />
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Min Credit Amount">
                        <InputGroup readOnly value={utils.formatNumber2(utils.StringToNum(GsbConfig.x_CR_MINAMT))} />
                    </FormGroup>
                    <FormGroup className="Col-7-3" label="Credit Interest Update To Client Balance" labelFor="inputCRINT_UPD">
                        <RadioGroup
                            name="x_CRINT_UPD"
                            disabled={true}
                            inline={true}
                            selectedValue={GsbConfig.x_CRINT_UPD}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                { ! showFeeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFeeSectionMore(! showFeeSectionMore) }} >+ Fee Section </div> }
                { showFeeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFeeSectionMore(! showFeeSectionMore) }} >- Fee Section </div> }
                { showFeeSectionMore && <div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Contract Stamp Base Amount">
                            <InputGroup readOnly value={GsbConfig.x_STAMP_BASE} />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Stamp Unit Amount">
                            <InputGroup readOnly value={GsbConfig.x_STAMP_UNIT} />
                        </FormGroup>
                        <FormGroup className="Col-5-3" label="Combined Amount Of Diff Price To Calc Stamp" labelFor="inputCombined_Stamp">
                            <RadioGroup
                                name="x_COM_STAMP"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.x_COM_STAMP}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Transaction Levy (%)">
                            <InputGroup readOnly value={GsbConfig.x_LEVY_RATE} />
                        </FormGroup>
                        <FormGroup className="Col-3-3" label="Include Investor Compensation Levy (%)">
                            <InputGroup readOnly value={GsbConfig.x_CLEVY_RATE} />
                        </FormGroup>
                        <FormGroup className="Col-6-2" label="Trading Fee (%)">
                            <InputGroup readOnly value={GsbConfig.x_SLEVY_RATE} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="AFRC Levy Rate (%)">
                            <InputGroup readOnly value={GsbConfig.x_FLEVY_RATE} />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="AFRC Effect Date">
                            <InputGroup readOnly value= {utils.dispDate(GsbConfig.x_FRC_2201, dateFormat)} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Transfer Deed Unit Amount">
                            <InputGroup readOnly value={GsbConfig.x_TD_UNIT} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Charge Client For CCASS Fee" labelFor="inputCCASS_CALC">
                            <RadioGroup
                                name="x_CCASS_CALC"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.x_CCASS_CALC}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Allow Client For Max. Fee" labelFor="inputCCASS_CMAX">
                            <RadioGroup
                                name="x_CCASS_CMAX"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.x_CCASS_CMAX}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="CCASS Settle Rate (%)">
                            <InputGroup readOnly value={GsbConfig.x_CCASS_RATE} />
                        </FormGroup>
                        <FormGroup className="Col-3-1" label="Minimum">
                            <InputGroup readOnly value={GsbConfig.x_CCASS_MIN} />
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Maximum">
                            <InputGroup readOnly value={GsbConfig.x_CCASS_MAX} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" >
                            <span id="DispText">Actual CCASS Rate (CCASS Settlement Fee Charged by CCASS)</span>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Normal Trade CCASS Rate (%)">
                            <InputGroup readOnly value={GsbConfig.x_CCAS_RRATE} />
                        </FormGroup>
                        <FormGroup className="Col-3-1" label="Minimum">
                            <InputGroup readOnly value={GsbConfig.x_CCAS_RMIN} />
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Maximum">
                            <InputGroup readOnly value={GsbConfig.x_CCAS_RMAX} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Direct Trade CCASS Rate (%)">
                            <InputGroup readOnly value={GsbConfig.x_CCAS_DRATE} />
                        </FormGroup>
                        <FormGroup className="Col-3-1" label="Minimum">
                            <InputGroup readOnly value={GsbConfig.x_CCAS_DMIN} />
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Maximum">
                            <InputGroup readOnly value={GsbConfig.x_CCAS_DMAX} />
                        </FormGroup>
                    </div>
                </div> }
                <div className="Spacing-V-16" />
                { ! showDividendSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowDividendSectionMore(! showDividendSectionMore) }} >+ Dividend Section </div> }
                { showDividendSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowDividendSectionMore(! showDividendSectionMore) }} >- Dividend Section </div> }
                { showDividendSectionMore && <div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Dividend Collection Fee Rate (by CCASS %)">
                            <InputGroup readOnly value={GsbConfig.x_DIV_RATE} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Dividend Collection Fee Rate (Debit Client %)">
                            <InputGroup readOnly value={GsbConfig.x_CDIV_RATE} />
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Minimum">
                            <InputGroup readOnly value={GsbConfig.x_CDIV_MIN} />
                        </FormGroup>
                        <FormGroup className="Col-5-1" label="Maximum">
                            <InputGroup readOnly value={GsbConfig.x_CDIV_MAX} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Fixed Dividend Handling Fee (Debit Client)">
                            <InputGroup readOnly value={GsbConfig.x_CDIV_FIX} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="To Debit Cash Client For Scrip Fee of Deemed Book Closed Stock" labelFor="inputCHK_HOLITBL">
                            <RadioGroup
                                name="x_POST_DEMBC"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.x_POST_DEMBC}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Registration Fee Per Board Lot Via CCASS Nominee">
                            <InputGroup readOnly value={GsbConfig.x_CDIV_TD} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Fixed Bonus Handling Fee (Debit Client)">
                            <InputGroup readOnly value={GsbConfig.x_CBWR_FIX} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label="New Shares (Bonus, Warrant, Rights) Collection Fee Per Board Lot">
                            <InputGroup readOnly value={GsbConfig.x_CBWR_RATE} />
                        </FormGroup>
                        <FormGroup className="Col-5-1" label="Minimum">
                            <InputGroup readOnly value={GsbConfig.x_CBWR_MIN} />
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Maximum">
                            <InputGroup readOnly value={GsbConfig.x_CBWR_MAX} />
                        </FormGroup>
                    </div>
                </div> }
                <div className="Spacing-V-16" />
                { ! showFRRSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFRRSectionMore(! showFRRSectionMore) }} >+ FRR Section </div> }
                { showFRRSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFRRSectionMore(! showFRRSectionMore) }} >- FRR Section </div> }
                { showFRRSectionMore && <div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Illiquid Stk if Val > ? % of Mkt.Cap">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_Illiquid_Stk_MktCap)} />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Illiquid Stk Accept Ratio">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_Illiquid_Stk_Accept_Ratio)} />
                        </FormGroup>
                        <FormGroup className="Col-5-4" label="No need to check a stock is illiquid if Mkt Value of Mgn Client < ">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_Illiquid_Mkt_Value_Mgn_Client)} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Stock Grade">
                            <InputGroup readOnly value="A: HSI" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Accept Ratio (Hair Cut)">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_HariCut1)} />
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="Concentrate Ceiling Ratio">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_CDF_Ratio1)} />
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="Not Count as Illiquid Stk">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk1"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.d_Not_Illiquid_Stk1}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="B: HS HK MidCap Stock" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_HariCut2)} />
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_CDF_Ratio2)} />
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk2"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.d_Not_Illiquid_Stk2}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="C: Other Stock" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_HariCut3)} />
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_CDF_Ratio3)} />
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk3"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.d_Not_Illiquid_Stk3}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="D: Warrant" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_HariCut4)} />
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_CDF_Ratio4)} />
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk4"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.d_Not_Illiquid_Stk4}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="E: User Defined" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_HariCut5)} />
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_CDF_Ratio5)} />
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk5"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.d_Not_Illiquid_Stk5}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="F: User Defined" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_HariCut6)} />
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_CDF_Ratio6)} />
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk6"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.d_Not_Illiquid_Stk6}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="   <Others>" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_HariCut0)} />
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup readOnly value={utils.formatNumber2(GsbConfig.d_CDF_Ratio0)} />
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk0"
                                disabled={true}
                                inline={true}
                                selectedValue={GsbConfig.d_Not_Illiquid_Stk0}
                                onChange={(s) => { }}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                </div> }
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Print Exchange Rate on Statement" labelFor="input_STMT_EXCHRATE">
                        <RadioGroup
                            name="x_STMT_EXCHRATE"
                            disabled={true}
                            inline={true}
                            selectedValue={GsbConfig.x_STMT_EXCHRATE}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Cash P/R Amount & Stock D/W Value Limit">
                        <InputGroup readOnly value={utils.formatNumber2(utils.StringToNum(GsbConfig.x_VALUE_LMT))} />
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Check Holiday Table" labelFor="inputCHK_HOLITBL">
                        <RadioGroup
                            name="x_CHK_HOLITBL"
                            disabled={true}
                            inline={true}
                            selectedValue={GsbConfig.x_CHK_HOLITBL}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme for Production" >
                    <InputGroup readOnly value={GsbConfig.d_theme1} />
                    { GsbConfig.d_theme1 === "0" && <div id="DispText" >Basic </div> }
                    { GsbConfig.d_theme1 === "8" && <div id="DispText" >Dark </div> }
                    { GsbConfig.d_theme1 === "1" && <div id="DispText" >Theme 1 </div> }
                    { GsbConfig.d_theme1 === "2" && <div id="DispText" >Theme 2 </div> }
                    { GsbConfig.d_theme1 === "3" && <div id="DispText" >Theme 3 </div> }
                    { GsbConfig.d_theme1 === "4" && <div id="DispText" >Theme 4 </div> }
                    { GsbConfig.d_theme1 === "5" && <div id="DispText" >Theme 5 </div> }
                    { GsbConfig.d_theme1 === "6" && <div id="DispText" >Theme 6 </div> }
                    { GsbConfig.d_theme1 === "7" && <div id="DispText" >Theme 7 </div> }
                </FormGroup>
             </div>     
             <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme for Try / Other" >
                    <InputGroup readOnly value={GsbConfig.d_theme2} />
                    { GsbConfig.d_theme2 === "0" && <div id="DispText" >Basic </div> }
                    { GsbConfig.d_theme2 === "8" && <div id="DispText" >Dark </div> }
                    { GsbConfig.d_theme2 === "1" && <div id="DispText" >Theme 1 </div> }
                    { GsbConfig.d_theme2 === "2" && <div id="DispText" >Theme 2 </div> }
                    { GsbConfig.d_theme2 === "3" && <div id="DispText" >Theme 3 </div> }
                    { GsbConfig.d_theme2 === "4" && <div id="DispText" >Theme 4 </div> }
                    { GsbConfig.d_theme2 === "5" && <div id="DispText" >Theme 5 </div> }
                    { GsbConfig.d_theme2 === "6" && <div id="DispText" >Theme 6 </div> }
                    { GsbConfig.d_theme2 === "7" && <div id="DispText" >Theme 7 </div> }
                </FormGroup>
             </div>   
             {/*  
             <div className="Row">
                <FormGroup className="Col-1-2" label="Enter to next input field" labelFor="inputEnter2Tab">
                    <RadioGroup
                        name="d_Enter2Tab"
                        disabled={true}
                        inline={true}
                        selectedValue={GsbConfig.d_Enter2Tab}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            */}
        </div>
    )
}

interface GsbConfigEditProps {
    gsb: AxiosInstance,
    GsbConfig?: sbConfig,
    isNew?: boolean,
    onSave?: (GsbConfig: sbConfig) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function GsbConfigEdit({ gsb, GsbConfig = {} as sbConfig, isNew = false, onSave = () => { }, onCancel = () => { } }: GsbConfigEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<sbConfig>()

    const [showFeeSectionMore, setshowFeeSectionMore] = useState(true)
    const [showDividendSectionMore, setshowDividendSectionMore] = useState(true)
    const [showFRRSectionMore, setshowFRRSectionMore] = useState(true)

    const [dateFormat, setdateFormat] = useState("1")

    const [Bkr_CommValue, setBkr_CommValue] = useState(GsbConfig.x_BKR_COMM)
    const [Base_CommValue, setBase_CommValue] = useState(GsbConfig.x_BASE_COMM)
    const [Zero_RebateValue, setZero_RebateValue] = useState(GsbConfig.x_ZERO_RBATE)
    const [RateYMDValue, setRateYMDValue] = useState(GsbConfig.x_IRATE_YMD)
    const [DrMinAmtValue, setDrMinAmtValue] = useState(GsbConfig.x_DR_MINAMT)
    const [CrMinAmtValue, setCrMinAmtValue] = useState(GsbConfig.x_CR_MINAMT)
    const [CrInt_UpdValue, setCrInt_UpdValue] = useState(GsbConfig.x_CRINT_UPD)
    const [Stamp_BaseValue, setStamp_BaseValue] = useState(GsbConfig.x_STAMP_BASE)
    const [Stamp_UnitValue, setStamp_UnitValue] = useState(GsbConfig.x_STAMP_UNIT)
    const [Combined_StampValue, setCombined_StampValue] = useState(GsbConfig.x_COM_STAMP)
    const [Levy_RateValue, setLevy_RateValue] = useState(GsbConfig.x_LEVY_RATE)
    const [CLevy_RateValue, setCLevy_RateValue] = useState(GsbConfig.x_CLEVY_RATE)
    const [SLevy_RateValue, setSLevy_RateValue] = useState(GsbConfig.x_SLEVY_RATE)
    const [FLevy_RateValue, setFLevy_RateValue] = useState(GsbConfig.x_FLEVY_RATE)
    const [FRC_2201Value, setFRC_2201Value] = useState(GsbConfig.x_FRC_2201)
    const [TD_UnitValue, setTD_UnitValue] = useState(GsbConfig.x_TD_UNIT)
    const [CCASS_CalcValue, setCCASS_CalcValue] = useState(GsbConfig.x_CCASS_CALC)
    const [CCASS_CMaxValue, setCCASS_CMaxValue] = useState(GsbConfig.x_CCASS_CMAX)
    const [CCASS_RateValue, setCCASS_RateValue] = useState(GsbConfig.x_CCASS_RATE)
    const [CCASS_MinValue, setCCASS_MinValue] = useState(GsbConfig.x_CCASS_MIN)
    const [CCASS_MaxValue, setCCASS_MaxValue] = useState(GsbConfig.x_CCASS_MAX)
    const [CCASS_RRateValue, setCCASS_RRateValue] = useState(GsbConfig.x_CCAS_RRATE)
    const [CCASS_RMinValue, setCCASS_RMinValue] = useState(GsbConfig.x_CCAS_RMIN)
    const [CCASS_RMaxValue, setCCASS_RMaxValue] = useState(GsbConfig.x_CCAS_RMAX)
    const [CCASS_DRateValue, setCCASS_DRateValue] = useState(GsbConfig.x_CCAS_DRATE)
    const [CCASS_DMinValue, setCCASS_DMinValue] = useState(GsbConfig.x_CCAS_DMIN)
    const [CCASS_DMaxValue, setCCASS_DMaxValue] = useState(GsbConfig.x_CCAS_DMAX)
    const [DIV_RATEValue, setDIV_RATEValue] = useState(GsbConfig.x_DIV_RATE)
    const [CDIV_RATEValue, setCDIV_RATEValue] = useState(GsbConfig.x_CDIV_RATE)
    const [CDIV_MINValue, setCDIV_MINValue] = useState(GsbConfig.x_CDIV_MIN)
    const [CDIV_MAXValue, setCDIV_MAXValue] = useState(GsbConfig.x_CDIV_MAX)
    const [CDIV_FIXValue, setCDIV_FIXValue] = useState(GsbConfig.x_CDIV_FIX)
    const [POST_DEMBCValue, setPOST_DEMBCValue] = useState(GsbConfig.x_POST_DEMBC)
    const [CDIV_TDValue, setCDIV_TDValue] = useState(GsbConfig.x_CDIV_TD)
    const [CBWR_FIXValue, setCBWR_FIXValue] = useState(GsbConfig.x_CBWR_FIX)
    const [CBWR_RATEValue, setCBWR_RATEValue] = useState(GsbConfig.x_CBWR_RATE)
    const [CBWR_MINValue, setCBWR_MINValue] = useState(GsbConfig.x_CBWR_MIN)
    const [CBWR_MAXValue, setCBWR_MAXValue] = useState(GsbConfig.x_CBWR_MAX)

    const [STMT_EXCHRATEValue, setSTMT_EXCHRATEValue] = useState(GsbConfig.x_STMT_EXCHRATE)
    const [Value_LimitValue, setValue_LimitValue] = useState(GsbConfig.x_VALUE_LMT)
    const [Chk_HolidayTableValue, setChk_HolidayTableValue] = useState(GsbConfig.x_CHK_HOLITBL)

    const [Illiquid_Stk_MktCapValue, setIlliquid_Stk_MktCapValue] = useState(utils.numToString(GsbConfig.d_Illiquid_Stk_MktCap))
    const [Illiquid_Stk_Accept_RatioValue, setIlliquid_Stk_Accept_RatioValue] = useState(utils.numToString(GsbConfig.d_Illiquid_Stk_Accept_Ratio))
    const [Illiquid_Mkt_Value_Mgn_ClientValue, setIlliquid_Mkt_Value_Mgn_ClientValue] = useState(utils.numToString(GsbConfig.d_Illiquid_Mkt_Value_Mgn_Client))
    const [HariCut1Value, setHariCut1Value] = useState(utils.numToString(GsbConfig.d_HariCut1))
    const [HariCut2Value, setHariCut2Value] = useState(utils.numToString(GsbConfig.d_HariCut2))
    const [HariCut3Value, setHariCut3Value] = useState(utils.numToString(GsbConfig.d_HariCut3))
    const [HariCut4Value, setHariCut4Value] = useState(utils.numToString(GsbConfig.d_HariCut4))
    const [HariCut5Value, setHariCut5Value] = useState(utils.numToString(GsbConfig.d_HariCut5))
    const [HariCut6Value, setHariCut6Value] = useState(utils.numToString(GsbConfig.d_HariCut6))
    const [HariCut0Value, setHariCut0Value] = useState(utils.numToString(GsbConfig.d_HariCut0))
    const [CDF_Ratio1Value, setCDF_Ratio1Value] = useState(utils.numToString(GsbConfig.d_CDF_Ratio1))
    const [CDF_Ratio2Value, setCDF_Ratio2Value] = useState(utils.numToString(GsbConfig.d_CDF_Ratio2))
    const [CDF_Ratio3Value, setCDF_Ratio3Value] = useState(utils.numToString(GsbConfig.d_CDF_Ratio3))
    const [CDF_Ratio4Value, setCDF_Ratio4Value] = useState(utils.numToString(GsbConfig.d_CDF_Ratio4))
    const [CDF_Ratio5Value, setCDF_Ratio5Value] = useState(utils.numToString(GsbConfig.d_CDF_Ratio5))
    const [CDF_Ratio6Value, setCDF_Ratio6Value] = useState(utils.numToString(GsbConfig.d_CDF_Ratio6))
    const [CDF_Ratio0Value, setCDF_Ratio0Value] = useState(utils.numToString(GsbConfig.d_CDF_Ratio0))
    const [Not_Illiquid_Stk1Value, setNot_Illiquid_Stk1Value] = useState(GsbConfig.d_Not_Illiquid_Stk1)
    const [Not_Illiquid_Stk2Value, setNot_Illiquid_Stk2Value] = useState(GsbConfig.d_Not_Illiquid_Stk2)
    const [Not_Illiquid_Stk3Value, setNot_Illiquid_Stk3Value] = useState(GsbConfig.d_Not_Illiquid_Stk3)
    const [Not_Illiquid_Stk4Value, setNot_Illiquid_Stk4Value] = useState(GsbConfig.d_Not_Illiquid_Stk4)
    const [Not_Illiquid_Stk5Value, setNot_Illiquid_Stk5Value] = useState(GsbConfig.d_Not_Illiquid_Stk5)
    const [Not_Illiquid_Stk6Value, setNot_Illiquid_Stk6Value] = useState(GsbConfig.d_Not_Illiquid_Stk6)
    const [Not_Illiquid_Stk0Value, setNot_Illiquid_Stk0Value] = useState(GsbConfig.d_Not_Illiquid_Stk0)

    const [GSBx_USER, setGSBx_USER] = useState(false)
    const [def_d_theme1, setdef_d_theme1] = useState(GsbConfig.d_theme1)
    const [def_d_theme2, setdef_d_theme2] = useState(GsbConfig.d_theme2)
    const [def_Enter2Tab, setdef_Enter2Tab] = useState(GsbConfig.d_Enter2Tab)

  

    useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_tr_date" in GsbConfig) {
            reset(GsbConfig)
        }
    }, [GsbConfig, reset])

    
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');

                setGSBx_USER(GsbFlag.GSBx_USER) ;
                setdateFormat(GsbFlag.dateFormat)

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, GsbConfig])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_status" })
    }, [register])

    
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    if (! isNew && !("d_tr_date" in GsbConfig)) return null


    const onSubmit = (data: sbConfig) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        //data.d_CE_No = data.d_CE_No.toUpperCase();

        data.x_BKR_COMM = Bkr_CommValue;
        data.x_BASE_COMM = Base_CommValue;
        data.x_ZERO_RBATE = Zero_RebateValue;
        data.x_IRATE_YMD = RateYMDValue;
        data.x_DR_MINAMT = DrMinAmtValue;
        data.x_CR_MINAMT = CrMinAmtValue;
        data.x_CRINT_UPD = CrInt_UpdValue;
        data.x_STAMP_BASE = Stamp_BaseValue;
        data.x_STAMP_UNIT = Stamp_UnitValue;
        data.x_COM_STAMP = Combined_StampValue;
        data.x_LEVY_RATE = Levy_RateValue;
        data.x_CLEVY_RATE = CLevy_RateValue;
        data.x_SLEVY_RATE = SLevy_RateValue;
        data.x_FLEVY_RATE = FLevy_RateValue;
        data.x_FRC_2201 = FRC_2201Value;
        data.x_TD_UNIT = TD_UnitValue;
        data.x_CCASS_CALC = CCASS_CalcValue;
        data.x_CCASS_CMAX = CCASS_CMaxValue;
        data.x_CCASS_RATE = CCASS_RateValue;
        data.x_CCASS_MIN = CCASS_MinValue;
        data.x_CCASS_MAX = CCASS_MaxValue;
        data.x_CCAS_RRATE = CCASS_RRateValue;
        data.x_CCAS_RMIN = CCASS_RMinValue;
        data.x_CCAS_RMAX = CCASS_RMaxValue;
        data.x_CCAS_DRATE = CCASS_DRateValue;
        data.x_CCAS_DMIN = CCASS_DMinValue;
        data.x_CCAS_DMAX = CCASS_DMaxValue;

        data.x_DIV_RATE = DIV_RATEValue;
        data.x_CDIV_RATE = CDIV_RATEValue;
        data.x_CDIV_MIN = CDIV_MINValue;
        data.x_CDIV_MAX = CDIV_MAXValue;
        data.x_CDIV_FIX = CDIV_FIXValue;
        data.x_POST_DEMBC = POST_DEMBCValue;
        data.x_CDIV_TD = CDIV_TDValue;
        data.x_CBWR_FIX = CBWR_FIXValue;
        data.x_CBWR_RATE = CBWR_RATEValue;
        data.x_CBWR_MIN = CBWR_MINValue;
        data.x_CBWR_MAX = CBWR_MAXValue;

        data.x_STMT_EXCHRATE = STMT_EXCHRATEValue;
        data.x_VALUE_LMT = Value_LimitValue;
        data.x_CHK_HOLITBL = Chk_HolidayTableValue;

        data.d_Illiquid_Stk_MktCap = utils.StringToNum(Illiquid_Stk_MktCapValue);
        data.d_Illiquid_Stk_Accept_Ratio = utils.StringToNum(Illiquid_Stk_Accept_RatioValue);
        data.d_Illiquid_Mkt_Value_Mgn_Client = utils.StringToNum(Illiquid_Mkt_Value_Mgn_ClientValue);
        data.d_HariCut1 = utils.StringToNum(HariCut1Value);
        data.d_HariCut2 = utils.StringToNum(HariCut2Value);
        data.d_HariCut3 = utils.StringToNum(HariCut3Value);
        data.d_HariCut4 = utils.StringToNum(HariCut4Value);
        data.d_HariCut5 = utils.StringToNum(HariCut5Value);
        data.d_HariCut6 = utils.StringToNum(HariCut6Value);
        data.d_HariCut0 = utils.StringToNum(HariCut0Value);
        data.d_CDF_Ratio1 = utils.StringToNum(CDF_Ratio1Value);
        data.d_CDF_Ratio2 = utils.StringToNum(CDF_Ratio2Value);
        data.d_CDF_Ratio3 = utils.StringToNum(CDF_Ratio3Value);
        data.d_CDF_Ratio4 = utils.StringToNum(CDF_Ratio4Value);
        data.d_CDF_Ratio5 = utils.StringToNum(CDF_Ratio5Value);
        data.d_CDF_Ratio6 = utils.StringToNum(CDF_Ratio6Value);
        data.d_CDF_Ratio0 = utils.StringToNum(CDF_Ratio0Value);
        data.d_Not_Illiquid_Stk1 = Not_Illiquid_Stk1Value
        data.d_Not_Illiquid_Stk2 = Not_Illiquid_Stk2Value
        data.d_Not_Illiquid_Stk3 = Not_Illiquid_Stk3Value
        data.d_Not_Illiquid_Stk4 = Not_Illiquid_Stk4Value
        data.d_Not_Illiquid_Stk5 = Not_Illiquid_Stk5Value
        data.d_Not_Illiquid_Stk6 = Not_Illiquid_Stk6Value
        data.d_Not_Illiquid_Stk0 = Not_Illiquid_Stk0Value

        
        data.d_Enter2Tab = def_Enter2Tab
        ;
        (async () => {
            try {
                //console.log("call GsbConfigedit data", data)
                //console.log("call GsbConfigedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "GsbConfig", cAction: "GsbConfigValidate", isNew: isNew})
                //console.log("GsbConfigedit res.data.ok", res.data.ok)
                //console.log("GsbConfigedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="System Date Format">
                    { dateFormat === "0" && <div id="DispText" >YYYY-MM-DD</div> }
                    { dateFormat === "1" && <div id="DispText" >DD-MM-YYYY</div> }
                    { dateFormat === "2" && <div id="DispText" >MM-DD-YYYY</div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Transaction Date">
                    {/* <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_tr_date, dateFormat)} /> */}
                    <InputGroup id="inputOpenDate" name="d_tr_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Next Transaction Date">
                    {/* <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_Next_tr_date, dateFormat)} /> */}
                    <InputGroup id="inputOpenDate" name="d_Next_tr_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="Settlement Date">
                    {/* <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_Settlement_date, dateFormat)} /> */}
                    <InputGroup id="inputOpenDate" name="d_Settlement_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Month End Date">
                    {/* <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_MonthEnd_date, dateFormat)} /> */}
                    <InputGroup id="inputOpenDate" name="d_MonthEnd_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Year End Date">
                    {/* <InputGroup readOnly value= {utils.dispDate(GsbConfig.d_YearEnd_date, dateFormat)} /> */}
                    <InputGroup id="inputOpenDate" name="d_YearEnd_date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            { GSBx_USER && <div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Fixed Comm Amount For I Account" labelInfo="">
                        <InputGroup id="inputBkr_Comm" defaultValue={Bkr_CommValue} value={Bkr_CommValue} onChange={utils.handleStringChange(n => setBkr_CommValue(n))} />
                        {errors.x_BKR_COMM && errors.x_BKR_COMM.type === "min" && <span id="Err_msg">Fixed Comm Amount sholud be greater than 0 </span>}
                        {errors.x_BKR_COMM && errors.x_BKR_COMM.types && <span id="Err_msg">{errors.x_BKR_COMM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-4" label="Comm Amount Reserved Before Rebate To Runner" labelInfo="">
                        <InputGroup id="inputBase_Comm" defaultValue={Base_CommValue} value={Base_CommValue} onChange={utils.handleStringChange(n => setBase_CommValue(n))} />
                        {errors.x_BASE_COMM && errors.x_BASE_COMM.type === "min" && <span id="Err_msg">Comm Amt Reserved sholud be greater than 0 </span>}
                        {errors.x_BASE_COMM && errors.x_BASE_COMM.types && <span id="Err_msg">{errors.x_BASE_COMM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-4" label="Keep Runner Rebate Records for Zero Rebate Amount" labelInfo="">
                        <RadioGroup
                            name="x_ZERO_RBATE"
                            inline={true}
                            selectedValue={Zero_RebateValue}
                            onChange={utils.handleStringChange(s => setZero_RebateValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.x_ZERO_RBATE && errors.x_ZERO_RBATE.types && <span id="Err_msg">{errors.x_ZERO_RBATE.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Int Rate Calc. Y/P/D)" labelInfo="*">
                        <select  id="inputRateYMD" value={RateYMDValue} onChange={utils.handleStringChange(s => setRateYMDValue(s)) } >
                            <option value="Y">Y:  Yearly Rate</option>
                            <option value="P">P:  Prime Rate</option>
                            <option value="D">D:  Daily Rate</option>
                        </select>
                        {errors.x_IRATE_YMD && errors.x_IRATE_YMD.types && <span id="Err_msg">{errors.x_IRATE_YMD.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Min Debit Amount">
                        <InputGroup id="inputDrMinAmt" defaultValue={DrMinAmtValue} value={DrMinAmtValue} onChange={utils.handleStringChange(n => setDrMinAmtValue(n))} />
                        {errors.x_DR_MINAMT && errors.x_DR_MINAMT.type === "min" && <span id="Err_msg">Min Debit Amount sholud be greater than 0 </span>}
                        {errors.x_DR_MINAMT && errors.x_DR_MINAMT.types && <span id="Err_msg">{errors.x_DR_MINAMT.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Min Credit Amount">
                        <InputGroup id="inputCrMinAmt" defaultValue={CrMinAmtValue} value={CrMinAmtValue} onChange={utils.handleStringChange(n => setCrMinAmtValue(n))} />
                        {errors.x_CR_MINAMT && errors.x_CR_MINAMT.type === "min" && <span id="Err_msg">Min Debit Amount sholud be greater than 0 </span>}
                        {errors.x_CR_MINAMT && errors.x_CR_MINAMT.types && <span id="Err_msg">{errors.x_CR_MINAMT.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-7-3" label="Credit Interest Update To Client Balance" labelFor="inputCRINT_UPD">
                        <RadioGroup
                            name="x_CRINT_UPD"
                            inline={true}
                            selectedValue={CrInt_UpdValue}
                            onChange={utils.handleStringChange(s => setCrInt_UpdValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.x_CRINT_UPD && errors.x_CRINT_UPD.types && <span id="Err_msg">{errors.x_CRINT_UPD.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                { ! showFeeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFeeSectionMore(! showFeeSectionMore) }} >+ Fee Section </div> }
                { showFeeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFeeSectionMore(! showFeeSectionMore) }} >- Fee Section </div> }
                { showFeeSectionMore && <div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Contract Stamp Base Amount">
                            <InputGroup id="inputStamp_Base" defaultValue={Stamp_BaseValue} value={Stamp_BaseValue} onChange={utils.handleStringChange(n => setStamp_BaseValue(n))} />
                            {errors.x_STAMP_BASE && errors.x_STAMP_BASE.type === "min" && <span id="Err_msg">Contract Stamp Base Amount sholud be greater than 0 </span>}
                            {errors.x_STAMP_BASE && errors.x_STAMP_BASE.types && <span id="Err_msg">{errors.x_STAMP_BASE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Stamp Unit Amount">
                            <InputGroup id="inputStamp_Unit" defaultValue={Stamp_UnitValue} value={Stamp_UnitValue} onChange={utils.handleStringChange(n => setStamp_UnitValue(n))} />
                            {errors.x_STAMP_UNIT && errors.x_STAMP_UNIT.type === "min" && <span id="Err_msg">Stamp Unit Amount sholud be greater than 0 </span>}
                            {errors.x_STAMP_UNIT && errors.x_STAMP_UNIT.types && <span id="Err_msg">{errors.x_STAMP_UNIT.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-3" label="Combined Amount Of Diff Price To Calc Stamp" labelFor="inputCombined_Stamp">
                            <RadioGroup
                                name="x_COM_STAMP"
                                inline={true}
                                selectedValue={Combined_StampValue}
                                onChange={utils.handleStringChange(s => setCombined_StampValue(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.x_COM_STAMP && errors.x_COM_STAMP.types && <span id="Err_msg">{errors.x_COM_STAMP.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Transaction Levy (%)">
                            <InputGroup id="inputLevy" defaultValue={Levy_RateValue} value={Levy_RateValue} onChange={utils.handleStringChange(n => setLevy_RateValue(n))} />
                            {errors.x_LEVY_RATE && errors.x_LEVY_RATE.type === "min" && <span id="Err_msg">Transaction Levy sholud be greater than 0 </span>}
                            {errors.x_LEVY_RATE && errors.x_LEVY_RATE.types && <span id="Err_msg">{errors.x_LEVY_RATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-3" label="Include Investor Compensation Levy (%)">
                            <InputGroup id="inputCLevy" defaultValue={CLevy_RateValue} value={CLevy_RateValue} onChange={utils.handleStringChange(n => setCLevy_RateValue(n))} />
                            {errors.x_CLEVY_RATE && errors.x_CLEVY_RATE.type === "min" && <span id="Err_msg">Investor Compensation Levy sholud be greater than 0 </span>}
                            {errors.x_CLEVY_RATE && errors.x_CLEVY_RATE.types && <span id="Err_msg">{errors.x_CLEVY_RATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-2" label="Trading Fee (%)">
                            <InputGroup id="inputSLevy" defaultValue={SLevy_RateValue} value={SLevy_RateValue} onChange={utils.handleStringChange(n => setSLevy_RateValue(n))} />
                            {errors.x_SLEVY_RATE && errors.x_SLEVY_RATE.type === "min" && <span id="Err_msg">Transaction Levy sholud be greater than 0 </span>}
                            {errors.x_SLEVY_RATE && errors.x_SLEVY_RATE.types && <span id="Err_msg">{errors.x_SLEVY_RATE.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="AFRC Levy Rate (%)">
                            <InputGroup id="inputFLevy" defaultValue={FLevy_RateValue} value={FLevy_RateValue} onChange={utils.handleStringChange(n => setFLevy_RateValue(n))} />
                            {errors.x_FLEVY_RATE && errors.x_FLEVY_RATE.type === "min" && <span id="Err_msg">AFRC Levy Rate sholud be greater than 0 </span>}
                            {errors.x_FLEVY_RATE && errors.x_FLEVY_RATE.types && <span id="Err_msg">{errors.x_FLEVY_RATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="AFRC Effect Date">
                            <InputGroup id="inputs_Date7" type="date" defaultValue={FRC_2201Value} placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={utils.handleStringChange(s => setFRC_2201Value(s)) } />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Transfer Deed Unit Amount">
                            <InputGroup id="inputTD_Unit" defaultValue={TD_UnitValue} value={TD_UnitValue} onChange={utils.handleStringChange(n => setTD_UnitValue(n))} />
                            {errors.x_TD_UNIT && errors.x_TD_UNIT.type === "min" && <span id="Err_msg">TD  sholud Unit Amount be greater than 0 </span>}
                            {errors.x_TD_UNIT && errors.x_TD_UNIT.types && <span id="Err_msg">{errors.x_TD_UNIT.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Charge Client For CCASS Fee" labelFor="inputCCASS_CALC">
                            <RadioGroup
                                name="x_CCASS_CALC"
                                inline={true}
                                selectedValue={CCASS_CalcValue}
                                onChange={utils.handleStringChange(s => setCCASS_CalcValue(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Allow Client For Max. Fee" labelFor="inputCCASS_CMAX">
                            <RadioGroup
                                name="x_CCASS_CMAX"
                                inline={true}
                                selectedValue={CCASS_CMaxValue}
                                onChange={utils.handleStringChange(s => setCCASS_CMaxValue(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="CCASS Settle Rate (%)">
                            <InputGroup id="inputCCASS_Ratee" defaultValue={CCASS_RateValue} value={CCASS_RateValue} onChange={utils.handleStringChange(n => setCCASS_RateValue(n))} />
                            {errors.x_CCASS_RATE && errors.x_CCASS_RATE.type === "min" && <span id="Err_msg">CCASS Settle Rate sholud be greater than 0 </span>}
                            {errors.x_CCASS_RATE && errors.x_CCASS_RATE.types && <span id="Err_msg">{errors.x_CCASS_RATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-1" label="Minimum">
                            <InputGroup id="inputCCASS_Min" defaultValue={CCASS_MinValue} value={CCASS_MinValue} onChange={utils.handleStringChange(n => setCCASS_MinValue(n))} />
                            {errors.x_CCASS_MIN && errors.x_CCASS_MIN.type === "min" && <span id="Err_msg">Minimumsholud be greater than 0 </span>}
                            {errors.x_CCASS_MIN && errors.x_CCASS_MIN.types && <span id="Err_msg">{errors.x_CCASS_MIN.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Maximum">
                            <InputGroup id="inputCCASS_Max" defaultValue={CCASS_MaxValue} value={CCASS_MaxValue} onChange={utils.handleStringChange(n => setCCASS_MaxValue(n))} />
                            {errors.x_CCASS_MAX && errors.x_CCASS_MAX.type === "min" && <span id="Err_msg">Maximum sholud be greater than 0 </span>}
                            {errors.x_CCASS_MAX && errors.x_CCASS_MAX.types && <span id="Err_msg">{errors.x_CCASS_MAX.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" >
                            <span id="DispText">Actual CCASS Rate (CCASS Settlement Fee Charged by CCASS)</span>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Normal Trade CCASS Rate (%)">
                            <InputGroup id="inputCCASS_RRate" defaultValue={CCASS_RRateValue} value={CCASS_RRateValue} onChange={utils.handleStringChange(n => setCCASS_RRateValue(n))} />
                            {errors.x_CCAS_RRATE && errors.x_CCAS_RRATE.type === "min" && <span id="Err_msg">Normal Trade Rate sholud be greater than 0 </span>}
                            {errors.x_CCAS_RRATE && errors.x_CCAS_RRATE.types && <span id="Err_msg">{errors.x_CCAS_RRATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-1" label="Minimum">
                            <InputGroup id="inputCCASS_RMin" defaultValue={CCASS_RMinValue} value={CCASS_RMinValue} onChange={utils.handleStringChange(n => setCCASS_RMinValue(n))} />
                            {errors.x_CCAS_RMIN && errors.x_CCAS_RMIN.type === "min" && <span id="Err_msg">Minimum sholud be greater than 0 </span>}
                            {errors.x_CCAS_RMIN && errors.x_CCAS_RMIN.types && <span id="Err_msg">{errors.x_CCAS_RMIN.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Maximum">
                            <InputGroup id="inputCCASS_RMax" defaultValue={CCASS_RMaxValue} value={CCASS_RMaxValue} onChange={utils.handleStringChange(n => setCCASS_RMaxValue(n))} />
                            {errors.x_CCAS_RMAX && errors.x_CCAS_RMAX.type === "min" && <span id="Err_msg">Maximum sholud be greater than 0 </span>}
                            {errors.x_CCAS_RMAX && errors.x_CCAS_RMAX.types && <span id="Err_msg">{errors.x_CCAS_RMAX.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Direct Trade CCASS Rate (%)">
                            <InputGroup id="inputCCASS_DRate" defaultValue={CCASS_DRateValue} value={CCASS_DRateValue} onChange={utils.handleStringChange(n => setCCASS_DRateValue(n))} />
                            {errors.x_CCAS_DRATE && errors.x_CCAS_DRATE.type === "min" && <span id="Err_msg">Direct Trade Rate sholud be greater than 0 </span>}
                            {errors.x_CCAS_DRATE && errors.x_CCAS_DRATE.types && <span id="Err_msg">{errors.x_CCAS_DRATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-1" label="Minimum">
                            <InputGroup id="inputCCASS_DMin" defaultValue={CCASS_DMinValue} value={CCASS_DMinValue} onChange={utils.handleStringChange(n => setCCASS_DMinValue(n))} />
                            {errors.x_CCAS_DMIN && errors.x_CCAS_DMIN.type === "min" && <span id="Err_msg">Minimum sholud be greater than 0 </span>}
                            {errors.x_CCAS_DMIN && errors.x_CCAS_DMIN.types && <span id="Err_msg">{errors.x_CCAS_DMIN.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Maximum">
                            <InputGroup id="inputCCASS_DMax" defaultValue={CCASS_DMaxValue} value={CCASS_DMaxValue} onChange={utils.handleStringChange(n => setCCASS_DMaxValue(n))} />
                            {errors.x_CCAS_DMAX && errors.x_CCAS_DMAX.type === "min" && <span id="Err_msg">Maximum sholud be greater than 0 </span>}
                            {errors.x_CCAS_DMAX && errors.x_CCAS_DMAX.types && <span id="Err_msg">{errors.x_CCAS_DMAX.types.message}</span>}
                        </FormGroup>
                    </div>
                </div> }
                <div className="Spacing-V-16" />
                { ! showDividendSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowDividendSectionMore(! showDividendSectionMore) }} >+ Dividend Section </div> }
                { showDividendSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowDividendSectionMore(! showDividendSectionMore) }} >- Dividend Section </div> }
                { showDividendSectionMore && <div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Dividend Collection Fee Rate (by CCASS %)">
                            <InputGroup id="inputDIV_RATE" defaultValue={DIV_RATEValue} value={DIV_RATEValue} onChange={utils.handleStringChange(n => setDIV_RATEValue(n))} />
                            {errors.x_DIV_RATE && errors.x_DIV_RATE.type === "min" && <span id="Err_msg">Dividend Collection Fee Rate sholud be greater than 0 </span>}
                            {errors.x_DIV_RATE && errors.x_DIV_RATE.types && <span id="Err_msg">{errors.x_DIV_RATE.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Dividend Collection Fee Rate (Debit Client %)">
                            <InputGroup id="inputCDIV_RATE" defaultValue={CDIV_RATEValue} value={CDIV_RATEValue} onChange={utils.handleStringChange(n => setCDIV_RATEValue(n))} />
                            {errors.x_CDIV_RATE && errors.x_CDIV_RATE.type === "min" && <span id="Err_msg">Dividend Collection Fee Rate sholud be greater than 0 </span>}
                            {errors.x_CDIV_RATE && errors.x_CDIV_RATE.types && <span id="Err_msg">{errors.x_CDIV_RATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-4-1" label="Minimum">
                            <InputGroup id="inputCDIV_MIN" defaultValue={CDIV_MINValue} value={CDIV_MINValue} onChange={utils.handleStringChange(n => setCDIV_MINValue(n))} />
                            {errors.x_CDIV_MIN && errors.x_CDIV_MIN.type === "min" && <span id="Err_msg">Minimum sholud be greater than 0 </span>}
                            {errors.x_CDIV_MIN && errors.x_CDIV_MIN.types && <span id="Err_msg">{errors.x_CDIV_MIN.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-1" label="Maximum">
                            <InputGroup id="inputCDIV_MAX" defaultValue={CDIV_MAXValue} value={CDIV_MAXValue} onChange={utils.handleStringChange(n => setCDIV_MAXValue(n))} />
                            {errors.x_CDIV_MAX && errors.x_CDIV_MAX.type === "min" && <span id="Err_msg">Maximum sholud be greater than 0 </span>}
                            {errors.x_CDIV_MAX && errors.x_CDIV_MAX.types && <span id="Err_msg">{errors.x_CDIV_MAX.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Fixed Dividend Handling Fee (Debit Client)">
                            <InputGroup id="inputCDIV_FIX" defaultValue={CDIV_FIXValue} value={CDIV_FIXValue} onChange={utils.handleStringChange(n => setCDIV_FIXValue(n))} />
                            {errors.x_CDIV_FIX && errors.x_CDIV_FIX.type === "min" && <span id="Err_msg">Fixed Dividend Handling Fee sholud be greater than 0 </span>}
                            {errors.x_CDIV_FIX && errors.x_CDIV_FIX.types && <span id="Err_msg">{errors.x_CDIV_FIX.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="To Debit Cash Client For Scrip Fee of Deemed Book Closed Stock" labelFor="inputCHK_HOLITBL">
                            <RadioGroup
                                name="x_POST_DEMBC"
                                inline={true}
                                selectedValue={POST_DEMBCValue}
                                onChange={utils.handleStringChange(s => setPOST_DEMBCValue(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.x_POST_DEMBC && errors.x_POST_DEMBC.types && <span id="Err_msg">{errors.x_POST_DEMBC.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label="Registration Fee Per Board Lot Via CCASS Nominee">
                            <InputGroup id="inputCDIV_TD" defaultValue={CDIV_TDValue} value={CDIV_TDValue} onChange={utils.handleStringChange(n => setCDIV_TDValue(n))} />
                            {errors.x_CDIV_TD && errors.x_CDIV_TD.type === "min" && <span id="Err_msg">Registration Fee sholud be greater than 0 </span>}
                            {errors.x_CDIV_TD && errors.x_CDIV_TD.types && <span id="Err_msg">{errors.x_CDIV_TD.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label="Fixed Bonus Handling Fee (Debit Client)">
                            <InputGroup id="inputCBWR_FIX" defaultValue={CBWR_FIXValue} value={CBWR_FIXValue} onChange={utils.handleStringChange(n => setCBWR_FIXValue(n))} />
                            {errors.x_CBWR_FIX && errors.x_CBWR_FIX.type === "min" && <span id="Err_msg">Fixed bonus Handling Fee sholud be greater than 0 </span>}
                            {errors.x_CBWR_FIX && errors.x_CBWR_FIX.types && <span id="Err_msg">{errors.x_CBWR_FIX.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-4" label="New Shares (Bonus, Warrant, Rights) Collection Fee Per Board Lot">
                            <InputGroup id="inputCBWR_RATE" defaultValue={CBWR_RATEValue} value={CBWR_RATEValue} onChange={utils.handleStringChange(n => setCBWR_RATEValue(n))} />
                            {errors.x_CBWR_RATE && errors.x_CBWR_RATE.type === "min" && <span id="Err_msg">Dividend Collection Fee Rate sholud be greater than 0 </span>}
                            {errors.x_CBWR_RATE && errors.x_CBWR_RATE.types && <span id="Err_msg">{errors.x_CBWR_RATE.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-1" label="Minimum">
                            <InputGroup id="inputCBWR_MIN" defaultValue={CBWR_MINValue} value={CBWR_MINValue} onChange={utils.handleStringChange(n => setCBWR_MINValue(n))} />
                            {errors.x_CBWR_MIN && errors.x_CBWR_MIN.type === "min" && <span id="Err_msg">Minimum sholud be greater than 0 </span>}
                            {errors.x_CBWR_MIN && errors.x_CBWR_MIN.types && <span id="Err_msg">{errors.x_CBWR_MIN.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Maximum">
                            <InputGroup id="inputCBWR_MAX" defaultValue={CBWR_MAXValue} value={CBWR_MAXValue} onChange={utils.handleStringChange(n => setCBWR_MAXValue(n))} />
                            {errors.x_CBWR_MAX && errors.x_CBWR_MAX.type === "min" && <span id="Err_msg">Maximum sholud be greater than 0 </span>}
                            {errors.x_CBWR_MAX && errors.x_CBWR_MAX.types && <span id="Err_msg">{errors.x_CBWR_MAX.types.message}</span>}
                        </FormGroup>
                    </div>
                </div> }
                <div className="Spacing-V-16" />
                { ! showFRRSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFRRSectionMore(! showFRRSectionMore) }} >+ FRR Section </div> }
                { showFRRSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowFRRSectionMore(! showFRRSectionMore) }} >- FRR Section </div> }
                { showFRRSectionMore && <div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Illiquid Stk if Val > ? % of Mkt.Cap">
                            <InputGroup id="inputIlliquid_Stk_MktCap" defaultValue={Illiquid_Stk_MktCapValue} value={Illiquid_Stk_MktCapValue} onChange={utils.handleStringChange(n => setIlliquid_Stk_MktCapValue(n))} />
                            {errors.d_Illiquid_Stk_MktCap && errors.d_Illiquid_Stk_MktCap.type === "min" && <span id="Err_msg">Mkt Cap % sholud be greater than 0 </span>}
                            {errors.d_Illiquid_Stk_MktCap && errors.d_Illiquid_Stk_MktCap.types && <span id="Err_msg">{errors.d_Illiquid_Stk_MktCap.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Illiquid Stk Accept Ratio">
                            <InputGroup id="inputIlliquid_Stk_Accept_Ratio" defaultValue={Illiquid_Stk_Accept_RatioValue} value={Illiquid_Stk_Accept_RatioValue} onChange={utils.handleStringChange(n => setIlliquid_Stk_Accept_RatioValue(n))} />
                            {errors.d_Illiquid_Stk_Accept_Ratio && errors.d_Illiquid_Stk_Accept_Ratio.type === "min" && <span id="Err_msg">Illiquid Stk Accept Ratio sholud be greater than 0 </span>}
                            {errors.d_Illiquid_Stk_Accept_Ratio && errors.d_Illiquid_Stk_Accept_Ratio.types && <span id="Err_msg">{errors.d_Illiquid_Stk_Accept_Ratio.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-4" label="No need to check a stock is illiquid if Mkt Value of Mgn Client < ">
                            <InputGroup id="inputIlliquid_Mkt_Value_Mgn_Client" defaultValue={Illiquid_Mkt_Value_Mgn_ClientValue} value={Illiquid_Mkt_Value_Mgn_ClientValue} onChange={utils.handleStringChange(n => setIlliquid_Mkt_Value_Mgn_ClientValue(n))} />
                            {errors.d_Illiquid_Mkt_Value_Mgn_Client && errors.d_Illiquid_Mkt_Value_Mgn_Client.type === "min" && <span id="Err_msg">Mkt Value of Mgn Client sholud be greater than 0 </span>}
                            {errors.d_Illiquid_Mkt_Value_Mgn_Client && errors.d_Illiquid_Mkt_Value_Mgn_Client.types && <span id="Err_msg">{errors.d_Illiquid_Mkt_Value_Mgn_Client.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Stock Grade">
                            <InputGroup readOnly value="A: HSI" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Accept Ratio (Hair Cut)">
                            <InputGroup id="inputHariCut" defaultValue={HariCut1Value} value={HariCut1Value} onChange={utils.handleStringChange(n => setHariCut1Value(n))} />
                            {errors.d_HariCut1 && errors.d_HariCut1.type === "min" && <span id="Err_msg">Accept Ratio (Hair Cut) sholud be greater than 0 </span>}
                            {errors.d_HariCut1 && errors.d_HariCut1.types && <span id="Err_msg">{errors.d_HariCut1.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="Concentrate Ceiling Ratio">
                            <InputGroup id="inputCDF_Ratio" defaultValue={CDF_Ratio1Value} value={CDF_Ratio1Value} onChange={utils.handleStringChange(n => setCDF_Ratio1Value(n))} />
                            {errors.d_CDF_Ratio1 && errors.d_CDF_Ratio1.type === "min" && <span id="Err_msg">Concentrate Ceiling Ratio sholud be greater than 0 </span>}
                            {errors.d_CDF_Ratio1 && errors.d_CDF_Ratio1.types && <span id="Err_msg">{errors.d_CDF_Ratio1.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="Not Count as Illiquid Stk">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk1"
                                inline={true}
                                selectedValue={Not_Illiquid_Stk1Value}
                                onChange={utils.handleStringChange(s => setNot_Illiquid_Stk1Value(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.d_Not_Illiquid_Stk1 && errors.d_Not_Illiquid_Stk1.types && <span id="Err_msg">{errors.d_Not_Illiquid_Stk1.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="B: HS HK MidCap Stock" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup id="inputHariCut" defaultValue={HariCut2Value} value={HariCut2Value} onChange={utils.handleStringChange(n => setHariCut2Value(n))} />
                            {errors.d_HariCut2 && errors.d_HariCut2.type === "min" && <span id="Err_msg">Accept Ratio (Hair Cut) sholud be greater than 0 </span>}
                            {errors.d_HariCut2 && errors.d_HariCut2.types && <span id="Err_msg">{errors.d_HariCut2.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup id="inputCDF_Ratio" defaultValue={CDF_Ratio2Value} value={CDF_Ratio2Value} onChange={utils.handleStringChange(n => setCDF_Ratio2Value(n))} />
                            {errors.d_CDF_Ratio2 && errors.d_CDF_Ratio2.type === "min" && <span id="Err_msg">Concentrate Ceiling Ratio sholud be greater than 0 </span>}
                            {errors.d_CDF_Ratio2 && errors.d_CDF_Ratio2.types && <span id="Err_msg">{errors.d_CDF_Ratio2.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk2"
                                inline={true}
                                selectedValue={Not_Illiquid_Stk2Value}
                                onChange={utils.handleStringChange(s => setNot_Illiquid_Stk2Value(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.d_Not_Illiquid_Stk2 && errors.d_Not_Illiquid_Stk2.types && <span id="Err_msg">{errors.d_Not_Illiquid_Stk2.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="C: Other Stock" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup id="inputHariCut" defaultValue={HariCut3Value} value={HariCut3Value} onChange={utils.handleStringChange(n => setHariCut3Value(n))} />
                            {errors.d_HariCut3 && errors.d_HariCut3.type === "min" && <span id="Err_msg">Accept Ratio (Hair Cut) sholud be greater than 0 </span>}
                            {errors.d_HariCut3 && errors.d_HariCut3.types && <span id="Err_msg">{errors.d_HariCut3.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup id="inputCDF_Ratio" defaultValue={CDF_Ratio3Value} value={CDF_Ratio3Value} onChange={utils.handleStringChange(n => setCDF_Ratio3Value(n))} />
                            {errors.d_CDF_Ratio3 && errors.d_CDF_Ratio3.type === "min" && <span id="Err_msg">Concentrate Ceiling Ratio sholud be greater than 0 </span>}
                            {errors.d_CDF_Ratio3 && errors.d_CDF_Ratio3.types && <span id="Err_msg">{errors.d_CDF_Ratio3.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk3"
                                inline={true}
                                selectedValue={Not_Illiquid_Stk3Value}
                                onChange={utils.handleStringChange(s => setNot_Illiquid_Stk3Value(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.d_Not_Illiquid_Stk3 && errors.d_Not_Illiquid_Stk3.types && <span id="Err_msg">{errors.d_Not_Illiquid_Stk3.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="D: Warrant" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup id="inputHariCut" defaultValue={HariCut4Value} value={HariCut4Value} onChange={utils.handleStringChange(n => setHariCut4Value(n))} />
                            {errors.d_HariCut4 && errors.d_HariCut4.type === "min" && <span id="Err_msg">Accept Ratio (Hair Cut) sholud be greater than 0 </span>}
                            {errors.d_HariCut4 && errors.d_HariCut4.types && <span id="Err_msg">{errors.d_HariCut4.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup id="inputCDF_Ratio" defaultValue={CDF_Ratio4Value} value={CDF_Ratio4Value} onChange={utils.handleStringChange(n => setCDF_Ratio4Value(n))} />
                            {errors.d_CDF_Ratio4 && errors.d_CDF_Ratio4.type === "min" && <span id="Err_msg">Concentrate Ceiling Ratio sholud be greater than 0 </span>}
                            {errors.d_CDF_Ratio4 && errors.d_CDF_Ratio4.types && <span id="Err_msg">{errors.d_CDF_Ratio4.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk4"
                                inline={true}
                                selectedValue={Not_Illiquid_Stk4Value}
                                onChange={utils.handleStringChange(s => setNot_Illiquid_Stk4Value(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.d_Not_Illiquid_Stk4 && errors.d_Not_Illiquid_Stk4.types && <span id="Err_msg">{errors.d_Not_Illiquid_Stk4.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="E: User Defined" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup id="inputHariCut" defaultValue={HariCut5Value} value={HariCut5Value} onChange={utils.handleStringChange(n => setHariCut5Value(n))} />
                            {errors.d_HariCut5 && errors.d_HariCut5.type === "min" && <span id="Err_msg">Accept Ratio (Hair Cut) sholud be greater than 0 </span>}
                            {errors.d_HariCut5 && errors.d_HariCut5.types && <span id="Err_msg">{errors.d_HariCut5.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup id="inputCDF_Ratio" defaultValue={CDF_Ratio5Value} value={CDF_Ratio5Value} onChange={utils.handleStringChange(n => setCDF_Ratio5Value(n))} />
                            {errors.d_CDF_Ratio5 && errors.d_CDF_Ratio5.type === "min" && <span id="Err_msg">Concentrate Ceiling Ratio sholud be greater than 0 </span>}
                            {errors.d_CDF_Ratio5 && errors.d_CDF_Ratio5.types && <span id="Err_msg">{errors.d_CDF_Ratio5.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk5"
                                inline={true}
                                selectedValue={Not_Illiquid_Stk5Value}
                                onChange={utils.handleStringChange(s => setNot_Illiquid_Stk5Value(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.d_Not_Illiquid_Stk5 && errors.d_Not_Illiquid_Stk5.types && <span id="Err_msg">{errors.d_Not_Illiquid_Stk5.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="F: User Defined" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup id="inputHariCut" defaultValue={HariCut6Value} value={HariCut6Value} onChange={utils.handleStringChange(n => setHariCut6Value(n))} />
                            {errors.d_HariCut6 && errors.d_HariCut6.type === "min" && <span id="Err_msg">Accept Ratio (Hair Cut) sholud be greater than 0 </span>}
                            {errors.d_HariCut6 && errors.d_HariCut6.types && <span id="Err_msg">{errors.d_HariCut6.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup id="inputCDF_Ratio" defaultValue={CDF_Ratio6Value} value={CDF_Ratio6Value} onChange={utils.handleStringChange(n => setCDF_Ratio6Value(n))} />
                            {errors.d_CDF_Ratio6 && errors.d_CDF_Ratio6.type === "min" && <span id="Err_msg">Concentrate Ceiling Ratio sholud be greater than 0 </span>}
                            {errors.d_CDF_Ratio6 && errors.d_CDF_Ratio6.types && <span id="Err_msg">{errors.d_CDF_Ratio6.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk6"
                                inline={true}
                                selectedValue={Not_Illiquid_Stk6Value}
                                onChange={utils.handleStringChange(s => setNot_Illiquid_Stk6Value(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.d_Not_Illiquid_Stk6 && errors.d_Not_Illiquid_Stk6.types && <span id="Err_msg">{errors.d_Not_Illiquid_Stk6.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="">
                            <InputGroup readOnly value="   <Others>" />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="">
                            <InputGroup id="inputHariCut" defaultValue={HariCut0Value} value={HariCut0Value} onChange={utils.handleStringChange(n => setHariCut0Value(n))} />
                            {errors.d_HariCut0 && errors.d_HariCut0.type === "min" && <span id="Err_msg">Accept Ratio (Hair Cut) sholud be greater than 0 </span>}
                            {errors.d_HariCut0 && errors.d_HariCut0.types && <span id="Err_msg">{errors.d_HariCut0.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-2" label="">
                            <InputGroup id="inputCDF_Ratio" defaultValue={CDF_Ratio0Value} value={CDF_Ratio0Value} onChange={utils.handleStringChange(n => setCDF_Ratio0Value(n))} />
                            {errors.d_CDF_Ratio0 && errors.d_CDF_Ratio0.type === "min" && <span id="Err_msg">Concentrate Ceiling Ratio sholud be greater than 0 </span>}
                            {errors.d_CDF_Ratio0 && errors.d_CDF_Ratio0.types && <span id="Err_msg">{errors.d_CDF_Ratio0.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-7-2" label="">
                            <RadioGroup
                                name="d_Not_Illiquid_Stk0"
                                inline={true}
                                selectedValue={Not_Illiquid_Stk0Value}
                                onChange={utils.handleStringChange(s => setNot_Illiquid_Stk0Value(s)) }
                                ref={register}
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                            {errors.d_Not_Illiquid_Stk0 && errors.d_Not_Illiquid_Stk0.types && <span id="Err_msg">{errors.d_Not_Illiquid_Stk0.types.message}</span>}
                        </FormGroup>
                    </div>
                </div> }
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Print Exchange Rate on Statement" labelFor="input_STMT_EXCHRATE">
                        <RadioGroup
                            name="x_STMT_EXCHRATE"
                            inline={true}
                            selectedValue={STMT_EXCHRATEValue}
                            onChange={utils.handleStringChange(s => setSTMT_EXCHRATEValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.x_STMT_EXCHRATE && errors.x_STMT_EXCHRATE.types && <span id="Err_msg">{errors.x_STMT_EXCHRATE.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Cash P/R Amount & Stock D/W Value Limit">
                        <InputGroup id="inputValue_Limi" defaultValue={Value_LimitValue} value={Value_LimitValue} onChange={utils.handleStringChange(n => setValue_LimitValue(n))} />
                        {errors.x_VALUE_LMT && errors.x_VALUE_LMT.type === "min" && <span id="Err_msg">Value Limit  sholud Unit Amount be greater than 0 </span>}
                        {errors.x_VALUE_LMT && errors.x_VALUE_LMT.types && <span id="Err_msg">{errors.x_VALUE_LMT.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Check Holiday Table" labelFor="inputCHK_HOLITBL">
                        <RadioGroup
                            name="x_CHK_HOLITBL"
                            inline={true}
                            selectedValue={Chk_HolidayTableValue}
                            onChange={utils.handleStringChange(s => setChk_HolidayTableValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.x_CHK_HOLITBL && errors.x_CHK_HOLITBL.types && <span id="Err_msg">{errors.x_CHK_HOLITBL.types.message}</span>}
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme for Production" labelFor="inputd_theme1">
                    <select  id="inputd_theme1" name="d_theme1" value={def_d_theme1} onChange={utils.handleStringChange(s => setdef_d_theme1(s)) }ref={register}>
                        <option value="0">0: Basic </option>
                        <option value="8">8: Dark </option>
                        <option value="1">1: Theme 1</option>
                        <option value="2">2: Theme 2</option>
                        <option value="3">3: Theme 3</option>
                        <option value="4">4: Theme 4</option>
                        <option value="5">5: Theme 5</option>
                        {/*
                        <option value="6">6: Theme 6</option>
                        <option value="7">7: Theme 7</option>
                        */}
                    </select>
                    {errors.d_theme1 && errors.d_theme1.types && <span id="Err_msg">{errors.d_theme1.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme for Try / Other" labelFor="inputd_theme2">
                    <select  id="inputd_theme2" name="d_theme2" value={def_d_theme2} onChange={utils.handleStringChange(s => setdef_d_theme2(s)) }ref={register}>
                        <option value="0">0: Basic </option>
                        <option value="8">8: Dark </option>
                        <option value="1">1: Theme 1</option>
                        <option value="2">2: Theme 2</option>
                        <option value="3">3: Theme 3</option>
                        <option value="4">4: Theme 4</option>
                        <option value="5">5: Theme 5</option>
                        {/*
                        <option value="6">6: Theme 6</option>
                        <option value="7">7: Theme 7</option>
                        */}
                    </select>
                    {errors.d_theme2 && errors.d_theme2.types && <span id="Err_msg">{errors.d_theme2.types.message}</span>}
                </FormGroup>
            </div>
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="Enter to next input field" labelFor="inputEnter2Tab">
                    <RadioGroup
                        name="d_Enter2Tab"
                        inline={true}
                        selectedValue={def_Enter2Tab}
                        onChange={utils.handleStringChange(s => setdef_Enter2Tab(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Enter2Tab && errors.d_Enter2Tab.types && <span id="Err_msg">{errors.d_Enter2Tab.types.message}</span>}
                </FormGroup>
            </div>
            */}

        
        </form>
    )
}

interface GsbxConfigMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function GsbxConfigMain({ gsb }: GsbxConfigMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as sbConfig[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentAc, setCurrentAc] = useState({} as sbConfig)
    const [BlankAc, setBlankAc] = useState({} as sbConfig)


    const [currentMode, setCurrentMode] = useState("show")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    

    
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    //BlankAc.d_p_Pref = "P"



    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("GsbxConfigMain disp user_key: ", res.data.Flag.user_key)
                //console.log("GsbxConfigMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("GsbxConfigMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("GsbxConfigMain disp GsbFlag: ", GsbFlag)
                //console.log("GsbxConfigMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("GsbxConfigMain disp dateFormat: ", dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("GsbxConfigMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("GsbxConfigMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("GsbxConfigMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;

                //console.log("GsbxConfigMain isProduction: ", GsbFlag.isProduction)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])


    useEffect(() => {

        console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, GsbFlag, cardOpen01, currentMode])


    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "GsbConfig",
                        cAction: "ListRec",
                    });
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                console.log("main res.data", res.data.data)
                console.log("main currentAc", currentAc)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    const PrevRecProcess = async (GsbConfig: sbConfig) => {
/*        
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GsbConfig", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const DeleteProcess = async (GsbConfig: sbConfig) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GsbConfig", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const NextRecProcess = async (GsbConfig: sbConfig) => {
/*        
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GsbConfig", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }


    const newOnSave = async (GsbConfig: sbConfig) => {
/*        
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GsbConfig", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const editOnSave = async (GsbConfig: sbConfig) => {
        try {
            console.log("call editOnSave", GsbConfig)
            const res = await gsb.post('/Master', {sb_data: GsbConfig, cPgmId: "GsbConfig", cAction: "UpdateRec"})
            //showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record saved")
/*            
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
*/            
            //setCurrentAc(res.data.data)
            showSuccess("GSBx Configuration Record Saved")
            setCurrentAc(res.data.data[0])
            setCurrentMode("show")

        } catch (error) {
            utils.showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <GsbConfigView gsb={gsb} GsbConfig={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></GsbConfigView>
    switch (currentMode) {
        case "edit":
            detail = <GsbConfigEdit gsb={gsb} GsbConfig={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></GsbConfigEdit>
            break
        case "new":
            detail = <GsbConfigEdit gsb={gsb} GsbConfig={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></GsbConfigEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    

    return (
        <>
            <form >
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>GSBx Configuration</H3>
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}

                    {detail}
                </Card>
            </form>
        </>
    );
}

export default GsbxConfigMain