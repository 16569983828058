import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { UserMast } from './Types'
import { SystemHeader } from './CommonUI';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "S0007" ;
var isReqAuth2:boolean = false ;


interface UserMastViewProps {
    gsb: AxiosInstance,
    UserMast: UserMast,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function UserMastView({ gsb, UserMast = {} as UserMast, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: UserMastViewProps) {

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [docList, setDocList] = useState([] as Document[])


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("UserMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, UserMast])
    
    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_Userid" in UserMast)) return null


    return (
        <div>
            <div className="Row">
            <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="User ID">
                    <InputGroup readOnly value={UserMast.d_Userid} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="User Name">
                    <InputGroup readOnly value={UserMast.d_User_Name} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="User Password">
                    <InputGroup readOnly type="password" value={UserMast.d_User_Password} />
                </FormGroup>
            </div>
        </div>
    )
}

interface UserMastEditProps {
    gsb: AxiosInstance,
    UserMast?: UserMast,
    isNew?: boolean,
    onSave?: (UserMast: UserMast) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function UserMastEdit({ gsb, UserMast = {} as UserMast, isNew = false, onSave = () => { }, onCancel = () => { } }: UserMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<UserMast>()

    const [def_d_User_Password, setdef_d_User_Password] = useState(UserMast.d_User_Password)

  
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_Userid" in UserMast) {
            reset(UserMast)
        }
    }, [UserMast, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_status" })
    }, [register])

    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    if (! isNew && !("d_Userid" in UserMast)) return null

    
    const onSubmit = (data: UserMast) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_Userid = data.d_Userid.toUpperCase();

/*
        data.d_StaffRelation = "N"
        if (def_d_StaffRelation) {
            data.d_StaffRelation = "Y";
        } else {
            data.d_StaffRelation = "N";
        }
*/



        (async () => {
            try {
                console.log("call UserMastedit data", data)
                console.log("call UserMastedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "UserMast", cAction: "UserMastValidate", isNew: isNew})
                //console.log("SysConfigedit res.data.ok", res.data.ok)
                //console.log("SysConfigedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="System Date">
                    <InputGroup disabled= {true} value= {utils.dispDate(SysFl.d_tr_date, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>
            */}
            <div className="Row">
                <FormGroup className="Col-1-2" label="User ID" labelFor="inputd_Userid">
                    <InputGroup disabled={! isNew} id="inputd_Userid" name="d_Userid" autoFocus={isNew} defaultValue="" placeholder="User ID ..." inputRef={register({ required: true , maxLength: 20}) } />
                    {errors.d_Userid && errors.d_Userid.types && <span id="Err_msg">{errors.d_Userid.types.message}</span>}
                    {errors.d_Userid && errors.d_Userid.type === "required" && (<span id="Err_msg">User ID cannot be blank</span>)}
                    {errors.d_Userid && errors.d_Userid.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="User Name" labelFor="inpud_User_Name">
                    <InputGroup id="inpud_User_Name" name="d_User_Name" defaultValue="" placeholder="User Name ..." inputRef={ register({ maxLength: 30}) } />
                    {errors.d_User_Name && errors.d_User_Name.types && <span id="Err_msg">{errors.d_User_Name.types.message}</span>}
                    {errors.d_User_Name && errors.d_User_Name.type === "required" && (<span id="Err_msg">User Name cannot be blank</span>)}
                    {errors.d_User_Name && errors.d_User_Name.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                </FormGroup>
            </div>
            { isNew && <div className="Row">
                <FormGroup className="Col-1-3" label="User Password" labelFor="inpud_User_Password">
                    <InputGroup disabled={! isNew} id="inpud_User_Password" name="d_User_Password" type="password" defaultValue="" placeholder="User Password ..." inputRef={ register({ required: true , maxLength: 20}) } />
                    {errors.d_User_Password && errors.d_User_Password.types && <span id="Err_msg">{errors.d_User_Password.types.message}</span>}
                    {errors.d_User_Password && errors.d_User_Password.type === "required" && (<span id="Err_msg">User Password cannot be blank</span>)}
                    {errors.d_User_Password && errors.d_User_Password.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                </FormGroup>
            </div>
            }
        </form>
    )
}

interface UserMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function UserMastMain({ gsb }: UserMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as UserMast[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as UserMast)
    const [BlankAc, setBlankAc] = useState({} as UserMast)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankAc.d_Userid = ""
    BlankAc.d_User_Password = ""


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("UserMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("UserMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("UserMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("UserMastMain disp GsbFlag: ", GsbFlag)
                //console.log("UserMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("UserMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("UserMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("UserMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("UserMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])





    const PrevRecProcess = async (UserMast: UserMast) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: UserMast, cPgmId: "UserMast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (UserMast: UserMast) => {
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: UserMast, cPgmId: "UserMast", cAction: "DelRec"})
            showSuccess("User ID " + UserMast.d_Userid + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (UserMast: UserMast) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: UserMast, cPgmId: "UserMast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (UserMast: UserMast) => {
        try {
            const res = await gsb.post('/Master', {sb_data: UserMast, cPgmId: "UserMast", cAction: "AddRec"})
            showSuccess("User ID " + UserMast.d_Userid + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (UserMast: UserMast) => {
        try {
            console.log("call editOnSave", UserMast)
            const res = await gsb.post('/Master', {sb_data: UserMast, cPgmId: "UserMast", cAction: "UpdateRec"})
            showSuccess("User ID " + UserMast.d_Userid + " record saved")
            
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                        },
                    },
                    cPgmId: "UserMast",
                    cAction: "ListRec",
                });

                console.log("ListRec res.data.count", res.data.count)
                console.log("ListRec res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
                setshowButton(false)
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_Userid: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "UserMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                    setshowButton(false)
                } else {
                    alert(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }


    //Press page No 1 2 3 4
    useEffect(() => {
        if (nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                            },
                            },
                        cPgmId: "UserMast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
                    setshowButton(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <UserMastView gsb={gsb} UserMast={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></UserMastView>
    switch (currentMode) {
        case "edit":
            detail = <UserMastEdit gsb={gsb} UserMast={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></UserMastEdit>
            break
        case "new":
            detail = <UserMastEdit gsb={gsb} UserMast={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></UserMastEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
let list = <>
    <div className="Row">
        <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
    </div>
    <div className="Spacing-V-16" />
    {ShowWait && <div>{imgWait}</div> }
    {nPage > 0 && Pagination}
    <HTMLTable striped interactive condensed>
        <thead>
            <th className="TCol-UserId">User ID</th>
            <th className="TCol-UserName">User Name</th>
        </thead>
        <tbody id="ma_tr">
            {data.map((UserMast, i) =>
                <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                    <td className="TCol-UserId" onClick={() => { setCurrentAc(UserMast) ; MoblieDev && setSelectedTabId("detail") }}>{UserMast.d_Userid}</td>
                    <td className="TCol-UserName" onClick={() => { setCurrentAc(UserMast) ; MoblieDev && setSelectedTabId("detail") }}>{UserMast.d_User_Name}</td>
                </tr>
            )}
        </tbody>
    </HTMLTable>
    {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
    {nPage > 0 && Pagination}
</>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader> 

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>User Master Setup</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="Userid">User ID</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-2 Button-LabeledFormGroup" icon="search" type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-8-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        {/*
                        <div className="Row">
                            <FormGroup className="Col3-1-2" label="Verified Email" labelFor="searchVerifiedEmail">
                                <select  id="searchVerifiedEmail" value={searchVerifiedEmail} onChange={utils.handleStringChange(s => setsearchVerifiedEmail(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>
                        </div>
                        */}
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    <br></br>
                    <p>Notes</p>
                    <div>
                        <div>The password is case-sensitive</div>
                        <div>This new create user may be CANNOT login old GSB</div>
                        <div>If you want to login old GSB, Plase use All UPPER case letter(s)</div>
                    </div >
                 </Card>
            </form>
        </>
    );
}

export default UserMastMain