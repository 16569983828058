import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, ProgressBar } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import svgWait1 from './svg/wait1.svg'
import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { SystemHeader, ClientPicker } from './CommonUI';
import i18next from 'i18next';

import { EmailConfig } from './Types'


interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}

var GsbFlag: any;


interface EmailServiceSetupFormProps {
    gsb: AxiosInstance,
}


function EmailServiceSetupForm({ gsb }: EmailServiceSetupFormProps) {

    const { t, i18n } = useTranslation();

    const { register, reset, setError, errors } = useForm<EmailConfig>()

    const [currentEmailRec, setcurrentEmailRec] = useState({} as EmailConfig)
    const [LastEmailRec, setLastEmailRec] = useState({} as EmailConfig)
    const [BlankEmailRec, setBlankEmailRec] = useState({} as EmailConfig)

    const [Email_OwnValue, setEmail_OwnValue] = useState(BlankEmailRec.x_EMAIL_OWN)
    const [Email_SvrValue, setEmail_SvrValue] = useState(BlankEmailRec.x_EMAIL_SVR)
    const [Email_Svr2Value, setEmail_Svr2Value] = useState(BlankEmailRec.x_EMAIL_SVR2)
    const [Email_Svr3Value, setEmail_Svr3Value] = useState(BlankEmailRec.x_EMAIL_SVR3)
    const [Email_Svr4Value, setEmail_Svr4Value] = useState(BlankEmailRec.x_EMAIL_SVR4)
    const [Email_AUserValue, setEmail_AUserValue] = useState(BlankEmailRec.x_EMAIL_AUSR)
    const [Email_AUser2Value, setEmail_AUser2Value] = useState(BlankEmailRec.x_EMAIL_AUSR2)
    const [Email_AUser3Value, setEmail_AUser3Value] = useState(BlankEmailRec.x_EMAIL_AUSR3)
    const [Email_AUser4Value, setEmail_AUser4Value] = useState(BlankEmailRec.x_EMAIL_AUSR4)
    const [Email_APasswdValue, setEmail_APasswdValue] = useState(BlankEmailRec.x_EMAIL_APWD)
    const [Email_APasswd2Value, setEmail_APasswd2Value] = useState(BlankEmailRec.x_EMAIL_APWD2)
    const [Email_APasswd3Value, setEmail_APasswd3Value] = useState(BlankEmailRec.x_EMAIL_APWD3)
    const [Email_APasswd4Value, setEmail_APasswd4Value] = useState(BlankEmailRec.x_EMAIL_APWD4)
    const [M_PortValue, setM_PortValue] = useState(BlankEmailRec.x_M_PORT)
    const [Email_AttachedValue, setEmail_AttachedValue] = useState(BlankEmailRec.x_EMAIL_ATH)
    const [Send_Stmt_AEValue, setSend_Stmt_AEValue] = useState(BlankEmailRec.x_SSTMT2AE)


    const [isEmailSetupNew, setisEmailSetupNew] = useState(false)
    const [isEmailSetupEdit, setisEmailSetupEdit] = useState(false)
    const [isEmailSetupDeletePress, setisEmailSetupDeletePress] = useState(false)

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

    //EmailConfig default value
    BlankEmailRec.x_EMAIL_OWN = ""
    BlankEmailRec.x_EMAIL_SVR = ""
    BlankEmailRec.x_EMAIL_SVR2 = ""
    BlankEmailRec.x_EMAIL_SVR3 = ""
    BlankEmailRec.x_EMAIL_SVR4 = ""
    BlankEmailRec.x_EMAIL_AUSR = ""
    BlankEmailRec.x_EMAIL_AUSR2 = ""
    BlankEmailRec.x_EMAIL_AUSR3 = ""
    BlankEmailRec.x_EMAIL_AUSR4 = ""
    BlankEmailRec.x_EMAIL_APWD = ""
    BlankEmailRec.x_EMAIL_APWD2 = ""
    BlankEmailRec.x_EMAIL_APWD3 = ""
    BlankEmailRec.x_EMAIL_APWD4 = ""
    BlankEmailRec.x_M_PORT = "25"
    BlankEmailRec.x_EMAIL_ATH = ""
    BlankEmailRec.x_SSTMT2AE = "N"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "EmailSetup", cAction: "GetEmailSetupInfo"})
                console.log("EmailServiceSetupForm EmailConfig res.data.data : ", res.data.data) 
                if ( res.data.count > 0 )
                {
                    setcurrentEmailRec(res.data.data[0])
                    setLastEmailRec(res.data.data[0])
                } else {
                    setcurrentEmailRec(BlankEmailRec)
                    setLastEmailRec(BlankEmailRec)
                }
        
                } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb])

    useEffect(() => { 
        reset(currentEmailRec);
        setEmail_OwnValue(currentEmailRec.x_EMAIL_OWN)
        setEmail_SvrValue(currentEmailRec.x_EMAIL_SVR)
        setEmail_Svr2Value(currentEmailRec.x_EMAIL_SVR2)
        setEmail_Svr3Value(currentEmailRec.x_EMAIL_SVR3)
        setEmail_Svr4Value(currentEmailRec.x_EMAIL_SVR4)
        setEmail_AUserValue(currentEmailRec.x_EMAIL_AUSR)
        setEmail_AUser2Value(currentEmailRec.x_EMAIL_AUSR2)
        setEmail_AUser3Value(currentEmailRec.x_EMAIL_AUSR3)
        setEmail_AUser4Value(currentEmailRec.x_EMAIL_AUSR4)
        setEmail_APasswdValue(currentEmailRec.x_EMAIL_APWD)
        setEmail_APasswd2Value(currentEmailRec.x_EMAIL_APWD2)
        setEmail_APasswd3Value(currentEmailRec.x_EMAIL_APWD3)
        setEmail_APasswd4Value(currentEmailRec.x_EMAIL_APWD4)
        setM_PortValue(currentEmailRec.x_M_PORT)
        setEmail_AttachedValue(currentEmailRec.x_EMAIL_ATH)
        setSend_Stmt_AEValue(currentEmailRec.x_SSTMT2AE)
    }, [gsb, currentEmailRec.x_EMAIL_OWN, isEmailSetupEdit])
    

    const onEmailSetupDelete = ( ) => {
    }

    const onEmailSetupSave1 = ( ) => {
        currentEmailRec.x_EMAIL_OWN = Email_OwnValue
        currentEmailRec.x_EMAIL_SVR = Email_SvrValue
        currentEmailRec.x_EMAIL_SVR2 = Email_Svr2Value
        currentEmailRec.x_EMAIL_SVR3 = Email_Svr3Value
        currentEmailRec.x_EMAIL_SVR4 = Email_Svr4Value
        currentEmailRec.x_EMAIL_AUSR = Email_AUserValue
        currentEmailRec.x_EMAIL_AUSR2 = Email_AUser2Value
        currentEmailRec.x_EMAIL_AUSR3 = Email_AUser3Value
        currentEmailRec.x_EMAIL_AUSR4 = Email_AUser4Value
        currentEmailRec.x_EMAIL_APWD = Email_APasswdValue
        currentEmailRec.x_EMAIL_APWD2 = Email_APasswd2Value
        currentEmailRec.x_EMAIL_APWD3 = Email_APasswd3Value
        currentEmailRec.x_EMAIL_APWD4 = Email_APasswd4Value
        currentEmailRec.x_M_PORT = M_PortValue
        currentEmailRec.x_EMAIL_ATH = Email_AttachedValue
        currentEmailRec.x_SSTMT2AE = Send_Stmt_AEValue

        ;
        (async () => {
            try {
                //console.log("onForeignCurrencySave1 currentCB", currentCB)
                const res = await gsb.post('/Master', {sb_data: currentEmailRec, cPgmId: "EmailSetup", cAction: "EmailSetupValidate", isNew: isEmailSetupNew})
                //console.log("onForeignCurrencySave1 CbValidate res.data.data", res.data.data)
                ;
                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    setisEmailSetupEdit(true)
                    return
                }

                onEmailSetupSave2(currentEmailRec)

            } catch (error) {
                showErrorBox(error)
            }
        })()
    }

    const onEmailSetupSave2 = (EmailSetupdata: EmailConfig) => {
        (async () => {
            try {
                //console.log("onForeignCurrencySave2 CBdata", CBdata)
                var res1_data = {} as EmailConfig
                if ( isEmailSetupNew ) {
                    const res = await gsb.post('/Master', {sb_data: EmailSetupdata, cPgmId: "EmailSetup", cAction: "AddRec"})
                    res1_data = res.data.data[0]
                } else {
                    const res = await gsb.post('/Master', {sb_data: EmailSetupdata, cPgmId: "EmailSetup", cAction: "UpdateRec"})
                    res1_data = res.data.data[0]
                }
                //console.log("onForeignCurrencySave2 save res1.data.data", res1_data)
                setcurrentEmailRec(res1_data)
                setLastEmailRec(res1_data)
                setisEmailSetupEdit(false)

                utils.showSuccess("Email Service record saved")

                setisShowAlert1(true)
                setShowAlert1Msg("Email Service record saved")

            } catch (error) {
                //utils.showError(error)
                showErrorBox(error)
            }
        })()
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    return (
        <form>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >            
                <div><H3>Email Service Setup</H3></div>
                { ! isEmailSetupEdit && <div>
                    <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => {} } >Prev</Button>
                    <Button className="Col-2-1" disabled={true} icon="add" onClick={() => { setcurrentEmailRec(BlankEmailRec); setisEmailSetupNew(true) ; setisEmailSetupEdit(true) } } >New</Button>
                    <Button className="Col-3-1" disabled={false} icon="edit" onClick={() => { setisEmailSetupNew(false) ; setisEmailSetupEdit(true) } } >Edit</Button>
                    <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => {} } >Delete</Button>
                        <Alert
                            cancelButtonText="Cancel"
                            confirmButtonText="Delete"
                            canEscapeKeyCancel={true}
                            icon="trash"
                            intent={Intent.DANGER}
                            isOpen={isEmailSetupDeletePress}
                            onCancel={ () => setisEmailSetupDeletePress(false) }
                            onConfirm={ () => { onEmailSetupDelete(); setisEmailSetupDeletePress(false) } }
                        >
                            <p>
                                Are you sure to Delete ?
                            </p>
                        </Alert>
                    <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => {} } >Next</Button>
                </div> }
                { isEmailSetupEdit && <div>
                    <Button className="Col-1-1" icon="cross" onClick={() => { setcurrentEmailRec(LastEmailRec); setisEmailSetupEdit(false) } }>Cancel</Button>
                    <Button className="Col-2-1" icon="tick" onClick={() => { onEmailSetupSave1() } }>Save</Button>
                </div> }
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-4" label="Company E-mail Address">
                        <InputGroup readOnly value={Email_OwnValue} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-4" label="Company E-mail Address">
                        <InputGroup id="inputCoEmailAddress" value={Email_OwnValue} autoFocus={true} placeholder="Company E-mail Address ..." onChange={utils.handleStringChange(s => setEmail_OwnValue(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_OWN && errors.x_EMAIL_OWN.types && <span id="Err_msg">{errors.x_EMAIL_OWN.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 1">
                        <InputGroup readOnly value={Email_SvrValue} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 1">
                        <InputGroup id="inputEmailSvr" value={Email_SvrValue} placeholder="ISP e-mail Server ..." onChange={utils.handleStringChange(s => setEmail_SvrValue(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_SVR && errors.x_EMAIL_SVR.types && <span id="Err_msg">{errors.x_EMAIL_SVR.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 1">
                        <InputGroup readOnly value={Email_AUserValue} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 1">
                        <InputGroup id="inputAUser" value={Email_AUserValue} placeholder="Authentication User ..." onChange={utils.handleStringChange(s => setEmail_AUserValue(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_AUSR && errors.x_EMAIL_AUSR.types && <span id="Err_msg">{errors.x_EMAIL_AUSR.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 1">
                        <InputGroup readOnly value={Email_APasswdValue} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 1">
                        <InputGroup id="inputAPwd" value={Email_APasswdValue} placeholder="Password ..." onChange={utils.handleStringChange(s => setEmail_APasswdValue(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_APWD && errors.x_EMAIL_APWD.types && <span id="Err_msg">{errors.x_EMAIL_APWD.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 2">
                        <InputGroup readOnly value={Email_Svr2Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 2">
                        <InputGroup id="inputEmailSvr" value={Email_Svr2Value} placeholder="ISP e-mail Server ..." onChange={utils.handleStringChange(s => setEmail_Svr2Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_SVR2 && errors.x_EMAIL_SVR2.types && <span id="Err_msg">{errors.x_EMAIL_SVR2.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 2">
                        <InputGroup readOnly value={Email_AUser2Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 2">
                        <InputGroup id="inputAUser" value={Email_AUser2Value} placeholder="Authentication User ..." onChange={utils.handleStringChange(s => setEmail_AUser2Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_AUSR2 && errors.x_EMAIL_AUSR2.types && <span id="Err_msg">{errors.x_EMAIL_AUSR2.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 2">
                        <InputGroup readOnly value={Email_APasswd2Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 2">
                        <InputGroup id="inputAPwd" value={Email_APasswd2Value} placeholder="Password ..." onChange={utils.handleStringChange(s => setEmail_APasswd2Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_APWD2 && errors.x_EMAIL_APWD2.types && <span id="Err_msg">{errors.x_EMAIL_APWD2.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 3">
                        <InputGroup readOnly value={Email_Svr3Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 3">
                        <InputGroup id="inputEmailSvr" value={Email_Svr3Value} placeholder="ISP e-mail Server ..." onChange={utils.handleStringChange(s => setEmail_Svr3Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_SVR3 && errors.x_EMAIL_SVR3.types && <span id="Err_msg">{errors.x_EMAIL_SVR3.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 3">
                        <InputGroup readOnly value={Email_AUser3Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 3">
                        <InputGroup id="inputAUser" value={Email_AUser3Value} placeholder="Authentication User ..." onChange={utils.handleStringChange(s => setEmail_AUser3Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_AUSR3 && errors.x_EMAIL_AUSR3.types && <span id="Err_msg">{errors.x_EMAIL_AUSR3.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 3">
                        <InputGroup readOnly value={Email_APasswd3Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 3">
                        <InputGroup id="inputAPwd" value={Email_APasswd3Value} placeholder="Password ..." onChange={utils.handleStringChange(s => setEmail_APasswd3Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_APWD3 && errors.x_EMAIL_APWD3.types && <span id="Err_msg">{errors.x_EMAIL_APWD3.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 4">
                        <InputGroup readOnly value={Email_Svr4Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-4" label="ISP e-mail Server 4">
                        <InputGroup id="inputEmailSvr" value={Email_Svr4Value} placeholder="ISP e-mail Server ..." onChange={utils.handleStringChange(s => setEmail_Svr4Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_SVR4 && errors.x_EMAIL_SVR4.types && <span id="Err_msg">{errors.x_EMAIL_SVR4.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 4">
                        <InputGroup readOnly value={Email_AUser4Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-5-2" label="Authentication User 4">
                        <InputGroup id="inputAUser" value={Email_AUser4Value} placeholder="Authentication User ..." onChange={utils.handleStringChange(s => setEmail_AUser4Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_AUSR4 && errors.x_EMAIL_AUSR4.types && <span id="Err_msg">{errors.x_EMAIL_AUSR4.types.message}</span>}
                    </FormGroup> }

                    { ! isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 4">
                        <InputGroup readOnly value={Email_APasswd4Value} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-7-2" label="Password 4">
                        <InputGroup id="inputAPwd" value={Email_APasswd4Value} placeholder="Password ..." onChange={utils.handleStringChange(s => setEmail_APasswd4Value(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_APWD4 && errors.x_EMAIL_APWD4.types && <span id="Err_msg">{errors.x_EMAIL_APWD4.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-4" label="Port">
                        <InputGroup readOnly value={M_PortValue} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-4" label="Port">
                        <InputGroup id="inputPort" defaultValue={M_PortValue} value={M_PortValue} onChange={utils.handleStringChange(n => setM_PortValue(n))} />
                        {errors.x_M_PORT && errors.x_M_PORT.type === "min" && <span id="Err_msg">Port sholud be greater than 0 </span>}
                        {errors.x_M_PORT && errors.x_M_PORT.types && <span id="Err_msg">{errors.x_M_PORT.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-4" label="Additional attachment">
                        <InputGroup readOnly value={Email_AttachedValue} />
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-4" label="Additional attachment">
                        <InputGroup id="inputEmail_Attached" value={Email_AttachedValue} autoFocus={true} placeholder="Additional attachment ..." onChange={utils.handleStringChange(s => setEmail_AttachedValue(s)) } inputRef={register({ }) } />
                        {errors.x_EMAIL_ATH && errors.x_EMAIL_ATH.types && <span id="Err_msg">{errors.x_EMAIL_ATH.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    { ! isEmailSetupEdit && <FormGroup className="Col-1-2" label="Send Statement to AE" labelFor="inputSendStmtAE">
                        <RadioGroup
                            name="x_SSTMT2AE"
                            disabled={true}
                            inline={true}
                            selectedValue={Send_Stmt_AEValue}
                            onChange={(s) => { }}
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                    </FormGroup> }
                    { isEmailSetupEdit && <FormGroup className="Col-1-2" label="Send Statement to AE" labelFor="inputSendStmtAE">
                        <RadioGroup
                            name="x_SSTMT2AE"
                            inline={true}
                            selectedValue={Send_Stmt_AEValue}
                            onChange={utils.handleStringChange(s => setSend_Stmt_AEValue(s)) }
                            ref={register}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        {errors.x_SSTMT2AE && errors.x_SSTMT2AE.types && <span id="Err_msg">{errors.x_SSTMT2AE.types.message}</span>}
                    </FormGroup> }
                </div>
                <div className="Spacing-V-16" />
            </Card>
            <div className="Spacing-V-16"></div>
            <Alert
                confirmButtonText="OK"
                icon="tick-circle"
                intent={Intent.SUCCESS}
                isOpen={isShowAlert1}
                onCancel={ () => setisShowAlert1(false) }
                onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
            >
                <p>
                    {ShowAlert1Msg}
                </p>
            </Alert>
            <Alert
                confirmButtonText="OK"
                icon="cross"
                intent={Intent.WARNING}
                isOpen={isShowAlert2}
                onCancel={ () => setisShowAlert2(false) }
                onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
            >
                <p>
                    {ShowAlert2Msg}
                </p>
            </Alert>
        </form>
    )

}

interface SentStatementMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function SentStatementMain({ gsb }: SentStatementMainProps) {

    let ProgressId2 = ""
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
        

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)

 
    const [s_SentStatement, sets_SentStatement] = useState("D")

    const [isEmailSetupPress, setisEmailSetupPress] = useState(false)

    const [DataFormatValue, setDataFormatValue] = useState(false)
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")


    //alert(navigator.userAgent)

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
    

    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "isMonthEndDay",
                });
                if ( res.data.ok ) {
                    //console.log("isMonthEndDay", res.data.data, res.data.data[0].isMonthEndDay)
                    if ( res.data.data[0].isMonthEndDay ) {
                        sets_SentStatement("B")
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        setShowRpt(false)
        setShowNoRecord(false)
        setShowOK(true)
    }, [s_SentStatement, DataFormatValue])


    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            //console.log("Progresslist ProgressId: ", ProgressId2)
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 10*1000)
        return () => {clearInterval(timer)}
    }, [gsb, ProgressId ])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()


        setShowOK(false)
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptName("")
        setRptNameNo(0)
        setnPage(1)
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0)
        ;
        //console.log("StkHoldMain onsubmit ProgressId: ", ProgressId2) 

    
        (async () => {
            try {
                //console.log("SmMastMain onsubmit called", data)
                const res = await gsb.post('/Report',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_SentStatement: s_SentStatement,
                            DataFormat: DataFormatValue,
                            nPage: 1,
                            cPrintMode: "PS",
                        },
                        cPgmId: "SentStatement",
                    });

                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    //console.log("res.data.TotalPageNo: ", res.data.TotalPageNo) 
                    setRptHead1(res.data.Rpthead1)
                    setRptName(res.data.RptName)
                    setRptNameNo(res.data.RptNameNo)
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(res.data.TotalPageNo)
                        for ( let j = 1; j <= res.data.TotalPageNo; j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }
                    }
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    //Press page No 1 2 3 4
    useEffect(() => {
        if (nPage > 0 && nPage <= nTotalPage && RptName.trim().length > 0) {
            // console.log("calling API for Pagination");
            setShowWait(true)
            setShowNoRecord(false)
            setShowRpt(false)
            setRstdata([])

            ProgressId2 = utils.genUUID()
            setProgressId(ProgressId2)
            setProgressValue(0)

            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Report',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_SentStatement: s_SentStatement,
                            s_RptName: RptName,
                            DataFormat: DataFormatValue,
                            nPage: nPage,
                            cPrintMode: "S",
                        },
                        cPgmId: "SentStatement",
                    });

                    //console.log("res.data.data: ", res.data.data) 
                    if ( res.data.count > 0 ) {
                        setRstdata2([])
                        for (let j = 0; j < res.data.Rptdata.length; j++) {
                            Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                        }
                        setShowWait(false)
                        setShowNoRecord(false)
                        setShowRpt(true)
                        setRstdata(Rstdata2)
                        //console.log("Rstdata: ", Rstdata) 
                    } else {
                        setShowWait(false)
                        setShowNoRecord(true)
                        setShowRpt(false)
                    }
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let RptNameaRef = <>
        { RptNameNo > 0 && <div> Download / Print &nbsp;
            {aRptNameNo.map((no, j) => 
                <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}-${no.n}.pdf`}> <span id="RPageNo2" onClick={() => {} }> {no.n} &nbsp; </span> </a>
            )}
        </div> }
        { RptNameNo <= 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>}
    </>


    let list = <>
        <div className="Spacing-V-16" />
        {RptName.trim().length > 0 && RptNameaRef}
        {ShowRpt && ! DataFormatValue && Pagination}
        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    { ! DataFormatValue && <th className="RCol-Head_p">
                        {RptName.trim().length > 0 && RptNameaRef}
                    </th>
                    }
                </tr>                    
                <tr>
                    <td className="RCol-Head1">{RptHead1}</td>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                {Rstdata.map((rpt, i) =>
                    <tr key={i} onDoubleClick={() => { {rpt.Rst_Key.trim().length > 0 && window.open(`/#/AccInfo?acCode=${rpt.Rst_Key}`, '_blank') } } }>
                        <td className="RCol-Rpt" onClick={() => { MoblieDev && rpt.Rst_Key.trim().length > 0 && window.open(`/#/AccInfo?acCode=${rpt.Rst_Key}`, '_blank') } }>{rpt.Rst_Line}</td>
                    </tr>
                )}
            </tbody>
            <div className="Spacing-V-16" />
            {ShowRpt && ! DataFormatValue && Pagination}
        </HTMLTable>
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Send Email Statment</H3>
                    <div className="Row">
                        <Button className="Col-9-2" icon="document" onClick={() => { setisEmailSetupPress(true) } } >Email Service Setup</Button>
                    </div>
                    <Dialog
                        icon={IconNames.EDIT} 
                        title = "Email Service Setup"
                        isOpen={isEmailSetupPress}
                        onClose={ () => { setisEmailSetupPress(false) } }
                        style={{ minWidth: '80.0%' }}
                    >
                        <DialogBody>
                        <EmailServiceSetupForm gsb={gsb} ></EmailServiceSetupForm> 
                        </DialogBody>
                    </Dialog>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/*
                        <FormGroup className="Col3-1-1" label="Send Statement" labelFor="inputAcStatus">
                            <select  id="inputAcStatus" value={s_SentStatement} onChange={utils.handleStringChange(s => sets_SentStatement(s))}>
                                <option value="B">Both</option>
                                <option value="D">Daily Statement</option>
                                <option value="M">Monthly Statement</option>                                
                            </select>
                        </FormGroup>
                        */}

                        <FormGroup className="Col-1-4" label="Send Statement">
                        <RadioGroup
                            inline={true}
                            selectedValue={s_SentStatement}
                            onChange={utils.handleStringChange(s => sets_SentStatement(s)) }
                        >
                            <Radio label="Both"  value="B"/>
                            <Radio label="Daily Statement"  value="D"/>
                            <Radio label="Monthly Statement"  value="M"/>
                        </RadioGroup>
                     </FormGroup> 


                    </div>
                    <div className="Row">
                        <Button disabled={! ShowOK} type="submit">Send</Button>
                    </div>
                </Card>
                {/* {ShowWait && <div>{imgWait}</div> } */}
                {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
                {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }
                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">No Record sent !!!</span>
                        </FormGroup>
                    </div>
                </p>
                }
                {ShowRpt && list}
                <div>
                    <div className="Spacing-V-16"></div>
                    <div >Note : </div>
                    <div >Email folder is SBEMAILx </div>
                    <div >Daily Statement content is DSTMT.html </div>
                    <div >Monthly Statement content is MSTMT.html </div>
                    <div >popSign.jpg is Company Logo </div>
                </div>
            </form>
        </>
    );
}

export default SentStatementMain